import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'

interface ICogsSummaryNodeType {
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
  endingInventory: number
}

interface ICogsSummaryType {
  cogsSummary: {
    nodes: ICogsSummaryNodeType[]
  }
}

const query = gql`
  query cogsSummary($iLocationId: Int!, $iStartDate: Date!, $iEndDate: Date!) {
    cogsSummary(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
        endingInventory
      }
    }
  }
`

const useBbbFoodCost = (skip: boolean) => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { data, loading } = useQuery<ICogsSummaryType>(query, {
    variables: {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      const cogsData = data.cogsSummary?.nodes[0]
      const percent = cogsData?.endingInventory / cogsData?.totalNetSales
      const message = (() => {
        if (percent >= 0.5) return 'higher than 50%'
        if (percent >= 0.4) return 'higher than 40%'
        if (percent >= 0.3) return 'higher than 30%'
        return null
      })()

      return {
        title: 'Food Cost',
        total:
          cogsData?.annualOpportunityCost > 0
            ? cogsData?.annualOpportunityCost
            : null,
        detail: message
          ? `${locationName}\`s ending Inventory % of Net Sales is ${message}.`
          : `${locationName} is doing well with its ending inventory`,
      }
    }, [locationName, data]),
    loading,
  }
}

export default useBbbFoodCost
