import { useMemo } from 'react'

import { acePopConfig } from './configs/ace'
import { bibibopConfig } from './configs/bibibop'
import { defaultConfig } from './configs/default'
import { demoBkConfig } from './configs/demo_bk'
import { ghaiPopConfig } from './configs/ghai_pop'
import { irmgBkConfig } from './configs/irmg_bk'
import { jitbConfig } from './configs/jitb'
import { jscBkConfig } from './configs/jsc_bk'
import { mbnBkConfig } from './configs/mbn_bk'
import { mwbConfig } from './configs/mwb'
import { sandBoxBkConfig } from './configs/sandbox_bk'
import { timoneyBkConfig } from './configs/timoney_bk'
import { vikingBkConfig } from './configs/viking_bk'
import { wingitnorthConfig } from './configs/wingitnorth'

export interface IBasicKpiType {
  category: 'basic'
  key:
    | 'cashOverOrShort'
    | 'totalComps'
    | 'inaccurateCashierShiftCount'
    | 'inaccurateCashierShiftPercent'
    | 'oloOverOrShort'
    | 'compsPercent'
  title: string
}

export interface ILossPreventionItemsType {
  category: 'custom'
  type: string
  title: string
  key: 'amount' | 'count' | 'checkPercent'
}

export type IKpiSettingType = IBasicKpiType | ILossPreventionItemsType

export interface ICashHandlingColumnType {
  key: string
  title: string
  valueType?: 'count' | 'price' | 'percent' | 'detail'
  width?: string
  sorter?: boolean
  children?: ICashHandlingColumnType[]
}

export interface ILossPreventionConfig {
  summary: {
    isVisible: boolean
  }
  kpiSettings: IKpiSettingType[]

  discountByEmployeeTable?: {
    display: boolean
  }

  cashHandlingTable?: {
    display: boolean
    columns: ICashHandlingColumnType[]
  }

  voidsAndDeletesTable?: {
    display: boolean
    columns: ICashHandlingColumnType[]
  }

  opsCompsBreakdownTable?: { display: boolean }
}

const useLossPreventionConfig = (brand: string): ILossPreventionConfig =>
  useMemo(() => {
    switch (brand) {
      case 'viking_bk':
        return vikingBkConfig
      case 'timoney_bk':
        return timoneyBkConfig
      case 'irmg_bk':
      case 'irmg_pop':
      case 'supreme_bk':
      case 'supreme_pop':
        return irmgBkConfig
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return jscBkConfig
      case 'sandbox_bk':
        return sandBoxBkConfig
      case 'mbn_bk':
        return mbnBkConfig
      case 'demo_bk':
        return demoBkConfig
      case 'ghai_pop':
        return ghaiPopConfig
      case 'jitb':
        return jitbConfig
      case 'wingitnorth':
        return wingitnorthConfig
      case 'bibibop':
        return bibibopConfig
      case 'mwb':
        return mwbConfig
      case 'ace_pop':
        return acePopConfig
      default:
        return defaultConfig
    }
  }, [brand])

export default useLossPreventionConfig
