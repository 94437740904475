import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'

import { IApiDataType } from '../../types'

interface IGuestSummaryNodeType {
  problemPercentage: number
  mostCommonProblem?: string[]
  mostCommonDay?: string[]
}

interface IGuestSummaryType {
  locationGuestSummaryV2: {
    nodes: IGuestSummaryNodeType[]
  }
}

const query = gql`
  query LocationGuestSummaryV2(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    locationGuestSummaryV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        problemPercentage
        mostCommonProblem
        mostCommonDay
      }
    }
  }
`

const useGuest = (skip: boolean): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { data, loading } = useQuery<IGuestSummaryType>(query, {
    variables: {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      const guestData = data.locationGuestSummaryV2.nodes[0]

      return {
        title: 'Guest',
        total: null,
        detail:
          !guestData || !guestData.mostCommonProblem || !guestData.mostCommonDay
            ? 'Great job!  None of your OSAT responses had a problem!'
            : `${locationName}'s top problems are: ${
                guestData.mostCommonProblem.join(', ') || ''
              }. ${
                guestData.mostCommonDay.map((day) => `${day}s`).join(', ') || ''
              } have the most negative reviews.  Address these issues with your team.
          `,
      }
    }, [locationName, data]),
    loading,
  }
}

export default useGuest
