import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IDataType {
  trendLocationGroupMetricValues: {
    nodes: {
      startDate: string
      endDate: string
      metricData: Record<
        'total_sales',
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
}

const query = gql`
  query trendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_month"
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        startDate
        endDate
        metricData
      }
    }
  }
`

export const bbbAllPeriodMetricConfigs = {
  period: 'string',
  '<%- JSON(date?.yearIds.map(id => ({ key: "compSalesPercent" + id, label: id }))) %>':
    'percent',
} as const

const useBbbAllPeriodMetric = () => {
  const { variables } = useVariables()
  const periodCalendar = variables.date?.periodCalendar || [[]]
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: periodCalendar.slice(-1)[0]?.[0]?.dateRange.startDateStr,
      iEndDate: periodCalendar[0]?.slice(-1)[0]?.dateRange.endDateStr,
      iLocationGroupIds: [variables.allStores?.locationGroupId],
      iMetricCodes: ['total_sales'],
    },
    skip: !variables.date || !variables.allStores,
  })

  return {
    data: useMemo((): IApiDataType => {
      const metricData = data?.trendLocationGroupMetricValues.nodes

      if (!metricData) return null

      const periods = periodCalendar.flat()
      const metricPeriods = metricData.reduce(
        (result, data, index, allData) => {
          const period = periods.find(
            (t) =>
              t.dateRange.startDateStr === data.startDate &&
              t.dateRange.endDateStr === data.endDate,
          )

          if (!period || index === 0) return result

          return {
            ...result,
            [period.id]: {
              ...result[period.id],
              [`compSalesPercent${period.year}`]:
                ((data.metricData.total_sales.value -
                  allData[index - 1].metricData.total_sales.value) *
                  100) /
                allData[index - 1].metricData.total_sales.value,
            },
          }
        },
        {} as Record<string, Record<string, number>>,
      )

      return Object.keys(metricPeriods)
        .map((period) => ({
          ...metricPeriods[period],
          period,
        }))
        .sort(
          (a, b) =>
            parseInt(a.period.replace(/P/, ''), 10) -
            parseInt(b.period.replace(/P/, ''), 10),
        )
    }, [data, periodCalendar]),
    loading,
  }
}

export default useBbbAllPeriodMetric
