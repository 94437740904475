import FormControl from '@material-ui/core/FormControl'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useContext, useEffect, useLayoutEffect, useMemo } from 'react'
import styled from 'styled-components'

import { AdvancedFilterContext } from './AdvancedFilterProvider'
import useApi, { IApiKeyType } from './hooks/useApi'
import { IOptionsType } from './types'

export type IPropsType =
  | {
      selectKey: string
      label?: string
      options: IOptionsType
    }
  | {
      selectKey: string
      label?: string
      api: IApiKeyType
    }

const StyledFormControl = styled(FormControl)`
  min-width: 150px;
`

const StyledSelect = styled(Select)`
  border-radius: 0;
  font-family: Lexend-Regular;
`

const StyledMenuItem = styled(MenuItem)`
  font-family: Lexend-Regular;
`

const AdvancedSelect = ({ selectKey, label, ...props }: IPropsType) => {
  const { data } = useApi('api' in props ? props.api : null)
  const options = ('options' in props ? props.options : data) as IOptionsType
  const [advancedState, setAdvancedState] = useContext(AdvancedFilterContext)

  useEffect(() => {
    if (advancedState.type === 'init')
      setAdvancedState({
        type: 'select',
        key: selectKey,
        value: options[0].value,
      })
  }, [selectKey, options, advancedState, setAdvancedState])

  return (
    <>
      {!label ? null : <div>{label}</div>}

      <StyledFormControl variant="outlined" margin="dense">
        <StyledSelect
          value={
            advancedState.type !== 'select'
              ? options[0].value || ''
              : advancedState.value[selectKey] || ''
          }
          onChange={(e) => {
            const value = e.target.value as string

            if (!value) return

            setAdvancedState({
              type: 'select',
              key: selectKey,
              value,
            })
          }}
        >
          {options.map((option) => {
            if ('type' in option) {
              return (
                <ListSubheader key={option.label}>{option.label}</ListSubheader>
              )
            }

            return (
              <StyledMenuItem key={option.value} value={option.value}>
                {option.label}
              </StyledMenuItem>
            )
          })}
        </StyledSelect>
      </StyledFormControl>
    </>
  )
}

export default AdvancedSelect
