import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

const getLocalFavoriteConfig = <K>(keys: (keyof K)[]) => {
  const kpis = {
    totalSales: {
      name: 'Total Sales',
      tableType: 'sales',
      salesType: 'total',
      kpiKey: 'totalSales',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 0,
    },
    appetizerSales: {
      name: 'Appetizer Sales',
      tableType: 'sales',
      salesType: 'appetizer',
      kpiKey: 'totalSales',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 0,
    },
    lbwSales: {
      name: 'LBW Sales',
      tableType: 'sales',
      salesType: 'lbw',
      kpiKey: 'totalSales',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 0,
    },
    dessertSales: {
      name: 'Dessert Sales',
      tableType: 'sales',
      salesType: 'dessert',
      kpiKey: 'totalSales',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 0,
    },
    addOnSales: {
      name: 'AddOn Sales',
      tableType: 'sales',
      salesType: 'addOn',
      kpiKey: 'totalSales',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 0,
    },
    naBeverageSales: {
      name: 'NA Beverage Sales',
      tableType: 'sales',
      salesType: 'naBeverage',
      kpiKey: 'totalSales',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 0,
    },
    sidesSales: {
      name: 'Sides Sales',
      tableType: 'sales',
      salesType: 'sides',
      kpiKey: 'totalSales',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 0,
    },
    shakeSales: {
      name: 'Shake Sales',
      tableType: 'sales',
      salesType: 'shake',
      kpiKey: 'totalSales',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 0,
    },
    totalPpa: {
      name: 'Total PPA',
      tableType: 'sales',
      salesType: 'total',
      kpiKey: 'ppa',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 2,
    },
    appetizerPpa: {
      name: 'Appetizer PPA',
      tableType: 'sales',
      salesType: 'appetizer',
      kpiKey: 'ppa',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 2,
    },
    lbwPpa: {
      name: 'LBW PPA',
      tableType: 'sales',
      salesType: 'lbw',
      kpiKey: 'ppa',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 2,
    },
    dessertPpa: {
      name: 'Dessert PPA',
      tableType: 'sales',
      salesType: 'dessert',
      kpiKey: 'ppa',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 2,
    },
    addOnPpa: {
      name: 'AddOn PPA',
      tableType: 'sales',
      salesType: 'addOn',
      kpiKey: 'ppa',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 2,
    },
    naBeveragePpa: {
      name: 'NA Beverage PPA',
      tableType: 'sales',
      salesType: 'naBeverage',
      kpiKey: 'ppa',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 2,
    },
    sidesPpa: {
      name: 'Sides PPA',
      tableType: 'sales',
      salesType: 'sides',
      kpiKey: 'ppa',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 2,
    },
    shakePpa: {
      name: 'Shake PPA',
      tableType: 'sales',
      salesType: 'shake',
      kpiKey: 'ppa',
      valueType: 'cent',
      orderBy: 'desc',
      digitNum: 2,
    },
  } as K

  return {
    kpis: keys.reduce(
      (result, key) => ({
        ...result,
        [key]: kpis[key],
      }),
      {},
    ),
    defaultKpi: 'totalSales',
  }
}

const useStaffSalesTableConfig = (): any => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'cava':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckCount: {
              name: 'Total Check Count',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkCount',
              valueType: 'count',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckAverage: {
              name: 'Total Check Average',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            totalPpa: {
              name: 'Total PPA',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'ppa',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            entreePremiumSales: {
              name: 'Entree Premium',
              tableType: 'sales',
              salesType: 'Entree Premium',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            entreePremiumIncidents: {
              name: 'Entree Premium Incidents',
              tableType: 'sales',
              salesType: 'Entree Premium',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            entreePremiumPpa: {
              name: 'Entree Premium PPA',
              tableType: 'sales',
              salesType: 'Entree Premium',
              kpiKey: 'ppa',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            curatedSales: {
              name: 'Curated Sales',
              tableType: 'sales',
              salesType: 'Curated',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            curatedIncidents: {
              name: 'Curated Incidents',
              tableType: 'sales',
              salesType: 'Curated',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            curatedPpa: {
              name: 'Curated PPA',
              tableType: 'sales',
              salesType: 'Curated',
              kpiKey: 'ppa',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sideSales: {
              name: 'Side Sales',
              tableType: 'sales',
              salesType: 'Side',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sideAttachmentRate: {
              name: 'Side Attachment Rate',
              tableType: 'sales',
              salesType: 'Side',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sidePpa: {
              name: 'Side PPA',
              tableType: 'sales',
              salesType: 'Side',
              kpiKey: 'ppa',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertSales: {
              name: 'Dessert Sales',
              tableType: 'sales',
              salesType: 'Dessert',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertAttachmentRate: {
              name: 'Dessert Attachment Rate',
              tableType: 'sales',
              salesType: 'Dessert',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertPpa: {
              name: 'Dessert PPA',
              tableType: 'sales',
              salesType: 'Dessert',
              kpiKey: 'ppa',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            beverageSales: {
              name: 'Beverage Sales',
              tableType: 'sales',
              salesType: 'Beverage',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            beverageAttachmentRate: {
              name: 'Beverage Attachment Rate',
              tableType: 'sales',
              salesType: 'Beverage',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            beveragePpa: {
              name: 'Beverage PPA',
              tableType: 'sales',
              salesType: 'Beverage',
              kpiKey: 'ppa',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
          },
          defaultKpi: 'totalSales',
        }

      case 'irmg_bk':
      case 'viking_bk':
      case 'sandbox_bk':
      case 'supreme_bk':
      case 'timoney_bk':
      case 'mbn_bk':
      case 'demo_bk':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckCount: {
              name: 'Total Check Count',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkCount',
              valueType: 'count',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckAverage: {
              name: 'Total Check Average',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            baconSales: {
              name: 'Bacon Sales',
              tableType: 'sales',
              salesType: 'Bacon',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            baconAttachmentRate: {
              name: 'Bacon Attachment Rate',
              tableType: 'sales',
              salesType: 'Bacon',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cheeseSales: {
              name: 'Cheese Sales',
              tableType: 'sales',
              salesType: 'Cheese',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cheeseAttachmentRate: {
              name: 'Cheese Attachment Rate',
              tableType: 'sales',
              salesType: 'Cheese',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            treatSales: {
              name: 'Treat Sales',
              tableType: 'sales',
              salesType: 'Treat',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            treatAttachmentRate: {
              name: 'Treat Attachment Rate',
              tableType: 'sales',
              salesType: 'Treat',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            wrapSales: {
              name: 'Wrap Sales',
              tableType: 'sales',
              salesType: 'Wrap',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            wrapCheckAverage: {
              name: 'Wrap Check Average',
              tableType: 'sales',
              salesType: 'Wrap',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            wrapIncidents: {
              name: 'Wrap Incidents',
              tableType: 'sales',
              salesType: 'Wrap',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 1,
            },
            upsizePercent: {
              name: 'Upsize %',
              tableType: 'upsize',
              upsizeType: 'VM Upsize',
              kpiKey: 'upsizePercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 1,
            },
          },
          defaultKpi: 'totalSales',
        }

      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckCount: {
              name: 'Total Check Count',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkCount',
              valueType: 'count',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckAverage: {
              name: 'Total Check Average',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            baconSales: {
              name: 'Bacon Sales',
              tableType: 'sales',
              salesType: 'Bacon',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            baconAttachmentRate: {
              name: 'Bacon Attachment Rate',
              tableType: 'sales',
              salesType: 'Bacon',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cheeseSales: {
              name: 'Cheese Sales',
              tableType: 'sales',
              salesType: 'Cheese',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cheeseAttachmentRate: {
              name: 'Cheese Attachment Rate',
              tableType: 'sales',
              salesType: 'Cheese',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            treatSales: {
              name: 'Treat Sales',
              tableType: 'sales',
              salesType: 'Treat',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            treatAttachmentRate: {
              name: 'Treat Attachment Rate',
              tableType: 'sales',
              salesType: 'Treat',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            shakeSales: {
              name: 'Shake Sales',
              tableType: 'sales',
              salesType: 'Shake',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            shakeAttachmentRate: {
              name: 'Shake Attachment Rate',
              tableType: 'sales',
              salesType: 'Shake',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            beverageSales: {
              name: 'Beverage Sales',
              tableType: 'sales',
              salesType: 'Beverage',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            beverageAttachmentRate: {
              name: 'Beverage Attachment Rate',
              tableType: 'sales',
              salesType: 'Beverage',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            upsizePercent: {
              name: 'Upsize %',
              tableType: 'upsize',
              upsizeType: 'VM Upsize',
              kpiKey: 'upsizePercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 1,
            },
          },
          defaultKpi: 'totalSales',
        }

      case 'irmg_pop':
      case 'ghai_pop':
      case 'demo_pop':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckCount: {
              name: 'Total Check Count',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkCount',
              valueType: 'count',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckAverage: {
              name: 'Total Check Average',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sideSales: {
              name: 'Side Sales',
              tableType: 'sales',
              salesType: 'Side',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sideAttachmentRate: {
              name: 'Side Attachment Rate',
              tableType: 'sales',
              salesType: 'Side',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            drinkSales: {
              name: 'Drink Sales',
              tableType: 'sales',
              salesType: 'Drink',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            drinkAttachmentRate: {
              name: 'Drink Attachment Rate',
              tableType: 'sales',
              salesType: 'Drink',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertSales: {
              name: 'Dessert Sales',
              tableType: 'sales',
              salesType: 'Dessert',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertAttachmentRate: {
              name: 'Dessert Attachment Rate',
              tableType: 'sales',
              salesType: 'Dessert',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            ltoSales: {
              name: 'LTO Sales',
              tableType: 'sales',
              salesType: 'LTO',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            ltoCheckAverage: {
              name: 'LTO Check Average',
              tableType: 'sales',
              salesType: 'LTO',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            ltoIncidents: {
              name: 'LTO Incidents',
              tableType: 'sales',
              salesType: 'LTO',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 1,
            },
          },
          defaultKpi: 'totalSales',
        }

      case 'supreme_pop':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckCount: {
              name: 'Total Check Count',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkCount',
              valueType: 'count',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckAverage: {
              name: 'Total Check Average',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            wingSales: {
              name: 'Wing Sales',
              tableType: 'sales',
              salesType: 'Wing',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            wingAttachmentRate: {
              name: 'Wing Attachment Rate',
              tableType: 'sales',
              salesType: 'Wing',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sideSales: {
              name: 'Side Sales',
              tableType: 'sales',
              salesType: 'Side',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sideAttachmentRate: {
              name: 'Side Attachment Rate',
              tableType: 'sales',
              salesType: 'Side',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            drinkSales: {
              name: 'Drink Sales',
              tableType: 'sales',
              salesType: 'Drink',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            drinkAttachmentRate: {
              name: 'Drink Attachment Rate',
              tableType: 'sales',
              salesType: 'Drink',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertSales: {
              name: 'Dessert Sales',
              tableType: 'sales',
              salesType: 'Dessert',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertAttachmentRate: {
              name: 'Dessert Attachment Rate',
              tableType: 'sales',
              salesType: 'Dessert',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            ltoSales: {
              name: 'LTO Sales',
              tableType: 'sales',
              salesType: 'LTO',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            ltoCheckAverage: {
              name: 'LTO Check Average',
              tableType: 'sales',
              salesType: 'LTO',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            ltoIncidents: {
              name: 'LTO Incidents',
              tableType: 'sales',
              salesType: 'LTO',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 1,
            },
          },
          defaultKpi: 'totalSales',
        }

      case 'wingitnorth':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckCount: {
              name: 'Total Check Count',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkCount',
              valueType: 'count',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckAverage: {
              name: 'Total Check Average',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cornSales: {
              name: 'Corn Sales',
              tableType: 'sales',
              salesType: 'CORN',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cornAttachmentRate: {
              name: 'Corn Attachment Rate',
              tableType: 'sales',
              salesType: 'CORN',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            waterSales: {
              name: 'Bottled Water Sales',
              tableType: 'sales',
              salesType: 'WATER',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            waterAttachmentRate: {
              name: 'Bottled Water Attachment Rate',
              tableType: 'sales',
              salesType: 'WATER',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            saucesSales: {
              name: 'Sauces Sales',
              tableType: 'sales',
              salesType: 'SIDE SAUCES / DIPS',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            saucesAttachmentRate: {
              name: 'Sauces Attachment Rate',
              tableType: 'sales',
              salesType: 'SIDE SAUCES / DIPS',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sticksSales: {
              name: 'Veggie Sticks Sales',
              tableType: 'sales',
              salesType: 'STICKS',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sticksAttachmentRate: {
              name: 'Veggie Sticks Attachment Rate',
              tableType: 'sales',
              salesType: 'STICKS',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
          },
          defaultKpi: 'totalSales',
        }

      case 'wingstop':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            friesSales: {
              name: 'Fries Sales',
              tableType: 'sales',
              salesType: 'fries',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            voodofriesSales: {
              name: 'Voodoo Fries Sales',
              tableType: 'sales',
              salesType: 'voodooFries',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            buffaloFriesSales: {
              name: 'Buffalo Fries Sales',
              tableType: 'sales',
              salesType: 'buffaloFries',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            cornSales: {
              name: 'Corn Sales',
              tableType: 'sales',
              salesType: 'corn',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            brownieSales: {
              name: 'Brownie Sales',
              tableType: 'sales',
              salesType: 'brownies',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
          },
          defaultKpi: 'totalSales',
        }
      case 'jitb':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckCount: {
              name: 'Total Check Count',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkCount',
              valueType: 'count',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckAverage: {
              name: 'Total Check Average',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            baconSales: {
              name: 'Bacon Sales',
              tableType: 'sales',
              salesType: 'Bacon',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            baconAttachmentRate: {
              name: 'Bacon Attachment Rate',
              tableType: 'sales',
              salesType: 'Bacon',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cheeseSales: {
              name: 'Cheese Sales',
              tableType: 'sales',
              salesType: 'Cheese',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cheeseAttachmentRate: {
              name: 'Cheese Attachment Rate',
              tableType: 'sales',
              salesType: 'Cheese',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            treatSales: {
              name: 'Treat Sales',
              tableType: 'sales',
              salesType: 'Treat',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            treatAttachmentRate: {
              name: 'Treat Attachment Rate',
              tableType: 'sales',
              salesType: 'Treat',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            upsizePercent: {
              name: 'Upsize %',
              tableType: 'upsize',
              upsizeType: 'VM Upsize',
              kpiKey: 'upsizePercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 1,
            },
          },
          defaultKpi: 'totalSales',
        }
      case 'mwb':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckCount: {
              name: 'Total Check Count',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkCount',
              valueType: 'count',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckAverage: {
              name: 'Total Check Average',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertSales: {
              name: 'Dessert Sales',
              tableType: 'sales',
              salesType: 'Desserts',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertAttachmentRate: {
              name: 'Dessert Attachment Rate',
              tableType: 'sales',
              salesType: 'Desserts',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            beverageSales: {
              name: 'Beverage Sales',
              tableType: 'sales',
              salesType: 'Beverages',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            beverageAttachmentRate: {
              name: 'Beverage Attachment Rate',
              tableType: 'sales',
              salesType: 'Beverages',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            baconSales: {
              name: 'Bacon Sales',
              tableType: 'sales',
              salesType: 'Bacon',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            BaconAttachmentRate: {
              name: 'Bacon Attachment Rate',
              tableType: 'sales',
              salesType: 'Bacon',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cheeseSales: {
              name: 'Cheese Sales',
              tableType: 'sales',
              salesType: 'Cheese',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            cheeseAttachmentRate: {
              name: 'Cheese Attachment Rate',
              tableType: 'sales',
              salesType: 'Cheese',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            jalapenoSales: {
              name: 'Jalapeno Sales',
              tableType: 'sales',
              salesType: 'Jalapeno',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            jalapenoAttachmentRate: {
              name: 'Jalapeno Attachment Rate',
              tableType: 'sales',
              salesType: 'Jalapeno',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
          },
          defaultKpi: 'totalSales',
        }
      // Local Favorite
      case 'lfr_ef':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',
          'sidesSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_tr':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'naBeverageSales',
          'sidesSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_sn':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',
          'sidesSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_lv':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',
          'sidesSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_vb':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',
          'sidesSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_tu':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
        ])
      case 'lfr_ws':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
        ])
      case 'lfr_jn':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
        ])
      case 'lfr_mm':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',
          'sidesSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_dt':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',
          'sidesSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_uv':
        return getLocalFavoriteConfig([
          'totalSales',
          'appetizerSales',
          'lbwSales',
          'dessertSales',
          'naBeverageSales',
          'sidesSales',

          'totalPpa',
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'primanti':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            appetizersAndSidesAttachmentRate: {
              name: 'Appetizers & Sides Attachment Rate',
              tableType: 'sales',
              salesType: 'appetizers & sides',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            beverageAttachmentRate: {
              name: 'Beverage Attachment Rate',
              tableType: 'sales',
              salesType: 'beverage',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            friesAttachmentRate: {
              name: 'Fries Attachment Rate',
              tableType: 'sales',
              salesType: 'fries',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            lbwAttachmentRate: {
              name: 'LBW Attachment Rate',
              tableType: 'sales',
              salesType: 'lbw',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },

            appetizersAndSidesSales: {
              name: 'Appetizers & Sides Sales',
              tableType: 'sales',
              salesType: 'appetizers & sides',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            beveragesSales: {
              name: 'Beverage Sales',
              tableType: 'sales',
              salesType: 'beverage',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            friesSales: {
              name: 'Fries Sales',
              tableType: 'sales',
              salesType: 'fries',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            lbwSales: {
              name: 'LBW Sales',
              tableType: 'sales',
              salesType: 'lbw',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
          },
          defaultKpi: 'totalSales',
        }
      case 'ace_pop':
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckCount: {
              name: 'Total Check Count',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkCount',
              valueType: 'count',
              orderBy: 'desc',
              digitNum: 0,
            },
            totalCheckAverage: {
              name: 'Total Check Average',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'checkAvg',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            wingSales: {
              name: 'Wing Sales',
              tableType: 'sales',
              salesType: 'Wing',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            wingAttachmentRate: {
              name: 'Wing Attachment Rate',
              tableType: 'sales',
              salesType: 'Wing',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sideSales: {
              name: 'Side Sales',
              tableType: 'sales',
              salesType: 'Side',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            sideAttachmentRate: {
              name: 'Side Attachment Rate',
              tableType: 'sales',
              salesType: 'Side',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            drinkSales: {
              name: 'Drink Sales',
              tableType: 'sales',
              salesType: 'Drink',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            drinkAttachmentRate: {
              name: 'Drink Attachment Rate',
              tableType: 'sales',
              salesType: 'Drink',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertSales: {
              name: 'Dessert Sales',
              tableType: 'sales',
              salesType: 'Dessert',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 2,
            },
            dessertAttachmentRate: {
              name: 'Dessert Attachment Rate',
              tableType: 'sales',
              salesType: 'Dessert',
              kpiKey: 'checkPercent',
              valueType: 'percent',
              orderBy: 'desc',
              digitNum: 2,
            },
          },
          defaultKpi: 'totalSales',
        }
      default:
        return {
          kpis: {
            totalSales: {
              name: 'Total Sales',
              tableType: 'sales',
              salesType: 'total',
              kpiKey: 'totalSales',
              valueType: 'cent',
              orderBy: 'desc',
              digitNum: 0,
            },
          },
          defaultKpi: 'totalSales',
        }
    }
  }, [brand])
}

export default useStaffSalesTableConfig
