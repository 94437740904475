import { useMemo } from 'react'

import { TYPE_THIS_WEEK } from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

const useConfig = () => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      default:
        return { preSelectedDateRange: TYPE_THIS_WEEK }
    }
  }, [brand])
}

export default useConfig
