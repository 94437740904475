import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query bibibopListLocationKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        financialScore
        offPremiseScore
        guestScore
        totalScore
        overallRank
        sales
        eightWeekSalesAvg
        salesPercent
        foodAndPaperPercent
        foodAndPaperBudgetPercent
        foodAndPaperPercentVariance
        laborPercent
        laborBudgetPercent
        laborPercentVariance
        cfPercent
        cfBudgetPercent
        cfPercentVariance
        checkCount
        eightWeekCheckCountAvg
        checkCountPercent
        checkAverage
        eightWeekCheckAverageAvg
        checkAveragePercent
        sixtyTwentyTwo
        sixtyTwentyTwoGoal
        sixtyTwentyTwoVariance
        sixtyTwentyTwoScore
        starsGoal
        complaintsGoal
        digital
        eightWeekDigitalAvg
        digitalPercent
        digitalScore
        catering
        eightWeekCateringAvg
        cateringPercent
        cateringScore
        salesScore
        foodAndPaperScore
        laborScore
        cfScore
        checkCountScore
        checkAverageScore
      }
    }
  }
`

export const bibibopListLocationKpisConfigs = {
  financialScore: 'number',
  offPremiseScore: 'number',
  guestScore: 'number',
  totalScore: 'number',
  overallRank: 'number',
  sales: 'price',
  eightWeekSalesAvg: 'price',
  salesPercent: 'percent',
  salesScore: 'number',
  foodAndPaperPercent: 'percent',
  foodAndPaperBudgetPercent: 'percent',
  foodAndPaperPercentVariance: 'percent',
  foodAndPaperScore: 'number',
  laborPercent: 'percent',
  laborBudgetPercent: 'percent',
  laborPercentVariance: 'percent',
  laborScore: 'number',
  cfPercent: 'percent',
  cfBudgetPercent: 'percent',
  cfPercentVariance: 'percent',
  cfScore: 'number',
  digital: 'percent',
  eightWeekDigitalAvg: 'percent',
  digitalPercent: 'percent',
  digitalScore: 'number',
  catering: 'percent',
  eightWeekCateringAvg: 'percent',
  cateringPercent: 'percent',
  cateringScore: 'number',
  checkCount: 'number',
  eightWeekCheckCountAvg: 'number',
  checkCountPercent: 'percent',
  checkCountScore: 'number',
  checkAverage: 'price',
  eightWeekCheckAverageAvg: 'price',
  checkAveragePercent: 'percent',
  checkAverageScore: 'number',
  sixtyTwentyTwo: 'percent',
  sixtyTwentyTwoGoal: 'percent',
  sixtyTwentyTwoVariance: 'percent',
  sixtyTwentyTwoScore: 'number',
  stars: 'number',
  starsGoal: 'number',
  starsVariance: 'number',
  starsScore: 'percent',
  complaints: 'percent',
  complaintsGoal: 'percent',
  complaintsVariance: 'percent',
  complaintsScore: 'percent',
} as const

const useBibibopListLocationKpis = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.ids },
    },
    skip: !startDate || !endDate || !groupFilter || !hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const listLocationKpis = data?.listLocationKpis.nodes as
        | {
            locationId: number
          }[]
        | undefined

      if (!listLocationKpis) return null

      return {
        source: listLocationKpis.map((n: { locationId: number }) => {
          const groupInfo = groupFilter?.list?.find(
            (l) => l.id === n.locationId,
          )

          return {
            ...n,
            groupInfo,
          }
        }),
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useBibibopListLocationKpis
