import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_ID, BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType, ISelectDataType } from '../types'

interface IQueryDataType {
  listDirectors: {
    nodes: {
      employeeId: number
      locationGroupId: number
      firstName: string
      lastName: string
    }[]
  }
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
      }[]
    }[]
  }
  getLocationAccessGroupsForBrand: {
    nodes: {
      id: number
      name: string
      type: string
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids: [number]
    brand_ids: [number]
  }
  iBrandId: number
}

const query = gql`
  query corporateGroupFilter($iFilter: JSON!, $iBrandId: Int!) {
    listDirectors(iFilter: $iFilter) {
      nodes {
        employeeId
        locationGroupId
        firstName
        lastName
      }
    }

    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }

    getLocationAccessGroupsForBrand(iBrandId: $iBrandId) {
      nodes {
        id
        name
        type
      }
    }
  }
`

const LOCAITON_GROUP_TYPES: Record<string, string[]> = {
  bibibop: [
    'Comp or Non-Comp',
    'District Manager',
    'Region',
    'Market',
    'Multi-Unit Manager',
  ],
}
const DISABLE_GROUP_BY_LINK = ['ghai_pop', 'bibibop', 'fw_wingstop']

const useCorporateGroupFilter = () => {
  const { brand } = useBrands()
  const directorLabel = getBusinessLabel('director')
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        iFilter: {
          location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
          brand_ids: [BRAND_ID[brand]],
        },
        iBrandId: BRAND_ID[brand],
      },
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const locationAccessGroupsForBrand = (
        data?.getLocationAccessGroupsForBrand?.nodes || []
      ).filter(
        (d) => d.type !== 'Brand' && d.type !== 'Custom' && d.type !== 'null',
      )
      // FIXME: should use locationAccessGroupsForBrand for all brands
      const locationGroups =
        locationAccessGroupsForBrand.length !== 0
          ? locationAccessGroupsForBrand.filter(
              (l) => LOCAITON_GROUP_TYPES[brand]?.includes(l.type) ?? true,
            )
          : data.listDirectors.nodes.map((director) => ({
              id: director.locationGroupId,
              name: `${director.firstName} ${director.lastName}`,
              type: directorLabel,
            }))
      const locationGroupTypes = locationGroups.reduce(
        (result, { type }) =>
          result.includes(type) ? result : [...result, type],
        [] as string[],
      )

      const allStores = data.listLocationDetails.nodes.map((l) => {
        const director = data.listDirectors.nodes.find((d) =>
          l.locationGroups.some((lg) => lg.id === d.locationGroupId),
        )
        const locationGroup = locationGroups.find(
          (lg) => lg.id === director?.locationGroupId,
        )

        return {
          id: l.id,
          name: `${l.code} - ${l.name}`,
          header: 'Stores',
          link: `/${brand}/store_detail?store=${l.id}`,
          groupBy: director && {
            id: director.employeeId,
            header: locationGroup?.type || directorLabel,
            name: `${director.firstName} ${director.lastName}`,
            link:
              !DISABLE_GROUP_BY_LINK.includes(brand) &&
              `/${brand}/employee_profile/${director.employeeId}`,
          },
        }
      })

      const values = [
        {
          id: 'all-stores',
          parentId: 'root',
          ids: [BRAND_LOCATION_GROUP_ID[brand]],
          label: 'All Stores',
          list: allStores,
        },
        ...locationGroupTypes.map((locationGroupType) => [
          {
            id: locationGroupType,
            parentId: 'root',
            label: locationGroupType,
          },
          {
            id: `${locationGroupType} Breakdown`,
            parentId: locationGroupType,
            label: `Breakdown By ${locationGroupType}`,
            ids: locationGroups
              .filter((l) => l.type === locationGroupType)
              .map((d) => d.id),
            list: locationGroups
              .filter((l) => l.type === locationGroupType)
              .map((d) => {
                const director = data.listDirectors.nodes.find(
                  (l) => l.locationGroupId === d.id,
                )

                return {
                  id: d.id,
                  name: d.name,
                  header: d.type,
                  link:
                    director &&
                    !DISABLE_GROUP_BY_LINK.includes(brand) &&
                    `/${brand}/employee_profile/${director.employeeId}`,
                }
              }),
          },
        ]),
        locationGroups.map(({ id, type, name }) => ({
          id: id.toString(),
          parentId: type,
          ids: [id],
          label: name,
          list: data.listLocationDetails.nodes
            .filter((l) => l.locationGroups.some((lg) => lg.id === id))
            .map((l) => {
              const director = data.listDirectors.nodes.find((d) =>
                l.locationGroups.some((lg) => lg.id === d.locationGroupId),
              )
              const locationGroup = locationGroups.find(
                (lg) => lg.id === director?.locationGroupId,
              )

              return {
                id: l.id,
                name: `${l.code} - ${l.name}`,
                header: 'Stores',
                link: `/${brand}/store_detail?store=${l.id}`,
                groupBy: director && {
                  id: director.employeeId,
                  header: locationGroup?.type || directorLabel,
                  name: `${director.firstName} ${director.lastName}`,
                  link:
                    !DISABLE_GROUP_BY_LINK.includes(brand) &&
                    `/${brand}/employee_profile/${director.employeeId}`,
                },
              }
            }),
        })),
      ].flat()

      return {
        values:
          locationGroupTypes.length !== 1
            ? values
            : values
                .filter(({ id }) => id !== locationGroupTypes[0])
                .map(({ parentId, ...value }) => ({
                  ...value,
                  parentId:
                    parentId === locationGroupTypes[0] ? 'root' : parentId,
                })),
        defaultValue: [values[0].id],
      }
    }, [data, brand, directorLabel]),
    loading,
  }
}

export default useCorporateGroupFilter
