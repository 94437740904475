import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'
import {
  cavaKpiDefs,
  defaultKpiDefs,
  irmgBkKpiDefs,
  irmgPopKpiDefs,
  jitbKpiDefs,
  mwbKpiDefs,
} from 'pared/customer/salesmanshipKpiDefs'
import { getBrand } from 'pared/utils/brand'

type Section =
  | 'plusMinusTable'
  | 'productivityCoefficient'
  | 'leaderboards'
  | 'staffSalesTable'
  | 'salesmanshipTrendChart'
  | 'upsizeTrendChart'
  | 'entreesPerLaborHour'
  | 'salesPerLaborHour'
  | 'speedOfService'
  | 'schedulingAnalysis'
  | 'speedOfServiceDayPart'
  | 'employeeShifts'

type LeaderboardSection =
  | 'sales'
  | 'ppa'
  | 'salesPerLaborHour'
  | 'upsize'
  | 'lto'
  | 'wraps'
  | 'lbw'

interface Config {
  sections: Section[]
  leaderboardSections: LeaderboardSection[]
  ppaLabel?: string
  salesmanshipTrendChart?: any
  plusMinusTable?: {
    isMockUp: boolean
  }
  staffSales?: {
    hide?: boolean
    roleNameOverride?: string
  }
  speedOfService?: {
    isVisible: boolean
    sosChartKpi: { type: string; displayName: string } | null
  }
  salesPerLaborHour?: {
    isSummaryHidden?: boolean
  }
}

const defaultConfig: Config = {
  sections: ['leaderboards', 'staffSalesTable'],
  leaderboardSections: ['sales', 'ppa', 'salesPerLaborHour'],
  speedOfService: {
    isVisible: false,
    sosChartKpi: null,
  },
}

const irmgBkConfig: Config = {
  sections: [
    'salesPerLaborHour',
    'leaderboards',
    'staffSalesTable',
    'salesmanshipTrendChart',
    'upsizeTrendChart',
  ],
  leaderboardSections: ['sales', 'salesPerLaborHour', 'upsize', 'wraps'],
  salesmanshipTrendChart: {
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgBkKpiDefs.baconAttachmentRate,
        color: COLORS.Plum,
        hidden: true,
      },
      {
        ...irmgBkKpiDefs.cheeseAttachmentRate,
        color: COLORS.Mango,
        hidden: true,
      },
      { ...irmgBkKpiDefs.treatAttachmentRate, color: COLORS.Basil },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const jscBkConfig: Config = {
  sections: [
    'salesPerLaborHour',
    'leaderboards',
    'staffSalesTable',
    'salesmanshipTrendChart',
    'upsizeTrendChart',
  ],
  leaderboardSections: ['sales', 'salesPerLaborHour', 'upsize', 'lto'],
  salesmanshipTrendChart: {
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgBkKpiDefs.baconAttachmentRate,
        color: COLORS.Plum,
        hidden: true,
      },
      {
        ...irmgBkKpiDefs.cheeseAttachmentRate,
        color: COLORS.Mango,
        hidden: true,
      },
      { ...irmgBkKpiDefs.treatAttachmentRate, color: COLORS.Basil },
      {
        ...irmgBkKpiDefs.beverageAttachmentRate,
        color: COLORS.DarkOrchid,
        hidden: true,
      },
      {
        ...irmgBkKpiDefs.shakeAttachmentRate,
        color: COLORS.Acai,
        hidden: true,
      },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const demoBkConfig: Config = {
  sections: [
    'plusMinusTable',
    'salesPerLaborHour',
    'leaderboards',
    'staffSalesTable',
    'salesmanshipTrendChart',
    'upsizeTrendChart',
  ],
  leaderboardSections: ['sales', 'salesPerLaborHour', 'upsize', 'wraps'],
  plusMinusTable: {
    isMockUp: true,
  },
  salesmanshipTrendChart: {
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgBkKpiDefs.baconAttachmentRate,
        color: COLORS.Plum,
        hidden: true,
      },
      {
        ...irmgBkKpiDefs.cheeseAttachmentRate,
        color: COLORS.Mango,
        hidden: true,
      },
      { ...irmgBkKpiDefs.treatAttachmentRate, color: COLORS.Basil },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const irmgPopConfig: Config = {
  ...defaultConfig,
  sections: ['salesPerLaborHour', 'leaderboards', 'staffSalesTable'],
  leaderboardSections: ['sales', 'salesPerLaborHour', 'lto'],
  salesmanshipTrendChart: {
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgPopKpiDefs.comboRate,
        color: COLORS.Plum,
      },
      {
        ...irmgPopKpiDefs.drinkRate,
        color: COLORS.Acai,
      },
      { ...irmgPopKpiDefs.dessertRate, color: COLORS.Mango },

      { ...irmgPopKpiDefs.sideRate, color: COLORS.DarkOrchid },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const supremePopConfig: Config = {
  ...defaultConfig,
  sections: [
    'salesPerLaborHour',
    'leaderboards',
    'staffSalesTable',
    'speedOfService',
  ],
  leaderboardSections: ['sales', 'salesPerLaborHour', 'lto'],
  salesmanshipTrendChart: {
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgPopKpiDefs.comboRate,
        color: COLORS.Plum,
      },
      {
        ...irmgPopKpiDefs.drinkRate,
        color: COLORS.Acai,
      },
      { ...irmgPopKpiDefs.dessertRate, color: COLORS.Mango },

      { ...irmgPopKpiDefs.sideRate, color: COLORS.DarkOrchid },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
  speedOfService: {
    isVisible: true,
    sosChartKpi: { type: 'Pickup Window', displayName: 'Pickup' },
  },
}

const ghaiPopConfig: Config = {
  sections: [
    'salesPerLaborHour',
    'schedulingAnalysis',
    'leaderboards',
    'staffSalesTable',
    'salesmanshipTrendChart',
    'upsizeTrendChart',
    'speedOfService',
  ],
  staffSales: { hide: true },
  leaderboardSections: [],
  salesmanshipTrendChart: {
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgPopKpiDefs.drinkRate,
        color: COLORS.Acai,
      },
      { ...irmgPopKpiDefs.dessertRate, color: COLORS.Mango },

      { ...irmgPopKpiDefs.sideRate, color: COLORS.DarkOrchid },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
  speedOfService: {
    isVisible: false,
    sosChartKpi: null,
  },
}

const cavaConfig: Config = {
  sections: [
    'entreesPerLaborHour',
    'salesmanshipTrendChart',
    'leaderboards',
    'staffSalesTable',
  ],
  leaderboardSections: ['sales', 'ppa'],
  ppaLabel: 'Non-Digital PPA',
  salesmanshipTrendChart: {
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...cavaKpiDefs.sideAttachmentRate,
        color: COLORS.Plum,
        hidden: true,
      },
      {
        ...cavaKpiDefs.dessertAttachmentRate,
        color: COLORS.Mango,
        hidden: true,
      },
      { ...cavaKpiDefs.beverageAttachmentRate, color: COLORS.Basil },
    ],
    yRightAxisLabel: 'Non-Digital PPA',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: {
      ...defaultKpiDefs.totalPpa,
      name: 'Non-Digital PPA',
      color: COLORS.Pomodoro,
    },
  },
}

const jitbConfig: Config = {
  sections: ['salesPerLaborHour', 'salesmanshipTrendChart', 'upsizeTrendChart'],
  leaderboardSections: [],
  salesmanshipTrendChart: {
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...jitbKpiDefs.baconAttachmentRate,
        color: COLORS.Plum,
        hidden: true,
      },
      {
        ...jitbKpiDefs.cheeseAttachmentRate,
        color: COLORS.Mango,
        hidden: true,
      },
      { ...jitbKpiDefs.beverageAttachmentRate, color: COLORS.Basil },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const wingstopConfig: Config = {
  sections: ['leaderboards', 'staffSalesTable'],
  leaderboardSections: defaultConfig.leaderboardSections,
  staffSales: {
    roleNameOverride: 'Team Member',
  },
}

const wingitnorthConfig: Config = {
  sections: ['salesPerLaborHour', 'leaderboards', 'staffSalesTable'],
  leaderboardSections: ['sales', 'salesPerLaborHour'],
}

const acePopConfig: Config = {
  ...defaultConfig,
  sections: [
    'salesPerLaborHour',
    'leaderboards',
    'staffSalesTable',
    'speedOfService',
  ],
  leaderboardSections: ['sales', 'salesPerLaborHour'],
  salesmanshipTrendChart: {
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgPopKpiDefs.comboRate,
        color: COLORS.Plum,
      },
      {
        ...irmgPopKpiDefs.drinkRate,
        color: COLORS.Acai,
      },
      { ...irmgPopKpiDefs.dessertRate, color: COLORS.Mango },

      { ...irmgPopKpiDefs.sideRate, color: COLORS.DarkOrchid },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
  speedOfService: {
    isVisible: true,
    sosChartKpi: { type: 'Pickup Window', displayName: 'Pickup' },
  },
}

const useSalesmanshipConfig = (): Config => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'mbn_bk':
      case 'sandbox_bk':
        return {
          ...irmgBkConfig,
          speedOfService: {
            isVisible: true,
            sosChartKpi: { type: 'Pickup Window', displayName: 'Pickup' },
          },
          sections: [
            'salesPerLaborHour',
            'leaderboards',
            'staffSalesTable',
            'salesmanshipTrendChart',
            'upsizeTrendChart',
            'speedOfService',
          ],
        }
      case 'timoney_bk':
        return {
          ...irmgBkConfig,
          speedOfService: {
            isVisible: true,
            sosChartKpi: { type: 'Pickup Window', displayName: 'Pickup' },
          },
          sections: [
            'salesmanshipTrendChart',
            'upsizeTrendChart',
            'speedOfService',
          ],
        }
      case 'supreme_bk':
        return {
          ...irmgBkConfig,
          speedOfService: {
            isVisible: true,
            sosChartKpi: { type: 'Pickup Window', displayName: 'Pickup' },
          },
          sections: [
            'salesPerLaborHour',
            'leaderboards',
            'staffSalesTable',
            'salesmanshipTrendChart',
            'upsizeTrendChart',
            'speedOfServiceDayPart',
          ],
        }
      case 'irmg_bk':
        return irmgBkConfig
      case 'viking_bk':
        return {
          ...irmgBkConfig,
          salesPerLaborHour: {
            isSummaryHidden: true,
          },
        }
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          ...jscBkConfig,
          speedOfService: {
            isVisible: true,
            sosChartKpi: {
              type: 'Pickup Window',
              displayName: 'Window Time (seconds)',
            },
          },
          sections: [
            'salesPerLaborHour',
            'leaderboards',
            'staffSalesTable',
            'salesmanshipTrendChart',
            'upsizeTrendChart',
            'speedOfService',
          ],
        }
      case 'ghai_pop':
        return {
          ...ghaiPopConfig,
          speedOfService: {
            isVisible: true,
            sosChartKpi: {
              type: 'Pickup Window',
              displayName: 'Window Time (seconds)',
            },
          },
        }
      case 'supreme_pop':
        return supremePopConfig
      case 'demo_bk':
        return demoBkConfig
      case 'irmg_pop':
      case 'demo_pop':
        return irmgPopConfig
      case 'wingitnorth':
        return wingitnorthConfig
      case 'wingstop':
        return wingstopConfig
      case 'cava':
        return cavaConfig
      case 'jitb':
        return jitbConfig
      case 'wingitnorth':
        return wingitnorthConfig
      case 'primanti':
        return {
          ...defaultConfig,
          leaderboardSections: ['sales', 'lbw'],
        }
      case 'bibibop':
        return {
          ...jscBkConfig,
          sections: ['salesPerLaborHour'],
        }
      case 'mwb':
        return {
          ...defaultConfig,
          sections: [
            'salesPerLaborHour',
            'employeeShifts',
            'leaderboards',
            'staffSalesTable',
            'salesmanshipTrendChart',
            'upsizeTrendChart',
          ],
          leaderboardSections: ['sales', 'ppa', 'upsize'],
          salesmanshipTrendChart: {
            yLeftAxisLabel: 'Attachment Rate %',
            yLeftAxisValueType: 'percent',
            yLeftAxisKpiDefs: [
              {
                ...mwbKpiDefs.dessertsAttachmentRate,
                color: COLORS.Plum,
              },
              {
                ...mwbKpiDefs.beveragesAttachmentRate,
                color: COLORS.Mango,
              },
              { ...mwbKpiDefs.baconAttachmentRate, color: COLORS.Basil },
              {
                ...mwbKpiDefs.cheeseAttachmentRate,
                color: COLORS.DarkOrchid,
              },
              {
                ...mwbKpiDefs.jalapenoAttachmentRate,
                color: COLORS.Acai,
              },
            ],
            yRightAxisLabel: 'Check Avg',
            yRightAxisValueType: 'cent',
            yRightAxisKpiDef: {
              ...defaultKpiDefs.checkAvg,
              color: COLORS.Pomodoro,
            },
          },
        }
      case 'ace_pop':
        return acePopConfig
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useSalesmanshipConfig
