import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import useTrendUnifiedData from './useTrendUnifiedData'
import useUnifiedData from './useUnifiedData'

export const farwestPmixCategoryDataConfigs = {
  categoryName: 'string',
  subcategoryName: 'string',
  itemName: 'string',
  totalSales: 'price',
  quantitySold: 'number',
  salesPercentage: 'percent',

  currentWeekEndDate: 'date-string',
  salesPercentageWeek1InPast: 'percent',
  salesPercentageWeek2InPast: 'percent',
  salesPercentageWeek3InPast: 'percent',
  salesPercentageWeek4InPast: 'percent',

  quantitySoldWeek1InPast: 'number',
  quantitySoldWeek2InPast: 'number',
  quantitySoldWeek3InPast: 'number',
  quantitySoldWeek4InPast: 'number',

  totalSalesWeek1InPast: 'price',
  totalSalesWeek2InPast: 'price',
  totalSalesWeek3InPast: 'price',
  totalSalesWeek4InPast: 'price',
} as const

const useFarwestPmixCategoryData = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const {
    variables: { isTrend },
  } = useVariables()

  const { data: trendData, loading: trendLoading } = useTrendUnifiedData({
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: 'SUBCATEGORY',
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        item_category_codes: ['EXPO_MENU_CATEGORY_ROOT'],
        metrics: ['total_sales', 'quantity_sold', 'sales_percentage'],
      },
    },
    skip: !isTrend || !endDate || !groupFilter,
  })

  const { data: categoryData, loading: categoryLoading } = useUnifiedData({
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: 'SUBCATEGORY',
      iFilter: {
        location_group_ids: groupFilter?.ids,
        item_category_codes: ['EXPO_MENU_CATEGORY_ROOT'],
        metrics: ['total_sales', 'quantity_sold', 'sales_percentage'],
      },
    },
    skip: isTrend || !startDate || !endDate || !groupFilter || !hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const source = (isTrend ? trendData : categoryData)?.source.map(
        (rowData: any) => {
          return {
            ...rowData,
            categoryName: rowData?.itemName,
            categoryCode: rowData?.itemCode,
          }
        },
      )

      return {
        source: _.orderBy(source, [
          'categoryName',
          'subcategoryName',
          'itemName',
        ]),
        summary: (isTrend ? trendData : categoryData)?.summary,
      }
    }, [categoryData, trendData]),
    loading: categoryLoading || trendLoading,
  }
}

export default useFarwestPmixCategoryData
