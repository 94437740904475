import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import LocationInfo from 'pared/components/LocationInfo/index'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import { FILTER_TYPE } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'

import AcrChart from './AcrChart'
import Chart from './Chart'
import OpsCompsByEmployee from './OpsCompsByEmployee'
import OpsCompsByReason from './OpsCompsByReason'
import Reviews from './Reviews'
import SummaryV3 from './SummaryV3'
import useGuestConfig from './hooks/useGuestConfig'
import {
  IGuestKpi,
  IGuestOpentablKpi,
  IGuestReview,
  IOpsCompsByReason,
} from './index'

interface IProps {
  navParams: INavParams
  employeeDayPart: string | null
  onSelectEmployeeDayPart: (event: any) => void
  opsCompsByReasons: IOpsCompsByReason[]
  guestKpis: IGuestKpi[]
  selectedDateRange: IDateRange | null
  selectedLocation: ILocationInfo | null
  guestReviews: IGuestReview[]
  isGuestReviewsLoading: boolean
  guestOpentableKpis: IGuestOpentablKpi
}

interface IKpiTh {
  danger?: boolean
  success?: boolean
  book?: boolean
  isBackgroundGrey?: boolean
}

function WebLargeMain({
  navParams,
  employeeDayPart,
  onSelectEmployeeDayPart,
  opsCompsByReasons,
  guestKpis,
  selectedDateRange,
  selectedLocation,
  guestReviews,
  isGuestReviewsLoading,
  guestOpentableKpis,
}: IProps) {
  const brandSettings = getBrandSettings()
  const guestConfig = useGuestConfig(brandSettings.brand)

  if (!selectedLocation) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  const momentfeed = _.first(
    _.filter(guestKpis, ['reviewSource', 'momentfeed']),
  )
  const acr = _.first(_.filter(guestKpis, ['reviewSource', 'acr']))
  const google = _.first(_.filter(guestKpis, ['reviewSource', 'google']))
  const facebook = _.first(_.filter(guestKpis, ['reviewSource', 'facebook']))
  const delivery = _.first(_.filter(guestKpis, ['reviewSource', 'delivery']))
  const yelp = _.first(_.filter(guestKpis, ['reviewSource', 'yelp']))
  const all = _.first(_.filter(guestKpis, ['reviewSource', 'all']))
  const dissatisfaction = _.first(
    _.filter(guestKpis, ['reviewSource', 'dissatisfaction']),
  )
  const inaccurate = _.first(
    _.filter(guestKpis, ['reviewSource', 'inaccurate']),
  )
  const overallSatisfaction = _.first(
    _.filter(guestKpis, ['reviewSource', 'overallSatisfaction']),
  )
  const orderNotOnTime = _.first(
    _.filter(guestKpis, ['reviewSource', 'orderNotOnTime']),
  )
  const tasteOfFood = _.first(
    _.filter(guestKpis, ['reviewSource', 'tasteOfFood']),
  )

  const acrRatingScore = _.get(acr, 'ratingScore', '-')
  const momentfeedRatingScore = _.get(momentfeed, 'ratingScore', '-')
  const deliveryRatingScore = _.get(delivery, 'ratingScore', '-')

  const acrRatingRank = _.get(acr, 'ratingRank', '-')
  const momentfeedRatingRank = _.get(momentfeed, 'ratingRank', '-')
  const deliveryRatingRank = _.get(delivery, 'ratingRank', '-')
  const allRatingRank = _.get(all, 'ratingRank', '-')
  const opentableRatingRank = _.get(guestOpentableKpis, 'ratingRank', '-')

  let acrRatingCountText = 'No surveys'
  if (acr) {
    if (guestConfig?.acr?.hideSurveyCount) {
      acrRatingCountText = ''
    } else {
      const acrRatingCount = _.get(acr, 'ratingCount', 0) || 0
      acrRatingCountText =
        acrRatingCount === 1 ? '1 survey' : `${acrRatingCount} surveys`
    }
  }

  let deliveryRatingCountText = 'No ratings'
  if (delivery?.ratingCount !== null) {
    const deliveryRatingCount = _.get(delivery, 'ratingCount', 0) || 0
    deliveryRatingCountText =
      deliveryRatingCount === 1 ? '1 rating' : `${deliveryRatingCount} ratings`
  }

  let momentfeedRatingCountText = 'No ratings'
  if (momentfeed) {
    const momentfeedRatingCount = _.get(momentfeed, 'ratingCount', 0) || 0
    momentfeedRatingCountText =
      momentfeedRatingCount === 1
        ? '1 rating'
        : `${momentfeedRatingCount} ratings`
  }

  let googleRating = '-'
  let googleRatingCountText = 'No ratings'
  const googleRatingScore = _.get(google, 'ratingScore', '-')
  if (google) {
    googleRating = googleRatingScore
    const googleRatingCount = _.get(google, 'ratingCount', 0)

    googleRatingCountText =
      googleRatingCount === 1 ? '1 rating' : `${googleRatingCount} ratings`
  }

  let facebookRating = '-'
  let facebookRatingCountText = 'No ratings'
  const facebookRatingScore = _.get(facebook, 'ratingScore', '-')
  if (facebook) {
    facebookRating = facebookRatingScore
    const facebookRatingCount = _.get(facebook, 'ratingCount', 0)

    facebookRatingCountText =
      facebookRatingCount === 1 ? '1 rating' : `${facebookRatingCount} ratings`
  }

  let yelpRatingCountText = 'No ratings'
  const yelpRatingScore = _.get(yelp, 'ratingScore', '-')
  if (yelp) {
    const yelpRatingCount = _.get(yelp, 'ratingCount', 0) || 0

    yelpRatingCountText =
      yelpRatingCount === 1 ? '1 rating' : `${yelpRatingCount} ratings`
  }

  let allRating = '-'
  let allRatingCountText = 'No ratings'
  const allRatingScore = _.get(all, 'ratingScore', '-')
  if (all) {
    allRating = allRatingScore
    const allRatingCount = _.get(all, 'ratingCount', 0) || 0

    allRatingCountText =
      allRatingCount === 1 ? '1 rating' : `${allRatingCount} ratings`
  }

  let dissatisfactionPercent = '-'
  let dissatisfactionPercentCountText = 'No surveys'
  const dissatisfactionPercentScore = _.get(dissatisfaction, 'ratingScore', '-')
  if (dissatisfaction) {
    dissatisfactionPercent = dissatisfactionPercentScore
    const dissatisfactionPercentCount =
      _.get(dissatisfaction, 'ratingCount', 0) || 0

    dissatisfactionPercentCountText =
      dissatisfactionPercentCount === 1
        ? '1 survey'
        : `${dissatisfactionPercentCount || 'No'} surveys`
  }

  let inaccuratePercent = '-'
  let inaccuratePercentCountText = 'No surveys'
  const inaccuratePercentScore = _.get(inaccurate, 'ratingScore', '-')
  if (inaccurate) {
    inaccuratePercent = inaccuratePercentScore
    const inaccuratePercentCount = _.get(inaccurate, 'ratingCount', 0) || 0

    inaccuratePercentCountText =
      inaccuratePercentCount === 1
        ? '1 survey'
        : `${inaccuratePercentCount || 'No'} surveys`
  }

  let overallSatisfactionRating = '-'
  let overallSatisfactionCountText = 'No surveys'
  const overallSatisfactionScore = _.get(
    overallSatisfaction,
    'ratingScore',
    '-',
  )
  if (overallSatisfaction) {
    overallSatisfactionRating = overallSatisfactionScore
    const overallSatisfactionCount =
      _.get(overallSatisfaction, 'ratingCount', 0) || 0

    overallSatisfactionCountText =
      overallSatisfactionCount === 1
        ? '1 survey'
        : `${overallSatisfactionCount || 'No'} surveys`
  }

  let orderNotOnTimeRating = '-'
  let orderNotOnTimeCountText = 'No surveys'
  const orderNotOnTimeScore = _.get(orderNotOnTime, 'ratingScore', '-')
  if (orderNotOnTime) {
    orderNotOnTimeRating = orderNotOnTimeScore
    const orderNotOnTimeCount = _.get(orderNotOnTime, 'ratingCount', 0) || 0

    orderNotOnTimeCountText =
      orderNotOnTimeCount === 1
        ? '1 survey'
        : `${orderNotOnTimeCount || 'No'} surveys`
  }

  let tasteOfFoodRating = '-'
  let tasteOfFoodCountText = 'No surveys'
  const tasteOfFoodScore = _.get(tasteOfFood, 'ratingScore', '-')
  if (tasteOfFood) {
    tasteOfFoodRating = tasteOfFoodScore
    const tasteOfFoodCount = _.get(tasteOfFood, 'ratingCount', 0) || 0

    tasteOfFoodCountText =
      tasteOfFoodCount === 1
        ? '1 survey'
        : `${tasteOfFoodCount || 'No'} surveys`
  }

  const opentableRatingScore = _.get(guestOpentableKpis, 'ratingScore', '-')
  const opentableRatingCount = _.get(guestOpentableKpis, 'ratingCount', '-')
  const opentableRatingScoreChange = _.get(
    guestOpentableKpis,
    'ratingScoreChange',
    '-',
  )
  const opentableSeatedCovers = _.get(guestOpentableKpis, 'seatedCovers', '-')
  const opentableSeatedCoversChange = _.get(
    guestOpentableKpis,
    'seatedCoversChange',
    '-',
  )
  const opentableOpentableCovers = _.get(
    guestOpentableKpis,
    'opentableCovers',
    '-',
  )
  const opentableOpentableCoversChange = _.get(
    guestOpentableKpis,
    'opentableCoversChange',
    '-',
  )
  const opentableYourNetworkCovers = _.get(
    guestOpentableKpis,
    'yourNetworkCovers',
    '-',
  )
  const opentableYourNetworkCoversChange = _.get(
    guestOpentableKpis,
    'yourNetworkCoversChange',
    '-',
  )
  const opentablePhoneCovers = _.get(guestOpentableKpis, 'phoneCovers', '-')
  const opentablePhoneCoversChange = _.get(
    guestOpentableKpis,
    'phoneCoversChange',
    '-',
  )
  const opentableWalkinCovers = _.get(guestOpentableKpis, 'walkinCovers', '-')
  const opentableWalkinCoversChange = _.get(
    guestOpentableKpis,
    'walkinCoversChange',
    '-',
  )
  const opentableNoShowRate = _.get(guestOpentableKpis, 'noShowRate', '-')
  const opentableNoShowRateChange = _.get(
    guestOpentableKpis,
    'noShowRateChange',
    '-',
  )

  const storeTitle = `
    ${brandSettings.labels.business.store}:
    ${selectedLocation.displayName}
  `

  const locationCount = Math.max(
    _.get(delivery, 'locationCount', 1),
    _.get(all, 'locationCount', 1),
    _.get(acr, 'locationCount', 1),
  )

  return (
    <MainContainer>
      <PageHeaderLineContainer>
        <PageTopContainer>
          <PageLeftHeader>{storeTitle}</PageLeftHeader>
          {guestConfig.isUpdatedWeekly ? (
            <SubtitleContainer>Updated Weekly</SubtitleContainer>
          ) : null}
        </PageTopContainer>
        <SelectorContainer>
          <LocationSelectorContainer>
            <LocationSelector navParams={navParams} />
          </LocationSelectorContainer>
          <DateRangeSelector
            navParams={navParams}
            excludedDateRanges={[TYPE_YESTERDAY]}
            preSelectedDateRange={TYPE_PERIOD}
          />
        </SelectorContainer>
      </PageHeaderLineContainer>
      <LocationInfo locationId={selectedLocation.id} />

      {guestConfig.summary && selectedDateRange ? (
        <SummaryCotinaer>
          <SummaryV3
            startDate={selectedDateRange?.startDate?.format('YYYY-MM-DD')}
            endDate={selectedDateRange?.endDate?.format('YYYY-MM-DD')}
            locationId={selectedLocation.id}
          />
        </SummaryCotinaer>
      ) : null}

      <VerticalSpacer80px />

      <KpiOverviewTable>
        <tbody>
          <tr>
            <PtdKpiCategoryTd>
              <PtdKpiCategoryNameDiv>
                {guestConfig.kpiDisplayName || 'KPIs'}
              </PtdKpiCategoryNameDiv>
            </PtdKpiCategoryTd>
            <PtdOverviewSpacerTd></PtdOverviewSpacerTd>
            {guestConfig.isRankingHidden ? null : (
              <PtdKpiCategoryTd>
                <PtdKpiCategoryNameDiv>Rankings</PtdKpiCategoryNameDiv>
              </PtdKpiCategoryTd>
            )}
          </tr>
          <tr>
            <td>
              <PtdKpiTable>
                <thead>
                  <tr>
                    {guestConfig.smgMwb?.isVisible ? (
                      <>
                        <td>SMG Overall Satisfaction Rating</td>
                        <td>SMG Time to Receive Order Rating</td>
                        <td>SMG Taste of Food Rating</td>
                      </>
                    ) : null}
                    {guestConfig.acr?.isVisible ? <td>{'ACR Score'}</td> : null}

                    {guestConfig.momentfeed?.isVisible ? (
                      <td>{'Momentfeed Rating'}</td>
                    ) : null}

                    {guestConfig.google?.isHidden ? null : (
                      <td>{'Google Rating'}</td>
                    )}

                    {guestConfig.facebook?.isVisible ? (
                      <td>{'Facebook Rating'}</td>
                    ) : null}
                    {guestConfig.delivery?.isVisible ? (
                      <td>{'Delivery Rating'}</td>
                    ) : null}
                    {guestConfig.yelp?.isVisible ? (
                      <td>{'Yelp Rating'}</td>
                    ) : null}
                    {guestConfig.allRating.isVisible ? (
                      <td>{guestConfig.allRating.displayName} Rating</td>
                    ) : null}
                    {guestConfig.smg?.isVisible ? (
                      <td>Dissatisfaction %</td>
                    ) : null}
                    {guestConfig.smg?.isVisible ? <td>Inaccurate %</td> : null}
                  </tr>
                  <tr>
                    {guestConfig.smgMwb?.isVisible ? (
                      <>
                        <KpiTh book={overallSatisfactionScore === 'loading'}>
                          {overallSatisfactionRating}
                        </KpiTh>
                        <KpiTh book={orderNotOnTimeScore === 'loading'}>
                          {orderNotOnTimeRating}
                        </KpiTh>
                        <KpiTh book={tasteOfFoodScore === 'loading'}>
                          {tasteOfFoodRating}
                        </KpiTh>
                      </>
                    ) : null}
                    {guestConfig.acr?.isVisible ? (
                      <KpiTh book={acrRatingScore === 'loading'}>
                        {acrRatingScore}
                      </KpiTh>
                    ) : null}

                    {guestConfig.momentfeed?.isVisible ? (
                      <KpiTh book={momentfeedRatingScore === 'loading'}>
                        {momentfeedRatingScore}
                      </KpiTh>
                    ) : null}
                    {guestConfig.google?.isHidden ? null : (
                      <KpiTh book={googleRatingScore === 'loading'}>
                        {googleRating}
                      </KpiTh>
                    )}
                    {guestConfig.facebook?.isVisible ? (
                      <KpiTh book={facebookRatingScore === 'loading'}>
                        {facebookRating}
                      </KpiTh>
                    ) : null}
                    {guestConfig.delivery?.isVisible ? (
                      <KpiTh book={deliveryRatingScore === 'loading'}>
                        {deliveryRatingScore}
                      </KpiTh>
                    ) : null}
                    {guestConfig.yelp?.isVisible ? (
                      <KpiTh book={yelpRatingScore === 'loading'}>
                        {yelpRatingScore}
                      </KpiTh>
                    ) : null}
                    {guestConfig.allRating.isVisible ? (
                      <KpiTh book={allRatingScore === 'loading'}>
                        {allRating}
                      </KpiTh>
                    ) : null}
                    {guestConfig.smg?.isVisible ? (
                      <KpiTh book={dissatisfactionPercentScore === 'loading'}>
                        {dissatisfactionPercent}
                      </KpiTh>
                    ) : null}
                    {guestConfig.smg?.isVisible ? (
                      <KpiTh book={inaccuratePercentScore === 'loading'}>
                        {inaccuratePercent}
                      </KpiTh>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {guestConfig.smgMwb?.isVisible ? (
                      <>
                        <td>{overallSatisfactionCountText}</td>
                        <td>{orderNotOnTimeCountText}</td>
                        <td>{tasteOfFoodCountText}</td>
                      </>
                    ) : null}
                    {guestConfig.acr?.isVisible ? (
                      <td>{acrRatingCountText}</td>
                    ) : null}
                    {guestConfig.momentfeed?.isVisible ? (
                      <td>{momentfeedRatingCountText}</td>
                    ) : null}
                    {guestConfig.google?.isHidden ? null : (
                      <td>{googleRatingCountText}</td>
                    )}
                    {guestConfig.facebook?.isVisible ? (
                      <td>{facebookRatingCountText}</td>
                    ) : null}
                    {guestConfig.delivery?.isVisible ? (
                      <td>{deliveryRatingCountText}</td>
                    ) : null}
                    {guestConfig.yelp?.isVisible ? (
                      <td>{yelpRatingCountText}</td>
                    ) : null}
                    {guestConfig.allRating.isVisible ? (
                      <td>{allRatingCountText}</td>
                    ) : null}
                    {guestConfig.smg?.isVisible ? (
                      <td>{dissatisfactionPercentCountText}</td>
                    ) : null}
                    {guestConfig.smg?.isVisible ? (
                      <td>{inaccuratePercentCountText}</td>
                    ) : null}
                  </tr>
                </tbody>
              </PtdKpiTable>
            </td>
            <PtdOverviewSpacerTd></PtdOverviewSpacerTd>
            {guestConfig.isRankingHidden ? null : (
              <td>
                <PtdKpiTable>
                  <thead>
                    <tr>
                      {guestConfig.acr?.isVisible ? (
                        <td>{'ACR Score'}</td>
                      ) : null}
                      {guestConfig.momentfeed?.isVisible ? (
                        <td>{'Momentfeed Rating'}</td>
                      ) : null}
                      {guestConfig.delivery?.isVisible ? (
                        <td>{'Delivery Rating'}</td>
                      ) : null}
                      {guestConfig.allRating.isVisible ? (
                        <td>{guestConfig.allRating.displayName} Rating</td>
                      ) : null}
                      {guestConfig.opentable?.isVisible ? (
                        <td>{'Opentable Rating'}</td>
                      ) : null}
                    </tr>
                    <tr>
                      {guestConfig.acr?.isVisible ? (
                        <KpiTh book={acrRatingRank === 'loading'}>
                          {acrRatingRank}
                        </KpiTh>
                      ) : null}
                      {guestConfig.momentfeed?.isVisible ? (
                        <KpiTh book={momentfeedRatingRank === 'loading'}>
                          {momentfeedRatingRank}
                        </KpiTh>
                      ) : null}
                      {guestConfig.delivery?.isVisible ? (
                        <KpiTh book={deliveryRatingRank === 'loading'}>
                          {deliveryRatingRank}
                        </KpiTh>
                      ) : null}
                      {guestConfig.allRating.isVisible ? (
                        <KpiTh book={allRatingRank === 'loading'}>
                          {allRatingRank}
                        </KpiTh>
                      ) : null}
                      {guestConfig.opentable?.isVisible ? (
                        <KpiTh book={opentableRatingRank === 'loading'}>
                          {opentableRatingRank}
                        </KpiTh>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={2}>{`out of ${locationCount} stores`}</td>
                    </tr>
                  </tbody>
                </PtdKpiTable>
              </td>
            )}
          </tr>
        </tbody>
      </KpiOverviewTable>

      {!guestConfig.opentable?.isVisible ? null : (
        <>
          <VerticalSpacer20px />

          <OpentableTable>
            <tbody>
              <tr>
                <PtdKpiCategoryTd>
                  <PtdKpiCategoryNameDiv>Opentable</PtdKpiCategoryNameDiv>
                </PtdKpiCategoryTd>
                <PtdOverviewSpacerTd></PtdOverviewSpacerTd>
              </tr>
              <tr>
                <td>
                  <PtdKpiTable>
                    <thead>
                      <tr>
                        <td>Rating</td>
                        <td>Seated Covers</td>
                        <td>OpenTable Network</td>
                        <td>Your Network</td>
                        <td>Phone</td>
                        <td>Walk-in</td>
                        <td>No-show rate</td>
                      </tr>
                      <tr>
                        <KpiTh book={opentableRatingScore === 'loading'}>
                          {opentableRatingScore}
                        </KpiTh>
                        <KpiTh book={opentableSeatedCovers === 'loading'}>
                          {opentableSeatedCovers}
                        </KpiTh>
                        <KpiTh book={opentableOpentableCovers === 'loading'}>
                          {opentableOpentableCovers}
                        </KpiTh>
                        <KpiTh book={opentableYourNetworkCovers === 'loading'}>
                          {opentableYourNetworkCovers}
                        </KpiTh>
                        <KpiTh book={opentablePhoneCovers === 'loading'}>
                          {opentablePhoneCovers}
                        </KpiTh>
                        <KpiTh book={opentableWalkinCovers === 'loading'}>
                          {opentableWalkinCovers}
                        </KpiTh>
                        <KpiTh book={opentableNoShowRate === 'loading'}>
                          {opentableNoShowRate}
                        </KpiTh>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{opentableRatingScoreChange}</td>
                        <td>{opentableSeatedCoversChange}</td>
                        <td>{opentableOpentableCoversChange}</td>
                        <td>{opentableYourNetworkCoversChange}</td>
                        <td>{opentablePhoneCoversChange}</td>
                        <td>{opentableWalkinCoversChange}</td>
                        <td>{opentableNoShowRateChange}</td>
                      </tr>
                    </tbody>
                  </PtdKpiTable>
                </td>
              </tr>
            </tbody>
          </OpentableTable>
        </>
      )}

      {!guestConfig.acr.isVisible ? null : (
        <>
          <VerticalSpacer80px />

          <AcrChart
            selectedLocation={selectedLocation}
            selectedDateRange={selectedDateRange}
          />
        </>
      )}

      <VerticalSpacer20px />

      <Reviews guestReviews={guestReviews} isLoading={isGuestReviewsLoading} />

      <VerticalSpacer80px />

      {selectedDateRange && selectedLocation && !guestConfig.isChartHidden ? (
        <Chart
          startDate={selectedDateRange.startDateStr}
          endDate={selectedDateRange.endDateStr}
          dateRangeType={selectedDateRange.type}
          locationId={selectedLocation.id}
          locationName={selectedLocation.name}
        />
      ) : null}

      {!guestConfig.opsCompsBreakdown.isVisible ? null : (
        <>
          <VerticalSpacer80px />

          <OpsCompsByReason opsCompsByReasons={opsCompsByReasons} />
        </>
      )}

      <VerticalSpacer80px />

      {guestConfig['employeeTable']?.isVisible ? (
        <>
          <EmployeeOuterContainer>
            <table>
              <tbody>
                <tr>
                  <EmployeeDescriptionTd>
                    <EmployeeContainer>By Employee</EmployeeContainer>
                  </EmployeeDescriptionTd>
                  <td>
                    <FilterContainer>
                      <DescriptionContainer>Filter by</DescriptionContainer>
                      <EmployeeSelectorContainer>
                        <DaypartFormControl
                          variant="outlined"
                          margin="dense"
                          style={{ width: 140 }}
                        >
                          <OptionSelect
                            displayEmpty
                            value={employeeDayPart}
                            onChange={onSelectEmployeeDayPart}
                          >
                            <MenuItem key="dinner" value="dinner">
                              Dinner Only
                            </MenuItem>
                            <MenuItem key="lunch" value="lunch">
                              Lunch Only
                            </MenuItem>
                            <MenuItem key="all" value="all">
                              All
                            </MenuItem>
                          </OptionSelect>
                        </DaypartFormControl>
                      </EmployeeSelectorContainer>
                    </FilterContainer>
                  </td>
                </tr>
              </tbody>
            </table>
          </EmployeeOuterContainer>
          {employeeDayPart ? (
            <OpsCompsByEmployee
              navParams={navParams}
              dayPart={employeeDayPart}
              filterType={FILTER_TYPE.DATE}
            />
          ) : null}
        </>
      ) : null}
      <VerticalSpacer80px />
    </MainContainer>
  )
}

const MainContainer = styled.div``

const SummaryCotinaer = styled.div`
  margin: 20px 0px 0px;
`

const EmployeeOuterContainer = styled.div`
  display: inline-block;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
`

const EmployeeDescriptionTd = styled.td`
  width: 950px;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const EmployeeContainer = styled(PageHeader)`
  margin-bottom: 10px;
`

const FilterContainer = styled.div`
  display: inline-block;
`

const DescriptionContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  float: left;
  margin-top: 17px;
`

const DaypartFormControl = styled(FormControl)`
  background-color: white;
`

const OptionSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const EmployeeSelectorContainer = styled.div`
  float: left;
  margin-left: 25px;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const PageTopContainer = styled.div`
  float: left;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  padding: 10px 20px 0 0;
  margin-bottom: 10px;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const LocationSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

const VerticalSpacer80px = styled.div`
  width: 100%;
  height: 80px;
`

const VerticalSpacer20px = styled.div`
  width: 100%;
  height: 20px;
`

const KpiOverviewTable = styled.table`
  width: 1000px;
`

const OpentableTable = styled.table`
  width: 800px;
`

const PtdKpiCategoryTd = styled.td`
  vertical-align: top;
`

const PtdKpiCategoryNameDiv = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 0 5px 0;
  border-bottom: 1px solid ${COLORS.Chalkboard};
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const PtdOverviewSpacerTd = styled.td`
  width: 40px;
`

const PtdKpiTable = styled.table`
  width: 100%;
  text-align: center;

  thead th {
    font-style: normal;
    min-width: 60px;
  }

  tbody td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    vertical-align: top;
    height: 60px;
  }
`

const KpiTh = styled.th<IKpiTh>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-weight: ${(props) => (props.book ? '400' : '700')};
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

export default WebLargeMain
