import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListFarwestFlashSalesByOrderMode(
    $iDate: Datetime!
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        metricData
        metricSummaryData
      }
    }

    calendarByDate(date: $iDate) {
      businessWeekOfMonth
    }
  }
`

export const farwestFlashSalesByOrderModeConfigs = {
  storeNumber: 'number',
  periodEquivalentUnitCount: 'number',
  doordashSales: 'price',
  ubereatSales: 'price',
  grubhubSales: 'price',
  dispatchSales: 'price',
  oloTakeOut: 'price',
  callInToGo: 'price',
  walkInDineIn: 'price',
  walkInToGo: 'price',
  doordashSalesPercentage: 'percent',
  ubereatSalesPercentage: 'percent',
  grubhubSalesPercentage: 'percent',
  dispatchSalesPercentage: 'percent',
  oloTakeOutPercentage: 'percent',
  callInToGoPercentage: 'percent',
  walkInDineInPercentage: 'percent',
  walkInToGoPercentage: 'percent',
} as const

const useFarwestFlashSalesByOrderMode = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iDate: endDate,
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: groupFilter?.ids,
        metrics: [
          'store_number',
          'period_equivalent_unit_count',
          'doordash_sales',
          'ubereat_sales',
          'grubhub_sales',
          'dispatch_sales',
          'olo_take_out',
          'call_in_to_go',
          'walk_in_dine_in',
          'walk_in_to_go',
          'doordash_sales_percentage',
          'ubereat_sales_percentage',
          'grubhub_sales_percentage',
          'dispatch_sales_percentage',
          'olo_take_out_percentage',
          'call_in_to_go_percentage',
          'walk_in_dine_in_percentage',
          'walk_in_to_go_percentage',
        ],
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: any = data?.listLocationGroupMetricValues?.nodes
      const weeksOfMonth = data?.calendarByDate?.businessWeekOfMonth

      if (!customizedData) return null

      const equivalentUnitCount =
        customizedData?.[0].metricData?.period_equivalent_unit_count?.value

      const source = customizedData.map((locationData: any) => {
        const kpisData: { [key: string]: number } = {}

        for (const key in locationData.metricData) {
          if (locationData.metricData.hasOwnProperty(key)) {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            switch (locationData.metricData[key].unit) {
              case 'DOLLAR':
                if (
                  [
                    'olo_take_out',
                    'call_in_to_go',
                    'walk_in_dine_in',
                    'walk_in_to_go',
                  ].includes(key)
                ) {
                  kpisData[newKey] =
                    (locationData.metricData[key].value * 100) /
                    weeksOfMonth /
                    equivalentUnitCount
                } else {
                  kpisData[newKey] = locationData.metricData[key].value * 100
                }
                break

              case 'PERCENTAGE':
                kpisData[newKey] = locationData.metricData[key].value * 100
                break

              default:
                kpisData[newKey] = locationData.metricData[key].value
                break
            }
          }
        }

        return kpisData
      })

      return {
        source,
      }
    }, [data]),
    loading,
  }
}

export default useFarwestFlashSalesByOrderMode
