import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query bbbSystemwideOverviewKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $iInputParams: JSON!
  ) {
    summaryLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        netSales
        netSalesBudget
      }
    }

    getCustomizedReport(
      iCustomizedReportName: "LIST_LOCATION_GROUP_FINANCIAL_KPIS"
      iInputParams: $iInputParams
    ) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`

export const bbbSystemwideOverviewKpisConfigs = {
  title: 'string',
  netSales: 'price',
  fAndPPercent: 'percent',
  laborPercent: 'percent',
  fplPercent: 'percent',
  ebitdaBeforeBonusPercent: 'percent',
  ebitdaAfterBonus: 'price',
  ebitdaAfterBonusPercent: 'percent',
  grossProfitPercent: 'percent',
  restaurantLevelProfitPercent: 'percent',
} as const

const useBbbSystemwideOverviewKpis = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.ids },
      iInputParams: {
        startDate: startDate,
        endDate: endDate,
        locationGroupIds: groupFilter?.ids,
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const summaryLocationGroupFinancialKpis = (
        data?.summaryLocationGroupFinancialKpis.nodes as
          | Record<string, number>[]
          | undefined
      )?.[0]
      const customizedReport = (
        data?.getCustomizedReport?.nodes[0]?.reportResult?.tableData as
          | Record<string, number>[]
          | undefined
      )?.[0]

      if (!summaryLocationGroupFinancialKpis) return null

      const kpis: Record<string, number> = {
        ...summaryLocationGroupFinancialKpis,
        ...customizedReport,
        netSalesDiff:
          summaryLocationGroupFinancialKpis.netSales -
          summaryLocationGroupFinancialKpis.netSalesBudget,
      }

      return {
        source: (['Actual', 'Budget', 'Difference'] as const).map((type) =>
          (
            Object.keys(
              bbbSystemwideOverviewKpisConfigs,
            ) as (keyof typeof bbbSystemwideOverviewKpisConfigs)[]
          ).reduce(
            (result, key) => {
              if (key === 'title') return result

              const name = (() => {
                switch (type) {
                  case 'Actual':
                    return key
                  case 'Budget':
                    return /Percent$/.test(key)
                      ? key.replace(/Percent$/, 'BudgetPercent')
                      : `${key}Budget`
                  default:
                    return /Percent$/.test(key)
                      ? key.replace(/Percent$/, 'Variance')
                      : `${key}Diff`
                }
              })()

              return {
                ...result,
                [key]:
                  type === 'Difference' &&
                  ['fAndPPercent', 'laborPercent', 'fplPercent'].includes(key)
                    ? kpis[name] * -1
                    : kpis[name],
              }
            },
            {
              title: type,
            },
          ),
        ),
      }
    }, [data]),
    loading,
  }
}

export default useBbbSystemwideOverviewKpis
