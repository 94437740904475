import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeList'
import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import {
  toPercentString,
  toPercentageString,
  toUsdStr,
} from 'pared/utils/number'

import {
  IAcrScore,
  IDailyDashboardData,
  IHourlyLabor,
  ISpeedOfService,
} from './useDataSource'

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

function formatSosValue(rawSosValueInSeconds: number) {
  const minutes = Math.floor(rawSosValueInSeconds / 60)
  const remainingSeconds = Math.round(rawSosValueInSeconds % 60)

  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds.toString()

  return `${minutes}:${formattedSeconds}`
}

const useColumns = (): IColumnsType<IDailyDashboardData>[] => {
  return useMemo(() => {
    return [
      {
        key: 'storeCode',
        title: 'Store',
        minWidth: '200px',
        render: (_, values) => {
          return (
            <LinkButton
              to={navigator.storeDetail(values?.storeId)}
              onClick={() => track.locationLinkClicked(values?.storeId || 0)}
            >
              {values?.storeCode} - {values?.storeName}
            </LinkButton>
          )
        },
        summary: () => 'Average',
        sorter: true,
      },
      {
        key: 'hourlyLabor',
        title: 'Hourly Labor %',
        minWidth: '100px',
        align: 'center',
        render: (value: IHourlyLabor) =>
          value.sales ? toPercentageString(value.costs / value.sales) : '-',
        csvRender: (value: IHourlyLabor) =>
          value.sales ? toPercentageString(value.costs / value.sales) : '-',
        summary: (data: IDailyDashboardData[]) => {
          const totalCost = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.hourlyLabor.costs,
            0,
          )
          const totalSales = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.hourlyLabor.sales,
            0,
          )

          return totalSales ? toPercentageString(totalCost / totalSales) : '-'
        },
        sorter: (aValue: IHourlyLabor, bValue: IHourlyLabor) => {
          if (!aValue || !aValue.sales) {
            return 1
          }

          if (!bValue || !bValue.sales) {
            return -1
          }
          return aValue.costs / aValue.sales - bValue.costs / bValue.sales
        },
      },
      {
        key: 'mtdHourlyLabor',
        title: 'MTD Labor %',
        minWidth: '100px',
        align: 'center',
        render: (value: IHourlyLabor) =>
          value.sales ? toPercentageString(value.costs / value.sales) : '-',
        csvRender: (value: IHourlyLabor) =>
          value.sales ? toPercentageString(value.costs / value.sales) : '-',
        summary: (data: IDailyDashboardData[]) => {
          const totalCost = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.mtdHourlyLabor.costs,
            0,
          )
          const totalSales = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.mtdHourlyLabor.sales,
            0,
          )

          return totalSales ? toPercentageString(totalCost / totalSales) : '-'
        },
        sorter: (aValue: IHourlyLabor, bValue: IHourlyLabor) => {
          if (!aValue || !aValue.sales) {
            return 1
          }

          if (!bValue || !bValue.sales) {
            return -1
          }
          return aValue.costs / aValue.sales - bValue.costs / bValue.sales
        },
      },
      {
        key: 'driveThruSos',
        title: 'Yesterday’s Drive Thru Speed of Service (Minutes)',
        minWidth: '100px',
        align: 'center',
        render: (value: ISpeedOfService) =>
          value.count
            ? formatSosValue((value.seconds * 1.0) / value.count)
            : '-',
        csvRender: (value: ISpeedOfService) =>
          value.count
            ? formatSosValue((value.seconds * 1.0) / value.count)
            : '-',
        summary: (data: IDailyDashboardData[]) => {
          const totalSeconds = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.driveThruSos.seconds,
            0,
          )
          const totalCount = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.driveThruSos.count,
            0,
          )

          return totalCount
            ? formatSosValue((totalSeconds * 1.0) / totalCount)
            : '-'
        },
        sorter: (aValue: ISpeedOfService, bValue: ISpeedOfService) => {
          if (!aValue || !aValue.count) {
            return 1
          }

          if (!bValue || !bValue.count) {
            return -1
          }
          return aValue.seconds / aValue.count - bValue.seconds / bValue.count
        },
      },
      {
        key: 'mtdAvgTotalSos',
        title: 'MTD Average Total Time (Minutes)',
        minWidth: '100px',
        align: 'center',
        render: (value: ISpeedOfService) =>
          value.count ? formatSosValue(value.seconds / value.count) : '-',
        csvRender: (value: ISpeedOfService) =>
          value.count ? formatSosValue(value.seconds / value.count) : '-',
        summary: (data: IDailyDashboardData[]) => {
          const totalSeconds = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.mtdAvgTotalSos.seconds,
            0,
          )
          const totalCount = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.mtdAvgTotalSos.count,
            0,
          )

          return totalCount ? formatSosValue(totalSeconds / totalCount) : '-'
        },
        sorter: (aValue: ISpeedOfService, bValue: ISpeedOfService) => {
          if (!aValue || !aValue.count) {
            return 1
          }

          if (!bValue || !bValue.count) {
            return -1
          }
          return aValue.seconds / aValue.count - bValue.seconds / bValue.count
        },
      },
      {
        key: 'pickupTime',
        title: 'Yesterday Pick Up Window Time (Seconds)',
        minWidth: '100px',
        align: 'center',
        render: (value: ISpeedOfService) =>
          value.count ? Math.round(value.seconds / value.count) : '-',
        csvRender: (value: ISpeedOfService) =>
          value.count ? Math.round(value.seconds / value.count) : '-',
        summary: (data: IDailyDashboardData[]) => {
          const totalSeconds = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.pickupTime.seconds,
            0,
          )
          const totalCount = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.pickupTime.count,
            0,
          )

          return totalCount ? Math.round(totalSeconds / totalCount) : '-'
        },
        sorter: (aValue: ISpeedOfService, bValue: ISpeedOfService) => {
          if (!aValue || !aValue.count) {
            return 1
          }

          if (!bValue || !bValue.count) {
            return -1
          }
          return aValue.seconds / aValue.count - bValue.seconds / bValue.count
        },
      },
      {
        key: 'mtdPickupTime',
        title: 'MTD Pickup Window Time Average (Seconds)',
        minWidth: '100px',
        align: 'center',
        render: (value: ISpeedOfService) =>
          value.count ? Math.round(value.seconds / value.count) : '-',
        csvRender: (value: ISpeedOfService) =>
          value.count ? Math.round(value.seconds / value.count) : '-',
        summary: (data: IDailyDashboardData[]) => {
          const totalSeconds = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.mtdPickupTime.seconds,
            0,
          )
          const totalCount = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.mtdPickupTime.count,
            0,
          )

          return totalCount ? Math.round(totalSeconds / totalCount) : '-'
        },
        sorter: (aValue: ISpeedOfService, bValue: ISpeedOfService) => {
          if (!aValue || !aValue.count) {
            return 1
          }

          if (!bValue || !bValue.count) {
            return -1
          }
          return aValue.seconds / aValue.count - bValue.seconds / bValue.count
        },
      },
      {
        key: 'mtdAcrScore',
        title: 'ACR Score (#)',
        minWidth: '100px',
        align: 'center',
        render: (value: IAcrScore) =>
          value.traffic
            ? ((10000 * value.b3sValue) / value.traffic).toFixed(2)
            : '-',
        csvRender: (value: IAcrScore) =>
          value.traffic
            ? ((10000 * value.b3sValue) / value.traffic).toFixed(2)
            : '-',
        summary: (data: IDailyDashboardData[]) => {
          const totalB3sValue = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.mtdAcrScore.b3sValue,
            0,
          )
          const totalTraffic = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.mtdAcrScore.traffic,
            0,
          )

          return totalTraffic
            ? ((10000 * totalB3sValue) / totalTraffic).toFixed(2)
            : '-'
        },
        sorter: (aValue: IAcrScore, bValue: IAcrScore) => {
          if (!aValue || !aValue.traffic) {
            return 1
          }

          if (!bValue || !bValue.traffic) {
            return -1
          }
          return (
            aValue.b3sValue / aValue.traffic - bValue.b3sValue / bValue.traffic
          )
        },
      },
      {
        key: 'revScore',
        title: 'Rev Score (%)',
        minWidth: '100px',
        align: 'center',
        render: (value) => toPercentString(value),
        csvRender: (value) => toPercentString(value),
        summary: (data: IDailyDashboardData[]) => {
          const totalValue = data.reduce(
            (total: number, row: IDailyDashboardData) => total + row.revScore,
            0,
          )

          return toPercentString(data?.length ? totalValue / data.length : '-')
        },
        sorter: true,
      },
      {
        key: 'cashOverShort',
        title: 'Cash Over Short ($)',
        minWidth: '100px',
        align: 'center',
        render: (value: number) => toUsdStr(value / 100),
        csvRender: (value: number) => toUsdStr(value / 100),
        summary: (data: IDailyDashboardData[]) => {
          const totalValue = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.cashOverShort,
            0,
          )

          return toUsdStr(data?.length ? totalValue / 100 / data.length : '-')
        },
        sorter: true,
      },
      {
        key: 'mtdCashOverShort',
        title: 'Cash Over Short MTD ($)',
        minWidth: '100px',
        align: 'center',
        render: (value: number) => toUsdStr(value / 100),
        csvRender: (value: number) => toUsdStr(value / 100),
        summary: (data: IDailyDashboardData[]) => {
          const totalValue = data.reduce(
            (total: number, row: IDailyDashboardData) =>
              total + row.mtdCashOverShort,
            0,
          )

          return toUsdStr(data?.length ? totalValue / 100 / data.length : '-')
        },
        sorter: true,
      },
    ]
  }, [])
}

export default useColumns
