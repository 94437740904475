import { IConfigsType } from '../../types'

const bibibopFlash: IConfigsType = {
  bibibop: {
    '/:brand/flash': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
        ],
      },
      flash: {
        type: 'title',
        title: 'Systemwide Flash Report',
      },
      downloadFlashTable: {
        title: 'DAILY FLASH',
        type: 'download-csv',
        api: 'flash',
        fileName: 'getexpo-flash',
        message: 'Download Daily Flash as CSV',
        fields: [
          'groupInfo',
          {
            key: 'netSales',
            title: 'Current Sales',
          },
          {
            key: 'yoyNetSales',
            title: 'PY Sales',
          },
          {
            key: 'netSalesSssPercent',
            title: 'PY %',
          },
          {
            key: 'netSalesBudget',
            title: 'Budget Sales',
          },
          {
            key: 'netSalesBudgetVariance',
            title: 'Budget %',
          },
          {
            key: 'onlinePercent',
            title: 'Online %',
          },
          {
            key: 'deliveryPercent',
            title: 'Delivery %',
          },
          {
            key: 'catering',
            title: 'Catering $',
          },
          {
            key: 'totalCheckCount',
            title: 'Orders',
          },
          {
            key: 'yoyCheckCountGrowth',
            title: 'Orders PY %',
          },
          {
            key: 'totalCheckAverage',
            title: 'Check Avg',
          },
          {
            key: 'yoyTotalCheckAverage',
            title: 'Check Avg PY',
          },
          {
            key: 'totalCheckAverageSssPercent',
            title: 'Check Avg %',
          },
          {
            key: 'totalComps',
            title: 'Discount $',
          },
          {
            key: 'compsPercent',
            title: 'Discount %',
          },
          {
            key: 'Employee Discount.totalComps',
            title: 'Employee Discount $',
          },
          {
            key: 'voids',
            title: 'Void $',
          },
          {
            key: 'cashOverOrShort',
            title: 'Cash Over/Short',
          },
          {
            key: 'laborHours',
            title: 'Labor Hours',
          },
          {
            key: 'overtimeHours',
            title: 'Overtime Hours',
          },
          {
            key: 'salesPerLaborHour',
            title: 'Productivity',
          },
          {
            key: 'laborPercent',
            title: 'Labor %',
          },
          {
            key: 'cookiesPer100Orders',
            title: 'Cookies Per 100 Orders',
          },
          {
            key: 'avocadoPer100Orders',
            title: 'Avocado Per 100 Orders',
          },
          {
            key: 'customerRating',
            title: 'Customer Rating',
          },
          {
            key: 'numberOfComplaints',
            title: 'Number of Complaints',
          },
          {
            key: 'bonus',
            title: 'Bonus Payouts',
          },
        ],
      },
      flashTable: {
        type: 'table',
        api: 'flash',
        groupInfo: {
          width: '250px',
          groupBy: {
            width: '150px',
          },
        },
        highlineEvenRows: true,
        defaultSortedField: 'groupInfo',
        pageSize: 100,
        columns: [
          {
            key: 'netSales',
            title: 'Current Sales',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyNetSales',
            title: 'PY Sales',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'netSalesSssPercent',
            title: 'PY %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'netSalesBudget',
            title: 'Budget Sales',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'netSalesBudgetVariance',
            title: 'Budget %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'onlinePercent',
            title: 'Online %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'deliveryPercent',
            title: 'Delivery %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'catering',
            title: 'Catering $',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalCheckCount',
            title: 'Orders',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyCheckCountGrowth',
            title: 'Orders PY %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalCheckAverage',
            title: 'Check Avg',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'yoyTotalCheckAverage',
            title: 'Check Avg PY',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'totalCheckAverageSssPercent',
            title: 'Check Avg %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalComps',
            title: 'Discount $',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'compsPercent',
            title: 'Discount %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'Employee Discount.totalComps',
            title: 'Employee Discount $',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'voids',
            title: 'Void $',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'cashOverOrShort',
            title: 'Cash Over/Short',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'laborHours',
            title: 'Labor Hours',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'overtimeHours',
            title: 'Overtime Hours',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'salesPerLaborHour',
            title: 'Productivity',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'laborPercent',
            title: 'Labor %',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'cookiesPer100Orders',
            title: 'Cookies Per 100 Orders',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'avocadoPer100Orders',
            title: 'Avocado Per 100 Orders',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'customerRating',
            title: 'Customer Rating',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'numberOfComplaints',
            title: 'Number of Complaints',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'bonus',
            title: 'Bonus Payouts',
            width: '120px',
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default bibibopFlash
