import { Chart, ChartEvent } from 'chart.js'
import _ from 'lodash'

import COLORS from 'pared/constants/colors'

export interface ICustomChart extends Chart {
  labelsButton: ReturnType<typeof createState>
}

interface IOptionsType {
  display: boolean
}

interface IArgsType {
  changed: boolean
  event: ChartEvent
}

interface IStateType {
  hideLabels: boolean
  coordinate: Record<'x' | 'y' | 'w' | 'h', number>
}

const createState = () => {
  const state: IStateType = {
    hideLabels: false,
    coordinate: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
  }

  return {
    toggleLabels: () => {
      state.hideLabels = !state.hideLabels
    },
    hideLabels: () => state.hideLabels,
    setCoordinate: (coordinate: IStateType['coordinate']) => {
      _.merge(state.coordinate, coordinate)
    },
    getCoordinate: () => state.coordinate,
  }
}

export default {
  id: 'labelsButton',
  afterInit: (chart: ICustomChart, _: unknown, { display }: IOptionsType) => {
    if (!display) return

    chart.labelsButton = createState()
  },
  beforeDraw: (chart: ICustomChart, _: unknown, { display }: IOptionsType) => {
    if (!display) return

    const {
      ctx,
      chartArea: { right },
    } = chart

    if (chart.config.data.datasets.length === 0) return

    ctx.save()

    const text = chart.labelsButton.hideLabels() ? 'Show labels' : 'Hide labels'
    const { width: textWidth } = ctx.measureText(text)
    const padding = 5
    const coordinate = {
      x: right - (textWidth + padding * 2),
      y: 5,
      w: textWidth + padding * 2,
      h: 20,
    }

    ctx.fillStyle = COLORS.Link
    ctx.fillText(text, coordinate.x + padding, coordinate.y + 10)

    ctx.beginPath()
    ctx.strokeStyle = COLORS.Link
    ctx.lineWidth = 1.5
    ctx.moveTo(coordinate.x + padding - 1, coordinate.y + 16)
    ctx.lineTo(coordinate.x + padding + textWidth + 1, coordinate.y + 16)
    ctx.stroke()

    chart.labelsButton.setCoordinate(coordinate)
    ctx.restore()
  },
  afterEvent: (
    chart: ICustomChart,
    args: IArgsType,
    { display }: IOptionsType,
  ) => {
    if (args.event.type !== 'click' || !display) return

    const coordinate = chart.labelsButton.getCoordinate()
    const x = args.event.x || 0
    const y = args.event.y || 0

    if (
      coordinate.x <= x &&
      x <= coordinate.x + coordinate.w &&
      coordinate.y <= y &&
      y <= coordinate.y + coordinate.h
    ) {
      chart.labelsButton.toggleLabels()
      args.changed = true
    }
  },
  defaults: {
    display: false,
  },
}
