import { IConfigsType } from '../../types'

const fwWingstopFlashDiscountsAndVoids: IConfigsType = {
  fw_wingstop: {
    '/:brand/discounts_and_voids': {
      variables: ['date'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['week', 'this_week'],
        defaultType: 'this_week',
      },
      reportTitle: {
        type: 'title',
        title: 'Discounts and Voids',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'farwestFourWeekTrendData',
        fileName: 'getexpo-far-west-discounts-and-voids',
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'discountPercentageWeek4InPast',
            title: 'Discount (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'discountPercentageWeek3InPast',
            title: 'Discount (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'discountPercentageWeek2InPast',
            title: 'Discount (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'discountPercentageWeek1InPast',
            title: 'Discount (<%- date?.getInfo(0)?.displayName %>)',
          },
          {
            key: 'voidPercentageWeek4InPast',
            title: 'Void (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'voidPercentageWeek3InPast',
            title: 'Void (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'voidPercentageWeek2InPast',
            title: 'Void (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'voidPercentageWeek1InPast',
            title: 'Void (<%- date?.getInfo(0)?.displayName %>)',
          },
        ],
      },
      flashTable: {
        type: 'table',
        api: 'farwestFourWeekTrendData',
        pageSize: 30,
        heatmap: {
          direction: 'asc',
        },
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'market',
            title: 'Market',
            sortable: true,
          },
          {
            key: 'discountPercentage',
            title: 'Discount',
            align: 'center',
            highlight: true,
            hasRightGutter: true,
            children: [
              {
                key: 'discountPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
                hasRightGutter: true,
              },
            ],
          },
          {
            key: 'voidPercentage',
            title: 'Void',
            align: 'center',
            children: [
              {
                key: 'voidPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'voidPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'voidPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'voidPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashDiscountsAndVoids
