import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrand } from 'pared/utils/brand'

const CorporateFssTable = () => {
  const brand = getBrand()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]

  return (
    <Container>
      <Header>FSS</Header>
      <CustomizedReport
        reportName="CORPORATE_FSS_TABLE"
        inputParams={{ locationGroupId: brandLocationGroupId }}
        csvFileName="rankings-fss"
      />
    </Container>
  )
}

export default CorporateFssTable

const Container = styled.div`
  padding: 0 0 60px 0;
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

const Header = styled.div`
  margin: 0px;
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  line-height: 52px;

  @media ${MOBILE_WIDTH} {
    padding-bottom: 25px;
  }
`
