import { gql } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'

import { apolloClient } from 'pared/reactiveVariables/apollo'

export interface IDateRange {
  key: string
  year: number
  type:
    | typeof TYPE_YEAR
    | typeof TYPE_QUARTER
    | typeof TYPE_PERIOD
    | typeof TYPE_LAST_WEEK
    | typeof TYPE_THIS_WEEK
    | typeof TYPE_YESTERDAY
    | typeof TYPE_TRAILING_7_DAYS
    | typeof TYPE_TRAILING_90_DAYS
    | typeof TYPE_TRAILING_12_MONTHS
    | typeof TYPE_WEEK
    | typeof TYPE_CUSTOM

  // index is only string type when type is TYPE_WEEK
  index: number | string
  startDate: moment.Moment
  endDate: moment.Moment
  startDateStr: string
  endDateStr: string
  numDays: number
}

export interface IDateRangeMap {
  [dateRangeKey: string]: IDateRange
}

export interface IAllDateRangeData {
  defaultPeriod: IDateRange | null
  latestPeriod: IDateRange | null
  periodsByYear: { [year: number]: IDateRange[] }
  quartersByYear: { [year: number]: IDateRange[] }
  weeksByYear: { [year: number]: IDateRange[] }
  years: IDateRange[]
  yearMap: { [year: number]: IDateRange }
  dateRangeMap: IDateRangeMap
}

const FETCH_PERIODS = gql`
  query FetchPeriods($minYear: Int!, $queryDate: Date!) {
    listBusinessMonths(minYear: $minYear, maxYear: ${moment()
      .add(1, 'years')
      .format('YYYY')}) {
      nodes {
        year: businessYear
        period: businessMonth
        periodStart: businessMonthBeginDate
        periodEnd: businessMonthEndDate
        quarter: businessQuarter
      }
    }

    getDateInfo(iQueryDate: $queryDate) {
      nodes {
        businessWeekBeginDate
        businessWeekEndDate
      }
    }

    listBusinessWeeks(minYear: $minYear, maxYear: ${moment()
      .add(1, 'years')
      .format('YYYY')}) {
      nodes {
        year: businessYear
        quarter: businessQuarter
        period: businessMonth
        week: businessWeek
        weekOfPeriod: businessWeekOfMonth
        weekStart: businessWeekBeginDate
        weekEnd: businessWeekEndDate
      }
    }
  }
`

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_COMPACT_FORMAT = 'YYYYMMDD'

export const TYPE_YEAR = 'year'
export const TYPE_QUARTER = 'quarter'
export const TYPE_PERIOD = 'period'
export const TYPE_LAST_WEEK = 'last_week'
export const TYPE_THIS_WEEK = 'this_week'
export const TYPE_YESTERDAY = 'yesterday'
export const TYPE_TRAILING_7_DAYS = 'trailing_7_days'
export const TYPE_TRAILING_90_DAYS = 'trailing_90_days'
export const TYPE_TRAILING_12_MONTHS = 'trailing_12_months'
export const TYPE_WEEK = 'week'
export const TYPE_CUSTOM = 'custom'
export const GROUP_BY_YEAR = 'business_year'
export const GROUP_BY_QUARTER = 'business_quarter'
export const GROUP_BY_PERIOD = 'business_month'
export const GROUP_BY_WEEK = 'business_week'
export const GROUP_BY_DAY = 'business_day'
export const GROUP_BY_LAST_X_DAYS = 'last_x_days'
export const GROUP_BY_LAST_X_WEEKS = 'last_x_weeks'
export const GROUP_BY_LAST_X_PERIODS = 'last_x_periods'

let defaultPeriod: IDateRange | null
let latestPeriod: IDateRange | null
let periodsByYear: { [year: number]: IDateRange[] } = {}
let quartersByYear: { [year: number]: IDateRange[] } = {}
let weeksByYear: { [year: number]: IDateRange[] } = {}
let years: IDateRange[] = []
let yearMap: { [year: number]: IDateRange } = {}
let dateRangeMap: IDateRangeMap = {}
let lastCustomStartDate: string = ''

let lastDataFetchDate: string = ''

async function getDateRanges(
  customStartDate: string = '01/01/2019',
  customEndDate?: string,
): Promise<IAllDateRangeData> {
  if (
    defaultPeriod &&
    latestPeriod &&
    years.length > 0 &&
    lastDataFetchDate &&
    moment().format(DATE_FORMAT) === lastDataFetchDate &&
    lastCustomStartDate === customStartDate
  ) {
    // use cached data
    return {
      defaultPeriod,
      latestPeriod,
      periodsByYear,
      quartersByYear,
      weeksByYear,
      years,
      yearMap,
      dateRangeMap,
    }
  } else {
    const currentApolloClient = apolloClient()
    const numDaysDelayToSetLatestPeriodAsDefault =
      process.env.REACT_APP_NUM_DAYS_DELAY_TO_SET_LATEST_PERIOD_AS_DEFAULT || 0

    if (currentApolloClient) {
      const currentTime = moment()

      let response: any
      try {
        response = await currentApolloClient.query({
          query: FETCH_PERIODS,
          variables: {
            minYear: moment.utc(customStartDate, 'MM/DD/YYYY', true).year(),
            queryDate: currentTime.format('YYYY-MM-DD'),
          },
        })
      } catch (error: any) {
        if (
          (error as Error).message !==
          'Store reset while query was in flight (not completed in link chain)'
        )
          console.log(error)
      }
      const rawPeriodList = _.get(response, 'data.listBusinessMonths.nodes')
      const rawWeekList = _.get(response, 'data.listBusinessWeeks.nodes')
      const rawDateInfo = _.get(response, 'data.getDateInfo.nodes[0]')

      if (
        Array.isArray(rawPeriodList) &&
        Array.isArray(rawWeekList) &&
        rawDateInfo
      ) {
        defaultPeriod = null
        latestPeriod = null
        periodsByYear = {}
        quartersByYear = {}
        weeksByYear = {}
        years = []
        yearMap = {}
        dateRangeMap = {}

        const allYears: any = {}
        const allQuarters: any = {}
        const allPeriods: IDateRange[] = []
        const allWeeks: IDateRange[] = []

        lastDataFetchDate = currentTime.format(DATE_FORMAT)

        // delay 1 day to show the new period
        const yesterday = currentTime.clone().subtract(1, 'day')
        const sevenDaysAgo = yesterday.clone().subtract(6, 'day')
        const ninetyDaysAgo = yesterday.clone().subtract(89, 'day')
        const twelveMonthsAgo = yesterday.clone().subtract(12, 'months')
        lastCustomStartDate = customStartDate
        const customStartDateMoment = moment(customStartDate, 'MM/DD/YYYY')

        rawPeriodList.forEach((data: any) => {
          if (data.year && data.period) {
            const periodStart = moment(data.periodStart, DATE_FORMAT)
            const periodEnd = moment(data.periodEnd, DATE_FORMAT)
            const customStartDateDiff = periodEnd.diff(customStartDateMoment)
            const customStartDateYear = parseInt(
              customStartDateMoment.format('YYYY'),
            )

            if (
              customEndDate &&
              periodEnd > moment(customEndDate, 'MM/DD/YYYY')
            )
              return

            if (periodStart.isValid() && periodEnd.isValid()) {
              if (yesterday >= periodStart && customStartDateDiff >= 0) {
                allPeriods.push({
                  key: `${periodStart.format(
                    DATE_COMPACT_FORMAT,
                  )}-${periodEnd.format(DATE_COMPACT_FORMAT)}`,
                  year: data.year,
                  type: TYPE_PERIOD,
                  index: data.period,
                  startDate: periodStart,
                  endDate: periodEnd,
                  startDateStr: periodStart.format(DATE_FORMAT),
                  endDateStr: periodEnd.format(DATE_FORMAT),
                  numDays: periodEnd.diff(periodStart, 'days') + 1,
                })
              }

              if (data.year && data.year >= customStartDateYear) {
                if (allYears[data.year]) {
                  if (periodStart < allYears[data.year].startDate) {
                    allYears[data.year].startDate = periodStart
                  }
                  if (periodEnd > allYears[data.year].endDate) {
                    allYears[data.year].endDate = periodEnd
                  }
                } else {
                  allYears[data.year] = {
                    year: data.year,
                    startDate: periodStart,
                    endDate: periodEnd,
                  }
                }
              }

              if (customStartDateDiff >= 0) {
                const quarterNum = data.quarter
                const quarterId = `${data.year}-${quarterNum}`
                if (allQuarters[quarterId]) {
                  if (periodStart < allQuarters[quarterId].startDate) {
                    allQuarters[quarterId].startDate = periodStart
                  }
                  if (periodEnd > allQuarters[quarterId].endDate) {
                    allQuarters[quarterId].endDate = periodEnd
                  }
                } else {
                  allQuarters[quarterId] = {
                    year: data.year,
                    quarter: quarterNum,
                    startDate: periodStart,
                    endDate: periodEnd,
                  }
                }
              }
            }
          }
        })

        rawWeekList.forEach((data: any) => {
          if (data.year && data.week) {
            const weekStart = moment(data.weekStart, DATE_FORMAT)
            const weekEnd = moment(data.weekEnd, DATE_FORMAT)
            const customStartDateDiff = weekEnd.diff(customStartDateMoment)

            if (weekStart.isValid() && weekEnd.isValid()) {
              if (yesterday >= weekStart && customStartDateDiff >= 0) {
                allWeeks.push({
                  key: `${weekStart.format(
                    DATE_COMPACT_FORMAT,
                  )}-${weekEnd.format(DATE_COMPACT_FORMAT)}`,
                  year: data.year,
                  type: TYPE_WEEK,
                  index: `${data.period}-${data.weekOfPeriod}`,
                  startDate: weekStart,
                  endDate: weekEnd,
                  startDateStr: weekStart.format(DATE_FORMAT),
                  endDateStr: weekEnd.format(DATE_FORMAT),
                  numDays: weekEnd.diff(weekStart, 'days') + 1,
                })
              }
            }
          }
        })

        _.sortBy(Object.values(allYears), ['year'])
          .reverse()
          .forEach((yearData: any) => {
            if (yesterday >= yearData.startDate) {
              const year: IDateRange = {
                key: `${yearData.startDate.format(
                  DATE_COMPACT_FORMAT,
                )}-${yearData.endDate.format(DATE_COMPACT_FORMAT)}`,
                year: yearData.year,
                type: TYPE_YEAR,
                index: yearData.year,
                startDate: yearData.startDate,
                endDate: yearData.endDate,
                startDateStr: yearData.startDate.format(DATE_FORMAT),
                endDateStr: yearData.endDate.format(DATE_FORMAT),
                numDays: yearData.endDate.diff(yearData.startDate, 'days') + 1,
              }

              dateRangeMap[year.key] = year
              years.push(year)
              yearMap[year.year] = year
            }
          })

        _.sortBy(Object.values(allQuarters), ['year', 'quarter'])
          .reverse()
          .forEach((quarterData: any) => {
            if (yesterday >= quarterData.startDate) {
              const quarter: IDateRange = {
                key: `${quarterData.startDate.format(
                  DATE_COMPACT_FORMAT,
                )}-${quarterData.endDate.format(DATE_COMPACT_FORMAT)}`,
                year: quarterData.year,
                type: TYPE_QUARTER,
                index: quarterData.quarter,
                startDate: quarterData.startDate,
                endDate: quarterData.endDate,
                startDateStr: quarterData.startDate.format(DATE_FORMAT),
                endDateStr: quarterData.endDate.format(DATE_FORMAT),
                numDays:
                  quarterData.endDate.diff(quarterData.startDate, 'days') + 1,
              }

              dateRangeMap[quarter.key] = quarter

              if (quartersByYear[quarterData.year]) {
                quartersByYear[quarterData.year].push(quarter)
              } else {
                quartersByYear[quarterData.year] = [quarter]
              }
            }
          })

        const sortedAllPeriods = _.sortBy(allPeriods, [
          'year',
          'index',
        ]).reverse()
        latestPeriod = sortedAllPeriods[0] || null
        if (latestPeriod) {
          if (
            moment().subtract(numDaysDelayToSetLatestPeriodAsDefault, 'day') >
            latestPeriod.startDate
          ) {
            defaultPeriod = latestPeriod
          } else {
            defaultPeriod = sortedAllPeriods[1] || null
          }
        }

        sortedAllPeriods.forEach((period) => {
          dateRangeMap[period.key] = period

          if (periodsByYear[period.year]) {
            periodsByYear[period.year].push(period)
          } else {
            periodsByYear[period.year] = [period]
          }
        })

        const sortedAllWeeks = allWeeks
          .sort((a, b) => {
            const aYear = a.year
            const bYear = b.year
            const [aPeriod, aWeek] = `${a.index}`.split('-')
            const [bPeriod, bWeek] = `${b.index}`.split('-')

            if (aYear - bYear !== 0) {
              return aYear - bYear
            }

            if (parseInt(aPeriod) - parseInt(bPeriod) !== 0) {
              return parseInt(aPeriod) - parseInt(bPeriod)
            }

            if (parseInt(aWeek) - parseInt(bWeek) !== 0) {
              return parseInt(aWeek) - parseInt(bWeek)
            }

            return 0
          })
          .reverse()

        sortedAllWeeks.forEach((week) => {
          dateRangeMap[week.key] = week

          if (weeksByYear[week.year]) {
            weeksByYear[week.year].push(week)
          } else {
            weeksByYear[week.year] = [week]
          }
        })

        dateRangeMap['yesterday'] = {
          key: 'yesterday',
          type: TYPE_YESTERDAY,
          year: yesterday.year(),
          index: 0,
          startDate: yesterday,
          endDate: yesterday,
          startDateStr: yesterday.format(DATE_FORMAT),
          endDateStr: yesterday.format(DATE_FORMAT),
          numDays: 0,
        }

        let thisWeekStart = moment(
          rawDateInfo.businessWeekBeginDate,
          'YYYY-MM-DD',
          true,
        )
        let thisWeekEnd = moment(
          rawDateInfo.businessWeekEndDate,
          'YYYY-MM-DD',
          true,
        )
        if (!thisWeekStart.isValid()) {
          thisWeekStart = currentTime.clone().startOf('week')
        }
        if (!thisWeekEnd.isValid()) {
          thisWeekEnd = thisWeekStart.clone().endOf('week')
        }

        const lastWeekStart = thisWeekStart.clone().subtract(1, 'week')
        const lastWeekEnd = thisWeekEnd.clone().subtract(1, 'week')

        dateRangeMap['last_week'] = {
          key: 'last_week',
          type: TYPE_LAST_WEEK,
          year: lastWeekStart.year(),
          index: 0,
          startDate: lastWeekStart,
          endDate: lastWeekEnd,
          startDateStr: lastWeekStart.format(DATE_FORMAT),
          endDateStr: lastWeekEnd.format(DATE_FORMAT),
          numDays: 0,
        }

        dateRangeMap['this_week'] = {
          key: 'this_week',
          type: TYPE_THIS_WEEK,
          year: thisWeekStart.year(),
          index: 0,
          startDate: thisWeekStart,
          endDate: thisWeekEnd,
          startDateStr: thisWeekStart.format(DATE_FORMAT),
          endDateStr: thisWeekEnd.format(DATE_FORMAT),
          numDays: 0,
        }

        dateRangeMap['trailing_7_days'] = {
          key: 'trailing_7_days',
          type: TYPE_TRAILING_7_DAYS,
          year: yesterday.year(),
          index: 0,
          startDate: sevenDaysAgo,
          endDate: yesterday,
          startDateStr: sevenDaysAgo.format(DATE_FORMAT),
          endDateStr: yesterday.format(DATE_FORMAT),
          numDays: 0,
        }

        dateRangeMap['trailing_90_days'] = {
          key: 'trailing_90_days',
          type: TYPE_TRAILING_90_DAYS,
          year: yesterday.year(),
          index: 0,
          startDate: ninetyDaysAgo,
          endDate: yesterday,
          startDateStr: ninetyDaysAgo.format(DATE_FORMAT),
          endDateStr: yesterday.format(DATE_FORMAT),
          numDays: 0,
        }

        dateRangeMap['trailing_12_months'] = {
          key: 'trailing_12_months',
          type: TYPE_TRAILING_12_MONTHS,
          year: yesterday.year(),
          index: 0,
          startDate: twelveMonthsAgo,
          endDate: yesterday,
          startDateStr: twelveMonthsAgo.format(DATE_FORMAT),
          endDateStr: yesterday.format(DATE_FORMAT),
          numDays: yesterday.diff(twelveMonthsAgo, 'days') + 1,
        }
      }
    }

    return {
      defaultPeriod,
      latestPeriod,
      periodsByYear,
      quartersByYear,
      weeksByYear,
      years,
      yearMap,
      dateRangeMap,
    }
  }
}

export default getDateRanges
