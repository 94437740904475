import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IDataType {
  trendLocationGroupMetricValues: {
    nodes: {
      startDate: string
      endDate: string
      metricData: Record<
        typeof METRIC_CODES[number],
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
}

const query = gql`
  query trendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_month"
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        startDate
        endDate
        metricData
      }
    }
  }
`

export const bbbTrendPeriodMetricConfigs = {
  bbbTrendPeriodItmeName: 'string',
  '<%- JSON(date?.periodCalendar[0].map(t => ({ key: "bbbTrend" + t.id, title: t.id }))) %>':
    'percent',
} as const

const METRIC_CODES = [
  'total_sales',
  'total_food_and_paper',
  'total_labor',
  'total_controllable_expenses_with_bank_charges_and_third_party_fees',
  'ebidta_with_bonus',
  'restaurant_level_profit',
] as const

const useBbbTrendPeriodMetric = () => {
  const { variables } = useVariables()
  const trendPeriods = variables.date?.periodCalendar[0] || []
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: trendPeriods[0]?.dateRange.startDateStr,
      iEndDate: trendPeriods.slice(-1)[0]?.dateRange.endDateStr,
      iLocationGroupIds: [variables.allStores?.locationGroupId],
      iMetricCodes: METRIC_CODES,
    },
    skip: !variables.date || !variables.allStores,
  })

  return {
    data: useMemo((): IApiDataType => {
      const metricData = data?.trendLocationGroupMetricValues.nodes

      if (!metricData) return null

      const metricPeriods = metricData.reduce((result, data) => {
        const period = trendPeriods.find(
          (t) =>
            t.dateRange.startDateStr === data.startDate &&
            t.dateRange.endDateStr === data.endDate,
        )

        if (!period) return result

        return {
          ...result,
          [period.id]: data.metricData,
        }
      }, {} as Record<string, IDataType['trendLocationGroupMetricValues']['nodes'][number]['metricData']>)

      return METRIC_CODES.filter((code) => code !== 'total_sales').map(
        (code) => ({
          ...trendPeriods.reduce(
            (result, period) => ({
              ...result,
              [`bbbTrend${period.id}`]:
                (metricPeriods[period.id]?.[code].value * 100) /
                metricPeriods[period.id]?.total_sales.value,
            }),
            {} as Record<string, number>,
          ),
          id: code,
          parentId: 'root',
          bbbTrendPeriodItmeName: {
            total_food_and_paper: 'Food & Paper',
            total_labor: 'Labor',
            total_controllable_expenses_with_bank_charges_and_third_party_fees:
              'Total Controllable Exp',
            ebidta_with_bonus: 'EBITDA after Bonus',
            restaurant_level_profit: 'Restaurant Level Margin',
            total_sales: 'Sales',
          }[code],
        }),
      )
    }, [data, trendPeriods]),
    loading,
  }
}

export default useBbbTrendPeriodMetric
