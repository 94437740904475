export interface IPropsType {
  type: 'image'
  source: string
  alt: string
  height?: string
}

export type IConfigsType = IPropsType

const Image = ({ source, alt, height }: IPropsType) => (
  <img height={height} src={source} alt={alt} />
)

export default Image
