import React, { useContext } from 'react'

import useGetDateRange from 'pared/hooks/useGetDateRange'

interface IDateFilterContextType {
  startDate?: string
  endDate?: string
}

interface IPropsType {
  children: React.ReactNode
}

const DateFilterContext = React.createContext<IDateFilterContextType>({})

export const useDateFilter = () => useContext(DateFilterContext)

const DateFilterProvider = ({ children }: IPropsType) => {
  const date = useGetDateRange({ isCustomAllowed: true })

  return (
    <DateFilterContext.Provider
      value={{ startDate: date?.startDateStr, endDate: date?.endDateStr }}
    >
      {children}
    </DateFilterContext.Provider>
  )
}

export default DateFilterProvider
