import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import getDateRanges, {
  IDateRange,
  TYPE_CUSTOM,
} from 'pared/data/getDateRanges'

interface IOptions {
  isCustomAllowed?: boolean
}

// FIXME: should be rewritten by useQuery
const useGetDateRange = (options?: IOptions) => {
  const { search } = useLocation()
  const [dateRange, setDateRange] = useState<IDateRange | null>(null)
  const state = useAsync(getDateRanges, [search])

  useEffect(() => {
    if (state.loading || !state.value) return

    const queryObject = new URLSearchParams(search)
    const currentDateRange = queryObject.get('date_range')

    if (!currentDateRange) {
      setDateRange(state.value.defaultPeriod)
    } else {
      if (options?.isCustomAllowed) {
        const [startDate, endDate] = currentDateRange.split('-')
        const start = moment(startDate)
        const end = moment(endDate)
        const customDateRange: IDateRange = {
          key: currentDateRange,
          type: TYPE_CUSTOM,
          startDate: start,
          endDate: end,
          startDateStr: start.format('YYYY-MM-DD'),
          endDateStr: end.format('YYYY-MM-DD'),
          year: parseInt(startDate.slice(0, 4)),
          numDays: end.diff(start, 'days') + 1,
          index: 0,
        }

        setDateRange(
          state.value.dateRangeMap[currentDateRange] || customDateRange,
        )
      } else {
        setDateRange(
          state.value.dateRangeMap[currentDateRange] ||
            state.value.defaultPeriod,
        )
      }
    }
  }, [state])

  return dateRange
}

export default useGetDateRange
