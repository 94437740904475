import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { INavParams } from 'pared/Routes/navParams'
import navigator from 'pared/Routes/navigator'
import getDateRanges, {
  IAllDateRangeData,
  IDateRange,
  TYPE_CUSTOM,
  TYPE_PERIOD,
  TYPE_THIS_WEEK,
} from 'pared/data/getDateRanges'
import {
  getBrandDefaultCalendarStartDate,
  getPathnameWithoutBrand,
} from 'pared/utils/brand'

import Main from './Main'
import useConfig from './hooks/useConfig'

interface IProps {
  navParams: INavParams
  isEnabled?: boolean
  customStartDate?: string
  customEndDate?: string
  dateRangeOptions?: string[]
  preSelectedDateRange?: string
  excludedDateRanges?: string[]
  isDailyCustom?: boolean
}

function DateRangeSelector({
  navParams,
  isEnabled,
  customStartDate,
  customEndDate,
  dateRangeOptions: dateRangeOptionsArg,
  preSelectedDateRange: preSelectedDateRangeArg,
  excludedDateRanges,
  isDailyCustom,
}: IProps) {
  const brandDefaultCalendarStartDate = getBrandDefaultCalendarStartDate()
  const config = useConfig()
  const dateRangeOptions = (
    dateRangeOptionsArg ?? config.dateRangeOptions
  ).filter((option) => !excludedDateRanges?.includes(option))
  const preSelectedDateRange =
    preSelectedDateRangeArg ?? config.preSelectedDateRange
  const isCustomAllowed = dateRangeOptions.includes(TYPE_CUSTOM)
  const [allDateRangeData, setAllDateRangeData] =
    useState<IAllDateRangeData | null>(null)
  const { search } = useLocation()

  useEffect(() => {
    async function fetchDateRanges() {
      const newAllDateRangeData = await getDateRanges(
        customStartDate || brandDefaultCalendarStartDate || '01/01/2019',
        customEndDate,
      )
      const years = newAllDateRangeData.years
      const queryObject = new URLSearchParams(search)
      const currentDateRange = queryObject.get('date_range')

      if (Array.isArray(years) && years.length > 0) {
        if (currentDateRange) {
          const dateRange = newAllDateRangeData.dateRangeMap[currentDateRange]

          if (
            navParams?.pagePathname &&
            (!dateRange || !dateRangeOptions.includes(dateRange.type)) &&
            !(isCustomAllowed && !isDailyCustom)
          ) {
            const newSearchParams = {
              dateRange: preSelectedDateRange || dateRangeOptions[0],
            }

            if (
              preSelectedDateRange === TYPE_PERIOD &&
              newAllDateRangeData.defaultPeriod
            ) {
              newSearchParams.dateRange = newAllDateRangeData.defaultPeriod.key
            }

            const pathnameWithoutBrand = getPathnameWithoutBrand(
              navParams.pagePathname,
            )

            navigator.go(pathnameWithoutBrand, newSearchParams)
          }
        } else if (preSelectedDateRange) {
          const newSearchParams = { dateRange: preSelectedDateRange }

          if (
            preSelectedDateRange === TYPE_PERIOD &&
            newAllDateRangeData.defaultPeriod
          ) {
            newSearchParams.dateRange = newAllDateRangeData.defaultPeriod.key
          }

          if (navParams?.pagePathname) {
            const pathnameWithoutBrand = getPathnameWithoutBrand(
              navParams?.pagePathname,
            )
            navigator.go(pathnameWithoutBrand, newSearchParams)
          }
        }

        setAllDateRangeData(newAllDateRangeData)
      } else {
        setAllDateRangeData(null)
      }
    }

    fetchDateRanges()
  }, [])

  async function handleDateRangeChange(dateRangeKey: string) {
    if (allDateRangeData) {
      const newSelectedDateRangeObj =
        allDateRangeData.dateRangeMap[dateRangeKey]
      if (
        (newSelectedDateRangeObj || isCustomAllowed) &&
        navParams &&
        navParams.pagePathname
      ) {
        const newSearchParams = { dateRange: dateRangeKey }
        const pathnameWithoutBrand = getPathnameWithoutBrand(
          navParams.pagePathname,
        )

        navigator.go(pathnameWithoutBrand, newSearchParams, undefined, true)
      }
    }
  }

  let selectedDateRangeObj: IDateRange | null = null
  if (allDateRangeData) {
    if (navParams && navParams.dateRange) {
      selectedDateRangeObj = allDateRangeData.dateRangeMap[navParams.dateRange]
    }
    if (!selectedDateRangeObj) {
      selectedDateRangeObj = allDateRangeData.defaultPeriod
      if (isCustomAllowed && navParams.dateRange) {
        const [startDate, endDate] = navParams.dateRange.split('-')
        const start = isDailyCustom ? moment(endDate) : moment(startDate)
        const end = moment(endDate)
        const customDateRange: IDateRange = {
          key: navParams.dateRange,
          type: TYPE_CUSTOM,
          startDate: start,
          endDate: end,
          startDateStr: start.format('YYYY-MM-DD'),
          endDateStr: end.format('YYYY-MM-DD'),
          year: parseInt(
            isDailyCustom ? endDate.slice(0, 4) : startDate.slice(0, 4),
          ),
          numDays: end.diff(start, 'days') + 1,
          index: 0,
        }

        selectedDateRangeObj = customDateRange
      }
    }
  }

  return (
    <Main
      allDateRangeData={allDateRangeData}
      selectedDateRange={selectedDateRangeObj}
      onDateRangeChange={handleDateRangeChange}
      isEnabled={isEnabled !== false}
      dateRangeOptions={dateRangeOptions}
      isDailyCustom={isDailyCustom}
    />
  )
}

export default DateRangeSelector
