import { useMemo, useState } from 'react'

export interface IDataType {
  bbbPnl?: {
    metricCode: {
      value: string[]
      onChange: (value: string[]) => void
      options: typeof P_AND_L_FIELDS
    }
    rankBy: {
      value: string[]
      onChange: (value: string[]) => void
      options: {
        id: string
        parentId: string
        displayName: string
      }[]
    }
    getBudgetCode: (id: string) => string
    getRequiredCodes: (id: string) => Record<string, string[]>
  }
}

export interface IOptionType {
  withRankOptions?: true
}

const P_AND_L_FIELDS = [
  { id: 'total_sales', parentId: 'root', displayName: 'Total Sales' },
  {
    id: 'total_food_sales',
    parentId: 'total_sales',
    displayName: 'Total Food Sales',
  },
  { id: 'food_sales', parentId: 'total_food_sales', displayName: 'Food Sales' },
  {
    id: 'food_sales_doordash',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Doordash',
  },
  {
    id: 'food_sales_ez_cater',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Ez Cater',
  },
  {
    id: 'food_sales_grubhub',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Grubhub',
  },
  {
    id: 'food_sales_foodsby',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Foodsby',
  },
  {
    id: 'food_sales_allset',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Allset',
  },
  {
    id: 'food_sales_fooda',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Fooda',
  },
  {
    id: 'food_sales_sharebite',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Sharebite',
  },
  {
    id: 'food_sales_flyer',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Flyer',
  },
  {
    id: 'food_sales_buckeye_id',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Buckeye ID',
  },
  {
    id: 'food_sales_yay_lunch',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Yay Lunch',
  },
  {
    id: 'food_sales_bearcat',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Bearcat',
  },
  {
    id: 'food_sales_flyer_express',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Flyer Express',
  },
  {
    id: 'food_sales_aramark',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Aramark',
  },
  {
    id: 'food_sales_catering',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Catering',
  },
  {
    id: 'food_sales_dine_in',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Dine In',
  },
  {
    id: 'food_sales_dispatch_delivery',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Dispatch Delivery',
  },
  {
    id: 'food_sales_forkable',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Forkable',
  },
  {
    id: 'food_sales_stadium',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Stadium',
  },
  {
    id: 'food_sales_online_sales',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Online Sales',
  },
  {
    id: 'food_sales_local_schools',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Local Schools',
  },
  {
    id: 'food_sales_online_togo',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Online To-Go',
  },
  {
    id: 'food_sales_togo',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - To-Go',
  },
  {
    id: 'food_sales_avi_catering',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Avi Catering',
  },
  {
    id: 'food_sales_try_hungry',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Try Hungry',
  },
  {
    id: 'food_sales_uber_eats',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Uber Eats',
  },
  {
    id: 'royalty_income',
    parentId: 'total_food_sales',
    displayName: 'Royalty Income',
  },
  {
    id: 'marketing_fund_income',
    parentId: 'total_food_sales',
    displayName: 'Marketing Fund Income',
  },
  {
    id: 'coke_rebate_income',
    parentId: 'total_food_sales',
    displayName: 'Coke Rebate Income',
  },
  {
    id: 'total_coupons_and_discounts',
    parentId: 'total_sales',
    displayName: 'Total Coupons & Discounts',
  },
  {
    id: 'coupons_and_discounts',
    parentId: 'total_coupons_and_discounts',
    displayName: 'Coupons & Discounts',
  },
  {
    id: 'employee_discount',
    parentId: 'total_coupons_and_discounts',
    displayName: 'Employee Discount',
  },
  {
    id: 'coupons',
    parentId: 'total_coupons_and_discounts',
    displayName: 'Coupons',
  },
  { id: 'ppa_revenue', parentId: 'total_sales', displayName: 'PPA Revenue' },
  { id: 'srec_revenue', parentId: 'total_sales', displayName: 'SREC Revenue' },
  {
    id: 'contribution_revenue',
    parentId: 'total_sales',
    displayName: 'Contribution Revenue',
  },
  {
    id: 'rental_income',
    parentId: 'total_sales',
    displayName: 'Rental Income',
  },
  {
    id: 'state_grant_income',
    parentId: 'total_sales',
    displayName: 'State Grant Income',
  },
  {
    id: 'ckids_coin_revenue',
    parentId: 'total_sales',
    displayName: 'CKids Coin Revenue',
  },
  {
    id: 'ckids_combo_revenue',
    parentId: 'total_sales',
    displayName: 'CKids Combo Revenue',
  },
  {
    id: 'ckids_bibibop_contribution',
    parentId: 'total_sales',
    displayName: 'CKids Bibibop Contribution',
  },
  { id: 'total_cogs', parentId: 'root', displayName: 'Total COGS' },
  {
    id: 'total_food_and_paper',
    parentId: 'total_cogs',
    displayName: 'Total Food & Paper',
  },
  {
    id: 'food_and_paper',
    parentId: 'total_food_and_paper',
    displayName: 'Food & Paper',
  },
  {
    id: 'food_meat',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Meat',
  },
  {
    id: 'food_dairy',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Dairy',
  },
  {
    id: 'food_produce',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Produce',
  },
  {
    id: 'food_grocery',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Grocery',
  },
  {
    id: 'food_beverages',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Beverages',
  },
  {
    id: 'food_chocolate',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Chocolate',
  },
  { id: 'paper', parentId: 'total_food_and_paper', displayName: 'Paper' },
  {
    id: 'food_waste',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Waste',
  },
  { id: 'total_labor', parentId: 'total_cogs', displayName: 'Total Labor' },
  { id: 'labor', parentId: 'total_labor', displayName: 'Labor' },
  {
    id: 'manager_salary_exempt',
    parentId: 'total_labor',
    displayName: 'Manager Salary-Exempt',
  },
  {
    id: 'dm_salary_store',
    parentId: 'total_labor',
    displayName: 'DM Salary-Store',
  },
  { id: 'crew_labor', parentId: 'total_labor', displayName: 'Crew Labor' },
  {
    id: 'total_payroll_taxes',
    parentId: 'total_cogs',
    displayName: 'Total Payroll Taxes',
  },
  {
    id: 'payroll_taxes',
    parentId: 'total_payroll_taxes',
    displayName: 'Payroll Taxes',
  },
  { id: 'fica', parentId: 'total_payroll_taxes', displayName: 'F.I.C.A.' },
  { id: 'futa', parentId: 'total_payroll_taxes', displayName: 'F.U.T.A.' },
  { id: 'suta', parentId: 'total_payroll_taxes', displayName: 'S.U.T.A.' },
  {
    id: 'total_payroll_benefits',
    parentId: 'total_cogs',
    displayName: 'Total Payroll Benefits',
  },
  {
    id: 'payroll_benefits',
    parentId: 'total_payroll_benefits',
    displayName: 'Payroll Benefits',
  },
  { id: 'bonus', parentId: 'total_payroll_benefits', displayName: 'Bonus' },
  {
    id: 'four_o_one_k_match_contri',
    parentId: 'total_payroll_benefits',
    displayName: '401K Match Contri',
  },
  {
    id: 'workers_comp',
    parentId: 'total_payroll_benefits',
    displayName: 'Workers Comp',
  },
  {
    id: 'employee_ins',
    parentId: 'total_payroll_benefits',
    displayName: 'Employee Ins.',
  },
  {
    id: 'employee_ins_pmt',
    parentId: 'total_payroll_benefits',
    displayName: 'Employee Ins Pmt',
  },
  {
    id: 'employee_incentive',
    parentId: 'total_payroll_benefits',
    displayName: 'Employee Incentive',
  },
  {
    id: 'referral_bonus',
    parentId: 'total_payroll_benefits',
    displayName: 'Referral Bonus',
  },
  { id: 'gross_profit', parentId: 'root', displayName: 'Gross Profit' },
  {
    id: 'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    parentId: 'root',
    displayName:
      'Total Controllable Expenses With Bank Charges and Third Party Fees',
  },
  {
    id: 'total_direct_operating',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Direct Operating',
  },
  {
    id: 'direct_operating',
    parentId: 'total_direct_operating',
    displayName: 'Direct Operating',
  },
  {
    id: 'store_smallwares',
    parentId: 'total_direct_operating',
    displayName: 'Store Smallwares',
  },
  {
    id: 'cleaning_supplies',
    parentId: 'total_direct_operating',
    displayName: 'Cleaning Supplies',
  },
  {
    id: 'uniform_supplies',
    parentId: 'total_direct_operating',
    displayName: 'Uniform Supplies',
  },
  {
    id: 'linen_services',
    parentId: 'total_direct_operating',
    displayName: 'Linen Services',
  },
  {
    id: 'pest_control',
    parentId: 'total_direct_operating',
    displayName: 'Pest Control',
  },
  {
    id: 'grease_trap',
    parentId: 'total_direct_operating',
    displayName: 'Grease Trap',
  },
  {
    id: 'knife_services',
    parentId: 'total_direct_operating',
    displayName: 'Knife Services',
  },
  {
    id: 'cleaning_services',
    parentId: 'total_direct_operating',
    displayName: 'Cleaning Services',
  },
  {
    id: 'security_services',
    parentId: 'total_direct_operating',
    displayName: 'Security Services',
  },
  {
    id: 'total_administrative_and_general',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Administrative & General',
  },
  {
    id: 'administrative_and_general',
    parentId: 'total_administrative_and_general',
    displayName: 'Administrative & General',
  },
  {
    id: 'charitable_contributions',
    parentId: 'total_administrative_and_general',
    displayName: 'Charitable Contributions',
  },
  {
    id: 'postage_and_mailing',
    parentId: 'total_administrative_and_general',
    displayName: 'Postage & Mailing',
  },
  {
    id: 'printing_miscellaneous',
    parentId: 'total_administrative_and_general',
    displayName: 'Printing Miscellaneous',
  },
  {
    id: 'printing_expenses_menu',
    parentId: 'total_administrative_and_general',
    displayName: 'Printing Expenses-Menu',
  },
  {
    id: 'music_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Music Services',
  },
  {
    id: 'digital_advertising_expenses',
    parentId: 'total_administrative_and_general',
    displayName: 'Digital Advertising Expenses',
  },
  {
    id: 'nondigital_advertising_expenses',
    parentId: 'total_administrative_and_general',
    displayName: 'Non-Digital Advertising Expens',
  },
  {
    id: 'landscape_maint',
    parentId: 'total_administrative_and_general',
    displayName: 'Landscape Maint.',
  },
  {
    id: 'swag',
    parentId: 'total_administrative_and_general',
    displayName: 'SWAG',
  },
  {
    id: 'other_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Other Services',
  },
  {
    id: 'wall_improvements',
    parentId: 'total_administrative_and_general',
    displayName: 'Wall Improvements',
  },
  {
    id: 'bibishop',
    parentId: 'total_administrative_and_general',
    displayName: 'BibiShop',
  },
  {
    id: 'store_supplies',
    parentId: 'total_administrative_and_general',
    displayName: 'Store Supplies',
  },
  {
    id: 'office_supplies',
    parentId: 'total_administrative_and_general',
    displayName: 'Office Supplies',
  },
  {
    id: 'printing_and_advertising',
    parentId: 'total_administrative_and_general',
    displayName: 'Printing & Advertising',
  },
  {
    id: 'postage_and_delivery',
    parentId: 'total_administrative_and_general',
    displayName: 'Postage & Delivery',
  },
  {
    id: 'delivery_servicesegift',
    parentId: 'total_administrative_and_general',
    displayName: 'Delivery Services-eGift',
  },
  {
    id: 'total_bank_charges_and_fees',
    parentId: 'total_administrative_and_general',
    displayName: 'Total Bank Charges & Fees',
  },
  {
    id: 'bank_charges_and_fees',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Bank Charges & Fees',
  },
  {
    id: 'bank_charges',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Bank Charges',
  },
  {
    id: 'refunds_and_chargebacks',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Refunds & Chargebacks',
  },
  {
    id: 'credit_card_fees',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Credit Card Fees',
  },
  {
    id: 'gift_card_fee',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Gift Card Fee',
  },
  {
    id: 'restaurant_service_fee',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Restaurant Service Fee',
  },
  {
    id: 'third_party_fee',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Third Party Fee',
  },
  {
    id: 'cash_over_or_short',
    parentId: 'total_administrative_and_general',
    displayName: 'Cash Over/Short',
  },
  {
    id: 'cr_card_over_or_short',
    parentId: 'total_administrative_and_general',
    displayName: 'Cr Card Over/Short',
  },
  {
    id: 'gc_over_or_short',
    parentId: 'total_administrative_and_general',
    displayName: 'GC Over/Short',
  },
  {
    id: 'licenses_permits',
    parentId: 'total_administrative_and_general',
    displayName: 'Licenses & Permits',
  },
  {
    id: 'payroll_processing_fee',
    parentId: 'total_administrative_and_general',
    displayName: 'Payroll Processing Fee',
  },
  {
    id: 'business_liability_ins',
    parentId: 'total_administrative_and_general',
    displayName: 'Business Liability Ins',
  },
  {
    id: 'training_and_seminars',
    parentId: 'total_administrative_and_general',
    displayName: 'Training & Seminars',
  },
  {
    id: 'subscriptions_and_dues',
    parentId: 'total_administrative_and_general',
    displayName: 'Subscriptions & Dues',
  },
  {
    id: 'meals',
    parentId: 'total_administrative_and_general',
    displayName: 'Meals',
  },
  {
    id: 'land_travel',
    parentId: 'total_administrative_and_general',
    displayName: 'Land Travel',
  },
  {
    id: 'air_travel',
    parentId: 'total_administrative_and_general',
    displayName: 'Air Travel',
  },
  {
    id: 'lodging',
    parentId: 'total_administrative_and_general',
    displayName: 'Lodging',
  },
  {
    id: 'acct_and_tax_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Acct. & Tax Services',
  },
  {
    id: 'legal_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Legal Services',
  },
  {
    id: 'consulting_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Consulting Services',
  },
  {
    id: 'management_fees',
    parentId: 'total_administrative_and_general',
    displayName: 'Management Fees',
  },
  {
    id: 'management_fee_troupsburg_ny',
    parentId: 'total_administrative_and_general',
    displayName: 'Management Fee-Troupsburg NY',
  },
  {
    id: 'management_fee_rochester_fourty_one',
    parentId: 'total_administrative_and_general',
    displayName: 'Management Fee-Rochester-41',
  },
  {
    id: 'srec_fees',
    parentId: 'total_administrative_and_general',
    displayName: 'SREC Fees',
  },
  {
    id: 'miscellaneous',
    parentId: 'total_administrative_and_general',
    displayName: 'Miscellaneous',
  },
  {
    id: 'leasing',
    parentId: 'total_administrative_and_general',
    displayName: 'Leasing',
  },
  {
    id: 'recruiting_fees',
    parentId: 'total_administrative_and_general',
    displayName: 'Recruiting Fees',
  },
  {
    id: 'donations',
    parentId: 'total_administrative_and_general',
    displayName: 'Donations',
  },
  {
    id: 'contribution_materials',
    parentId: 'total_administrative_and_general',
    displayName: 'Contribution Materials',
  },
  {
    id: 'relocation_expense',
    parentId: 'total_administrative_and_general',
    displayName: 'Relocation Expense',
  },
  {
    id: 'research_and_development',
    parentId: 'total_administrative_and_general',
    displayName: 'Research & Development',
  },
  {
    id: 'total_utilities',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Utilities',
  },
  { id: 'utilities', parentId: 'total_utilities', displayName: 'Utilities' },
  {
    id: 'internet_service',
    parentId: 'total_utilities',
    displayName: 'Internet Service',
  },
  { id: 'telephone', parentId: 'total_utilities', displayName: 'Telephone' },
  { id: 'electric', parentId: 'total_utilities', displayName: 'Electric' },
  { id: 'gas', parentId: 'total_utilities', displayName: 'Gas' },
  {
    id: 'water_and_sewer',
    parentId: 'total_utilities',
    displayName: 'Water & Sewer',
  },
  {
    id: 'trash_removal',
    parentId: 'total_utilities',
    displayName: 'Trash Removal',
  },
  { id: 'spinkler', parentId: 'total_utilities', displayName: 'Spinkler' },
  {
    id: 'total_repairs_and_maintenance',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Repairs & Maintenance',
  },
  {
    id: 'repairs_and_maintenance',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Repairs & Maintenance',
  },
  {
    id: 'hvac_exhaust',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'HVAC & Exhaust',
  },
  {
    id: 'oven',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Oven',
  },
  {
    id: 'kitchen_appliances',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Kitchen Appliances',
  },
  {
    id: 'plumbing',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Plumbing',
  },
  {
    id: 'ice_machine',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Ice Machine',
  },
  {
    id: 'refrigeration',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Refrigeration',
  },
  {
    id: 'stove',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Stove',
  },
  {
    id: 'grill',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Grill',
  },
  {
    id: 'register',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Register',
  },
  {
    id: 'menu_board',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Menu Board',
  },
  {
    id: 'other_repairs',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Other Repairs',
  },
  {
    id: 'tools_and_equip',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Tools & Equip',
  },
  {
    id: 'maintenance_project',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Maintenance - Project',
  },
  {
    id: 'maintenance_property',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Maintenance - Property',
  },
  {
    id: 'maintenance_equipment',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Maintenance - Equipment',
  },
  {
    id: 'total_store_marketing',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Store Marketing',
  },
  {
    id: 'store_marketing',
    parentId: 'total_store_marketing',
    displayName: 'Store Marketing',
  },
  {
    id: 'marketing_relations',
    parentId: 'total_store_marketing',
    displayName: 'Marketing Relations',
  },
  {
    id: 'customer_relations',
    parentId: 'total_store_marketing',
    displayName: 'Customer Relations',
  },
  {
    id: 'local_store_marketing',
    parentId: 'total_store_marketing',
    displayName: 'Local Store Marketing',
  },
  {
    id: 'catering_development',
    parentId: 'total_store_marketing',
    displayName: 'Catering Development',
  },
  {
    id: 'total_preopening_expense',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Preopening Expense',
  },
  {
    id: 'preopening_expense',
    parentId: 'total_preopening_expense',
    displayName: 'Preopening Expense',
  },
  {
    id: 'opening_food_bev',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Food & Bev',
  },
  {
    id: 'opening_paper',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Paper',
  },
  {
    id: 'opening_cleaning',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Cleaning',
  },
  {
    id: 'opening_mgr_salary',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Mgr. Salary',
  },
  {
    id: 'opening_crew',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Crew',
  },
  {
    id: 'opening_fica',
    parentId: 'total_preopening_expense',
    displayName: 'Opening F.I.C.A',
  },
  {
    id: 'opening_futa',
    parentId: 'total_preopening_expense',
    displayName: 'Opening F.U.T.A',
  },
  {
    id: 'opening_suta',
    parentId: 'total_preopening_expense',
    displayName: 'Opening S.U.T.A',
  },
  {
    id: 'opening_bonus',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Bonus',
  },
  {
    id: 'opening_emp_ins',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Emp Ins.',
  },
  {
    id: 'opening_travel',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Travel',
  },
  {
    id: 'opening_lodging',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Lodging',
  },
  {
    id: 'opening_store_mktg',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Store Mktg',
  },
  {
    id: 'opening_misc',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Misc',
  },
  {
    id: 'opening_rent',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Rent',
  },
  {
    id: 'training_material',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Training Material',
  },
  {
    id: 'total_controllable_expenses_without_bank_charges_and_third_party_fees',
    parentId: 'root',
    displayName:
      'Total Controllable Expenses Without Bank Charges and Third Party Fees',
  },
  {
    id: 'profit_after_controllables_before_donation',
    parentId: 'root',
    displayName: 'Profit After Controllables before Donation',
  },
  {
    id: 'profit_after_controllables_after_donation',
    parentId: 'root',
    displayName: 'Profit After Controllables After Donation',
  },
  {
    id: 'total_noncontrollable_expenses',
    parentId: 'root',
    displayName: 'Total Non-Controllable Expenses',
  },
  {
    id: 'total_occupancy_costs',
    parentId: 'total_noncontrollable_expenses',
    displayName: 'Total Occupancy Costs',
  },
  {
    id: 'occupancy_costs',
    parentId: 'total_occupancy_costs',
    displayName: 'Occupancy Costs',
  },
  {
    id: 'base_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Base Rent',
  },
  {
    id: 'storage_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Storage Rent',
  },
  {
    id: 'percentage_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Percentage Rent',
  },
  {
    id: 'food_court_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Food Court Rent',
  },
  {
    id: 'common_area_maint',
    parentId: 'total_occupancy_costs',
    displayName: 'Common Area Maint.',
  },
  {
    id: 'promo_media_fund',
    parentId: 'total_occupancy_costs',
    displayName: 'Promo & Media Fund',
  },
  {
    id: 'real_estate_taxes',
    parentId: 'total_occupancy_costs',
    displayName: 'Real Estate Taxes',
  },
  {
    id: 'building_insurance',
    parentId: 'total_occupancy_costs',
    displayName: 'Building Insurance',
  },
  {
    id: 'other_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Other Rent',
  },
  {
    id: 'deferred_rent_expense',
    parentId: 'total_occupancy_costs',
    displayName: 'Deferred Rent Expense',
  },
  {
    id: 'total_royalty_and_cafi',
    parentId: 'total_noncontrollable_expenses',
    displayName: 'Total Royalty & CAFI',
  },
  {
    id: 'royalty_and_cafi',
    parentId: 'total_royalty_and_cafi',
    displayName: 'Royalty & CAFI',
  },
  { id: 'royalty', parentId: 'total_royalty_and_cafi', displayName: 'Royalty' },
  {
    id: 'marketing_fund',
    parentId: 'total_royalty_and_cafi',
    displayName: 'Marketing Fund',
  },
  {
    id: 'total_depreciation_and_amortization',
    parentId: 'total_noncontrollable_expenses',
    displayName: 'Total Depreciation and Amortization',
  },
  {
    id: 'depreciation',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation',
  },
  {
    id: 'depreciation_lhi',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-LHI',
  },
  {
    id: 'depreciation_re',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-R/E',
  },
  {
    id: 'depreciation_f_and_f',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-F&F',
  },
  {
    id: 'depreciation_oe',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-O/E',
  },
  {
    id: 'depreciation_vehicle',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-Vehicle',
  },
  {
    id: 'depreciation_franchise_fee',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation Franchise Fee',
  },
  {
    id: 'depr_exp_solar_projects',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depr Exp - Solar Projects',
  },
  {
    id: 'depr_exp_solar_additions',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depr Exp -Solar Additions',
  },
  {
    id: 'depreciation_building',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-Building',
  },
  {
    id: 'depreciation_property_improvement',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-Property Improvement',
  },
  {
    id: 'tg_income_amortization',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'TG Income Amortization',
  },
  {
    id: 'startup_costs_amortization',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Startup Costs Amortization',
  },
  {
    id: 'debt_issuance_costs_amortization',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Debt Issuance Costs Amortization',
  },
  {
    id: 'finance_lease_amortization_expense',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Finance Lease Amortization Expense',
  },
  {
    id: 'dead_project_expenses',
    parentId: 'total_noncontrollable_expenses',
    displayName: 'Dead Project Expenses',
  },
  { id: 'total_other', parentId: 'root', displayName: 'Total Other' },
  {
    id: 'inkind_revenue',
    parentId: 'total_other',
    displayName: 'In-Kind Revenue',
  },
  {
    id: 'unrealized_gainsloss',
    parentId: 'total_other',
    displayName: 'Unrealized Gains/Loss',
  },
  {
    id: 'total_other_expense_or_income',
    parentId: 'total_other',
    displayName: 'Total Other Expense/Income',
  },
  {
    id: 'other_expense_or_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Other Expense/Income',
  },
  {
    id: 'total_interest_expense_or_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Total Interest Expense/Income',
  },
  {
    id: 'interest_expense_or_income',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest Expense/Income',
  },
  {
    id: 'interest_auxilior_loan',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest Auxilior Loan',
  },
  {
    id: 'interest_hb_loan_12m',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest HB loan 12M (2of $6M)',
  },
  {
    id: 'interest_hb_loan_12m_to_10m',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest HB loan 12M to 10M',
  },
  {
    id: 'interest_hb_loan_65m_manlius',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest HB loan 6.5M (Manlius)',
  },
  {
    id: 'finance_interest_expense',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Finance Interest Expense',
  },
  {
    id: 'operating_lease_expense',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Operating Lease Expense',
  },
  {
    id: 'dividend_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Dividend Income',
  },
  {
    id: 'miscellaneous_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Miscellaneous Income',
  },
  {
    id: 'accounting_service_revenue',
    parentId: 'total_other_expense_or_income',
    displayName: 'Accounting Service Revenue',
  },
  {
    id: 'other_investment_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Other Investment Income',
  },
  {
    id: 'partnership_investment_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Partnership Investment Income',
  },
  {
    id: 'gainloss_on_sale_of_assets',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on Sale of Assets',
  },
  {
    id: 'realized_gainloss',
    parentId: 'total_other_expense_or_income',
    displayName: 'Realized Gain/Loss',
  },
  {
    id: 'gainloss_on_fv_of_interest_rate_swap',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on FV of Interest Rate Swap',
  },
  {
    id: 'gainloss_on_investments',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on Investments',
  },
  {
    id: 'gainloss_on_finance_lease',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on Finance Lease',
  },
  {
    id: 'gainloss_on_operating_lease',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on Operating Lease',
  },
  {
    id: 'penalty_and_fees',
    parentId: 'total_other_expense_or_income',
    displayName: 'Penalty & Fees',
  },
  {
    id: 'bad_debt_expenses',
    parentId: 'total_other_expense_or_income',
    displayName: 'Bad Debt Expenses',
  },
  {
    id: 'area_support',
    parentId: 'total_other_expense_or_income',
    displayName: 'Area Support',
  },
  {
    id: 'extraordinary_items',
    parentId: 'total_other_expense_or_income',
    displayName: 'Extraordinary Items',
  },
  {
    id: 'dss_research',
    parentId: 'total_other_expense_or_income',
    displayName: 'DSS RESEARCH',
  },
  { id: 'total_taxes', parentId: 'root', displayName: 'Total Taxes' },
  { id: 'taxes', parentId: 'total_taxes', displayName: 'Taxes' },
  {
    id: 'federal_income_taxes',
    parentId: 'total_taxes',
    displayName: 'Federal Income Taxes',
  },
  {
    id: 'state_income_tax',
    parentId: 'total_taxes',
    displayName: 'State Income Tax',
  },
  {
    id: 'city_income_taxes',
    parentId: 'total_taxes',
    displayName: 'City Income Taxes',
  },
  {
    id: 'business_property_tax',
    parentId: 'total_taxes',
    displayName: 'Business Property Tax',
  },
  {
    id: 'suspense_account',
    parentId: 'total_taxes',
    displayName: 'Suspense Account',
  },
  { id: 'net_profit', parentId: 'root', displayName: 'Net Profit' },
  {
    id: 'ebidta_without_bonus',
    parentId: 'root',
    displayName: 'EBIDTA Without Bonus',
  },
  {
    id: 'ebidta_with_bonus',
    parentId: 'root',
    displayName: 'EBIDTA With Bonus',
  },
  { id: 'cash_flow', parentId: 'root', displayName: 'Cash Flow' },
  {
    id: 'total_restaurant_operating_expenses',
    parentId: 'root',
    displayName: 'Total Restaurant Operating Expenses',
  },
  {
    id: 'restaurant_operating_expenses_food_and_paper',
    parentId: 'total_restaurant_operating_expenses',
    dataId: 'total_food_and_paper',
    displayName: 'Food & Paper',
  },
  {
    id: 'restaurant_operating_expenses_labor',
    parentId: 'total_restaurant_operating_expenses',
    dataId: 'total_labor',
    displayName: 'Labor',
  },
  {
    id: 'occupancy',
    parentId: 'total_restaurant_operating_expenses',
    displayName: 'Occupancy',
  },
  {
    id: 'other_operating_expenses',
    parentId: 'total_restaurant_operating_expenses',
    displayName: 'Other Operating Expenses',
  },
  {
    id: 'restaurant_level_profit',
    parentId: 'root',
    displayName: 'Restaurant-Level Profit',
  },
]

const getBudgetCode = (id: string) => {
  if (/^food_sales/.test(id))
    return id.replace(/food_sales/, 'food_sales_budget')

  if (/^food/.test(id)) {
    if (['food_and_paper', 'food_waste', 'food_court_rent'].includes(id))
      return `${id}_budget`

    return id.replace(/food/, 'food_budget')
  }

  return `${id}_budget`
}

const P_NAD_L_MAPPING = P_AND_L_FIELDS.reduce((result, field) => {
  const dataId: string = field.dataId || field.id
  const budgetId = getBudgetCode(dataId)
  const totalSalesId = 'total_sales'
  const totalSalesBudgetId = getBudgetCode('total_sales')

  return {
    ...result,
    [field.id]: {
      pAndLSales: [dataId],
      pAndLSalesPercent: [dataId, totalSalesId],
      pAndLBudget: [budgetId],
      pAndLBudgetPercent: [budgetId, totalSalesBudgetId],
      pAndLCompare: [dataId, budgetId],
      pAndLComparePercent: [dataId, budgetId, totalSalesId, totalSalesBudgetId],
      pAndLYoySales: [`yoy_${dataId}`],
      pAndLYoySalesPercent: [`yoy_${dataId}`, `yoy_${totalSalesId}`],
      pAndLYoyCompare: [dataId, `yoy_${dataId}`],
      pAndLYoyComparePercent: [
        dataId,
        `yoy_${dataId}`,
        totalSalesId,
        `yoy_${totalSalesId}`,
      ],
      pAndLYtdSales: [`ytd_${dataId}`],
      pAndLYtdSalesPercent: [`ytd_${dataId}`, `ytd_${totalSalesId}`],
      pAndLYtdBudget: [`ytd_${budgetId}`],
      pAndLYtdBudgetPercent: [`ytd_${budgetId}`, `ytd_${totalSalesBudgetId}`],
      pAndLYtdCompare: [`ytd_${dataId}`, `ytd_${budgetId}`],
      pAndLYtdComparePercent: [
        `ytd_${dataId}`,
        `ytd_${budgetId}`,
        `ytd_${totalSalesId}`,
        `ytd_${totalSalesBudgetId}`,
      ],
      pAndLYytdSales: [`yytd_${dataId}`],
      pAndLYytdSalesPercent: [`yytd_${dataId}`, `yytd_${totalSalesId}`],
      pAndLYytdCompare: [`ytd_${dataId}`, `yytd_${dataId}`],
      pAndLYytdComparePercent: [
        `ytd_${dataId}`,
        `yytd_${dataId}`,
        `ytd_${totalSalesId}`,
        `yytd_${totalSalesId}`,
      ],
    },
  }
}, {} as Record<string, Record<string, string[]>>)

const useBbbPnl = ({ withRankOptions }: IOptionType): IDataType => {
  const metricCodeHook = useState(['total_sales', 'total_sales.total'])
  const rankByHook = useState(['pAndLSales'])
  const metricCodeOptions = useMemo(() => {
    if (!withRankOptions) return P_AND_L_FIELDS

    const hasSubOptions = P_AND_L_FIELDS.reduce(
      (result, field) =>
        field.parentId === 'root'
          ? result
          : {
              ...result,
              [field.parentId]: (result[field.parentId] || 0) + 1,
            },
      {} as Record<string, number>,
    )

    return [
      ...Object.keys(hasSubOptions).map((key) => ({
        id: `${key}.total`,
        parentId: key,
        displayName: 'Total',
      })),
      ...P_AND_L_FIELDS,
    ]
  }, [withRankOptions])

  return {
    bbbPnl: useMemo(
      () => ({
        metricCode: {
          value: metricCodeHook[0],
          onChange: metricCodeHook[1],
          options: metricCodeOptions,
        },
        rankBy: {
          value: rankByHook[0],
          onChange: rankByHook[1],
          options: [
            'pAndLSales',
            'pAndLCompare',
            'pAndLYtdCompare',
            'pAndLYoyCompare',
            'pAndLYytdCompare',
          ].map((column) => ({
            id: column,
            parentId: 'root',
            displayName: {
              pAndLSales: 'Current',
              pAndLCompare: 'Current vs Budget',
              pAndLYtdCompare: 'Current YTD vs Budget YTD',
              pAndLYoyCompare: 'Current vs. PY',
              pAndLYytdCompare: 'Current YTD vs. Prior YTD',
            }[column] as string,
          })),
        },
        getBudgetCode,
        getRequiredCodes: (id) => P_NAD_L_MAPPING[id],
      }),
      [metricCodeHook, rankByHook, metricCodeOptions],
    ),
  }
}

export default useBbbPnl
