import * as d3 from 'd3-hierarchy'
import { useEffect, useMemo } from 'react'

import { IPropsType, IValueType } from '../MenuItem'

export interface IValidateItemsOptionsType {
  value: IValueType['id'][]
  onChange: IPropsType['onChange']
  options: d3.HierarchyNode<IValueType>
}

const findNode = ({
  value,
  options,
}: Omit<IValidateItemsOptionsType, 'onChange'>): IValueType[] => {
  if (!options.children || value.length === 0) return []

  const subOption = options.children.find(
    ({ data: { id: childId } }) => value[0] === childId,
  )

  if (!subOption) return []

  return [
    subOption.data,
    ...findNode({
      value: value.slice(1),
      options: subOption,
    }),
  ]
}

const getFirstNodes = ({
  data,
  children,
}: d3.HierarchyNode<IValueType>): IValueType[] => [
  data,
  ...(!children ? [] : getFirstNodes(children[0])),
]

const useValidateItems = ({
  value,
  onChange,
  options,
}: IValidateItemsOptionsType) => {
  const node = useMemo(() => findNode({ value, options }), [value, options])

  useEffect(() => {
    if (options.children && node.length === 0) {
      const firstNode = getFirstNodes(options.children[0])

      onChange(
        firstNode.map(({ id }) => id),
        firstNode,
      )
    }
  }, [node, options])

  return node
}

export default useValidateItems
