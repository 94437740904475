import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'

import { ActionProvider } from './Action'
import Head from './Head'
import Pagination from './Pagination'
import useApi, { IApiKeyType, configs } from './hooks/useApi'
import useColumns, { IColumnsOptionsType } from './hooks/useColumns'
import useDataSource from './hooks/useDataSource'
import useGroupInfoColumns, {
  IGroupInfoConfigsType,
} from './hooks/useGroupInfoColumns'
import useMergedBody from './hooks/useMergedBody'
import usePagination from './hooks/usePagination'
import useSortedField from './hooks/useSortedField'
import useSummaryDataSource from './hooks/useSummaryDataSource'
import { IColumnsType as ColumnsType } from './types'

type IColumnsType<Data> = ColumnsType<Data>
export interface IPropsType<K extends IApiKeyType = IApiKeyType>
  extends IColumnsOptionsType<K> {
  type: 'table'
  api: K
  minWidth?: string
  rowKey?: keyof typeof configs[K] | 'groupInfo.id'
  pageSize?: number
  defaultSortedField?: keyof typeof configs[K] | 'groupInfo'
  highlineEvenRows?: boolean
  groupInfo?: IGroupInfoConfigsType
  bordered?: boolean
  heatmap?: { direction: 'asc' | 'desc' }
  summaryToTop?: boolean
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const TD_PROPS = ['align', 'cellStyle', 'backgroundColor'] as const

const Root = styled.div`
  margin-top: 20px;
`

const StyledTable = styled.table<{ tableStyle: string }>`
  border-collapse: collapse;
  ${({ tableStyle }) => tableStyle}
`

const Tr = styled.tr<
  { rowStyle: string } & {
    summary?: 0 | 1
  } & { summaryToTop?: false | true }
>`
  ${({ summary, summaryToTop }) =>
    !summary
      ? ''
      : !summaryToTop
      ? `
      &:last-child {
        border-top: 1px solid black;
        font-weight: 700;
      }
    `
      : `
    &:first-child {
      border-bottom: 1px solid black;
      font-weight: 700;
    }
  `}

  ${({ rowStyle }) => rowStyle}
`

const Td = styled.td<
  Pick<IColumnsType<any>, typeof TD_PROPS[number]> & {
    cellValue: any
    rawValue?: any
  }
>`
  padding: 8px 10px;
  text-align: ${({ align }) => align || 'left'};
  font-family: Lexend-Regular;

  ${({ cellStyle }) => {
    return cellStyle || ''
  }}
  background-color: ${({ backgroundColor }) => backgroundColor}
`

const getColor = (
  count: {
    total: number
    positive: number
    negative: number
  },
  rank: {
    total: number
    positive: number
    negative: number
  },
  color: 'green' | 'green-red',
  value: number,
) => {
  if (color === 'green') {
    const stepSize = 100 / (count.total - 1 || 1)
    const percentileRank = (count.total - rank.total) * stepSize
    const hue = (percentileRank * 50) / 100 + 70
    return `hsla(${hue}, 100%, 50%, 0.5)`
  }

  if (color === 'green-red') {
    if (value < 0) {
      const stepSize = 100 / (count.negative - 1 || 1)
      const percentileRank = (count.negative - rank.negative) * stepSize
      const hue = (percentileRank * 50) / 100 + 70
      return `hsla(${hue}, 100%, 50%, 0.5)`
    }

    const stepSize = 100 / (count.positive - 1 || 1)
    const percentileRank = (count.positive - rank.positive) * stepSize
    const hue = (percentileRank * 40) / 100
    return `hsla(${hue}, 100%, 50%, 0.5)`
  }
}

const Table = ({
  api,
  minWidth,
  pageSize,
  rowKey = 'groupInfo.id',
  defaultSortedField,
  highlineEvenRows,
  groupInfo,
  bordered,
  heatmap,
  summaryToTop,
  ...columnsOptions
}: IPropsType) => {
  const { data, loading } = useApi(api)
  const groupInfoColumns = useGroupInfoColumns(
    !!data?.summary,
    groupInfo,
    data?.source?.[0]?.groupInfo,
  )
  const columns = useColumns({
    ...columnsOptions,
    api,
    summary: data?.summary,
  })

  const mergedColumns = useMemo(
    () => [...groupInfoColumns, ...columns],
    [groupInfoColumns, columns],
  )

  const originalDataSource = useMemo(() => data?.source || [], [data])
  const total = originalDataSource.length
  const mergedBody = useMergedBody<Record<string, unknown>>(mergedColumns)

  const {
    current,
    pageSize: newPageSize,
    onChange,
  } = usePagination(!pageSize ? 0 : pageSize, total)

  const { sortedField, setSortedField } = useSortedField<
    Record<string, unknown>
  >(defaultSortedField as string, mergedBody, 'descend')

  const dataSource = useDataSource<Record<string, unknown>>(
    originalDataSource,
    current,
    newPageSize,
    sortedField,
    mergedBody,
  )

  const summary = useSummaryDataSource<Record<string, unknown>>(
    dataSource,
    mergedBody,
    'summary',
  )

  useEffect(() => {
    if (pageSize) onChange(0)
  }, [originalDataSource])

  const tableStyle = `
    ${!minWidth ? '' : `min-width: ${minWidth};`}
  `

  const rowStyle = `
  ${
    !highlineEvenRows
      ? ''
      : `
    &:nth-child(even) {
      background-color: ${COLORS.Porcelain};
    }
  `
  }
  ${
    !bordered
      ? ''
      : `
      border-bottom: 1px solid ${COLORS.Smoke};
      `
  }
  `

  const summaryRow =
    summary.length === 0 ? null : (
      <Tr
        rowStyle={rowStyle}
        className="table-summary-row"
        summary={1}
        summaryToTop={summaryToTop}
      >
        {summary.map(({ key, value, ...props }) => (
          <Td {..._.pick(props, TD_PROPS)} key={key} cellValue={value}>
            {value}
          </Td>
        ))}
      </Tr>
    )

  return (
    <Root>
      <Spin spinning={loading}>
        <StyledTable tableStyle={tableStyle}>
          <thead>
            <Head<Record<string, unknown>>
              columns={mergedColumns}
              sortedField={sortedField}
              setSortedField={setSortedField}
            />
          </thead>

          <tbody>
            {summary && summaryToTop ? summaryRow : null}
            {dataSource.map((data, index) => (
              <Tr
                key={_.get(data, rowKey) as unknown as React.Key}
                rowStyle={rowStyle}
              >
                {mergedBody.map(({ key, render, ...props }) => {
                  let cellValue = _.get(data, key)

                  // Heatmap horizontally across 4-week columns
                  const stringKey = `${key}`
                  if (heatmap && stringKey.search(/Week\dInPast/) >= 0) {
                    let kpi = stringKey.substring(
                      0,
                      stringKey.search(/Week\dInPast/),
                    )
                    let childrenKeys = [
                      `${kpi}Week5InPast`,
                      `${kpi}Week4InPast`,
                      `${kpi}Week3InPast`,
                      `${kpi}Week2InPast`,
                      `${kpi}Week1InPast`,
                    ]

                    const keysWithValues = childrenKeys
                      .map((key) => ({
                        key,
                        value: data[key] as number,
                      }))
                      .sort((a, b) =>
                        heatmap.direction === 'desc'
                          ? b.value - a.value
                          : a.value - b.value,
                      )

                    const rank =
                      keysWithValues.findIndex(
                        ({ key: sortedKey }) => sortedKey === key,
                      ) + 1
                    const positives = keysWithValues.filter(
                      ({ value }) => value >= 0,
                    )
                    const positivesRank =
                      positives.findIndex(
                        ({ key: sortedKey }) => sortedKey === key,
                      ) + 1
                    const negatives = keysWithValues.filter(
                      ({ value }) => value < 0,
                    )
                    const negativesRank =
                      negatives.findIndex(
                        ({ key: sortedKey }) => sortedKey === key,
                      ) + 1

                    const calculatedColor = getColor(
                      {
                        total: keysWithValues.length,
                        positive: positives.length,
                        negative: negatives.length,
                      },
                      {
                        total: rank,
                        positive: positivesRank,
                        negative: negativesRank,
                      },
                      stringKey.indexOf('varFoodCostPercentageWeek') === 0 ||
                        stringKey.indexOf('costOfSalesVarianceWeek') === 0
                        ? 'green-red'
                        : 'green',
                      cellValue,
                    )
                    cellValue = { color: calculatedColor, value: cellValue }
                  }

                  const color = cellValue?.color
                  if (color) {
                    cellValue = cellValue.value
                  }

                  return (
                    <Td
                      {..._.pick(props, TD_PROPS)}
                      key={key}
                      cellValue={cellValue}
                      rawValue={data}
                      backgroundColor={color}
                    >
                      {render?.(cellValue, data) || cellValue}
                    </Td>
                  )
                })}
              </Tr>
            ))}
            {total === 0 ||
            !pageSize ||
            pageSize >= originalDataSource.length ? null : (
              <Pagination
                current={current}
                pageSize={pageSize}
                total={total}
                onChange={onChange}
              />
            )}
            {summary && !summaryToTop ? summaryRow : null}
          </tbody>
        </StyledTable>
      </Spin>
    </Root>
  )
}

export { ActionProvider }
export default Table
