import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const bibibopConfig: IConfigsType = {
  bibibop: {
    '/:brand/systemwide_overview': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      systemwideOverview: {
        type: 'table',
        api: 'bbbSystemwideOverviewKpis',
        title: 'Systemwide Overview',
        highlineEvenRows: true,
        rowKey: 'title',
        columns: [
          {
            key: 'title',
            title: ' ',
            width: '130px',
          },
          {
            key: 'netSales',
            title: 'Sales',
            align: 'center',
            width: '120px',
          },
          {
            key: 'fAndPPercent',
            title: 'F&P %',
            align: 'center',
            width: '120px',
          },
          {
            key: 'laborPercent',
            title: 'Labor %',
            align: 'center',
            width: '120px',
          },
          {
            key: 'fplPercent',
            title: 'FPL %',
            align: 'center',
            width: '120px',
          },
          {
            key: 'ebitdaBeforeBonusPercent',
            title: 'EBITDA Before Bonus %',
            align: 'center',
            width: '130px',
          },
          {
            key: 'ebitdaAfterBonus',
            title: 'EBITDA After Bonus $',
            align: 'center',
            width: '130px',
          },
          {
            key: 'ebitdaAfterBonusPercent',
            title: 'EBITDA After Bonus %',
            align: 'center',
            width: '130px',
          },
          {
            key: 'grossProfitPercent',
            title: 'Gross Profit %',
            align: 'center',
            width: '130px',
          },
          {
            key: 'restaurantLevelProfitPercent',
            title: 'Restaurant Level Profit %',
            align: 'center',
            width: '150px',
          },
        ],
      },
      breakdownByStore: {
        type: 'table',
        api: 'systemwideOverview',
        title: 'Breakdown by Store',
        pageSize: 20,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '200px',
          },
        },
        defaultSortedField: 'netSales',
        minWidth: '1500px',
        columns: [
          {
            key: 'Sales',
            title: 'Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'netSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'netSalesBudgetVariance',
                title: 'Variance %',
                align: 'center',
                sortable: true,
                width: '120px',
              },
              {
                key: 'netSalesSssPercent',
                title: 'YOY %',
                align: 'center',
                sortable: true,
              },
              {
                key: 'netSalesBudget',
                title: 'Budget',
                align: 'center',
                sortable: true,
                width: '120px',
              },
              {
                key: 'netSalesBudgetDiff',
                title: 'Diff',
                align: 'center',
                sortable: true,
                width: '120px',
              },
            ],
          },
          {
            key: 'Weekly Sales',
            title: 'Average Weekly Sales',
            align: 'center',
            children: [
              {
                key: 'avgWeeklySales',
                title: '$',
                align: 'center',
                sortable: true,
                width: '120px',
              },
            ],
          },
          {
            key: 'Labor',
            title: 'Labor',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'laborPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'laborVariance',
                title: 'Variance',
                align: 'center',
                reverse: true,
                sortable: true,
              },
            ],
          },
          {
            key: 'Food & Paper',
            title: 'Food & Paper',
            align: 'center',
            children: [
              {
                key: 'fAndPPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'fAndPVariance',
                title: 'Variance',
                align: 'center',
                reverse: true,
                sortable: true,
              },
            ],
          },
          {
            key: 'EBITDA Before Bonus',
            title: 'EBITDA Before Bonus',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'ebitdaBeforeBonus',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'ebitdaBeforeBonusBudget',
                title: 'Budget',
                align: 'center',
                sortable: true,
                width: '120px',
              },
              {
                key: 'ebitdaBeforeBonusDiff',
                title: 'Diff',
                align: 'center',
                sortable: true,
                width: '120px',
              },
            ],
          },
          {
            key: 'ebitdaBeforeBonusPercent',
            title: 'EBITDA Before Bonus %',
            align: 'center',
            sortable: true,
            width: '150px',
          },
          {
            key: 'ebitdaAfterBonusPercent',
            title: 'EBITDA After Bonus %',
            align: 'center',
            sortable: true,
            highlight: true,
            width: '150px',
          },
          {
            key: 'grossProfitPercent',
            title: 'Gross Profit %',
            align: 'center',
            sortable: true,
            width: '150px',
          },
          {
            key: 'restaurantLevelProfitPercent',
            title: 'Restaurant Level Profit %',
            align: 'center',
            sortable: true,
            highlight: true,
            width: '150px',
          },
        ],
      },
      averageWeeklySales: {
        type: 'line-chart',
        title: 'AUV',
        api: 'bbbWeeklySales',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'auv',
            label: 'Weekly AUV',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            key: 'avgAuv',
            label: 'AUV for Time Period Selected',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default bibibopConfig
