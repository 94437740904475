import { useMemo } from 'react'

import {
  cavaKpiDefs,
  defaultKpiDefs,
  irmgBkKpiDefs,
  irmgPopKpiDefs,
  localFavoriteKpiDefs,
  mwbKpiDefs,
  wingitnorthKpiDefs,
  wingstopKpiDefs,
} from 'pared/customer/salesmanshipKpiDefs'

interface IRankingConfig {
  isHeader?: boolean
  key?: string
  name?: string
  salesType?: string
  kpiKey?: string
}

const defaultConfig = [
  { isHeader: true, name: 'FINANCIALS' },
  { key: 'scorecard' },
  { key: 'net_sales' },
  { key: 'net_sales_budget_variance' },
  { key: 'cogs' },
  { key: 'cogs_budget_variance' },
  { key: 'avt' },
  { key: 'daypart' },
  { key: 'rcp' },
  { key: 'rcp_budget_variance' },
  { key: 'net_sales_sss' },
  { key: 'hourly_labor_percent' },
  { key: 'hourly_labor_budget_variance' },
  { key: 'ops_comps' },
  { isHeader: true, name: 'TEAM' },
  { key: 'annualizedHourlyTurnover' },
  { key: 'annualizedManagerTurnover' },
  { key: 'hourlyTurnoverRateAndCount' },
  { key: 'managerTurnoverRateAndCount' },
  { isHeader: true, name: 'SALESMANSHIP' },
  { key: 'salesPerLaborHour' },
  { key: 'ppa' },
  { key: 'appetizerPpa' },
  { key: 'lbwPpa' },
  { key: 'dessertPpa' },
  { isHeader: true, name: 'GUEST' },
  { key: 'momentfeed' },
  { key: 'delivery' },
  { key: 'opentable' },
  { isHeader: true, name: 'DELIVERY' },
  { key: 'deliverySales' },
  { key: 'orderWithAnyIssues' },
  { key: 'combinedStarRating' },
]

const eddieMerlotsConfig = [
  { isHeader: true, name: 'FINANCIALS' },
  { key: 'scorecard' },
  { key: 'net_sales' },
  { key: 'net_sales_budget_variance' },
  { key: 'cogs' },
  { key: 'cogs_budget_variance' },
  { key: 'avt' },
  { key: 'rcp' },
  { key: 'rcp_budget_variance' },
  { key: 'net_sales_sss' },
  { key: 'hourly_labor_percent' },
  { key: 'hourly_labor_budget_variance' },
  { key: 'ops_comps' },
  { isHeader: true, name: 'TEAM' },
  { key: 'annualizedHourlyTurnover' },
  { key: 'annualizedManagerTurnover' },
  { key: 'hourlyTurnoverRateAndCount' },
  { key: 'managerTurnoverRateAndCount' },
  { isHeader: true, name: 'GUEST' },
  { key: 'momentfeed' },
  { key: 'delivery' },
  { key: 'opentable' },
  { isHeader: true, name: 'DELIVERY' },
  { key: 'deliverySales' },
  { key: 'orderWithAnyIssues' },
  { key: 'combinedStarRating' },
]

const pjConfig = [
  { isHeader: true, name: 'FINANCIALS' },
  { key: 'net_sales' },
  { key: 'cogs' },
  { key: 'daypart' },
  { key: 'rcp' },
  { key: 'hourly_labor_percent' },
  { isHeader: true, name: 'TEAM' },
  { key: 'annualizedHourlyTurnover' },
  { key: 'annualizedManagerTurnover' },
  { key: 'hourlyTurnoverRateAndCount' },
  { key: 'managerTurnoverRateAndCount' },
  { isHeader: true, name: 'SALESMANSHIP' },
  { key: 'salesPerLaborHour' },
  { key: 'ppa' },
  { key: 'appetizerPpa' },
  { key: 'lbwPpa' },
  { key: 'dessertPpa' },
  { isHeader: true, name: 'DELIVERY' },
  { key: 'deliveryOrders' },
  { key: 'deliverySales' },
  { key: 'averageTripTime' },
]

const pjDemoConfig = [
  { isHeader: true, name: 'FINANCIALS' },
  { key: 'scorecard' },
  { key: 'net_sales' },
  { key: 'net_sales_budget_variance' },
  { key: 'cogs' },
  { key: 'cogs_budget_variance' },
  { key: 'avt' },
  { key: 'daypart' },
  { key: 'rcp' },
  { key: 'rcp_budget_variance' },
  { key: 'net_sales_sss' },
  { key: 'hourly_labor_percent' },
  { key: 'hourly_labor_budget_variance' },
  { key: 'ops_comps' },
  { isHeader: true, name: 'TEAM' },
  { key: 'annualizedHourlyTurnover' },
  { key: 'annualizedManagerTurnover' },
  { key: 'hourlyTurnoverRateAndCount' },
  { key: 'managerTurnoverRateAndCount' },
  { key: 'avgHourlyRate' },
  { isHeader: true, name: 'SALESMANSHIP' },
  { key: 'salesPerLaborHour' },
  { key: 'ppa' },
  { key: 'appetizerPpa' },
  { key: 'lbwPpa' },
  { key: 'dessertPpa' },
  { key: 'productivityCoefficient' },
  { isHeader: true, name: 'GUEST' },
  { key: 'momentfeed' },
  { key: 'delivery' },
  { isHeader: true, name: 'DELIVERY' },
  { key: 'deliveryOrders' },
  { key: 'deliverySales' },
  { key: 'orderWithAnyIssues' },
  { key: 'combinedStarRating' },
  { key: 'averageTripTime' },
]

const irmgPopConfig = [
  { isHeader: true, name: 'FINANCIALS' },
  { key: 'net_sales' },
  { key: 'cogs' },
  { key: 'cogs_budget_variance' },
  { key: 'net_sales_sss' },
  { key: 'hourly_labor_percent' },
  { key: 'hourly_labor_budget_variance' },
  { isHeader: true, name: 'TEAM' },
  { key: 'annualizedHourlyTurnover' },
  { isHeader: true, name: 'SALESMANSHIP' },
  defaultKpiDefs.salesPerHour,
  defaultKpiDefs.checkAvg,
  irmgPopKpiDefs.comboRate,
  irmgPopKpiDefs.dessertRate,
  irmgPopKpiDefs.drinkRate,
]

const wingstopConfig = [
  { isHeader: true, name: 'FINANCIALS' },
  { key: 'net_sales' },
  { key: 'cogs' },
  { key: 'net_sales_sss' },
  { key: 'hourly_labor_percent' },
  { isHeader: true, name: 'PRODUCT' },
  {
    key: 'classicUsageVariancePercent',
    name: 'Classic Usage Variance %',
  },
  {
    key: 'bonelessUsageVariancePercent',
    name: 'Boneless Usage Variance %',
  },
  {
    key: 'tendersUsageVariancePercent',
    name: 'Tenders Usage Variance %',
  },
  { isHeader: true, name: 'SALESMANSHIP' },
  {
    key: 'sales_per_labor_hour',
    name: 'Sales Per Man Hour',
  },
  {
    key: 'total_check_average',
    name: 'Total Check Average',
  },
  wingstopKpiDefs.totalSidesSales,
  wingstopKpiDefs.friesSales,
  wingstopKpiDefs.cornSales,
  wingstopKpiDefs.browniesSales,
]

const cavaConfig = [
  { isHeader: true, name: 'FINANCIALS' },
  { key: 'scorecard' },
  { key: 'net_sales' },
  { key: 'net_sales_budget_variance' },
  { key: 'total_guest_average', name: 'PPA' },
  { key: 'cogs' },
  { key: 'cogs_budget_variance' },
  { key: 'daypart' },
  { key: 'net_sales_sss' },
  { key: 'hourly_labor_percent' },
  { key: 'hourly_labor_budget_variance' },
  { isHeader: true, name: 'TEAM' },
  { key: 'annualizedHourlyTurnover' },
  { key: 'annualizedManagerTurnover' },
  { key: 'hourlyTurnoverRateAndCount' },
  { key: 'managerTurnoverRateAndCount' },
  { key: 'zenput', name: 'Zenput' },
  { key: 'cavayou', name: 'Cavayou' },
  { isHeader: true, name: 'SALESMANSHIP' },
  {
    ...defaultKpiDefs.totalPpa,
    name: 'Non-Digital PPA',
  },
  cavaKpiDefs.entreePremiumIncidents,
  cavaKpiDefs.curatedIncidents,
  cavaKpiDefs.sideAttachmentRate,
  cavaKpiDefs.dessertAttachmentRate,
  cavaKpiDefs.beverageAttachmentRate,
  {
    key: 'entreesPerLaborHour',
    name: 'Entrees per Labor Hour',
  },
]

const localFavoriteConfig = [
  { isHeader: true, name: 'FINANCIALS' },
  { key: 'gross_sales' },
  { key: 'gross_sales_budget_variance' },
  { key: 'cogs' },
  { key: 'cogs_budget_variance' },
  { key: 'daypart' },
  { key: 'gross_sales_sss' },
  { key: 'hourly_labor_percent' },
  { key: 'hourly_labor_budget_variance' },
  { isHeader: true, name: 'TEAM' },
  { key: 'annualizedHourlyTurnover' },
  { key: 'annualizedManagerTurnover' },
  { key: 'hourlyTurnoverRateAndCount' },
  { key: 'managerTurnoverRateAndCount' },
  { isHeader: true, name: 'SALESMANSHIP' },
  defaultKpiDefs.salesPerHour,
  defaultKpiDefs.totalPpa,
  localFavoriteKpiDefs.appetizerPpa,
  localFavoriteKpiDefs.lbwPpa,
  localFavoriteKpiDefs.dessertPpa,
  localFavoriteKpiDefs.naBeveragePpa,
  localFavoriteKpiDefs.sidesPpa,
  { isHeader: true, name: 'GUEST' },
  { key: 'delivery' },
  { isHeader: true, name: 'DELIVERY' },
  { key: 'deliverySales' },
  { key: 'orderWithAnyIssues' },
  { key: 'combinedStarRating' },
]

const useRankingConfig = (brand: string): IRankingConfig[] =>
  useMemo(() => {
    switch (brand) {
      case 'eddiemerlots':
      case 'eddiemerlots_nso':
        return eddieMerlotsConfig

      case 'papajohns':
        return pjConfig

      case 'papajohns_demo':
        return pjDemoConfig

      case 'viking_bk':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'plv_percent' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnover' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgBkKpiDefs.baconAttachmentRate,
          irmgBkKpiDefs.cheeseAttachmentRate,
          irmgBkKpiDefs.treatAttachmentRate,
          {
            key: 'upsizePercent',
            name: 'Upsize Percent',
          },
          {
            key: 'sosPickup',
            name: 'Average Pickup Window Time',
            kpiKey: 'Pickup Window',
          },
          { key: 'fss_score', name: 'FSS Score' },
        ]

      case 'jsc_bk':
      case 'jsc_bk_nso':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'net_sales_sss' },
          {
            key: 'total_check_count',
            name: 'Guests',
          },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { key: 'avt_percent_monthly', name: 'AvT' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnoverV2' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgBkKpiDefs.baconAttachmentRate,
          irmgBkKpiDefs.cheeseAttachmentRate,
          irmgBkKpiDefs.treatAttachmentRate,
          {
            key: 'upsizePercent',
            name: 'Upsize Percent',
          },
          {
            key: 'sosPickup',
            name: 'Window Time (seconds)',
            kpiKey: 'Pickup Window',
          },
          { isHeader: true, name: 'GUEST' },
          { key: 'acr_score', name: 'ACR Score' },
          { key: 'fss_score', name: 'FSS Score' },
        ]

      case 'demo_bk':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { key: 'plv_percent' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnover' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgBkKpiDefs.baconAttachmentRate,
          irmgBkKpiDefs.cheeseAttachmentRate,
          irmgBkKpiDefs.treatAttachmentRate,
          {
            key: 'upsizePercent',
            name: 'Upsize Percent',
          },
          { isHeader: true, name: 'GUEST' },
          { key: 'fss_score', name: 'FSS Score' },
        ]

      case 'irmg_bk':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnover' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgBkKpiDefs.baconAttachmentRate,
          irmgBkKpiDefs.cheeseAttachmentRate,
          irmgBkKpiDefs.treatAttachmentRate,
          {
            key: 'upsizePercent',
            name: 'Upsize Percent',
          },
          { isHeader: true, name: 'GUEST' },
          { key: 'acr_score', name: 'ACR Score' },
          { key: 'fss_score', name: 'FSS Score' },
        ]

      case 'supreme_bk':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { key: 'plv_percent' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnover' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgBkKpiDefs.baconAttachmentRate,
          irmgBkKpiDefs.cheeseAttachmentRate,
          irmgBkKpiDefs.treatAttachmentRate,
          {
            key: 'upsizePercent',
            name: 'Upsize Percent',
          },
          { isHeader: true, name: 'GUEST' },
          { key: 'acr_score', name: 'ACR Score' },
          { key: 'fss_score', name: 'FSS Score' },
        ]

      case 'mbn_bk':
      case 'timoney_bk':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { key: 'plv_percent' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnover' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgBkKpiDefs.baconAttachmentRate,
          irmgBkKpiDefs.cheeseAttachmentRate,
          irmgBkKpiDefs.treatAttachmentRate,
          {
            key: 'upsizePercent',
            name: 'Upsize Percent',
          },
          { isHeader: true, name: 'GUEST' },
          { key: 'acr_score', name: 'ACR Score' },
          { key: 'fss_score', name: 'FSS Score' },
        ]

      case 'sandbox_bk':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'cogs' },
          { key: 'cogs_budget_variance' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { key: 'plv_percent' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnover' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgBkKpiDefs.baconAttachmentRate,
          irmgBkKpiDefs.cheeseAttachmentRate,
          irmgBkKpiDefs.treatAttachmentRate,
          {
            key: 'upsizePercent',
            name: 'Upsize Percent',
          },
          { isHeader: true, name: 'GUEST' },
          { key: 'acr_score', name: 'ACR Score' },
          { key: 'fss_score', name: 'FSS Score' },
        ]

      case 'irmg_pop':
      case 'demo_pop':
        return irmgPopConfig

      case 'supreme_pop':
      case 'ace_pop':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'cogs' },
          { key: 'cogs_budget_variance' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnover' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgPopKpiDefs.comboRate,
          irmgPopKpiDefs.dessertRate,
          irmgPopKpiDefs.drinkRate,
          {
            key: 'sosPickup',
            name: 'Average Pickup Window Time',
            kpiKey: 'Pickup Window',
          },
          { isHeader: true, name: 'GUEST' },
          { key: 'acr_score', name: 'ACR Score' },
          { key: 'fss_score', name: 'FSS Score' },
        ]

      case 'ghai_pop':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'net_sales_budget_variance' },
          { key: 'cogs' },
          { key: 'cogs_budget_variance' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnover' },
          { key: 'annualizedManagerTurnover' },
          { key: 'hourlyTurnoverRateAndCount' },
          { key: 'managerTurnoverRateAndCount' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgPopKpiDefs.comboRate,
          irmgPopKpiDefs.dessertRate,
          irmgPopKpiDefs.drinkRate,
          { isHeader: true, name: 'GUEST' },
          { key: 'delivery' },
          { key: 'acr_score', name: 'ACR Score' },
          { key: 'fss_score', name: 'FSS Score' },
        ]

      case 'wingitnorth':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'net_sales_sss' },
          { key: 'cogs' },
          {
            key: 'total_check_count',
            name: 'Check Count',
          },
          { key: 'daypart' },
          { key: 'hourly_labor_percent' },
          { key: 'avt', name: 'AvT' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          wingitnorthKpiDefs.cornAttachmentRate,
          wingitnorthKpiDefs.waterAttachmentRate,
          wingitnorthKpiDefs.saucesAttachmentRate,
          wingitnorthKpiDefs.sticksAttachmentRate,
          { isHeader: true, name: 'LOSS PREVENTION' },
          { key: 'voids', name: 'Voids' },
          { key: 'discounts', name: 'Discounts' },
          { key: 'cashOverOrShort', name: 'Cash +/-' },
          { isHeader: true, name: 'GUEST' },
          { key: 'google', name: 'Google Rating' },
          { key: 'yelp', name: 'Yelp Rating' },
          { key: 'smg', name: 'SMG' },
          { isHeader: true, name: 'DELIVERY' },
          { key: 'deliverySales' },
          { key: 'orderWithAnyIssues' },
          { key: 'combinedStarRating' },
          { isHeader: true, name: 'CALLS' },
          { key: 'inboundCalls', name: 'Inbound' },
        ]

      case 'wingstop':
        return wingstopConfig

      case 'cava':
        return cavaConfig

      case 'jitb':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'cogs' },
          { key: 'cogs_budget_variance' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { key: 'hourly_labor_budget_variance' },
          { isHeader: true, name: 'TEAM' },
          { key: 'annualizedHourlyTurnover' },
          { isHeader: true, name: 'SALESMANSHIP' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          irmgBkKpiDefs.baconAttachmentRate,
          irmgBkKpiDefs.cheeseAttachmentRate,
          irmgBkKpiDefs.treatAttachmentRate,
          {
            key: 'upsizePercent',
            name: 'Upsize Percent',
          },
        ]

      case 'mwb':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { key: 'cogs' },
          { key: 'avt_percent_monthly', name: 'AvT' },
          { key: 'daypart' },
          { key: 'net_sales_sss' },
          { key: 'hourly_labor_percent' },
          { isHeader: true, name: 'SALESMANSHIP' },
          { key: 'hme', name: 'Speed of Service' },
          defaultKpiDefs.salesPerHour,
          defaultKpiDefs.checkAvg,
          mwbKpiDefs.dessertsAttachmentRate,
          mwbKpiDefs.beveragesAttachmentRate,
          mwbKpiDefs.baconAttachmentRate,
          mwbKpiDefs.cheeseAttachmentRate,
          mwbKpiDefs.jalapenoAttachmentRate,
          { isHeader: true, name: 'LOSS PREVENTION' },
          { key: 'discounts', name: 'Discounts' },
          { isHeader: true, name: 'DELIVERY' },
          { key: 'deliverySales' },
          { key: 'orderWithAnyIssues' },
          { key: 'combinedStarRating' },
        ]

      // Local Favorite
      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return localFavoriteConfig

      case 'bibibop':
        return [
          { isHeader: true, name: 'FINANCIALS' },
          { key: 'net_sales' },
          { isHeader: true, name: 'LOSS PREVENTION' },
          { key: 'discounts', name: 'Discounts' },
          { key: 'cashOverOrShort', name: 'Cash +/-' },
          { isHeader: true, name: 'GUEST' },
          { key: 'delivery' },
          { isHeader: true, name: 'DELIVERY' },
          { key: 'deliverySales' },
          { key: 'orderWithAnyIssues' },
          { key: 'combinedStarRating' },
        ]

      default:
        return defaultConfig
    }
  }, [brand])

export default useRankingConfig
