import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import {
  MetricResult,
  useFetchGroupMetricValue,
} from './useFetchGroupMetricValue'

const useBbbCorpEbitda = (skip: boolean): IApiDataType => {
  const { variables } = useVariables()

  const currentPeriod = variables.date?.getInfo(0)
  const {
    startDateStr: currentPeriodBeginDate,
    endDateStr: currentPeriodEndDate,
  } = currentPeriod?.dateRange || {}
  const { startDateStr: priorPeriodBeginDate, endDateStr: priorPeriodEndDate } =
    variables.date?.getInfo(-1)?.dateRange || {}

  const { groupFilter } = useGroupFilter()
  const locationGroupId = groupFilter?.ids[0] || 1

  const metricCodes = [
    'ebidta_without_bonus',
    'ebidta_without_bonus_budget',
    'check_count',
    'check_average',
  ]

  const { results: currentMetrics, isLoading: currentLoading } =
    useFetchGroupMetricValue(
      metricCodes,
      currentPeriodBeginDate,
      currentPeriodEndDate,
      locationGroupId,
      skip,
    )

  const { results: priorMetrics, isLoading: priorLoading } =
    useFetchGroupMetricValue(
      metricCodes,
      priorPeriodBeginDate,
      priorPeriodEndDate,
      locationGroupId,
      skip,
    )

  const { results: yoyMetrics, isLoading: yoyLoading } =
    useFetchGroupMetricValue(
      metricCodes,
      currentPeriod?.yoy?.dateRange?.startDateStr,
      currentPeriod?.yoy?.dateRange?.endDateStr,
      locationGroupId,
      skip,
    )

  const getMetricValue = (metrics: MetricResult[], metricCode: string) => {
    return metrics.find((m) => m.metricCode === metricCode)?.value ?? 0
  }

  const ebitda = getMetricValue(currentMetrics, 'ebidta_without_bonus')
  const priorEbitda = getMetricValue(priorMetrics, 'ebidta_without_bonus')
  const yoyEbitda = getMetricValue(yoyMetrics, 'ebidta_without_bonus')
  const ebitdaBudget = getMetricValue(
    currentMetrics,
    'ebidta_without_bonus_budget',
  )

  const ebitdaVar = ebitda - ebitdaBudget

  const calculateChange = (
    currentValue: number,
    previousValue: number,
  ): number => {
    return previousValue !== 0
      ? (100 * (currentValue - previousValue)) / previousValue
      : 0
  }

  return {
    data: useMemo(() => {
      if (!ebitda) return null

      // ADD TEXT HERE START

      const ebitdaVarText =
        ebitdaVar <= 0
          ? `Missing the ${toUsdString(ebitdaBudget)} budget by ${toUsdString(
              -ebitdaVar,
            )}`
          : `Beating the ${toUsdString(ebitdaBudget)} budget by ${toUsdString(
              ebitdaVar,
            )}`

      const ebitdaVarPeriodChgText =
        priorEbitda === 0
          ? `No EBITDA data from Prior Period for comparison`
          : ebitda >= priorEbitda
          ? `Increasing ${toPercentString(
              ((ebitda - priorEbitda) / priorEbitda) * 100,
            )} from Prior Period of ${toUsdString(priorEbitda)}`
          : `Decreasing ${toPercentString(
              ((priorEbitda - ebitda) / priorEbitda) * 100,
            )} from Prior Period of ${toUsdString(priorEbitda)}`

      const ebitdaYoyChgText =
        yoyEbitda === 0
          ? `No EBITDA data from Prior Year for comparison`
          : ebitda >= yoyEbitda
          ? `Increasing ${toPercentString(
              ((ebitda - yoyEbitda) / yoyEbitda) * 100,
            )} from Prior Year of ${toUsdString(yoyEbitda)}`
          : `Decreasing ${toPercentString(
              ((yoyEbitda - ebitda) / yoyEbitda) * 100,
            )} from Prior Year of ${toUsdString(yoyEbitda)}`

      // ADD TEXT HERE END

      return {
        title: `EBITDA`,
        total: ebitdaVar * -1,
        summary: `EBITDA in ${currentPeriod?.displayName} was ${toUsdString(
          ebitda,
        )}`,
        detail: `
          <ul>
            <li>${ebitdaVarText}</li>
            <li>${ebitdaVarPeriodChgText}</li>
            <li>${ebitdaYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [ebitda, ebitdaBudget, priorEbitda, yoyEbitda, currentPeriod]),
    loading: currentLoading || priorLoading || yoyLoading,
  }
}

export default useBbbCorpEbitda
