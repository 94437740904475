import Menu from '@material-ui/core/Menu'
import MaterialMenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import * as d3 from 'd3-hierarchy'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

export interface IValueType {
  id: string
  parentId: string | null
  displayName: string
  groupBy?: string
}

export interface IPropsType {
  forwardRef?: React.Ref<HTMLLIElement> | null
  prevIds?: IValueType['id'][]
  prevValue?: IValueType[]
  onChange: (value: IValueType['id'][], selectedValue: IValueType[]) => void
  onClose: () => void
  option: d3.HierarchyNode<IValueType>
}

const StyledMenuItem = styled(MaterialMenuItem)<{ hasGroupBy: boolean }>`
  font-family: Lexend-Regular;
  padding-left: ${({ hasGroupBy }) => (!hasGroupBy ? '16px' : '24px')};
`

const StyledListSubheader = styled(ListSubheader)`
  font-family: Lexend-Regular;
  line-height: 36px;
`

const withForwardRef = (Compoment: typeof MenuItem): typeof MenuItem => {
  const MemoComponent = React.memo(Compoment) as unknown as typeof MenuItem

  return React.forwardRef<HTMLLIElement, IPropsType>((props, ref) => (
    <MemoComponent {...props} forwardRef={ref} />
  )) as unknown as typeof MenuItem
}

const MenuItem = ({
  forwardRef,
  prevIds = [],
  prevValue = [],
  onChange,
  onClose: prevOnClose,
  option: { data, children },
}: IPropsType) => {
  const { id, displayName } = data
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const onClose = useCallback(() => {
    prevOnClose()
    setAnchorEl(null)
  }, [prevOnClose])
  const SubMenuItem = withForwardRef(MenuItem)
  let groupBy = ''

  return (
    <>
      <StyledMenuItem
        ref={forwardRef}
        value={id}
        hasGroupBy={Boolean(data.groupBy)}
        onClick={({ target }) => {
          if (!children) {
            onChange([...prevIds, id], [...prevValue, data])
            onClose()
          } else setAnchorEl(target as HTMLElement)
        }}
      >
        {displayName}
      </StyledMenuItem>

      {!children ? null : (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          variant="menu"
        >
          {children.map((option) => {
            const showGroupBy = (() => {
              if (groupBy !== option.data.groupBy) {
                groupBy = option.data.groupBy || ''

                if (option.data.groupBy) return true
              }

              return false
            })()

            return [
              !showGroupBy ? null : (
                <StyledListSubheader>{groupBy}</StyledListSubheader>
              ),
              <SubMenuItem
                prevIds={[...prevIds, id]}
                prevValue={[...prevValue, data]}
                onChange={onChange}
                onClose={onClose}
                option={option}
              />,
            ]
          })}
        </Menu>
      )}
    </>
  )
}

export default withForwardRef(MenuItem)
