import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import { MetricResult, useFetchMetricValue } from './useFetchMetricValue'

const useBbbLabor = (skip: boolean): IApiDataType => {
  const { variables } = useVariables()

  const currentPeriod = variables.date?.getInfo(0)
  const {
    startDateStr: currentPeriodBeginDate,
    endDateStr: currentPeriodEndDate,
  } = currentPeriod?.dateRange || {}
  const { startDateStr: priorPeriodBeginDate, endDateStr: priorPeriodEndDate } =
    variables.date?.getInfo(-1)?.dateRange || {}

  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'

  const metricCodes = [
    'labor_cost',
    'labor_cost_budget',
    'labor_cost_percentage',
    'labor_budget_percent',
  ]

  const { results: currentMetrics, isLoading: currentLoading } =
    useFetchMetricValue(
      metricCodes,
      currentPeriodBeginDate,
      currentPeriodEndDate,
      locationId,
      skip,
    )

  const { results: priorMetrics, isLoading: priorLoading } =
    useFetchMetricValue(
      metricCodes,
      priorPeriodBeginDate,
      priorPeriodEndDate,
      locationId,
      skip,
    )

  const { results: yoyMetrics, isLoading: yoyLoading } = useFetchMetricValue(
    metricCodes,
    currentPeriod?.yoy?.dateRange?.startDateStr,
    currentPeriod?.yoy?.dateRange?.endDateStr,
    locationId,
    skip,
  )

  const getMetricValue = (
    metrics: MetricResult[],
    metricCode: string,
  ): number => {
    const metric = metrics.find(
      (m: MetricResult) => m.metricCode === metricCode,
    )
    return metric?.value ?? 0
  }

  const labor = getMetricValue(currentMetrics, 'labor_cost')
  const laborBudget = getMetricValue(currentMetrics, 'labor_cost_budget')
  const laborPct = getMetricValue(currentMetrics, 'labor_cost_percentage')
  const laborBudgetPct = getMetricValue(currentMetrics, 'labor_budget_percent')
  const priorLaborPct = getMetricValue(priorMetrics, 'labor_cost_percentage')
  const yoyLaborPct = getMetricValue(yoyMetrics, 'labor_cost_percentage')

  const laborVarPct = laborPct - laborBudgetPct

  return {
    data: useMemo(() => {
      if (labor === 0 && laborPct === 0) return null

      // ADD TEXT HERE START

      const laborVarPctText =
        laborVarPct >= 0
          ? `Higher than the ${toPercentString(
              laborBudgetPct,
            )} budget by ${toPercentString(laborVarPct)}`
          : `Lower than the ${toPercentString(
              laborBudgetPct,
            )} budget by ${toPercentString(-laborVarPct)}`

      const laborVarPctPeriodChgText =
        laborPct >= priorLaborPct
          ? `Increasing ${toPercentString(
              laborPct - priorLaborPct,
            )} from Prior Period of ${toPercentString(priorLaborPct)}`
          : `Decreasing ${toPercentString(
              priorLaborPct - laborPct,
            )} from Prior Period of ${toPercentString(priorLaborPct)}`

      const laborVarPctYoyChgText =
        laborPct >= yoyLaborPct
          ? `Increasing ${toPercentString(
              laborPct - yoyLaborPct,
            )} from Prior Year of ${toPercentString(yoyLaborPct)}`
          : `Decreasing ${toPercentString(
              yoyLaborPct - laborPct,
            )} from Prior Year of ${toPercentString(yoyLaborPct)}`

      // ADD TEXT HERE END

      return {
        title: `Labor`,
        total: laborVarPct,
        summary: `${locationName}\`s Labor Cost in ${
          currentPeriod?.displayName
        } was ${toPercentString(laborPct)}`,
        detail: `
          <ul>
            <li>${laborVarPctText} or ${toUsdString(
          Math.abs(labor - laborBudget),
        )}</li>
            <li>${laborVarPctPeriodChgText}</li>
            <li>${laborVarPctYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [
      locationName,
      labor,
      laborBudget,
      laborPct,
      laborBudgetPct,
      priorLaborPct,
      yoyLaborPct,
      currentPeriod,
    ]),
    loading: currentLoading || priorLoading || yoyLoading,
  }
}

export default useBbbLabor
