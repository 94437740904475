import { Link } from 'react-router-dom'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

export interface IPropsType {
  link?: string
  label: string
}

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const Location = ({ link, label, ...props }: IPropsType) =>
  !link ? (
    <>{label}</>
  ) : (
    <LinkButton {...props} to={link}>
      {label}
    </LinkButton>
  )

export default Location
