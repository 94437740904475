import { IConfigsType } from '../../types'

const fwWingstopFlashCogsTracker: IConfigsType = {
  fw_wingstop: {
    '/:brand/cogs_tracker': {
      variables: ['date'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['last_week', 'week'],
        defaultType: 'last_week',
      },
      reportTitle: {
        type: 'title',
        title: 'COGS Tracker',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'farwestFiveWeekItemTrendData',
        fileName: 'getexpo-far-west-cogs-tracker',
        message: 'Download as CSV',
        fields: [
          {
            key: 'cogsCategoryName',
            title: 'COGS Category',
          },
          {
            key: 'actualCostOfSalesPercentageWeek4InPast',
            title: 'Cost of Sales (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'actualCostOfSalesPercentageWeek3InPast',
            title: 'Cost of Sales (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'actualCostOfSalesPercentageWeek2InPast',
            title: 'Cost of Sales (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'actualCostOfSalesPercentageWeek1InPast',
            title: 'Cost of Sales (<%- date?.getInfo(0)?.displayName %>)',
          },
          {
            key: 'theoreticalCostOfSalesPercentageWeek4InPast',
            title: 'Theoretical COGS (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'theoreticalCostOfSalesPercentageWeek3InPast',
            title: 'Theoretical COGS (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'theoreticalCostOfSalesPercentageWeek2InPast',
            title: 'Theoretical COGS (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'theoreticalCostOfSalesPercentageWeek1InPast',
            title: 'Theoretical COGS (<%- date?.getInfo(0)?.displayName %>)',
          },
          {
            key: 'costOfSalesVarianceWeek4InPast',
            title: 'Food Cost Variance (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'costOfSalesVarianceWeek3InPast',
            title: 'Food Cost Variance (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'costOfSalesVarianceWeek2InPast',
            title: 'Food Cost Variance (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'costOfSalesVarianceWeek1InPast',
            title: 'Food Cost Variance (<%- date?.getInfo(0)?.displayName %>)',
          },
        ],
      },
      flashTable: {
        type: 'table',
        api: 'farwestFiveWeekItemTrendData',
        pageSize: 30,
        heatmap: {
          direction: 'asc',
        },
        summaryToTop: true,
        columns: [
          {
            key: 'cogsCategoryName',
            title: 'COGS Category',
            sortable: true,
          },
          {
            key: 'actualCostOfSalesPercentage',
            title: 'Cost of Sales',
            align: 'center',
            highlight: true,
            hasRightGutter: true,
            children: [
              {
                key: 'actualCostOfSalesPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'actualCostOfSalesPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'actualCostOfSalesPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'actualCostOfSalesPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
                hasRightGutter: true,
              },
            ],
          },
          {
            key: 'theoreticalCostOfSalesPercentage',
            title: 'Theoretical COGS',
            align: 'center',
            children: [
              {
                key: 'theoreticalCostOfSalesPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'theoreticalCostOfSalesPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'theoreticalCostOfSalesPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'theoreticalCostOfSalesPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'costOfSalesVariance',
            title: 'Food Cost Variance',
            align: 'center',
            highlight: true,
            hasLeftGutter: true,
            children: [
              {
                key: 'costOfSalesVarianceWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
                disableDangerColor: true,
                hasLeftGutter: true,
              },
              {
                key: 'costOfSalesVarianceWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
                disableDangerColor: true,
              },
              {
                key: 'costOfSalesVarianceWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
                disableDangerColor: true,
              },
              {
                key: 'costOfSalesVarianceWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
                disableDangerColor: true,
              },
            ],
          },
        ],
      },
      downloadcaseCountsReport: {
        type: 'download-csv',
        api: 'farwestFlashCogsTrackerCaseCounts',
        fileName: 'getexpo-far-west-cogs-tracker-case-counts',
        message: 'Download as CSV',
        fields: [
          {
            key: 'farwestCogsTrackerCaseCountsCaseCountItem',
            title: 'Category',
          },
          {
            key: 'farwestCogsTrackerCaseCountsWeek4InPast',
            title: '<%- date?.getInfo(-3)?.displayName %>',
          },
          {
            key: 'farwestCogsTrackerCaseCountsWeek3InPast',
            title: '<%- date?.getInfo(-2)?.displayName %>',
          },
          {
            key: 'farwestCogsTrackerCaseCountsWeek2InPast',
            title: '<%- date?.getInfo(-1)?.displayName %>',
          },
          {
            key: 'farwestCogsTrackerCaseCountsWeek1InPast',
            title: '<%- date?.getInfo(0)?.displayName %>',
          },
        ],
      },
      caseCountsTable: {
        type: 'table',
        api: 'farwestFlashCogsTrackerCaseCounts',
        pageSize: 20,
        columns: [
          {
            key: 'farwestCogsTrackerCaseCountsCaseCountItem',
            title: 'Category',
            align: 'left',
            width: '250px',
          },
          {
            key: 'farwestCogsTrackerCaseCountsWeek4InPast',
            title: '<%- date?.getInfo(-3)?.displayName %>',
            align: 'center',
          },
          {
            key: 'farwestCogsTrackerCaseCountsWeek3InPast',
            title: '<%- date?.getInfo(-2)?.displayName %>',
            align: 'center',
          },
          {
            key: 'farwestCogsTrackerCaseCountsWeek2InPast',
            title: '<%- date?.getInfo(-1)?.displayName %>',
            align: 'center',
          },
          {
            key: 'farwestCogsTrackerCaseCountsWeek1InPast',
            title: '<%- date?.getInfo(0)?.displayName %>',
            align: 'center',
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashCogsTracker
