import * as _ from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import useVariablesHook, {
  IDataType,
  IVariablesType,
} from './hooks/useVariables'

export { tableConfigs as allStoresTableConfigs } from './hooks/common/useAllStores'
export { tableConfigs as corporateGroupTableConfigs } from './hooks/common/useCorporateGroup'

interface IPropsType {
  types: IVariablesType[]
  children: React.ReactNode
}

interface IVariablesDataType extends IDataType {
  aiSummary: Record<
    string,
    {
      isClicked: boolean
      clickedId: unknown
      value: unknown
    }
  >
}

type IInternalVariablesDataType = Omit<IVariablesDataType, keyof IDataType>
type IInternalVariablesContextType = [
  IInternalVariablesDataType,
  (state: IInternalVariablesDataType) => void,
]

export type { IVariablesType, IVariablesDataType }

const VariablesContext = React.createContext<IDataType>({})

const defaultInternalVariables = {
  aiSummary: {},
}
const InternalVariablesContext =
  React.createContext<IInternalVariablesContextType>([
    defaultInternalVariables,
    () => {
      throw new Error('not found provider')
    },
  ])

const MAP_PATTERN = /JSON\((.+)\)/

export const useVariables = () => {
  const variables = useContext(VariablesContext)
  const [internalVariables, setInternalVariables] = useContext(
    InternalVariablesContext,
  )
  const mergedVariables = useMemo(
    () => ({
      ...variables,
      ...internalVariables,
    }),
    [variables, internalVariables],
  )
  const template = useCallback(
    (str: string) => {
      try {
        if (MAP_PATTERN.test(str))
          return JSON.parse(
            _.template(
              str.replace(/JSON\((.+)\)/, (_, p1) => `JSON.stringify(${p1})`),
            )(mergedVariables).replace(/&quot;/g, '"') || '[]',
          )

        return _.template(str)(mergedVariables) || ''
      } catch (e) {
        console.error(e)
        return ''
      }
    },
    [mergedVariables],
  )

  return {
    variables: mergedVariables,
    setVariables: setInternalVariables,
    template,
  }
}

const VariablesProvider = ({ types, children }: IPropsType) => {
  const variables = useVariablesHook(types)
  const internalVariablesHook = useState(defaultInternalVariables)

  return (
    <VariablesContext.Provider value={variables}>
      <InternalVariablesContext.Provider value={internalVariablesHook}>
        {children}
      </InternalVariablesContext.Provider>
    </VariablesContext.Provider>
  )
}

export default VariablesProvider
