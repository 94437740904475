import React from 'react'

import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'

import ChartContainer from './ChartContainer'
import { IFoodListFragmentType } from './gqls/foodList'
import useFoodListColumns from './hooks/useFoodListColumns'

interface IPropsType {
  listLocationGroupMenuItemsRecords: IFoodListFragmentType[]
  loading: boolean
}

const rowStyle = `
  &:first-child td:not(:nth-child(3)) {
    vertical-align: bottom;
    line-height: 26px;
  }
`

const FoodList = ({
  listLocationGroupMenuItemsRecords,
  loading,
}: IPropsType) => {
  const columns = useFoodListColumns(listLocationGroupMenuItemsRecords)

  return (
    <ChartContainer>
      <Spin spinning={loading}>
        <Table<IFoodListFragmentType>
          columns={columns}
          dataSource={listLocationGroupMenuItemsRecords}
          rowStyle={rowStyle}
          rowKey="itemName"
          defaultSortedField="popularity"
        />
      </Spin>
    </ChartContainer>
  )
}

export default React.memo(FoodList) as unknown as typeof FoodList
