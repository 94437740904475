export interface IFinancialLabels {
  cogs: string
  rcp: string
  transaction: string
  transactionLy: string
  transactionVsLy: string
  transactionVsLyPercent: string
  transactionAvg: string
  hourlyLabor: string
  sss: string
  sssAmount: string
  variance: string
  projected: string
  comps: string
  salesPerLaborHour: string
  avt: string
  plv: string
  sos: string
  netSalesVariance?: string
  hourlyLaborVariance?: string
  cogsVariance?: string
}

const defaultLabels: IFinancialLabels = {
  cogs: 'COGS',
  rcp: 'RCP',
  transaction: 'Check Count',
  transactionLy: 'YoY Check Count',
  transactionVsLy: 'Check Count vs LY',
  transactionVsLyPercent: 'Check Count % vs LY',
  transactionAvg: 'Check Average',
  hourlyLabor: 'Hourly Labor',
  sss: 'SSS %',
  sssAmount: 'SSS',
  variance: 'Budget Variance',
  projected: 'Budget',
  comps: 'Comps',
  salesPerLaborHour: 'Sales Per Labor Hour',
  avt: 'AvT',
  plv: 'PLV',
  sos: 'Pickup Time',
}

export function getFinancialLabels(brand: string): IFinancialLabels {
  switch (brand) {
    case 'rmg_nso':
    case 'rmg': // Romano's Macaroni Grill
    case 'sullivans_nso':
    case 'sullivans': // Sullivan's Steakhouse
    case 'eddie_merlots': // Eddie Merlot's
      return {
        cogs: 'COGS',
        rcp: 'RCP',
        transaction: 'Check Count',
        transactionLy: 'YoY Check Count',
        transactionVsLy: 'Check Count vs LY',
        transactionVsLyPercent: 'Check Count % vs LY',
        transactionAvg: 'Check Average',
        hourlyLabor: 'Hourly Labor',
        sss: 'SSS %',
        sssAmount: 'SSS',
        variance: 'Budget Variance',
        projected: 'Budget',
        comps: 'Comps',
        salesPerLaborHour: 'Sales Per Labor Hour',
        avt: 'AvT',
        plv: 'PLV',
        sos: 'Pickup Time',
      }
    case 'papajohns': // Papa Johns
    case 'papajohns_demo': // Papa Johns Demo
      return {
        cogs: 'COGS',
        rcp: 'Controllable Profit',
        transaction: 'Check Count',
        transactionLy: 'YoY Check Count',
        transactionVsLy: 'Check Count vs LY',
        transactionVsLyPercent: 'Check Count % vs LY',
        transactionAvg: 'Check Average',
        hourlyLabor: 'Labor',
        sss: 'SSS %',
        sssAmount: 'SSS',
        variance: 'Variance',
        projected: 'Projected',
        comps: 'Discounts',
        salesPerLaborHour: 'Sales Per Labor Hour',
        avt: 'AVT %',
        plv: 'PLV',
        sos: 'Pickup Time',
      }
    case 'qsr_shack': // QSR Demo
      return {
        cogs: 'COGS',
        rcp: 'Controllable Profit',
        transaction: 'Check Count',
        transactionLy: 'YoY Check Count',
        transactionVsLy: 'Check Count vs LY',
        transactionVsLyPercent: 'Check Count % vs LY',
        transactionAvg: 'Check Average',
        hourlyLabor: 'Hourly Labor',
        sss: 'SSS %',
        sssAmount: 'SSS',
        variance: 'Budget Variance',
        projected: 'Budget',
        comps: 'Comps',
        salesPerLaborHour: 'Sales Per Labor Hour',
        avt: 'AvT',
        plv: 'PLV',
        sos: 'Pickup Time',
      }
    case 'wingitnorth':
      return {
        ...defaultLabels,
        comps: 'Discounts',
        hourlyLabor: 'Labor',
      }
    case 'wingstop':
      return {
        ...defaultLabels,
        salesPerLaborHour: 'Sales Per Man Hour',
      }
    case 'cava':
      return {
        ...defaultLabels,
        transaction: 'Guest Count',
        transactionLy: 'YoY Check Count',
        transactionAvg: 'PPA',
        hourlyLabor: 'Controllable Labor',
        sss: 'SRS %',
        sssAmount: 'SRS',
        comps: 'Discounts',
      }
    case 'irmg_bk':
    case 'viking_bk':
    case 'sandbox_bk':
    case 'supreme_bk':
    case 'timoney_bk':
    case 'demo_bk':
    case 'mbn_bk':
      return {
        cogs: 'COGS',
        rcp: 'RCP',
        transaction: 'Check Count',
        transactionLy: 'YoY Check Count',
        transactionVsLy: 'Check Count vs LY',
        transactionVsLyPercent: 'Check Count % vs LY',
        transactionAvg: 'Check Average',
        hourlyLabor: 'Hourly Labor',
        sss: 'SSS %',
        sssAmount: 'SSS',
        variance: 'Budget Variance',
        projected: 'Budget',
        comps: 'Discount',
        salesPerLaborHour: 'Sales Per Labor Hour',
        avt: 'PLV',
        plv: 'PLV',
        sos: 'Pickup Time',
      }
    case 'jsc_bk':
    case 'jsc_bk_nso':
      return {
        cogs: 'COGS',
        rcp: 'RCP',
        transaction: 'Guests',
        transactionLy: 'YoY Check Count',
        transactionVsLy: 'Guests vs LY',
        transactionVsLyPercent: 'Guests % vs LY',
        transactionAvg: 'Check Average',
        hourlyLabor: 'Hourly Labor',
        sss: 'SSS %',
        sssAmount: 'SSS',
        variance: 'Budget Variance',
        projected: 'Budget',
        comps: 'Discount',
        salesPerLaborHour: 'Sales Per Labor Hour',
        avt: 'AvT',
        plv: 'PLV',
        sos: 'Pickup Time',
      }
    case 'irmg_pop':
    case 'supreme_pop':
    case 'demo_pop':
      return {
        cogs: 'COGS',
        rcp: 'RCP',
        transaction: 'Check Count',
        transactionLy: 'YoY Check Count',
        transactionVsLy: 'Check Count vs LY',
        transactionVsLyPercent: 'Check Count % vs LY',
        transactionAvg: 'Check Average',
        hourlyLabor: 'Labor',
        sss: 'SSS %',
        sssAmount: 'SSS',
        variance: 'Budget Variance',
        projected: 'Budget',
        comps: 'Discount',
        salesPerLaborHour: 'Sales Per Labor Hour',
        avt: 'PLV',
        plv: 'PLV',
        sos: 'Pickup Time',
      }
    case 'ghai_pop':
      return {
        cogs: 'COGS',
        rcp: 'RCP',
        transaction: 'Check Count',
        transactionLy: 'YoY Check Count',
        transactionVsLy: 'Check Count vs LY',
        transactionVsLyPercent: 'Check Count % vs LY',
        transactionAvg: 'Check Average',
        hourlyLabor: 'Labor',
        sss: 'SSS %',
        sssAmount: 'SSS',
        variance: 'Budget Variance',
        projected: 'Budget',
        comps: 'Discount',
        salesPerLaborHour: 'Sales Per Labor Hour',
        avt: 'PLV',
        plv: 'PLV',
        sos: 'Pickup Time',
      }
    case 'jitb':
      return {
        cogs: 'COGS',
        rcp: 'RCP',
        transaction: 'Check Count',
        transactionLy: 'YoY Check Count',
        transactionVsLy: 'Check Count vs LY',
        transactionVsLyPercent: 'Check Count % vs LY',
        transactionAvg: 'Check Average',
        hourlyLabor: 'Hourly Labor',
        sss: 'SSS %',
        sssAmount: 'SSS',
        variance: 'Budget Variance',
        projected: 'Budget',
        comps: 'Discount',
        salesPerLaborHour: 'Sales Per Labor Hour',
        avt: 'PLV',
        plv: 'PLV',
        sos: 'Pickup Time',
      }
    // Local Favorite
    case 'lfr':
    case 'lfr_ef':
    case 'lfr_tr':
    case 'lfr_sn':
    case 'lfr_lv':
    case 'lfr_vb':
    case 'lfr_tu':
    case 'lfr_ws':
    case 'lfr_jn':
    case 'lfr_mm':
    case 'lfr_ol':
    case 'lfr_dt':
    case 'lfr_uv':
    case 'lfr_bth':
      return {
        ...defaultLabels,
        rcp: 'EBITDA',
        sss: 'YOY%',
        sssAmount: 'YOY',
        projected: 'Target',
        variance: 'Target Variance',
      }
    case 'bibibop':
      return {
        ...defaultLabels,
        sss: 'YOY%',
        sssAmount: 'YOY',
      }
    case 'mwb':
      return {
        ...defaultLabels,
        cogs: 'Prime Costs',
      }
    case 'fw_wingstop':
      return {
        ...defaultLabels,
        netSalesVariance: 'Sales - Variance to Forecast',
        hourlyLaborVariance: 'Labor - Variance to Scheduled',
        cogsVariance: 'COGS - Variance to Theoretical',
        projected: 'Forecast/Theoretical/Scheduled',
      }
    default:
      return defaultLabels
  }
}
