import { gql, useQuery } from '@apollo/client'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_DATABASE_FORMAT } from 'pared/constants'

const QUERY_TREND_PMIX_DATA = gql`
  query ListFarwestWeekTrendData(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    trendItemMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_week"
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemId
        itemCode
        itemName
        displayParentCategoryName
        displayParentCategoryCode
        startDate
        metricData
        metricSummaryData
      }
    }
  }
`

const useTrendUnifiedData = (options: any) => {
  const momentEndDate = moment.utc(
    options.variables.iEndDate,
    DATE_DATABASE_FORMAT,
    true,
  )
  const calculatedStartDate = momentEndDate
    .clone()
    .subtract(27, 'days')
    .format(DATE_DATABASE_FORMAT)

  const { data, loading } = useQuery(QUERY_TREND_PMIX_DATA, {
    ...options,
    variables: {
      ...options.variables,
      iStartDate: calculatedStartDate,
    },
  })

  return {
    data: useMemo(() => {
      const dateToWeekMap: { [dateStr: string]: string } = {}

      for (let di = 0; di < 28; ++di) {
        const dateStr = momentEndDate
          .clone()
          .subtract(di, 'days')
          .format(DATE_DATABASE_FORMAT)
        dateToWeekMap[dateStr] = `Week${Math.floor(di / 7.0) + 1}InPast`
      }

      const currentWeekEndDate = momentEndDate.format(DATE_DATABASE_FORMAT)

      const unifiedEndpointData = data?.trendItemMetricValues?.nodes

      if (!unifiedEndpointData) return null

      const idToSourceDataMap: { [id: number]: any } = {}
      const summary: { [key: string]: number } = {}

      unifiedEndpointData.forEach((itemData: any) => {
        const weekStr = dateToWeekMap[itemData.startDate]

        let existingData: any = idToSourceDataMap[itemData.itemId]
        if (!existingData) {
          existingData = {
            ...itemData,
            categoryName: itemData.itemName,
            categoryCode: itemData.itemCode,
            currentWeekEndDate,
          }
        }

        for (const key in itemData.metricData) {
          if (itemData.metricData.hasOwnProperty(key)) {
            let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            newKey = `${newKey}${weekStr}`

            switch (itemData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                existingData[newKey] = itemData.metricData[key].value * 100
                break

              default:
                existingData[newKey] = itemData.metricData[key].value
                break
            }
          }
        }

        if (itemData.metricSummaryData) {
          const metricSummaryData = itemData.metricSummaryData

          for (const key in metricSummaryData) {
            if (metricSummaryData.hasOwnProperty(key)) {
              let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
              newKey = `${newKey}${weekStr}`

              switch (metricSummaryData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  summary[newKey] = metricSummaryData[key].value * 100
                  break

                default:
                  summary[newKey] = metricSummaryData[key].value
                  break
              }
            }
          }
        }

        idToSourceDataMap[itemData.itemId] = existingData
      })

      return {
        source: orderBy(Object.values(idToSourceDataMap), [
          'categoryName',
          'subcategoryName',
          'itemName',
        ]),
        summary: {
          ...summary,
          currentWeekEndDate,
          categoryName: 'TOTAL',
        },
      }
    }, [data]),
    loading,
  }
}

export default useTrendUnifiedData
