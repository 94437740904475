import useSystemwideOverview, {
  systemwideOverviewConfigs,
} from './useSystemwideOverview'

export const configs = {
  systemwideOverview: systemwideOverviewConfigs,
}

export const api = {
  systemwideOverview: useSystemwideOverview,
}
