import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_LOGOS } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useGroupFilter } from '../../groupFilter'
import { IDataType } from '../types'

interface IQueryDataType {
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      geo?: {
        latitude?: number
        longitude?: number
      }
      directorEmployeeInfo?: {
        id: number
        givenName: string
        familyName: string
      }
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids?: number[]
  }
}

const query = gql`
  query listGeoInfo($iFilter: JSON) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        geo
        directorEmployeeInfo {
          id
          givenName
          familyName
        }
      }
    }
  }
`

const useListGeoInfo = () => {
  const { brand } = useBrands()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        iFilter: { location_group_ids: groupFilter?.ids },
      },
      skip: !groupFilter,
    },
  )

  return {
    data: useMemo((): IDataType[] | null => {
      if (!data) return null

      const locationGroupIds = groupFilter?.ids.join('')
      const output = data.listLocationDetails.nodes.reduce(
        (result, { id, code, name, geo, directorEmployeeInfo }) => {
          const groupInfo = directorEmployeeInfo || {
            id: null,
            givenName: 'Others',
            familyName: '',
          }
          const key = !groupInfo.id
            ? 'Others'
            : `${locationGroupIds}${groupInfo.id}`

          if (!result[key])
            result[key] = {
              key,
              name: `${groupInfo.givenName} ${groupInfo.familyName}`,
              points: [],
            }

          if (geo?.latitude && geo?.longitude)
            result[key].points.push({
              key: id,
              name: `${code} - ${name}`,
              logo: BRAND_LOGOS[brand].dark,
              latitude: geo.latitude,
              longitude: geo.longitude,
            })

          return result
        },
        {} as Record<string, IDataType>,
      )

      return Object.values(output)
    }, [data, brand, groupFilter]),
    loading,
  }
}

export default useListGeoInfo
