import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { getBrandSettings } from 'pared/customer'
import { getLocationCode, getLocationName } from 'pared/utils/location'
import { toPercentageString } from 'pared/utils/number'

import {
  LIST_LOCATION_GROUP_GUEST_KPIS,
  LIST_LOCATION_GROUP_SMG_KPIS,
  LIST_LOCATION_GUEST_KPIS,
  LIST_LOCATION_SMG_KPIS,
} from '../gql'
import { IDetailGuestKpi } from '../index'
import Main from './Main'

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

interface IProps {
  isBreakdownByDirectors: boolean
  breakdownType: string | null
  startDate: string
  endDate: string
  locationGroupId: number
  locationGroupIds: number[]
}

function BreakdownTable({
  isBreakdownByDirectors,
  breakdownType,
  startDate,
  endDate,
  locationGroupId,
  locationGroupIds,
}: IProps) {
  const guestConfig = getBrandSettings().guest

  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false)

  const onToggleExpansion = () => {
    setIsTableExpanded(!isTableExpanded)
  }

  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: guestConfig.momentfeed ? 'momentfeedRatings' : 'yelpRatings',
    isAscending: false,
  })

  const [
    listLocationGuestKpis,
    {
      loading: listLocationGuestKpisLoading,
      error: listLocationGuestKpisError,
      data: listLocationGuestKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GUEST_KPIS)

  const [
    listDoGuestKpi,
    {
      loading: listDoGuestKpiLoading,
      error: listDoGuestKpiError,
      data: listDoGuestKpiData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_GUEST_KPIS)

  const [
    listLocationGroupSmgKpis,
    {
      loading: listLocationGroupSmgKpisLoading,
      error: listLocationGroupSmgKpisError,
      data: listLocationGroupSmgKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_SMG_KPIS(getBrandSettings().brand))

  const [
    listLocationSmgKpis,
    {
      loading: listLocationSmgKpisLoading,
      error: listLocationSmgKpisError,
      data: listLocationSmgKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_SMG_KPIS(getBrandSettings().brand))

  useEffect(() => {
    async function fetchData() {
      if (breakdownType) {
        listDoGuestKpi({
          variables: {
            iStartDate: startDate,
            iEndDate: endDate,
            iFilter: {
              location_group_types: [breakdownType],
            },
          },
        })

        if (guestConfig.smg?.isVisible || guestConfig.smgMwb?.isVisible) {
          listLocationGroupSmgKpis({
            variables: {
              iStartDate: startDate,
              iEndDate: endDate,
              iFilter: {
                location_group_types: [breakdownType],
              },
            },
          })
        }
      } else if (isBreakdownByDirectors) {
        listDoGuestKpi({
          variables: {
            iStartDate: startDate,
            iEndDate: endDate,
            iFilter: {
              location_group_ids: locationGroupIds,
            },
          },
        })

        if (guestConfig.smg?.isVisible || guestConfig.smgMwb?.isVisible) {
          listLocationGroupSmgKpis({
            variables: {
              iStartDate: startDate,
              iEndDate: endDate,
              iFilter: {
                location_group_ids: locationGroupIds,
              },
            },
          })
        }
      } else {
        listLocationGuestKpis({
          variables: {
            iFilter: {
              location_group_ids: [locationGroupId],
            },
            iStartDate: startDate,
            iEndDate: endDate,
          },
        })

        if (guestConfig.smg?.isVisible || guestConfig.smgMwb?.isVisible) {
          listLocationSmgKpis({
            variables: {
              iStartDate: startDate,
              iEndDate: endDate,
              iFilter: {
                location_group_ids: [locationGroupId],
              },
            },
          })
        }
      }
    }

    fetchData()
  }, [
    startDate,
    endDate,
    locationGroupId,
    isBreakdownByDirectors,
    breakdownType,
  ])

  let rankedItemizedKpis = []
  const columnName = _.get(orderBy, 'columnName', '')
  const ascending = orderBy.isAscending ? 'asc' : 'desc'

  const ascendingCoefficient = orderBy.isAscending ? 1 : -1

  let itemizedKpis: IDetailGuestKpi[] = []

  if (!isBreakdownByDirectors && !breakdownType) {
    // Breakdown by location
    if (
      listLocationGuestKpisData &&
      listLocationGuestKpisData.listLocationGuestKpis &&
      Array.isArray(listLocationGuestKpisData.listLocationGuestKpis.nodes)
    ) {
      const rawLocationGuestKpis = _.get(
        listLocationGuestKpisData,
        'listLocationGuestKpis.nodes',
        [],
      )

      const smgKpisData =
        listLocationSmgKpisData?.listLocationSmgKpis?.nodes ?? []
      const smgMap = _.keyBy(smgKpisData, 'locationId')
      itemizedKpis = _.map(rawLocationGuestKpis, (k) => {
        const locationId = _.get(k, 'locationId', 1)
        const locationCode = getLocationCode(
          locationId,
          _.get(k, 'locationInfo.code', ''),
        )
        const locationName = getLocationName(
          locationId,
          _.get(k, 'locationInfo.name', ''),
        )
        const ratings = _.get(k, 'ratings', {})
        const ratingCounts = _.get(k, 'ratingCounts', {})

        const acrRatings = _.get(ratings, 'acr')
        const acrRatingCount = _.get(ratingCounts, 'acr')
        const momentfeedRatings = _.get(ratings, 'momentfeed')
        const yelpRatings = _.get(ratings, 'yelp')
        const googleRatings = _.get(ratings, 'google')
        const facebookRatings = _.get(ratings, 'facebook')
        const deliveryRatings = _.get(ratings, 'delivery')
        const allRatings = _.get(ratings, 'all')

        const {
          dissatisfactionPercent,
          inaccuratePercent,
          totalSurveys,
          overallSatisfaction,
          orderNotOnTime,
          tasteOfFood,
        } = smgMap[locationId] ?? {}

        return {
          locationId,
          acrRatings: acrRatings ? acrRatings.toFixed(1) : '-',
          acrRatingCount: acrRatingCount ? acrRatingCount : '-',
          momentfeedRatings: momentfeedRatings
            ? momentfeedRatings.toFixed(1)
            : '-',
          yelpRatings: yelpRatings ? yelpRatings.toFixed(1) : '-',
          googleRatings: googleRatings ? googleRatings.toFixed(1) : '-',
          facebookRatings: facebookRatings ? facebookRatings.toFixed(1) : '-',
          deliveryRatings: deliveryRatings ? deliveryRatings.toFixed(1) : '-',
          allRatings: allRatings ? allRatings.toFixed(1) : '-',
          dissatisfactionRatings: dissatisfactionPercent?.toFixed(1) ?? '-',
          inaccurateRatings: inaccuratePercent?.toFixed(1) ?? '-',
          overallSatisfactionRatings: overallSatisfaction?.toFixed(1) ?? '-',
          orderNotOnTimeRatings: orderNotOnTime?.toFixed(1) ?? '-',
          tasteOfFoodRatings: tasteOfFood?.toFixed(1) ?? '-',
          smgSurveyCount: totalSurveys ?? '-',
          opsCompsPercent: toPercentageString(
            _.get(k, 'opsCompsPercent', 0) / 100,
            1,
          ),
          formattedName: `${locationCode} - ${locationName}`,
        }
      })
    }
  } else {
    // breakdownByDirectors or location group type
    if (Array.isArray(listDoGuestKpiData?.listLocationGroupGuestKpis?.nodes)) {
      const rawDoGuestKpis = _.get(
        listDoGuestKpiData,
        'listLocationGroupGuestKpis.nodes',
        [],
      )

      const smgKpisData =
        listLocationGroupSmgKpisData?.listLocationGroupSmgKpis?.nodes ?? []
      const smgMap = _.keyBy(smgKpisData, 'locationGroupId')

      itemizedKpis = _.map(rawDoGuestKpis, (k) => {
        const doFirstName = _.get(k, 'employeeInfo.firstName', '')
        const doLastName = _.get(k, 'employeeInfo.lastName', '')
        const doEmployeeId = _.get(k, 'employeeInfo.id')
        const locationGroupId = _.get(k, 'locationGroupId', '')
        const locationGroupName = _.get(k, 'locationGroupName', '')
        const ratings = _.get(k, 'ratings', {})
        const ratingCounts = _.get(k, 'ratingCounts', {})
        const acrRatings = _.get(ratings, 'acr')
        const acrRatingCount = _.get(ratingCounts, 'acr')
        const momentfeedRatings = _.get(ratings, 'momentfeed')
        const yelpRatings = _.get(ratings, 'yelp')
        const googleRatings = _.get(ratings, 'google')
        const facebookRatings = _.get(ratings, 'facebook')
        const deliveryRatings = _.get(ratings, 'delivery')
        const allRatings = _.get(ratings, 'all')

        const {
          dissatisfactionPercent,
          inaccuratePercent,
          overallSatisfaction,
          orderNotOnTime,
          tasteOfFood,
          totalSurveys,
        } = smgMap[locationGroupId] ?? {}

        const base = {
          totalOrderCount: _.get(k, 'totalOrderCount', 0),
          acrRatings: acrRatings ? acrRatings.toFixed(1) : '-',
          acrRatingCount: acrRatingCount ? acrRatingCount : '-',
          momentfeedRatings: momentfeedRatings
            ? momentfeedRatings.toFixed(1)
            : '-',
          yelpRatings: yelpRatings ? yelpRatings.toFixed(1) : '-',
          googleRatings: googleRatings ? googleRatings.toFixed(1) : '-',
          facebookRatings: facebookRatings ? facebookRatings.toFixed(1) : '-',
          deliveryRatings: deliveryRatings ? deliveryRatings.toFixed(1) : '-',
          dissatisfactionRatings: dissatisfactionPercent?.toFixed(1) ?? '-',
          inaccurateRatings: inaccuratePercent?.toFixed(1) ?? '-',
          overallSatisfactionRatings: overallSatisfaction?.toFixed(1) ?? '-',
          orderNotOnTimeRatings: orderNotOnTime?.toFixed(1) ?? '-',
          tasteOfFoodRatings: tasteOfFood?.toFixed(1) ?? '-',
          smgSurveyCount: totalSurveys ?? '-',
          allRatings: allRatings ? allRatings.toFixed(1) : '-',
          opsCompsPercent: toPercentageString(
            _.get(k, 'opsCompsPercent', 0) / 100,
            1,
          ),
        }

        if (isBreakdownByDirectors && doEmployeeId) {
          return {
            ...base,
            employeeId: doEmployeeId,
            formattedName: `${doFirstName} ${doLastName}`,
          }
        }

        return {
          ...base,
          formattedName: locationGroupName,
        }
      })
    }
  }

  switch (columnName) {
    case 'acrRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'acrRatings', '0')) * ascendingCoefficient
      })
      break
    case 'acrRatingCount':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'acrRatingCount', '0')) * ascendingCoefficient
        )
      })
      break
    case 'momentfeedRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'momentfeedRatings', '0')) * ascendingCoefficient
        )
      })
      break
    case 'yelpRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'yelpRatings', '0')) * ascendingCoefficient
      })
      break
    case 'googleRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'googleRatings', '0')) * ascendingCoefficient
      })
      break
    case 'facebookRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'facebookRatings', '0')) * ascendingCoefficient
        )
      })
      break
    case 'deliveryRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'deliveryRatings', '0')) * ascendingCoefficient
        )
      })
      break
    case 'allRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'allRatings', '0')) * ascendingCoefficient
      })
      break
    case 'dissatisfactionRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'dissatisfactionRatings', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'inaccurateRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'inaccurateRatings', '0')) * ascendingCoefficient
        )
      })
      break
    case 'overallSatisfactionRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'overallSatisfactionRatings', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'orderNotOnTimeRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'orderNotOnTimeRatings', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'tasteOfFoodRatings':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'tasteOfFoodRatings', '0')) * ascendingCoefficient
        )
      })
      break
    case 'opsCompsPercent':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'opsCompsPercent', '0')) * ascendingCoefficient
        )
      })
      break
    default:
      rankedItemizedKpis = _.orderBy(
        itemizedKpis,
        ['totalOrderCount'],
        [ascending],
      )
      break
  }

  return (
    <Main
      itemizedKpis={rankedItemizedKpis}
      isTableExpanded={isTableExpanded}
      onToggleExpansion={onToggleExpansion}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      isBreakdownByDirectors={isBreakdownByDirectors}
      breakdownType={breakdownType}
    />
  )
}

export default BreakdownTable
