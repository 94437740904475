import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import {
  MetricResult,
  useFetchGroupMetricValue,
} from './useFetchGroupMetricValue'

const useBbbCorpSales = (skip: boolean): IApiDataType => {
  const { variables } = useVariables()

  const currentPeriod = variables.date?.getInfo(0)
  const {
    startDateStr: currentPeriodBeginDate,
    endDateStr: currentPeriodEndDate,
  } = currentPeriod?.dateRange || {}
  const { startDateStr: priorPeriodBeginDate, endDateStr: priorPeriodEndDate } =
    variables.date?.getInfo(-1)?.dateRange || {}

  const { groupFilter } = useGroupFilter()
  const locationGroupId = groupFilter?.ids[0] || 1

  const metricCodes = [
    'total_sales',
    'total_sales_budget',
    'check_count',
    'check_average',
  ]

  const { results: currentMetrics, isLoading: currentLoading } =
    useFetchGroupMetricValue(
      metricCodes,
      currentPeriodBeginDate,
      currentPeriodEndDate,
      locationGroupId,
      skip,
    )

  const { results: priorMetrics, isLoading: priorLoading } =
    useFetchGroupMetricValue(
      metricCodes,
      priorPeriodBeginDate,
      priorPeriodEndDate,
      locationGroupId,
      skip,
    )

  const { results: yoyMetrics, isLoading: yoyLoading } =
    useFetchGroupMetricValue(
      metricCodes,
      currentPeriod?.yoy?.dateRange?.startDateStr,
      currentPeriod?.yoy?.dateRange?.endDateStr,
      locationGroupId,
      skip,
    )

  const getMetricValue = (metrics: MetricResult[], metricCode: string) => {
    return metrics.find((m) => m.metricCode === metricCode)?.value ?? 0
  }

  const sales = getMetricValue(currentMetrics, 'total_sales')
  const priorSales = getMetricValue(priorMetrics, 'total_sales')
  const yoySales = getMetricValue(yoyMetrics, 'total_sales')
  const salesBudget = getMetricValue(currentMetrics, 'total_sales_budget')

  const salesVar = sales - salesBudget

  const calculateChange = (
    currentValue: number,
    previousValue: number,
  ): number => {
    return previousValue !== 0
      ? (100 * (currentValue - previousValue)) / previousValue
      : 0
  }

  return {
    data: useMemo(() => {
      if (!sales) return null

      // ADD TEXT HERE START

      const salesVarText =
        salesVar <= 0
          ? `Missing the ${toUsdString(salesBudget)} budget by ${toUsdString(
              -salesVar,
            )}`
          : `Beating the ${toUsdString(salesBudget)} budget by ${toUsdString(
              salesVar,
            )}`

      const salesVarPeriodChgText =
        priorSales === 0
          ? `No Sales data from Prior Period for comparison`
          : sales >= priorSales
          ? `Increasing ${toPercentString(
              ((sales - priorSales) / priorSales) * 100,
            )} from Prior Period of ${toUsdString(priorSales)}`
          : `Decreasing ${toPercentString(
              ((priorSales - sales) / priorSales) * 100,
            )} from Prior Period of ${toUsdString(priorSales)}`

      const salesYoyChgText =
        yoySales === 0
          ? `No Sales data from Prior Year for comparison`
          : sales >= yoySales
          ? `Increasing ${toPercentString(
              ((sales - yoySales) / yoySales) * 100,
            )} from Prior Year of ${toUsdString(yoySales)}`
          : `Decreasing ${toPercentString(
              ((yoySales - sales) / yoySales) * 100,
            )} from Prior Year of ${toUsdString(yoySales)}`

      // ADD TEXT HERE END

      return {
        title: `Sales`,
        total: salesVar * -1,
        summary: `Sales in ${currentPeriod?.displayName} was ${toUsdString(
          sales,
        )}`,
        detail: `
          <ul>
            <li>${salesVarText}</li>
            <li>${salesVarPeriodChgText}</li>
            <li>${salesYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [sales, salesBudget, priorSales, yoySales, currentPeriod]),
    loading: currentLoading || priorLoading || yoyLoading,
  }
}

export default useBbbCorpSales
