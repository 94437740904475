import { IConfigsType } from '../../types'

const fwWingstopFlashSalesByOrderMode: IConfigsType = {
  fw_wingstop: {
    '/:brand/sales_by_order_mode': {
      dateFilter: {
        types: ['period'],
        defaultType: 'period',
      },
      groupFilter: {
        api: 'corporateFilter',
        hide: true,
      },
      reportTitle: {
        type: 'title',
        title: 'Sales by Order Mode',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'farwestFlashSalesByOrderMode',
        fileName: 'getexpo-far-west-sales-by-order-mode',
        message: 'Download as CSV',
        fields: [
          {
            key: 'oloTakeOut',
            title: 'Take Out - OLO',
          },
          {
            key: 'callInToGo',
            title: 'Call In / To Go',
          },
          {
            key: 'walkInDineIn',
            title: 'Walk In / Dine in',
          },
          {
            key: 'walkInDineIn',
            title: 'Walk In / To Go',
          },
          {
            key: 'doordashSales',
            title: 'DoorDash Sales',
          },
          {
            key: 'ubereatSales',
            title: 'Uber Eats Sales',
          },
          {
            key: 'dispatchSales',
            title: 'OLO Sales',
          },
          {
            key: 'grubhubSales',
            title: 'Grubhub Sales',
          },
          {
            key: 'oloTakeOutPercentage',
            title: 'Take Out - OLO Percentage',
          },
          {
            key: 'walkInDineInPercentage',
            title: 'Dine in - Walk in Percentage',
          },
          {
            key: 'walkInToGoPercentage',
            title: 'Take out - Walk in Percentage',
          },
          {
            key: 'callInToGoPercentage',
            title: 'Takeout - Call in/Call Center Percentage',
          },
          {
            key: 'doordashSalesPercentage',
            title: 'DoorDash Sales Percentage',
          },
          {
            key: 'ubereatSalesPercentage',
            title: 'Uber Eats Sales Percentage',
          },
          {
            key: 'dispatchSalesPercentage',
            title: 'OLO Sales Percentage',
          },
          {
            key: 'grubhubSalesPercentage',
            title: 'Grubhub Sales Percentage',
          },
          {
            key: 'periodEquivalentUnitCount',
            title: 'Equivalent Unit Count',
          },
        ],
      },
      table: {
        type: 'table',
        api: 'farwestFlashSalesByOrderMode',
        columns: [
          {
            key: 'salesByOrderMode',
            title: 'Avg Weekly Sales by Order Mode',
            align: 'center',
            children: [
              {
                key: 'oloTakeOut',
                title: 'Take Out - OLO',
                width: '100px',
                align: 'center',
                decimal: 2,
              },
              {
                key: 'walkInDineIn',
                title: 'Dine in - Walk in',
                align: 'center',
                decimal: 2,
              },
              {
                key: 'walkInToGo',
                title: 'Take out - Walk in',
                align: 'center',
                decimal: 2,
              },
              {
                key: 'callInToGo',
                title: 'Takeout - Call in/Call Center',
                align: 'center',
                decimal: 2,
              },
            ],
          },
          {
            key: 'deliveryMode',
            title: 'Total Sales by Delivery mode',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'doordashSales',
                title: 'DoorDash',
                align: 'center',
              },
              {
                key: 'ubereatSales',
                title: 'Uber Eats',
                align: 'center',
              },
              {
                key: 'dispatchSales',
                title: 'OLO',
                align: 'center',
              },
              {
                key: 'grubhubSales',
                title: 'Grubhub',
                align: 'center',
              },
            ],
          },
          {
            key: 'salesByOrderModePercent',
            title: 'Total Sales Percent by Order Mode',
            align: 'center',
            children: [
              {
                key: 'oloTakeOutPercentage',
                title: 'Take Out - OLO',
                width: '100px',
                align: 'center',
                decimal: 2,
              },
              {
                key: 'walkInDineInPercentage',
                title: 'Dine in - Walk in',
                align: 'center',
                decimal: 2,
              },
              {
                key: 'walkInToGoPercentage',
                title: 'Take out - Walk in',
                align: 'center',
                decimal: 2,
              },
              {
                key: 'callInToGoPercentage',
                title: 'Takeout - Call in/Call Center',
                align: 'center',
                decimal: 2,
              },
            ],
          },
          {
            key: 'deliveryModePercent',
            title: 'Total Sales Percent by Delivery mode',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'doordashSalesPercentage',
                title: 'DoorDash',
                align: 'center',
              },
              {
                key: 'ubereatSalesPercentage',
                title: 'Uber Eats',
                align: 'center',
              },
              {
                key: 'dispatchSalesPercentage',
                title: 'OLO',
                align: 'center',
              },
              {
                key: 'grubhubSalesPercentage',
                title: 'Grubhub',
                align: 'center',
              },
            ],
          },
          {
            key: 'periodEquivalentUnitCount',
            title: 'Equivalent Unit Count',
            align: 'center',
            decimal: 2,
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashSalesByOrderMode
