import { getBrandSettings, getBusinessLabel } from 'pared/customer'

export function getQueryFilterByQueryName(queryName: string, iFilters: any) {
  if (
    queryName === 'rankLocationSalesmanshipKpis' ||
    queryName === 'rankLocationGroupSalesmanshipKpis' ||
    queryName === 'rankLocationTurnovers' ||
    queryName === 'rankLocationGuestKpis' ||
    queryName === 'rankLocationDeliveryKpis' ||
    queryName === 'rankLocationGroupDeliveryKpis'
  ) {
    return { location_group_ids: iFilters['location_group_ids'] }
  } else {
    return { ...iFilters }
  }
}

export function getCustomizedReportName(rankBy: string, groupBy: string) {
  if (groupBy === 'store') {
    switch (rankBy) {
      case 'classicUsageVariancePercent':
      case 'bonelessUsageVariancePercent':
      case 'tendersUsageVariancePercent':
        return 'CORPORATE_FLASH_CHICKEN_INVENTORY'
      default:
        return ''
    }
  }

  if (groupBy === 'director') {
    switch (rankBy) {
      case 'classicUsageVariancePercent':
      case 'bonelessUsageVariancePercent':
      case 'tendersUsageVariancePercent':
        return 'CORPORATE_FLASH_CHICKEN_INVENTORY_BY_DIRECTOR'
      default:
        return ''
    }
  }
}

export const getQueryName = (filterBy: string, groupBy: string) => {
  if (groupBy !== 'store') {
    if (filterBy === 'scorecard') {
      return 'rankLocationGroupScorecard'
    } else if (
      filterBy === 'net_sales' ||
      filterBy === 'gross_sales' ||
      filterBy === 'cogs' ||
      filterBy === 'cogs_budget_variance' ||
      filterBy === 'avt' ||
      filterBy === 'avt_percent_monthly' ||
      filterBy === 'rcp' ||
      filterBy === 'rcp_budget_variance' ||
      filterBy === 'net_sales_sss' ||
      filterBy === 'gross_sales_sss' ||
      filterBy === 'net_sales_budget_variance' ||
      filterBy === 'gross_sales_budget_variance' ||
      filterBy === 'hourly_labor_percent' ||
      filterBy === 'hourly_labor_budget_variance' ||
      filterBy === 'ops_comps' ||
      filterBy === 'daypart' ||
      filterBy === 'sales_per_labor_hour' ||
      filterBy === 'total_check_average' ||
      filterBy === 'plv_percent' ||
      filterBy === 'total_guest_average' ||
      filterBy === 'total_check_count'
    ) {
      return 'listLocationGroupKpiRankings'
    } else if (filterBy === 'acr_score') {
      return 'listLocationGroupAcrScoreRankings'
    } else if (filterBy === 'annualizedHourlyTurnoverV2') {
      return 'rankLocationGroupTurnoversV2'
    } else if (
      filterBy === 'annualizedHourlyTurnover' ||
      filterBy === 'annualizedManagerTurnover' ||
      filterBy === 'hourlyTurnoverRateAndCount' ||
      filterBy === 'managerTurnoverRateAndCount'
    ) {
      return 'rankLocationGroupTurnovers'
    } else if (
      filterBy === 'salesPerLaborHour' ||
      filterBy === 'ppa' ||
      filterBy === 'appetizerPpa' ||
      filterBy === 'lbwPpa' ||
      filterBy === 'dessertPpa'
    ) {
      return 'rankLocationGroupSalesmanshipKpis'
    } else if (['momentfeed', 'google', 'yelp'].includes(filterBy)) {
      return 'rankLocationGroupGuestKpis'
    } else if (filterBy === 'delivery') {
      return 'rankLocationGroupGuestKpis'
    } else if (filterBy === 'deliveryOrders') {
      return 'rankLocationGroupDeliveryKpis'
    } else if (filterBy === 'deliverySales') {
      return 'rankLocationGroupDeliveryKpis'
    } else if (filterBy === 'averageTripTime') {
      return 'rankLocationGroupDeliveryKpis'
    } else if (filterBy === 'orderWithAnyIssues') {
      return 'rankLocationGroupDeliveryKpis'
    } else if (filterBy === 'combinedStarRating') {
      return 'rankLocationGroupDeliveryKpis'
    } else if (
      filterBy === 'classicUsageVariancePercent' ||
      filterBy === 'bonelessUsageVariancePercent' ||
      filterBy === 'tendersUsageVariancePercent'
    ) {
      return 'getCustomizedReport'
    } else if (
      filterBy === 'totalPpa' ||
      filterBy === 'salesPerHour' ||
      filterBy === 'checkAvg' ||
      filterBy === 'totalSidesSales' ||
      filterBy === 'friesSales' ||
      filterBy === 'cornSales' ||
      filterBy === 'brownieSales' ||
      filterBy === 'entreePremiumIncidents' ||
      filterBy === 'curatedIncidents' ||
      filterBy === 'sideAttachmentRate' ||
      filterBy === 'dessertAttachmentRate' ||
      filterBy === 'beverageAttachmentRate' ||
      filterBy === 'baconAttachmentRate' ||
      filterBy === 'cheeseAttachmentRate' ||
      filterBy === 'treatAttachmentRate' ||
      filterBy === 'cornAttachmentRate' ||
      filterBy === 'waterAttachmentRate' ||
      filterBy === 'saucesAttachmentRate' ||
      filterBy === 'sticksAttachmentRate' ||
      filterBy === 'dessertsAttachmentRate' ||
      filterBy === 'beveragesAttachmentRate' ||
      filterBy === 'jalapenoAttachmentRate' ||
      filterBy === 'dessertRate' ||
      filterBy === 'comboRate' ||
      filterBy === 'drinkRate' ||
      filterBy === 'appetizerPpaV2' ||
      filterBy === 'lbwPpaV2' ||
      filterBy === 'dessertPpaV2' ||
      filterBy === 'naBeveragePpa' ||
      filterBy === 'sidesPpa'
    ) {
      return 'listLocationGroupSalesmanshipKpis'
    } else if (filterBy === 'sosPickup') {
      return 'listLocationGroupSpeedOfService'
    } else if (filterBy === 'entreesPerLaborHour') {
      return 'listLocationGroupEntreesPerLaborHour'
    } else if (filterBy === 'upsizePercent') {
      return 'listLocationGroupUpsizePercent'
    } else if (filterBy === 'zenput') {
      return 'listLocationGroupZenputScore'
    } else if (filterBy === 'cavayou') {
      return 'listLocationGroupCavayouScore'
    } else if (filterBy === 'inboundCalls') {
      return 'listLocationGroupInboundCalls'
    } else if (['voids', 'discounts', 'cashOverOrShort'].includes(filterBy)) {
      return 'listLocationGroupLossPreventionKpis'
    } else if (['smg'].includes(filterBy)) {
      return 'listLocationGroupSmgKpis'
    } else if (['opentable'].includes(filterBy)) {
      return 'listLocationGroupGuestOpentableKpis'
    } else if (['marketingAnalysis'].includes(filterBy)) {
      return 'rankLocationGroupDiscountCampaign'
    } else if (['hme'].includes(filterBy)) {
      return 'listLocationGroupHme'
    }
  } else {
    if (filterBy === 'scorecard') {
      return 'rankLocationScorecard'
    } else if (
      filterBy === 'net_sales' ||
      filterBy === 'gross_sales' ||
      filterBy === 'cogs' ||
      filterBy === 'cogs_budget_variance' ||
      filterBy === 'avt' ||
      filterBy === 'avt_percent_monthly' ||
      filterBy === 'rcp' ||
      filterBy === 'rcp_budget_variance' ||
      filterBy === 'net_sales_sss' ||
      filterBy === 'gross_sales_sss' ||
      filterBy === 'net_sales_budget_variance' ||
      filterBy === 'gross_sales_budget_variance' ||
      filterBy === 'hourly_labor_percent' ||
      filterBy === 'hourly_labor_budget_variance' ||
      filterBy === 'ops_comps' ||
      filterBy === 'daypart' ||
      filterBy === 'sales_per_labor_hour' ||
      filterBy === 'total_check_average' ||
      filterBy === 'plv_percent' ||
      filterBy === 'total_guest_average' ||
      filterBy === 'total_check_count'
    ) {
      return 'listLocationKpiRankings'
    } else if (filterBy === 'acr_score') {
      return 'listLocationAcrScoreRankings'
    } else if (filterBy === 'annualizedHourlyTurnoverV2') {
      return 'rankLocationTurnoversV2'
    } else if (
      filterBy === 'annualizedHourlyTurnover' ||
      filterBy === 'annualizedManagerTurnover' ||
      filterBy === 'hourlyTurnoverRateAndCount' ||
      filterBy === 'managerTurnoverRateAndCount'
    ) {
      return 'rankLocationTurnovers'
    } else if (
      filterBy === 'salesPerLaborHour' ||
      filterBy === 'ppa' ||
      filterBy === 'appetizerPpa' ||
      filterBy === 'lbwPpa' ||
      filterBy === 'dessertPpa'
    ) {
      return 'rankLocationSalesmanshipKpis'
    } else if (['momentfeed', 'google', 'yelp'].includes(filterBy)) {
      return 'rankLocationGuestKpis'
    } else if (filterBy === 'delivery') {
      return 'rankLocationGuestKpis'
    } else if (filterBy === 'deliveryOrders') {
      return 'rankLocationDeliveryKpis'
    } else if (filterBy === 'deliverySales') {
      return 'rankLocationDeliveryKpis'
    } else if (filterBy === 'averageTripTime') {
      return 'rankLocationDeliveryKpis'
    } else if (filterBy === 'orderWithAnyIssues') {
      return 'rankLocationDeliveryKpis'
    } else if (filterBy === 'combinedStarRating') {
      return 'rankLocationDeliveryKpis'
    } else if (
      filterBy === 'classicUsageVariancePercent' ||
      filterBy === 'bonelessUsageVariancePercent' ||
      filterBy === 'tendersUsageVariancePercent'
    ) {
      return 'getCustomizedReport'
    } else if (
      filterBy === 'totalPpa' ||
      filterBy === 'salesPerHour' ||
      filterBy === 'checkAvg' ||
      filterBy === 'totalSidesSales' ||
      filterBy === 'friesSales' ||
      filterBy === 'cornSales' ||
      filterBy === 'brownieSales' ||
      filterBy === 'entreePremiumIncidents' ||
      filterBy === 'curatedIncidents' ||
      filterBy === 'sideAttachmentRate' ||
      filterBy === 'dessertAttachmentRate' ||
      filterBy === 'beverageAttachmentRate' ||
      filterBy === 'baconAttachmentRate' ||
      filterBy === 'cheeseAttachmentRate' ||
      filterBy === 'treatAttachmentRate' ||
      filterBy === 'cornAttachmentRate' ||
      filterBy === 'waterAttachmentRate' ||
      filterBy === 'saucesAttachmentRate' ||
      filterBy === 'sticksAttachmentRate' ||
      filterBy === 'dessertsAttachmentRate' ||
      filterBy === 'beveragesAttachmentRate' ||
      filterBy === 'jalapenoAttachmentRate' ||
      filterBy === 'dessertRate' ||
      filterBy === 'comboRate' ||
      filterBy === 'drinkRate' ||
      filterBy === 'appetizerPpaV2' ||
      filterBy === 'lbwPpaV2' ||
      filterBy === 'dessertPpaV2' ||
      filterBy === 'naBeveragePpa' ||
      filterBy === 'sidesPpa'
    ) {
      return 'listLocationSalesmanshipKpis'
    } else if (filterBy === 'sosPickup') {
      return 'listLocationSpeedOfService'
    } else if (filterBy === 'entreesPerLaborHour') {
      return 'listLocationEntreesPerLaborHour'
    } else if (filterBy === 'upsizePercent') {
      return 'listLocationUpsizePercent'
    } else if (filterBy === 'zenput') {
      return 'listLocationZenputScore'
    } else if (filterBy === 'cavayou') {
      return 'listLocationCavayouScore'
    } else if (filterBy === 'inboundCalls') {
      return 'listLocationInboundCalls'
    } else if (['voids', 'discounts', 'cashOverOrShort'].includes(filterBy)) {
      return 'listLocationLossPreventionKpis'
    } else if (['smg'].includes(filterBy)) {
      return 'listLocationSmgKpis'
    } else if (['opentable'].includes(filterBy)) {
      return 'listLocationGuestOpentableKpis'
    } else if (['marketingAnalysis'].includes(filterBy)) {
      return 'rankLocationDiscountCampaign'
    } else if (['hme'].includes(filterBy)) {
      return 'listLocationHme'
    }
  }
}

export const getQueryAvgFieldName = (filterBy: string) => {
  if (filterBy === 'salesPerLaborHour') {
    return 'avgGrossSalesPerLaborHour'
  } else if (filterBy === 'ppa') {
    return 'avgPpa'
  } else if (filterBy === 'appetizerPpa') {
    return 'avgAppetizerPpa'
  } else if (filterBy === 'lbwPpa') {
    return 'avgLbwPpa'
  } else if (filterBy === 'dessertPpa') {
    return 'avgDessertPpa'
  }
}

export const getColumnNames = (filterBy: string, groupBy: string) => {
  const brandSettings = getBrandSettings()
  const brand = brandSettings.brand
  const financialLabel = brandSettings.labels.financial
  const salesmanshipLabels = brandSettings.labels.salesmanship
  const directorLabel = getBusinessLabel('director')
  const storeLabel = getBusinessLabel('store')

  if (groupBy !== 'store') {
    let displayName = ''
    let key = ''
    let salesmanshipKey = ''

    if (groupBy === 'director') {
      displayName = directorLabel
      key = 'doEmployeeId'
      salesmanshipKey = 'doLocationGroupId'
    } else {
      displayName = groupBy
      key = 'locationGroupName'
      salesmanshipKey = 'locationGroupName'
    }

    if (filterBy === 'scorecard') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Score',
          key: 'totalScore',
          type: 'score',
        },
      ]
    } else if (filterBy === 'net_sales') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Net Sales',
          key: 'net_sales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'gross_sales') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Gross Sales',
          key: 'gross_sales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'cogs') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: financialLabel.cogs,
          key: 'cogs',
          type: 'currencyInCents',
        },
        {
          displayName: `${financialLabel.cogs} %`,
          key: 'cogs_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'cogs_budget_variance') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: `${financialLabel.cogs} ${financialLabel.variance}`,
          key: 'cogs_budget_variance',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `Unfavorable Variance to ${financialLabel.projected}`,
          key: 'cogs_budget_variance_amount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'avt') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'AvT',
          key: 'avt_percent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '$ AvT',
          key: 'avt_waste',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'avt_percent_monthly') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'AvT',
          key: 'avt_percent_monthly',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'rcp') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: financialLabel.rcp,
          key: 'rcp',
          type: 'currencyInCents',
        },
        {
          displayName: `${financialLabel.rcp} %`,
          key: 'rcp_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'rcp_budget_variance') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: `${financialLabel.rcp} ${financialLabel.variance}`,
          key: 'rcp_budget_variance',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'net_sales_sss') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: financialLabel.sss,
          key: 'net_sales_sss_percent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `$ ${financialLabel.sssAmount}`,
          key: 'net_sales_sss',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'gross_sales_sss') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: financialLabel.sss,
          key: 'gross_sales_sss_percent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `$ ${financialLabel.sssAmount}`,
          key: 'gross_sales_sss',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'net_sales_budget_variance') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: `Net Sales ${financialLabel.variance}`,
          key: 'net_sales_budget_variance',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `$ Net Sales ${financialLabel.variance}`,
          key: 'net_sales_budget_variance_amount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'gross_sales_budget_variance') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: `Gross Sales ${financialLabel.variance}`,
          key: 'gross_sales_budget_variance',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `$ Gross Sales ${financialLabel.variance}`,
          key: 'gross_sales_budget_variance_amount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'hourly_labor_percent') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: `${financialLabel.hourlyLabor} Percent`,
          key: 'hourly_labor_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'hourly_labor_budget_variance') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: `${financialLabel.hourlyLabor} ${financialLabel.variance}`,
          key: 'hourly_labor_budget_variance',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `Unfavorable Variance to ${financialLabel.projected}`,
          key: 'hourly_labor_budget_variance_amount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'plv_percent') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'PLV %',
          key: 'plv_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'ops_comps') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Ops Comps',
          key: 'ops_comps',
          type: 'currencyInCents',
        },
        {
          displayName: 'Ops Comps % of Gross Sales',
          key: 'ops_comps_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'daypart') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        ...(brand === 'mwb'
          ? [
              {
                displayName: 'Early Bird',
                key: 'early_bird_net_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        ...(brand === 'mwb'
          ? [
              {
                displayName: 'Breakfast',
                key: 'breakfast_net_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        {
          displayName: 'Lunch',
          key: 'lunch_sales',
          type: 'currencyInCents',
        },
        ...(brand === 'mwb'
          ? [
              {
                displayName: 'Afternoon Snack',
                key: 'afternoon_snack_net_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        ...(brand === 'wingitnorth'
          ? [
              {
                displayName: 'Snack',
                key: 'snack_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        {
          displayName: 'Dinner',
          key: 'dinner_sales',
          type: 'currencyInCents',
        },
        ...(brand === 'mwb'
          ? [
              {
                displayName: 'Evening Snack',
                key: 'evening_snack_net_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        ...(brand === 'wingitnorth'
          ? [
              {
                displayName: 'Late Night',
                key: 'late_night_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
      ]
    } else if (
      filterBy === 'annualizedHourlyTurnover' ||
      filterBy === 'annualizedHourlyTurnoverV2'
    ) {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Annualized Turnover Rate',
          key: 'annualizedHourlyTurnoverPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'YTD Turnover Count',
          key: 'hourlyTurnoverCountInLast12Months',
          type: 'number',
        },
      ]
    } else if (filterBy === 'annualizedManagerTurnover') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Annualized Turnover Rate',
          key: 'annualizedSalariedTurnoverPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'YTD Turnover Count',
          key: 'salariedTurnoverCountInLast12Months',
          type: 'number',
        },
      ]
    } else if (filterBy === 'hourlyTurnoverRateAndCount') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Turnover Rate',
          key: 'avgHourlyTurnoverPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'Turnover Count',
          key: 'hourlyTurnoverCount',
          type: 'number',
        },
      ]
    } else if (filterBy === 'managerTurnoverRateAndCount') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Turnover Rate',
          key: 'avgSalariedTurnoverPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'Turnover Count',
          key: 'salariedTurnoverCount',
          type: 'number',
        },
      ]
    } else if (filterBy === 'avgHourlyRate') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Avg Hourly Rates',
          key: 'avgHourlyRate',
          type: 'number',
        },
      ]
    } else if (filterBy === 'salesPerLaborHour') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.salesPerLaborHour,
          key: 'grossSalesPerLaborHour',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'ppa') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.ppa,
          key: 'ppa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'appetizerPpa') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.appetizerPpa,
          key: 'appetizerPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'lbwPpa') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.lbwPpa,
          key: 'lbwPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'dessertPpa') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.dessertPpa,
          key: 'dessertPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'productivityCoefficient') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Productivity Coefficient',
          key: 'productivityCoefficient',
          type: 'number',
        },
      ]
    } else if (['momentfeed', 'google', 'yelp'].includes(filterBy)) {
      return [
        {
          displayName,
          key: 'employeeInfo.id',
          type: 'string',
        },
        {
          displayName: 'Avg Rating',
          key: 'ratingScore',
          type: 'score',
        },
        {
          displayName: 'Rating Count',
          key: 'ratingCount',
          type: 'number',
        },
      ]
    } else if (filterBy === 'delivery') {
      return [
        {
          displayName,
          key: 'employeeInfo.id',
          type: 'string',
        },
        {
          displayName: 'Avg Rating',
          key: 'ratingScore',
          type: 'score',
        },
        {
          displayName: 'Rating Count',
          key: 'ratingCount',
          type: 'number',
        },
      ]
    } else if (filterBy === 'deliveryOrders') {
      return [
        {
          displayName: 'Supervisor',
          key,
          type: 'string',
        },
        {
          displayName: 'Delivery Orders',
          key: 'totalOrderCount',
          type: 'number',
        },
      ]
    } else if (filterBy === 'deliverySales') {
      return [
        {
          displayName: directorLabel,
          key,
          type: 'string',
        },
        {
          displayName: 'Delivery Sales',
          key: 'sumSubtotal',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'averageTripTime') {
      return [
        {
          displayName: 'Supervisor',
          key,
          type: 'string',
        },
        {
          displayName: 'Average Driver Trip Time (min)',
          key: 'averageTripTime',
          type: 'number',
        },
      ]
    } else if (filterBy === 'orderWithAnyIssues') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: '% Orders w/ Any Issues',
          key: 'orderWithAnyIssuePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'combinedStarRating') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Combined Star Rating',
          key: 'avgCustomerReviewScore',
          type: 'score',
        },
      ]
    } else if (filterBy === 'classicUsageVariancePercent') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Usage Variance',
          key: 'classicUsageVariancePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'bonelessUsageVariancePercent') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Usage Variance',
          key: 'bonelessUsageVariancePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'tendersUsageVariancePercent') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Usage Variance',
          key: 'tendersUsageVariancePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'sales_per_labor_hour') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: financialLabel.salesPerLaborHour,
          key: 'sales_per_labor_hour',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'total_check_average') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Total Check Average',
          key: 'total_check_average',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'total_guest_average') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'PPA',
          key: 'total_guest_average',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'total_check_count') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: financialLabel.transaction,
          key: 'total_check_count',
          type: 'number',
        },
        {
          displayName: financialLabel.transactionVsLy,
          key: 'yoy_total_check_count',
          type: 'number',
        },
        {
          displayName: '% vs LY',
          key: 'yoy_check_count_growth',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'acr_score') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'ACR Score',
          key: 'acr_score',
          type: 'score',
        },
      ]
    } else if (filterBy === 'zenput') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Zenput',
          key: 'zenputScore',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'cavayou') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Cavayou',
          key: 'cavayouScore',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'totalPpa') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'PPA',
          key: 'totalPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'salesPerHour') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Sales per Labor Hour',
          key: 'salesPerHour',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'checkAvg') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Check Avg',
          key: 'checkAvg',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'totalSidesSales') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Total Sides Sales',
          key: 'totalSidesSales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'friesSales') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Fries Sales',
          key: 'friesSales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'cornSales') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Corn Sales',
          key: 'cornSales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'brownieSales') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Brownie Sales',
          key: 'brownieSales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'entreePremiumIncidents') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Entree Premium Incidents',
          key: 'entreePremiumIncidents',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'curatedIncidents') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Curated Incidents',
          key: 'curatedIncidents',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'sideAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Side Attachment Rate',
          key: 'sideAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'dessertAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Dessert Attachment Rate',
          key: 'dessertAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'beverageAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Beverage Attachment Rate',
          key: 'beverageAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'baconAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Bacon Attachment Rate',
          key: 'baconAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'cheeseAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Cheese Attachment Rate',
          key: 'cheeseAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'treatAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Treat Attachment Rate',
          key: 'treatAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'cornAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Corn Attachment Rate',
          key: 'cornAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'waterAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Bottled Water Attachment Rate',
          key: 'waterAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'saucesAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Sauces Attachment Rate',
          key: 'saucesAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'sticksAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Veggie Sticks Attachment Rate',
          key: 'sticksAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'beveragesAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Beverages Attachment Rate',
          key: 'beveragesAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'dessertsAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Desserts Attachment Rate',
          key: 'dessertsAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'jalapenoAttachmentRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Jalapeno Attachment Rate',
          key: 'jalapenoAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'dessertRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Dessert Rate',
          key: 'dessertRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'comboRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Combo Rate',
          key: 'comboRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'drinkRate') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Drink Rate',
          key: 'drinkRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'appetizerPpaV2') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Appetizer PPA',
          key: 'appetizerPpaV2',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'lbwPpaV2') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'LBW PPA',
          key: 'lbwPpaV2',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'dessertPpaV2') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Dessert PPA',
          key: 'dessertPpaV2',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'naBeveragePpa') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'NA Beverage PPA',
          key: 'naBeveragePpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'sidesPpa') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Sides PPA',
          key: 'sidesPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'sosPickup') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.speedOfServiceAvg,
          key: 'sosPickup',
        },
      ]
    } else if (filterBy === 'entreesPerLaborHour') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: '11am',
          key: 'entreesPerLaborHourAt11',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '12pm',
          key: 'entreesPerLaborHourAt12',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '1pm',
          key: 'entreesPerLaborHourAt13',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '5pm',
          key: 'entreesPerLaborHourAt17',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '6pm',
          key: 'entreesPerLaborHourAt18',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '7pm',
          key: 'entreesPerLaborHourAt19',
          type: 'numberWithOneDecimal',
        },
      ]
    } else if (filterBy === 'upsizePercent') {
      return [
        {
          displayName,
          key: salesmanshipKey,
          type: 'string',
        },
        {
          displayName: 'Upsize Percent',
          key: 'upsizePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'inboundCalls') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Total Calls',
          key: 'inboundTotal',
          type: 'number',
        },
        {
          displayName: '% Answered',
          key: 'percentAnswered',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '% Missed',
          key: 'percentMissed',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '% Abandoned',
          key: 'percentAbandoned',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'Missed Opportunity',
          key: 'missedOpportunity',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'cashOverOrShort') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: '$',
          key: 'cashOverOrShort',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'discounts') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: '% of Sales',
          key: 'compsPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '$',
          key: 'compsTotalAmount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'voids') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: '% of Sales',
          key: 'overRingPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '$',
          key: 'overRingAmount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'smg') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Total Surveys',
          key: 'totalSurveys',
          type: 'number',
        },
        {
          displayName: 'Dissatisfaction %',
          key: 'dissatisfactionPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'Inaccurate %',
          key: 'inaccuratePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'opentable') {
      return [
        {
          displayName,
          key: 'employeeInfo.id',
          type: 'string',
        },
        {
          displayName: 'Avg Rating',
          key: 'ratingScore',
          type: 'score',
        },
        {
          displayName: 'Rating Count',
          key: 'opentableRatingCount',
          type: 'number',
        },
        {
          displayName: 'Seated Covers',
          key: 'seatedCovers',
          type: 'number',
        },
        {
          displayName: 'Opentable Seated Covers',
          key: 'opentableCovers',
          type: 'number',
        },
        {
          displayName: 'Your Network Seated Covers',
          key: 'yourNetworkCovers',
          type: 'number',
        },
        {
          displayName: 'Phone Seated Covers',
          key: 'phoneCovers',
          type: 'number',
        },
        {
          displayName: 'Walk-in Seated Covers',
          key: 'walkinCovers',
          type: 'number',
        },
        {
          displayName: 'No-show Rate',
          key: 'noShowRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'marketingAnalysis') {
      return [
        {
          displayName,
          key: 'employeeInfo.id',
          type: 'string',
        },
        {
          displayName: '# of Redemptions',
          key: 'totalCounts',
          type: 'number',
        },
        {
          displayName: '$ of Redemptions',
          key: 'totalComps',
          type: 'currencyInCents',
        },
        {
          displayName: 'Check Average',
          key: 'discountCheckAvg',
          type: 'currencyInCents',
        },
        {
          displayName: 'PPA',
          key: 'discountPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'hme') {
      return [
        {
          displayName,
          key,
          type: 'string',
        },
        {
          displayName: 'Speed of Service',
          key: 'speedOfService',
          type: 'seconds',
        },
        {
          displayName: 'Goal A %',
          key: 'goalPercent',
          type: 'percentTimesOneHundred',
        },
      ]
    }
  } else {
    if (filterBy === 'scorecard') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Score',
          key: 'totalScore',
          type: 'score',
        },
      ]
    } else if (filterBy === 'net_sales') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Net Sales',
          key: 'net_sales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'gross_sales') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Gross Sales',
          key: 'gross_sales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'cogs') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: financialLabel.cogs,
          key: 'cogs',
          type: 'currencyInCents',
        },
        {
          displayName: `${financialLabel.cogs} %`,
          key: 'cogs_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'cogs_budget_variance') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: `${financialLabel.cogs} ${financialLabel.variance}`,
          key: 'cogs_budget_variance',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `Unfavorable Variance to ${financialLabel.projected}`,
          key: 'cogs_budget_variance_amount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'avt') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'AvT',
          key: 'avt_percent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '$ AvT',
          key: 'avt_waste',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'avt_percent_monthly') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'AvT',
          key: 'avt_percent_monthly',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'rcp') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: financialLabel.rcp,
          key: 'rcp',
          type: 'currencyInCents',
        },
        {
          displayName: `${financialLabel.rcp} %`,
          key: 'rcp_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'rcp_budget_variance') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: `${financialLabel.rcp} ${financialLabel.variance}`,
          key: 'rcp_budget_variance',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'net_sales_sss') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: financialLabel.sss,
          key: 'net_sales_sss_percent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `$ ${financialLabel.sssAmount}`,
          key: 'net_sales_sss',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'gross_sales_sss') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: financialLabel.sss,
          key: 'gross_sales_sss_percent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `$ ${financialLabel.sssAmount}`,
          key: 'gross_sales_sss',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'hourly_labor_percent') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: `${financialLabel.hourlyLabor} Percent`,
          key: 'hourly_labor_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'net_sales_budget_variance') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: `Net Sales ${financialLabel.variance}`,
          key: 'net_sales_budget_variance',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `$ Net Sales ${financialLabel.variance}`,
          key: 'net_sales_budget_variance_amount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'gross_sales_budget_variance') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: `Gross Sales ${financialLabel.variance}`,
          key: 'gross_sales_budget_variance',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `$ Gross Sales ${financialLabel.variance}`,
          key: 'gross_sales_budget_variance_amount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'hourly_labor_budget_variance') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: `${financialLabel.hourlyLabor} ${financialLabel.variance}`,
          key: 'hourly_labor_budget_variance',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: `Unfavorable Variance to ${financialLabel.projected}`,
          key: 'hourly_labor_budget_variance_amount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'plv_percent') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'PLV %',
          key: 'plv_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'ops_comps') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Ops Comps',
          key: 'ops_comps',
          type: 'currencyInCents',
        },
        {
          displayName: 'Ops Comps % of Gross Sales',
          key: 'ops_comps_percent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'daypart') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        ...(brand === 'mwb'
          ? [
              {
                displayName: 'Early Bird',
                key: 'early_bird_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        ...(brand === 'mwb'
          ? [
              {
                displayName: 'Breakfast',
                key: 'breakfast_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        {
          displayName: 'Lunch',
          key: 'lunch_sales',
          type: 'currencyInCents',
        },
        ...(brand === 'mwb'
          ? [
              {
                displayName: 'Afternoon Snack',
                key: 'afternoon_snack_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        ...(brand === 'wingitnorth'
          ? [
              {
                displayName: 'Snack',
                key: 'snack_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        {
          displayName: 'Dinner',
          key: 'dinner_sales',
          type: 'currencyInCents',
        },
        ...(brand === 'mwb'
          ? [
              {
                displayName: 'Evening Snack',
                key: 'evening_snack_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
        ...(brand === 'wingitnorth'
          ? [
              {
                displayName: 'Late Night',
                key: 'late_night_sales',
                type: 'currencyInCents',
              },
            ]
          : []),
      ]
    } else if (
      filterBy === 'annualizedHourlyTurnover' ||
      filterBy === 'annualizedHourlyTurnoverV2'
    ) {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Annualized Turnover Rate',
          key: 'annualizedHourlyTurnoverPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'YTD Turnover Count',
          key: 'hourlyTurnoverCountInLast12Months',
          type: 'number',
        },
      ]
    } else if (filterBy === 'annualizedManagerTurnover') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Annualized Turnover Rate',
          key: 'annualizedSalariedTurnoverPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'YTD Turnover Count',
          key: 'salariedTurnoverCountInLast12Months',
          type: 'number',
        },
      ]
    } else if (filterBy === 'hourlyTurnoverRateAndCount') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Turnover Rate',
          key: 'avgHourlyTurnoverPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'Turnover Count',
          key: 'hourlyTurnoverCount',
          type: 'number',
        },
      ]
    } else if (filterBy === 'managerTurnoverRateAndCount') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Turnover Rate',
          key: 'avgSalariedTurnoverPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'Turnover Count',
          key: 'salariedTurnoverCount',
          type: 'number',
        },
      ]
    } else if (filterBy === 'avgHourlyRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Avg Hourly Rates',
          key: 'avgHourlyRate',
          type: 'number',
        },
      ]
    } else if (filterBy === 'salesPerLaborHour') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.salesPerLaborHour,
          key: 'grossSalesPerLaborHour',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'ppa') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.ppa,
          key: 'ppa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'appetizerPpa') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.appetizerPpa,
          key: 'appetizerPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'lbwPpa') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.lbwPpa,
          key: 'lbwPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'dessertPpa') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.dessertPpa,
          key: 'dessertPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'productivityCoefficient') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Productivity Coefficient',
          key: 'productivityCoefficient',
          type: 'number',
        },
      ]
    } else if (
      ['momentfeed', 'google', 'yelp', 'delivery'].includes(filterBy)
    ) {
      return [
        {
          displayName: storeLabel,
          key: 'locationInfo.id',
          type: 'string',
        },
        {
          displayName: 'Avg Rating',
          key: 'ratingScore',
          type: 'score',
        },
        {
          displayName: 'Rating Count',
          key: 'ratingCount',
          type: 'number',
        },
      ]
    } else if (filterBy === 'deliveryOrders') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Delivery Orders',
          key: 'totalOrderCount',
          type: 'number',
        },
      ]
    } else if (filterBy === 'deliverySales') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Delivery Sales',
          key: 'sumSubtotal',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'averageTripTime') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Average Driver Trip Time (min)',
          key: 'averageTripTime',
          type: 'number',
        },
      ]
    } else if (filterBy === 'orderWithAnyIssues') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: '% Orders w/ Any Issues',
          key: 'orderWithAnyIssuePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'combinedStarRating') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Combined Star Rating',
          key: 'avgCustomerReviewScore',
          type: 'score',
        },
      ]
    } else if (filterBy === 'classicUsageVariancePercent') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Usage Variance',
          key: 'classicUsageVariancePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'bonelessUsageVariancePercent') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Usage Variance',
          key: 'bonelessUsageVariancePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'tendersUsageVariancePercent') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Usage Variance',
          key: 'tendersUsageVariancePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'sales_per_labor_hour') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: financialLabel.salesPerLaborHour,
          key: 'sales_per_labor_hour',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'total_check_average') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Total Check Average',
          key: 'total_check_average',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'total_guest_average') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'PPA',
          key: 'total_guest_average',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'total_check_count') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: financialLabel.transaction,
          key: 'total_check_count',
          type: 'number',
        },
        {
          displayName: financialLabel.transactionVsLy,
          key: 'yoy_total_check_count',
          type: 'number',
        },
        {
          displayName: '% vs LY',
          key: 'yoy_check_count_growth',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'acr_score') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'ACR Score',
          key: 'acr_score',
          type: 'score',
        },
      ]
    } else if (filterBy === 'zenput') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Zenput',
          key: 'zenputScore',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'cavayou') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Cavayou',
          key: 'cavayouScore',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'totalPpa') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'PPA',
          key: 'totalPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'salesPerHour') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Sales per Labor Hour',
          key: 'salesPerHour',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'checkAvg') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Check Avg',
          key: 'checkAvg',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'totalSidesSales') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Total Sides Sales',
          key: 'totalSidesSales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'friesSales') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Fries Sales',
          key: 'friesSales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'cornSales') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Corn Sales',
          key: 'cornSales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'brownieSales') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Brownie Sales',
          key: 'brownieSales',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'entreePremiumIncidents') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Entree Premium Incidents',
          key: 'entreePremiumIncidents',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'curatedIncidents') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Curated Incidents',
          key: 'curatedIncidents',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'sideAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Side Attachment Rate',
          key: 'sideAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'dessertAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Dessert Attachment Rate',
          key: 'dessertAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'beverageAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Beverage Attachment Rate',
          key: 'beverageAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'baconAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Bacon Attachment Rate',
          key: 'baconAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'cheeseAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Cheese Attachment Rate',
          key: 'cheeseAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'treatAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Treat Attachment Rate',
          key: 'treatAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'cornAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Corn Attachment Rate',
          key: 'cornAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'waterAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Bottled Water Attachment Rate',
          key: 'waterAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'saucesAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Sauces Attachment Rate',
          key: 'saucesAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'sticksAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Veggie Sticks Attachment Rate',
          key: 'sticksAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'dessertsAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Desserts Attachment Rate',
          key: 'dessertsAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'beveragesAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Beverages Attachment Rate',
          key: 'beveragesAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'jalapenoAttachmentRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Jalapeno Attachment Rate',
          key: 'jalapenoAttachmentRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'dessertRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Dessert Rate',
          key: 'dessertRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'comboRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Combo Rate',
          key: 'comboRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'drinkRate') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Drink Rate',
          key: 'drinkRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'appetizerPpaV2') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Appetizer PPA',
          key: 'appetizerPpaV2',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'lbwPpaV2') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'LBW PPA',
          key: 'lbwPpaV2',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'dessertPpaV2') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Dessert PPA',
          key: 'dessertPpaV2',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'naBeveragePpa') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'NA Beverage PPA',
          key: 'naBeveragePpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'sidesPpa') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Sides PPA',
          key: 'sidesPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'sosPickup') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: salesmanshipLabels.speedOfServiceAvg,
          key: 'sosPickup',
        },
      ]
    } else if (filterBy === 'entreesPerLaborHour') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: '11am',
          key: 'entreesPerLaborHourAt11',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '12pm',
          key: 'entreesPerLaborHourAt12',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '1pm',
          key: 'entreesPerLaborHourAt13',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '5pm',
          key: 'entreesPerLaborHourAt17',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '6pm',
          key: 'entreesPerLaborHourAt18',
          type: 'numberWithOneDecimal',
        },
        {
          displayName: '7pm',
          key: 'entreesPerLaborHourAt19',
          type: 'numberWithOneDecimal',
        },
      ]
    } else if (filterBy === 'upsizePercent') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Upsize Percent',
          key: 'upsizePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'inboundCalls') {
      return [
        {
          displayName: storeLabel,
          key: 'locationId',
          type: 'string',
        },
        {
          displayName: 'Total Calls',
          key: 'inboundTotal',
          type: 'number',
        },
        {
          displayName: '% Answered',
          key: 'percentAnswered',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '% Missed',
          key: 'percentMissed',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '% Abandoned',
          key: 'percentAbandoned',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'Missed Opportunity',
          key: 'missedOpportunity',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'cashOverOrShort') {
      return [
        {
          displayName: storeLabel,
          key: 'locationInfo.id',
          type: 'string',
        },
        {
          displayName: '$',
          key: 'cashOverOrShort',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'discounts') {
      return [
        {
          displayName: storeLabel,
          key: 'locationInfo.id',
          type: 'string',
        },
        {
          displayName: '% of Sales',
          key: 'compsPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '$',
          key: 'totalComps',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'voids') {
      return [
        {
          displayName: storeLabel,
          key: 'locationInfo.id',
          type: 'string',
        },
        {
          displayName: '% of Sales',
          key: 'overRingPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: '$',
          key: 'overRingAmount',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'smg') {
      return [
        {
          displayName: storeLabel,
          key: 'locationInfo.id',
          type: 'string',
        },
        {
          displayName: 'Total Surveys',
          key: 'totalSurveys',
          type: 'number',
        },
        {
          displayName: 'Dissatisfaction %',
          key: 'dissatisfactionPercent',
          type: 'percentTimesOneHundred',
        },
        {
          displayName: 'Inaccurate %',
          key: 'inaccuratePercent',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'opentable') {
      return [
        {
          displayName: storeLabel,
          key: 'locationInfo.id',
          type: 'string',
        },
        {
          displayName: 'Avg Rating',
          key: 'ratingScore',
          type: 'score',
        },
        {
          displayName: 'Rating Count',
          key: 'opentableRatingCount',
          type: 'number',
        },
        {
          displayName: 'Seated Covers',
          key: 'seatedCovers',
          type: 'number',
        },
        {
          displayName: 'Opentable Seated Covers',
          key: 'opentableCovers',
          type: 'number',
        },
        {
          displayName: 'Your Network Seated Covers',
          key: 'yourNetworkCovers',
          type: 'number',
        },
        {
          displayName: 'Phone Seated Covers',
          key: 'phoneCovers',
          type: 'number',
        },
        {
          displayName: 'Walk-in Seated Covers',
          key: 'walkinCovers',
          type: 'number',
        },
        {
          displayName: 'No-show Rate',
          key: 'noShowRate',
          type: 'percentTimesOneHundred',
        },
      ]
    } else if (filterBy === 'marketingAnalysis') {
      return [
        {
          displayName: storeLabel,
          key: 'locationInfo.id',
          type: 'string',
        },
        {
          displayName: '# of Redemptions',
          key: 'totalCounts',
          type: 'number',
        },
        {
          displayName: '$ of Redemptions',
          key: 'totalComps',
          type: 'currencyInCents',
        },
        {
          displayName: 'Check Average',
          key: 'discountCheckAvg',
          type: 'currencyInCents',
        },
        {
          displayName: 'PPA',
          key: 'discountPpa',
          type: 'currencyInCents',
        },
      ]
    } else if (filterBy === 'hme') {
      return [
        {
          displayName: storeLabel,
          key: 'locationInfo.id',
          type: 'string',
        },
        {
          displayName: 'Speed of Service',
          key: 'speedOfService',
          type: 'seconds',
        },
        {
          displayName: 'Goal A %',
          key: 'goalPercent',
          type: 'percentTimesOneHundred',
        },
      ]
    }
  }
}
