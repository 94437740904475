import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const mwbConfig: IConfigsType = {
  mwb: {
    '/:brand/systemwide_overview': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
        ],
      },
      systemwideOverview: {
        type: 'list',
        api: 'systemwideOverviewKpis',
        title: 'Systemwide Overview',
        width: '120px',
        fields: [
          {
            key: 'netSales',
            title: 'Net Sales',
          },
          {
            key: 'netSalesSssPercent',
            title: 'SSS %',
          },
          {
            key: 'checkCount',
            title: 'Check Count',
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            decimal: 2,
          },
          {
            key: 'foodCost',
            title: 'Food %',
          },
          {
            key: 'paperCost',
            title: 'Paper %',
          },
          {
            key: 'avtFbPercent',
            title: 'Food / Bev AvT %',
          },
          {
            key: 'avtPaperPercent',
            title: 'Paper AvT %',
          },
          {
            key: 'variableLaborCost',
            title: 'Variable Labor %',
          },
        ],
      },
      breakdownByStore: {
        type: 'table',
        api: 'systemwideOverview',
        title: 'Breakdown by Store',
        pageSize: 20,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '200px',
          },
        },
        columns: [
          {
            key: 'Sales',
            title: 'Net Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'netSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Weekly Sales',
            title: 'Weekly Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'avgWeeklySales',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Food',
            title: 'Food',
            align: 'center',
            children: [
              {
                key: 'foodCost',
                title: '%',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Paper',
            title: 'Paper',
            align: 'center',
            children: [
              {
                key: 'paperCost',
                title: '%',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Food / Bev AvT',
            title: 'Food / Bev AvT',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'avtFbPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'avtFbDollars',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Paper AvT',
            title: 'Paper AvT',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'avtPaperPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'avtPaperDollars',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'VariableLabor',
            title: 'Variable Labor',
            align: 'center',
            children: [
              {
                key: 'variableLaborCost',
                title: '%',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      averageWeeklySales: {
        type: 'line-chart',
        title: 'Average Weekly Sales',
        api: 'trendLocationGroupFinancialKpis',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'avgWeeklySales',
            label: 'Net Sales',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default mwbConfig
