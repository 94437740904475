import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/corporateDelivery'
import { feature, page } from 'pared/analytics/user'
import { GUEST_REVIEW_SOURCES } from 'pared/constants'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBrandSettings } from 'pared/customer'
import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import getDirectors from 'pared/data/getDirectors'
import useDirectorFilterSelectorConfig from 'pared/pages/StoreList/DirectorFilterSelector/hooks/useConfig'
import { getBrand } from 'pared/utils/brand'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import {
  LIST_LOCATION_GROUP_GUEST_OPENTABLE_KPIS,
  LIST_LOCATION_GROUP_SMG_KPIS,
  LOCATION_GROUP_GUEST_KPIS,
} from './gql'

export interface IDetailGuestKpi {
  employeeId?: number
  locationId?: number
  acrRatings: string
  momentfeedRatings: string
  yelpRatings: string
  googleRatings: string
  facebookRatings: string
  deliveryRatings: string
  opsCompsPercent: string
  formattedLocationName?: string
}

export interface IGlobalGuestKpi {
  acr: {
    ratingCountStr: string
    ratingScore: string
  }
  momentfeed: {
    ratingCountStr: string
    ratingScore: string
  }
  google: {
    ratingCountStr: string
    ratingScore: string
  }
  facebook: {
    ratingCountStr: string
    ratingScore: string
  }
  delivery: {
    ratingCountStr: string
    ratingScore: string
  }
  yelp: {
    ratingCountStr: string
    ratingScore: string
  }
  all: {
    ratingCountStr: string
    ratingScore: string
  }
  dissatisfaction: {
    ratingCountStr: string
    ratingScore: string
  }
  inaccurate: {
    ratingCountStr: string
    ratingScore: string
  }
  overallSatisfaction: {
    ratingCountStr: string
    ratingScore: string
  }
  orderNotOnTime: {
    ratingCountStr: string
    ratingScore: string
  }
  tasteOfFood: {
    ratingCountStr: string
    ratingScore: string
  }
}

export interface IGlobalGuestOpentableKpi {
  ratingScore: string
  ratingCount: string
  ratingScoreChange: string
  seatedCovers: string
  seatedCoversChange: string
  phoneCovers: string
  phoneCoversChange: string
  walkinCovers: string
  walkinCoversChange: string
  yourNetworkCovers: string
  yourNetworkCoversChange: string
  opentableCovers: string
  opentableCoversChange: string
  noShowRate: string
  noShowRateChange: string
}

interface IProps {
  navParams: INavParams
}

const DEFAULT_DIRECTOR: IDirector = {
  employeeId: -1,
  firstName: 'All',
  lastName: 'Stores',
  directorId: -1,
  directorLevel: -1,
  brandId: -1,
  brandCode: '',
  brandName: '',
  locationGroupId: -1,
}

function CorporateGuest({ navParams }: IProps) {
  const guestConfig = getBrandSettings().guest
  const directorFilterSelectorConfig = useDirectorFilterSelectorConfig()
  const dateRange = navParams.dateRange || ''

  const [allDirectors, setAllDirectors] = useState<IDirector[] | null>([])
  const [selectedDirector, setSelectedDirector] = useState<IDirector | null>(
    DEFAULT_DIRECTOR,
  )
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )
  const [breakdownType, setBreakdownType] = useState<string | null>(null)

  const brand = getBrand()

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  let selectedLocationGroupId = _.get(selectedDirector, 'locationGroupId') || -1
  selectedLocationGroupId =
    selectedLocationGroupId < 0 ? brandLocationGroupId : selectedLocationGroupId

  const [
    locationGroupGuestKpis,
    {
      loading: locationGroupGuestKpisLoading,
      error: locationGroupGuestKpisError,
      data: locationGroupGuestKpisData,
    },
  ] = useLazyQuery(LOCATION_GROUP_GUEST_KPIS)

  const [
    listLocationGroupSmgKpis,
    {
      loading: listLocationGroupSmgKpisLoading,
      error: listLocationGroupSmgKpisError,
      data: listLocationGroupSmgKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_SMG_KPIS(brand))

  const [
    listLocationGroupGuestOpentableKpis,
    {
      loading: listLocationGroupGuestOpentableKpisLoading,
      data: listLocationGroupGuestOpentableKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_GUEST_OPENTABLE_KPIS)

  useEffect(() => {
    async function fetchData() {
      const [newAllDateRangeData, directors] = await Promise.all([
        getDateRanges(),
        getDirectors(),
      ])

      if (Array.isArray(directors) && directors.length > 0) {
        setAllDirectors(directors)
      }

      let newSelectedDateRange: IDateRange | null = null
      if (dateRange) {
        newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
      }

      if (!newSelectedDateRange) {
        newSelectedDateRange = newAllDateRangeData.defaultPeriod
      }

      if (newSelectedDateRange) {
        setSelectedDateRange(newSelectedDateRange)

        const periodStartDate = _.get(newSelectedDateRange, 'startDateStr', '')
        const periodEndDate = _.get(newSelectedDateRange, 'endDateStr', '')
        locationGroupGuestKpis({
          variables: {
            iLocationGroupId: selectedLocationGroupId,
            iStartDate: periodStartDate,
            iEndDate: periodEndDate,
          },
        })

        if (guestConfig.smg?.isVisible || guestConfig.smgMwb?.isVisible) {
          listLocationGroupSmgKpis({
            variables: {
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
              iFilter: {
                location_group_ids: [selectedLocationGroupId],
              },
            },
          })
        }

        if (guestConfig.opentable?.isVisible) {
          listLocationGroupGuestOpentableKpis({
            variables: {
              iFilter: {
                location_group_ids: [selectedLocationGroupId],
              },
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
            },
          })
        }
      }
    }

    fetchData()
  }, [selectedDirector, breakdownType, dateRange])

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Corporate Guest')
    }
  }, [navParams.pageUrl])

  const onDirectorChange = async (directorEmployeeId: number) => {
    let processedDirectorEmployeeId = 0

    track.directorChanged(directorEmployeeId)

    if (directorEmployeeId === -1) {
      // The all stores case

      setSelectedDirector({
        ...DEFAULT_DIRECTOR,
        employeeId: -1,
      })

      setBreakdownType(null)
    } else if (directorEmployeeId === -2) {
      // Case: breakdown by directors

      setSelectedDirector({
        ...DEFAULT_DIRECTOR,
        employeeId: -2,
      })

      setBreakdownType(null)
    } else if (directorEmployeeId < -2) {
      const locationGroupType =
        directorFilterSelectorConfig.locationGroupTypes.find(
          ({ id }: { id: number }) => id === directorEmployeeId,
        )

      setSelectedDirector({
        ...DEFAULT_DIRECTOR,
        employeeId: directorEmployeeId,
      })

      setBreakdownType(locationGroupType?.name || '')
    } else {
      processedDirectorEmployeeId = directorEmployeeId
      const selectedDirector = _.first(
        _.filter(allDirectors, { employeeId: processedDirectorEmployeeId }),
      )
      setSelectedDirector(selectedDirector || null)
      setBreakdownType(null)
    }
  }

  let globalGuestKpiSummary: IGlobalGuestKpi = {
    acr: {
      ratingCountStr: 'No ratings',
      ratingScore: '-',
    },
    momentfeed: {
      ratingCountStr: 'No ratings',
      ratingScore: '-',
    },
    google: {
      ratingCountStr: 'No ratings',
      ratingScore: '-',
    },
    facebook: {
      ratingCountStr: 'No ratings',
      ratingScore: '-',
    },
    delivery: {
      ratingCountStr: 'No ratings',
      ratingScore: '-',
    },
    yelp: {
      ratingCountStr: 'No ratings',
      ratingScore: '-',
    },
    all: {
      ratingCountStr: 'No ratings',
      ratingScore: '-',
    },
    dissatisfaction: {
      ratingCountStr: 'No surveys',
      ratingScore: '-',
    },
    inaccurate: {
      ratingCountStr: 'No surveys',
      ratingScore: '-',
    },
    overallSatisfaction: {
      ratingCountStr: 'No surveys',
      ratingScore: '-',
    },
    orderNotOnTime: {
      ratingCountStr: 'No surveys',
      ratingScore: '-',
    },
    tasteOfFood: {
      ratingCountStr: 'No surveys',
      ratingScore: '-',
    },
  }

  if (
    Array.isArray(locationGroupGuestKpisData?.locationGroupGuestKpisV2?.nodes)
  ) {
    const rawGlobalGuestKpis = _.get(
      locationGroupGuestKpisData,
      'locationGroupGuestKpisV2.nodes',
      [],
    )

    _.forEach(GUEST_REVIEW_SOURCES, (s) => {
      const source = _.first(
        _.filter(rawGlobalGuestKpis, (k) => {
          return _.get(k, 'reviewSource', '') === s
        }),
      )
      const ratingScore = _.get(
        source,
        guestConfig?.useSimpleAverage
          ? 'simpleAverageRatingScore'
          : 'ratingScore',
        '',
      )
      const ratingCount = _.get(source, 'ratingCount', '')
      const ratingSource = _.get(source, 'reviewSource', '')

      const countStr = ratingSource === 'acr' ? 'survey' : 'rating'
      let ratingCountStr =
        ratingCount === 1 ? `1 ${countStr}` : `${ratingCount} ${countStr}s`
      if (ratingCount || ratingSource === 'acr') {
        // for acr
        if (!ratingCount) {
          ratingCountStr = ''
        }

        globalGuestKpiSummary[s] = {
          ratingCountStr,
          ratingScore: ratingScore?.toFixed?.(1) || '-',
        }
      }
    })
  }

  if (
    Array.isArray(listLocationGroupSmgKpisData?.listLocationGroupSmgKpis?.nodes)
  ) {
    const {
      totalSurveys,
      totalOutstandingSurveys,
      dissatisfactionPercent,
      inaccuratePercent,
      overallSatisfaction,
      orderNotOnTime,
      tasteOfFood,
    } = listLocationGroupSmgKpisData?.listLocationGroupSmgKpis?.nodes[0] ?? {}
    const ratingCountStr =
      totalSurveys === 1 ? `1 survey` : `${totalSurveys || 'No'} surveys`
    const outstandingSurveysRatingCountStr =
      totalOutstandingSurveys === 1
        ? `1 survey`
        : `${totalOutstandingSurveys || 'No'} surveys`

    globalGuestKpiSummary.dissatisfaction = {
      ratingCountStr,
      ratingScore: dissatisfactionPercent?.toFixed(1) || '-',
    }

    globalGuestKpiSummary.inaccurate = {
      ratingCountStr,
      ratingScore: inaccuratePercent?.toFixed(1) || '-',
    }

    globalGuestKpiSummary.overallSatisfaction = {
      ratingCountStr,
      ratingScore: overallSatisfaction?.toFixed(1) || '-',
    }

    globalGuestKpiSummary.orderNotOnTime = {
      ratingCountStr: guestConfig.smgMwb
        ? outstandingSurveysRatingCountStr
        : ratingCountStr,
      ratingScore: orderNotOnTime?.toFixed(1) || '-',
    }

    globalGuestKpiSummary.tasteOfFood = {
      ratingCountStr: guestConfig.smgMwb
        ? outstandingSurveysRatingCountStr
        : ratingCountStr,
      ratingScore: tasteOfFood?.toFixed(1) || '-',
    }
  }

  if (locationGroupGuestKpisLoading) {
    globalGuestKpiSummary = {
      acr: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      momentfeed: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      google: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      facebook: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      delivery: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      yelp: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      all: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      dissatisfaction: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      inaccurate: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      overallSatisfaction: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      orderNotOnTime: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
      tasteOfFood: {
        ratingCountStr: 'loading',
        ratingScore: 'loading',
      },
    }
  }

  let globalGuestOpentableKpis: IGlobalGuestOpentableKpi = {
    ratingScore: '-',
    ratingCount: 'No Ratings',
    ratingScoreChange: '-',
    seatedCovers: '-',
    seatedCoversChange: '-',
    phoneCovers: '-',
    phoneCoversChange: '-',
    walkinCovers: '-',
    walkinCoversChange: '-',
    yourNetworkCovers: '-',
    yourNetworkCoversChange: '-',
    opentableCovers: '-',
    opentableCoversChange: '-',
    noShowRate: '-',
    noShowRateChange: '-',
  }

  if (
    Array.isArray(
      listLocationGroupGuestOpentableKpisData
        ?.listLocationGroupGuestOpentableKpis?.nodes,
    )
  ) {
    const {
      ratingScore,
      ratingCount,
      ratingScoreChange,
      seatedCovers,
      seatedCoversChange,
      phoneCovers,
      phoneCoversChange,
      walkinCovers,
      walkinCoversChange,
      yourNetworkCovers,
      yourNetworkCoversChange,
      opentableCovers,
      opentableCoversChange,
      noShowRate,
      noShowRateChange,
    } =
      listLocationGroupGuestOpentableKpisData
        ?.listLocationGroupGuestOpentableKpis?.nodes[0] ?? {}

    globalGuestOpentableKpis = {
      ratingScore: ratingScore?.toFixed(1) || '-',
      ratingCount: `${ratingCount ? ratingCount : 'No'} ratings`,
      ratingScoreChange: ratingScoreChange
        ? `${ratingScoreChange?.toFixed(1)}% YoY`
        : '-',
      seatedCovers: seatedCovers ? seatedCovers.toLocaleString() : '-',
      seatedCoversChange: seatedCoversChange
        ? `${seatedCoversChange?.toFixed(1)}% YoY`
        : '-',
      phoneCovers: phoneCovers ? phoneCovers.toLocaleString() : '-',
      phoneCoversChange: phoneCoversChange
        ? `${phoneCoversChange?.toFixed(1)}% YoY`
        : '-',
      walkinCovers: walkinCovers ? walkinCovers.toLocaleString() : '-',
      walkinCoversChange: walkinCoversChange
        ? `${walkinCoversChange?.toFixed(1)}% YoY`
        : '-',
      yourNetworkCovers: yourNetworkCovers
        ? yourNetworkCovers.toLocaleString()
        : '-',
      yourNetworkCoversChange: yourNetworkCoversChange
        ? `${yourNetworkCoversChange?.toFixed(1)}% YoY`
        : '-',
      opentableCovers: opentableCovers ? opentableCovers.toLocaleString() : '-',
      opentableCoversChange: opentableCoversChange
        ? `${opentableCoversChange?.toFixed(1)}% YoY`
        : '-',
      noShowRate: noShowRate ? `${noShowRate.toFixed(2)}%` : '-',
      noShowRateChange: noShowRateChange
        ? `${noShowRateChange?.toFixed(1)}% YoY`
        : '-',
    }
  }

  if (listLocationGroupGuestOpentableKpisLoading) {
    globalGuestOpentableKpis = {
      ratingScore: 'loading',
      ratingCount: 'loading',
      ratingScoreChange: 'loading',
      seatedCovers: 'loading',
      seatedCoversChange: 'loading',
      phoneCovers: 'loading',
      phoneCoversChange: 'loading',
      walkinCovers: 'loading',
      walkinCoversChange: 'loading',
      yourNetworkCovers: 'loading',
      yourNetworkCoversChange: 'loading',
      opentableCovers: 'loading',
      opentableCoversChange: 'loading',
      noShowRate: 'loading',
      noShowRateChange: 'loading',
    }
  }
  return (
    <>
      <Main
        navParams={navParams}
        allDirectors={allDirectors || []}
        selectedDirector={selectedDirector}
        onDirectorChange={onDirectorChange}
        selectedDateRange={selectedDateRange}
        globalGuestKpiSummary={globalGuestKpiSummary}
        selectedLocationGroupId={selectedLocationGroupId}
        breakdownType={breakdownType}
        globalGuestOpentableKpis={globalGuestOpentableKpis}
      />
    </>
  )
}

export default CorporateGuest
