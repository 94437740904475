import Pagination from '@mui/material/Pagination'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'

import useApi, { IApiKeyType, configs } from './hooks/useApi'
import useColumns, { IColumnsOptionsType } from './hooks/useColumns'
import useData, { IDataType } from './hooks/useData'

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'table-v2'
  api: K
  columns: IColumnsOptionsType<K>
  sorting?: {
    id: keyof typeof configs[K]
    desc: boolean
  }[]
  expanded?: true | string[]
  pagination?: boolean | { pageSize: number }
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const StyledTable = styled.table`
  border-collapse: collapse;
`

const StyledPagination = styled(Pagination)`
  button {
    font-family: Lexend-Regular;

    &.Mui-selected,
    &:hover {
      background: initial;
      border: 1px black solid;
    }
  }
`

const findIndex = (
  data: IDataType[],
  [id, ...subIds]: string[],
  prefix: number[] = [],
): -1 | number[] => {
  const index = data.findIndex((d) => d.id === id)

  if (index === -1) return -1

  if (subIds.length > 0)
    return findIndex(data[index].subRows || [], subIds, [...prefix, index])

  return [...prefix, index]
}

const Table = ({
  api,
  columns: columnsSource,
  sorting,
  expanded,
  pagination,
}: IPropsType) => {
  const { data: dataSource, loading, loadMore } = useApi(api)
  const data = useData(dataSource)
  const { tableRef, columns } = useColumns(
    configs[api],
    columnsSource,
    loadMore,
  )
  const [internalExapand, setInternalExpand] = useState({})
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: !pagination ? undefined : getPaginationRowModel(),
    getSubRows: (row) => row.subRows,
    initialState: {
      sorting,
      expanded: !expanded ? {} : true,
      pagination: !pagination
        ? undefined
        : {
            pageIndex: 0,
            pageSize: pagination === true ? 20 : pagination.pageSize,
          },
    },
    state: {
      expanded: internalExapand,
    },
    onExpandedChange: setInternalExpand,
    autoResetPageIndex: false,
  })
  const isLoadedRef = useRef(false)
  const { pageSize, pageIndex } = table.getState().pagination

  useEffect(() => {
    if (
      isLoadedRef.current ||
      !(expanded instanceof Array) ||
      loading ||
      !dataSource
    )
      return

    setInternalExpand(
      expanded
        .map((e) => {
          const index = findIndex(data, e.split(/\./))

          return index === -1 ? '' : index.join('.')
        })
        .filter(Boolean)
        .reduce(
          (result, key) => ({
            ...result,
            [key]: true,
          }),
          {},
        ),
    )
    isLoadedRef.current = true
  }, [dataSource, loading, data, expanded, setInternalExpand])

  return (
    <>
      <Spin spinning={loading}>
        <StyledTable ref={tableRef}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <React.Fragment key={header.id}>
                    {header.isPlaceholder ? (
                      <th colSpan={header.colSpan} />
                    ) : (
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )
                    )}
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <React.Fragment key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </Spin>

      {!pagination ? null : (
        <StyledPagination
          count={Math.ceil(table.getFilteredRowModel().rows.length / pageSize)}
          page={pageIndex + 1}
          onChange={(_, page) => {
            table.setPageIndex(page - 1)
          }}
          variant="text"
          shape="rounded"
        />
      )}
    </>
  )
}

export default Table
