import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  defaultDataIdFromObject,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RestLink } from 'apollo-link-rest'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { NavigatorInitializingComponent } from 'pared/Routes/navigator'
import Layout from 'pared/layouts'
import { apolloClient } from 'pared/reactiveVariables/apollo'
import store from 'pared/store'
import {
  getUserPersistedJwtToken,
  loadUserPersistedData,
  signOutUser,
} from 'pared/utils/user'

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const jwtToken = getUserPersistedJwtToken()

  // return the headers to the context so httpLink can read them
  const newHeaders = {
    ...headers,
    'Access-Control-Allow-Origin': '*',
  }

  if (jwtToken) {
    newHeaders.authorization = `Bearer ${jwtToken}`
  }

  return {
    headers: newHeaders,
  }
})

const restLink = new RestLink({
  uri: process.env.REACT_APP_BE_BASE_URL,
  responseTransformer: (res, typeName) => {
    if (!res) return null

    return typeName === 'String' ? res.text?.() || '' : res.json()
  },
})

const serverLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_URL,
})

const errorLink = onError(({ graphQLErrors }) => {
  if (Array.isArray(graphQLErrors)) {
    const errorCount = graphQLErrors.length
    for (let errorIndex = 0; errorIndex < errorCount; ++errorIndex) {
      const error = graphQLErrors[errorIndex]

      // Sign out a user if the user's JWT is expired
      if (error && error.message === 'jwt expired') {
        console.log(`--- User's JWT expired. ---`)
        signOutUser()
        break
      }
    }
  }
})

const newApolloClient = new ApolloClient({
  link: from([errorLink, authLink, restLink, serverLink]),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'GetQfpEntitiesRecord':
          return `GetQfpEntitiesRecord:${responseObject.type}:${responseObject.id}`
        default:
          return defaultDataIdFromObject(responseObject)
      }
    },
  }),
  connectToDevTools: process.env.NODE_ENV !== 'production',
})
apolloClient(newApolloClient)

loadUserPersistedData()

function ParedApp() {
  return (
    <Provider store={store}>
      <ApolloProvider client={newApolloClient}>
        <Router>
          <NavigatorInitializingComponent />
          <Layout />
        </Router>
      </ApolloProvider>
    </Provider>
  )
}

export default ParedApp
