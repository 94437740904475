import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'
import { getBrand } from 'pared/utils/brand'

import BreakdownTable from './BreakdownTable'
import Chart from './Chart'
import DisputeReasonTable from './DisputeReasonTable'
import DisputeTable from './DisputeTable'
import MissingItemTable from './MissingItemTable'
import useDeliveryConfig from './hooks/useDeliveryConfig'
import { IGlobalDeliveryKpi } from './index'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector | null
  navParams: INavParams
  onDirectorChange: (directorEmployeeId: number) => Promise<void>
  selectedDateRange: IDateRange | null
  globalDeliveryKpiSummary: IGlobalDeliveryKpi
  selectedLocationGroupId: number
  breakdownType: string | null
}

function WebSmallMain({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  globalDeliveryKpiSummary,
  selectedLocationGroupId,
  breakdownType,
}: IProps) {
  const deliveryConfig = useDeliveryConfig(getBrand())

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let breakdownTitle = 'BREAKDOWN BY STORE'
  let isBreakdownByDirectors = false
  if (selectedDirctorEmployeeId === -2) {
    isBreakdownByDirectors = true
    breakdownTitle = `BREAKDOWN BY ${getBusinessLabel(
      'director',
    ).toUpperCase()}`
  } else if (breakdownType) {
    breakdownTitle = `BREAKDOWN BY ${breakdownType}`.toUpperCase()
  } else if (deliveryConfig.breakdownByStoreTitle) {
    breakdownTitle = deliveryConfig.breakdownByStoreTitle
  }

  return (
    <>
      <SelectorContainer>
        {selectedDirector ? (
          <DirectorFilterSelector
            allDirectors={allDirectors}
            selectedDirector={selectedDirector}
            onDirectorChange={onDirectorChange}
            width={'87vw'}
          />
        ) : null}
        <DateRangeSelector
          navParams={navParams}
          customStartDate="06/01/2021"
          excludedDateRanges={[TYPE_YESTERDAY]}
          preSelectedDateRange={TYPE_PERIOD}
        />
      </SelectorContainer>
      <MainContainer>
        <PageTitleDiv>Systemwide Delivery</PageTitleDiv>
        <SubtitleContainer>Updated Weekly</SubtitleContainer>
        <VerticalSpacer30px />

        <FullScreenTable>
          <thead>
            <tr>
              <th>KPIs</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {deliveryConfig['realTimeKpisSmall'].map(
              ({
                variableName,
                displayName,
              }: {
                variableName: string
                displayName: string
              }) => {
                const value = _.get(globalDeliveryKpiSummary, variableName, '')
                return (
                  <tr>
                    <td>{displayName}</td>
                    <BoldNumTd>{value}</BoldNumTd>
                  </tr>
                )
              },
            )}
            {deliveryConfig.oloWaitTime?.isVisible ? (
              <tr>
                <td>{deliveryConfig.oloWaitTime?.displayName}</td>
                <BoldNumTd>
                  <CustomizedReport
                    reportName="CORPORATE_DELIVERY_OLO_WAIT_TIME"
                    inputParams={{
                      locationGroupId: selectedLocationGroupId,
                      startDate: selectedDateRange?.startDateStr || '',
                      endDate: selectedDateRange?.endDateStr || '',
                    }}
                  />
                </BoldNumTd>
              </tr>
            ) : null}
          </tbody>
        </FullScreenTable>
        {deliveryConfig['deliveryChart']?.isVisible && selectedDateRange ? (
          <>
            <VerticalSpacer40px />
            <Chart
              endDate={selectedDateRange.endDateStr}
              dateRangeType={selectedDateRange.type}
              locationGroupId={selectedLocationGroupId}
            />
          </>
        ) : null}

        <VerticalSpacer60px />

        <SectionTitleDiv>{breakdownTitle}</SectionTitleDiv>

        <ScrollableTableContainer>
          <ScrollableBreakdownTable>
            {selectedDateRange ? (
              <BreakdownTable
                isBreakdownByDirectors={isBreakdownByDirectors}
                startDate={selectedDateRange.startDateStr}
                endDate={selectedDateRange.endDateStr}
                locationGroupId={selectedLocationGroupId}
                locationGroupIds={allDirectors.map(
                  (doData) => doData.locationGroupId,
                )}
                breakdownType={breakdownType}
              />
            ) : null}
          </ScrollableBreakdownTable>
        </ScrollableTableContainer>

        {selectedDateRange && deliveryConfig.isDisputeTableVisible ? (
          <>
            <VerticalSpacer60px />
            <DisputeTable
              isBreakdownByDirectors={isBreakdownByDirectors}
              startDate={selectedDateRange.startDateStr}
              endDate={selectedDateRange.endDateStr}
              locationGroupId={selectedLocationGroupId}
              locationGroupIds={allDirectors.map(
                (doData) => doData.locationGroupId,
              )}
              breakdownType={breakdownType}
            />
            <VerticalSpacer60px />
            <DisputeReasonTable
              isBreakdownByDirectors={isBreakdownByDirectors}
              startDate={selectedDateRange.startDateStr}
              endDate={selectedDateRange.endDateStr}
              locationGroupId={selectedLocationGroupId}
              locationGroupIds={allDirectors.map(
                (doData) => doData.locationGroupId,
              )}
              breakdownType={breakdownType}
            />
            <VerticalSpacer60px />
            <MissingItemTable
              isBreakdownByDirectors={isBreakdownByDirectors}
              startDate={selectedDateRange.startDateStr}
              endDate={selectedDateRange.endDateStr}
              locationGroupId={selectedLocationGroupId}
              locationGroupIds={allDirectors.map(
                (doData) => doData.locationGroupId,
              )}
              breakdownType={breakdownType}
            />
          </>
        ) : null}
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  padding: 30px 25px 50px 25px;
`

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
`

const ScrollableTable = styled.div`
  width: 850px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const ScrollableBreakdownTable = styled(ScrollableTable)`
  width: 750px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const PageTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const VerticalSpacer40px = styled.div`
  width: 100%;
  height: 40px;
`

const BoldNumTd = styled.td`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const FullScreenTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  text-align: right;

  th {
    font-family: Lexend-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  td,
  th {
    vertical-align: middle;
    padding: 8px 0;
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    text-align: left;
  }

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead::after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background: transparent;
  }
`

const DirectorFilterSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const PageHeaderContainer = styled.div`
  padding: 0 0 50px 0;
  margin: 0 0 50px 0;
`

const LeftHeaderContainer = styled.div`
  float: left;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const SelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 0 20px 0 0;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
  font-size: 14px;
  font-weight: normal;
`

export default WebSmallMain
