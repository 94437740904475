import moment from 'moment'

import {
  toFormattedInteger,
  toPercentString,
  toUsdStr,
} from 'pared/utils/number'

import {
  VALUE_TYPE_CENT_AMOUNT,
  VALUE_TYPE_COUNT,
  VALUE_TYPE_DOLLAR_AMOUNT,
  VALUE_TYPE_FLOAT,
  VALUE_TYPE_PERCENT,
  VALUE_TYPE_SECOND,
  VALUE_TYPE_STRING,
} from './constants'

interface IKpiData {
  value: number
  type: string
  displayValue?: string
  isMissingData?: boolean
}

export default function getDisplayedString(data: string | IKpiData): any {
  if (typeof data === 'string') {
    const date = moment(data, 'YYYY-MM-DD', true)
    if (date.isValid()) {
      return date.format('M/D/YY')
    }
  } else if (data?.type || data?.type === '') {
    if (data?.isMissingData === true) {
      return 'No Data Found'
    }

    const dataValue: any = data.value || 0
    switch (data.type) {
      case VALUE_TYPE_COUNT:
        return toFormattedInteger(dataValue)
      case VALUE_TYPE_CENT_AMOUNT:
        return toUsdStr(parseFloat(dataValue) / 100.0)
      case VALUE_TYPE_DOLLAR_AMOUNT:
        return toUsdStr(parseFloat(dataValue))
      case VALUE_TYPE_PERCENT:
        return toPercentString(dataValue)
      case VALUE_TYPE_STRING:
        return data.displayValue || dataValue
      case VALUE_TYPE_SECOND:
        return toFormattedInteger(dataValue / 60)
      case VALUE_TYPE_FLOAT:
        return toFormattedInteger(dataValue)
      default:
        return '-'
    }
  }

  return data
}
