import useArbListLocationGroupPrimantiTattleKpis, {
  arbListLocationGroupPrimantiTattleKpisConfigs,
} from './useArbListLocationGroupPrimantiTattleKpis'
import useArbListLocationPrimantiTattleKpis, {
  arbListLocationPrimantiTattleKpisConfigs,
} from './useArbListLocationPrimantiTattleKpis'
import useLfrBusinessReviewKpis, {
  lfrBusinessReviewKpisConfigs,
} from './useLfrBusinessReviewKpis'
import useSystemwideOverviewKpis, {
  systemwideOverviewKpisConfigs,
} from './useSystemwideOverviewKpis'

export type IApiKeyType = keyof typeof api

export const configs = {
  lfrBusinessReviewKpis: lfrBusinessReviewKpisConfigs,
  systemwideOverviewKpis: systemwideOverviewKpisConfigs,
  arbListLocationGroupPrimantiTattleKpis:
    arbListLocationGroupPrimantiTattleKpisConfigs,
  arbListLocationPrimantiTattleKpis: arbListLocationPrimantiTattleKpisConfigs,
}

const api = {
  lfrBusinessReviewKpis: useLfrBusinessReviewKpis,
  systemwideOverviewKpis: useSystemwideOverviewKpis,
  arbListLocationGroupPrimantiTattleKpis:
    useArbListLocationGroupPrimantiTattleKpis,
  arbListLocationPrimantiTattleKpis: useArbListLocationPrimantiTattleKpis,
}

const useApi = (
  apiName: IApiKeyType,
): { data: Record<string, unknown> | null; loading: boolean } => api[apiName]()

export default useApi
