import moment from 'moment'
import { useMemo } from 'react'

import navigator from 'pared/Routes/navigator'
import { getBrandSettings } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'
import {
  CLICKABLE_BUTTON,
  LINK_BUTTON,
  VALUE_TYPE_CENT_AMOUNT,
  VALUE_TYPE_DOLLAR_AMOUNT,
  VALUE_TYPE_PERCENT,
  VALUE_TYPE_STRING,
} from 'pared/pages/ExpoAi/constants'
import { getLocationCode, getLocationName } from 'pared/utils/location'
import { toUsdString } from 'pared/utils/number'

import useConfig from '../../../hooks/useConfig'
import {
  ICorporateGuestSummaryDataType,
  ICorporateTeamSummaryDataType,
  ICorporateVoidsSummaryDataType,
  SummaryComponentProps,
} from '../../type'

const convertTo12HourFormat = (hour: number) => {
  const date = new Date()
  date.setHours(hour)

  const formattedTime = date.toLocaleString('en-US', {
    hour: 'numeric',
    hour12: true,
  })

  return formattedTime
}

const useSummaryTableData = ({ data }: SummaryComponentProps) => {
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business
  const config = useConfig()
  const tableData: any = []
  const { brand } = useBrands()

  return useMemo(() => {
    switch (data?.type) {
      case 'corporate_labor':
        tableData.push([
          'Store count',
          'Time of Day',
          'Annual Opportunity Cost',
        ])
        data?.data?.forEach((row) => {
          const timeOfDay = convertTo12HourFormat(row?.hour || 0)
          const rowData = []
          rowData.push([
            'Store',
            businessLabels['director'],
            `${timeOfDay} - Labor Annual Opportunity Cost`,
          ])
          rowData.push(
            ...[...(row?.locations ?? [])]
              .sort((a, b) => {
                return b.annualOpportunityCost - a.annualOpportunityCost
              })
              .map((location) => [
                {
                  type: LINK_BUTTON,
                  title: `${getLocationCode(
                    location.id,
                    location.code,
                  )} - ${getLocationName(location.id, location.name)}`,
                  value: location.code,
                  data: navigator.salesmanship(location.id),
                },
                location.director.preferred_name,
                {
                  type: VALUE_TYPE_CENT_AMOUNT,
                  value: location.annualOpportunityCost,
                },
              ]),
          )

          const storeCount = row?.locations?.length || 0
          const annualOpportunityCost = row?.annualOpportunityCost || 0

          tableData.push([
            {
              type: CLICKABLE_BUTTON,
              title: `${storeCount} stores`,
              value: storeCount,
              data: rowData,
            },
            timeOfDay,
            {
              type: VALUE_TYPE_CENT_AMOUNT,
              value: annualOpportunityCost,
            },
          ])
        })

        return tableData

      case 'corporate_cogs':
        if (brand === 'bibibop')
          tableData.push([
            'Store count',
            'Ending Inventory % of Net Sales',
            'Annual Opportunity Cost',
          ])
        else tableData.push(['Store count', 'Item', 'Annual Opportunity Cost'])

        data?.data?.forEach((row) => {
          const item = row?.item || ''
          const rowData = []

          switch (brand) {
            case 'bibibop': {
              rowData.push([
                'Store',
                'Ending Inventory % of Net Sales',
                'Opportunity Cost',
              ])
              rowData.push(
                ...[...(row?.locations ?? [])]
                  .sort((a, b) => {
                    return b.endingInventoryPercnet - a.endingInventoryPercnet
                  })
                  .map((location) => [
                    {
                      type: LINK_BUTTON,
                      title: `${getLocationCode(
                        location.id,
                        location.code,
                      )} - ${getLocationName(location.id, location.name)}`,
                      value: location.code,
                      data: config.usePurchasesPage
                        ? navigator.purchases(location.id)
                        : navigator.inventory(location.id),
                    },
                    {
                      type: VALUE_TYPE_PERCENT,
                      value: location.endingInventoryPercnet,
                    },
                    {
                      type: VALUE_TYPE_DOLLAR_AMOUNT,
                      value: location.opportunityCost,
                    },
                  ]),
              )
              break
            }

            default: {
              rowData.push([
                'Store',
                businessLabels['director'],
                `${item} - Annual Opportunity Cost`,
              ])
              rowData.push(
                ...[...(row?.locations ?? [])]
                  .sort((a, b) => {
                    return b.annualOpportunityCost - a.annualOpportunityCost
                  })
                  .map((location) => [
                    {
                      type: LINK_BUTTON,
                      title: `${getLocationCode(
                        location.id,
                        location.code,
                      )} - ${getLocationName(location.id, location.name)}`,
                      value: location.code,
                      data: config.usePurchasesPage
                        ? navigator.purchases(location.id)
                        : navigator.inventory(location.id),
                    },
                    location.director.preferred_name,
                    {
                      type: VALUE_TYPE_DOLLAR_AMOUNT,
                      value: location.annualOpportunityCost,
                    },
                  ]),
              )
              break
            }
          }

          const storeCount = row?.locations?.length || 0
          const annualOpportunityCost = row?.annualOpportunityCost || 0

          if (storeCount) {
            tableData.push([
              {
                type: CLICKABLE_BUTTON,
                title: `${storeCount} stores`,
                value: storeCount,
                data: rowData,
              },
              item,
              {
                type: VALUE_TYPE_DOLLAR_AMOUNT,
                value: annualOpportunityCost,
              },
            ])
          }
        })

        return tableData

      case 'corporate_loss_prevention':
        tableData.push([
          'Store count',
          'Discounting',
          'Annual Opportunity Cost',
        ])
        data?.data?.forEach((row) => {
          const rowData = []
          rowData.push([
            'Store',
            businessLabels['director'],
            'Discounting - Annual Opportunity Cost',
          ])
          rowData.push(
            ...[...(row?.locations ?? [])]
              .sort((a, b) => {
                return b.annualOpportunityCost - a.annualOpportunityCost
              })
              .map((location) => [
                {
                  type: LINK_BUTTON,
                  title: `${getLocationCode(
                    location.id,
                    location.code,
                  )} - ${getLocationName(location.id, location.name)}`,
                  value: location.code,
                  data: navigator.lossPrevention(location.id),
                },
                location.director.preferred_name,
                {
                  type: VALUE_TYPE_CENT_AMOUNT,
                  value: location.annualOpportunityCost,
                },
              ]),
          )

          const storeCount = row?.locations?.length || 0
          const item = `${row?.type || ''} more than expected`
          const annualOpportunityCost = row?.annualOpportunityCost || 0

          if (storeCount) {
            tableData.push([
              {
                type: CLICKABLE_BUTTON,
                title: `${storeCount} stores`,
                value: storeCount,
                data: rowData,
              },
              item,
              {
                type: VALUE_TYPE_CENT_AMOUNT,
                value: annualOpportunityCost,
              },
            ])
          }
        })

        return tableData

      case 'corporate_voids_summary':
        tableData.push(['Store count', 'Issue'])

        const voidingMoreThanTenPercent = data.data.filter(
          (d) => d.voidsCheckPercent > 10.0,
        )
        const voidingStoreCount = voidingMoreThanTenPercent.length

        if (voidingStoreCount > 0) {
          const voidingRowData = voidingMoreThanTenPercent
            .sort((a, b) => b.voidsCheckPercent - a.voidsCheckPercent)
            .map((v: ICorporateVoidsSummaryDataType) => [
              {
                type: LINK_BUTTON,
                title: `${getLocationCode(
                  v.locations.id,
                  v.locations.code,
                )} - ${getLocationName(v.locations.id, v.locations.name)}`,
                value: v.locations.code,
                data: navigator.lossPrevention(v.locations.id),
              },
              {
                type: VALUE_TYPE_PERCENT,
                value: v.voidsCheckPercent,
              },
            ])

          tableData.push([
            {
              type: CLICKABLE_BUTTON,
              title: `${voidingStoreCount} stores`,
              value: voidingStoreCount,
              data: [['Store', '% Checks Voided'], ...voidingRowData],
            },
            'Voiding more than 10% of Checks',
          ])
        }

        const voidedChecksOver = data.data.reduce((result, l) => {
          const voidsDetailsOver =
            l.voidsDetails?.filter((v) => v.voidAmount > 7500) || []

          return voidsDetailsOver.length > 0
            ? [...result, { ...l, voidsDetails: voidsDetailsOver }]
            : result
        }, [] as ICorporateVoidsSummaryDataType[])
        const voidedStoreCount = voidedChecksOver.length

        if (voidedStoreCount > 0) {
          const voidedRowData = voidedChecksOver
            .sort((a, b) => b.voidsDetails.length - a.voidsDetails.length)
            .map((v: ICorporateVoidsSummaryDataType) => [
              {
                type: LINK_BUTTON,
                title: `${getLocationCode(
                  v.locations.id,
                  v.locations.code,
                )} - ${getLocationName(v.locations.id, v.locations.name)}`,
                value: v.locations.code,
                data: navigator.lossPrevention(v.locations.id),
              },
              v.voidsDetails
                .map(
                  (d) =>
                    `Check #${d.checkId} (${moment
                      .utc(d.businessDate, 'YYYY-MM-DD')
                      .format('M/D')}, ${toUsdString(d.voidAmount / 100)})`,
                )
                .join(', '),
              v.voidsDetails.length,
            ])

          tableData.push([
            {
              type: CLICKABLE_BUTTON,
              title: `${voidedStoreCount} stores`,
              value: voidedStoreCount,
              data: [['Store', 'Voided Checks > $75', '#'], ...voidedRowData],
            },
            'Voided Checks > $75',
          ])
        }

        return tableData

      case 'corporate_delivery':
        tableData.push(['Store count', 'Delivery Issue'])
        data?.data?.forEach((row) => {
          const rowData = []
          switch (row.type) {
            case 'inaccurate':
              if (row.locations) {
                rowData.push([
                  'Store',
                  businessLabels['director'],
                  ,
                  'Orders with Accuracy Issues',
                ])
                rowData.push(
                  ...(row?.locations || []).map((location) => [
                    {
                      type: LINK_BUTTON,
                      title: `${getLocationCode(
                        location.id,
                        location.code,
                      )} - ${getLocationName(location.id, location.name)}`,
                      value: location.code,
                      data: navigator.delivery(location.id),
                    },
                    location.director.preferred_name,
                    {
                      type: VALUE_TYPE_PERCENT,
                      value: location.inaccurate_order_percent || 0,
                    },
                  ]),
                )
              }
              break

            case 'cancelled':
              if (row.locations) {
                rowData.push([
                  'Store',
                  businessLabels['director'],
                  'Orders with Cancelled Issues',
                ])
                rowData.push(
                  ...(row?.locations || []).map((location) => [
                    {
                      type: LINK_BUTTON,
                      title: `${getLocationCode(
                        location.id,
                        location.code,
                      )} - ${getLocationName(location.id, location.name)}`,
                      value: location.code,
                      data: navigator.delivery(location.id),
                    },
                    location.director.preferred_name,
                    {
                      type: VALUE_TYPE_PERCENT,
                      value: location.cancelled_order_percent || 0,
                    },
                  ]),
                )
              }
              break

            case 'delayed':
              if (row.locations) {
                rowData.push([
                  'Store',
                  businessLabels['director'],
                  'Orders with Lateness Issues',
                ])
                rowData.push(
                  ...(row?.locations || []).map((location) => [
                    {
                      type: LINK_BUTTON,
                      title: `${getLocationCode(
                        location.id,
                        location.code,
                      )} - ${getLocationName(location.id, location.name)}`,
                      value: location.code,
                      data: navigator.delivery(location.id),
                    },
                    location.director.preferred_name,
                    {
                      type: VALUE_TYPE_PERCENT,
                      value: location.delayed_order_percent || 0,
                    },
                  ]),
                )
              }
              break
          }

          const storeCount = row?.locations?.length || 0
          const item = (() => {
            switch (row?.type) {
              case 'inaccurate':
                return 'Accuracy'
              case 'delayed':
                return 'Lateness'
              case 'cancelled':
                return 'Cancellations'
              default:
                throw new Error('unknown type')
            }
          })()

          if (storeCount > 0) {
            tableData.push([
              {
                type: CLICKABLE_BUTTON,
                title: `${storeCount} stores`,
                value: storeCount,
                data: rowData,
              },
              item,
            ])
          }
        })

        if (tableData.length > 1) {
          return tableData
        } else {
          return []
        }

      case 'corporate_salesmanship':
        tableData.push([
          'Store count',
          'Area of Improvement',
          'Annual Opportunity Cost',
        ])
        data?.data?.forEach((row) => {
          const item = row?.area || ''
          const rowData = []
          rowData.push([
            'Store',
            businessLabels['director'],
            `${item} Annual Opportunity Cost`,
          ])
          rowData.push(
            ...[...(row?.locations ?? [])]
              .sort((a, b) => {
                return b.annualOpportunityCost - a.annualOpportunityCost
              })
              .map((location) => [
                {
                  type: LINK_BUTTON,
                  title: `${getLocationCode(
                    location.id,
                    location.code,
                  )} - ${getLocationName(location.id, location.name)}`,
                  value: location.code,
                  data: navigator.salesmanship(location.id),
                },
                location.director.preferred_name,
                {
                  type: VALUE_TYPE_CENT_AMOUNT,
                  value: location.annualOpportunityCost,
                },
              ]),
          )

          const storeCount = row?.locations?.length || 0
          const annualOpportunityCost = row?.annualOpportunityCost || 0

          tableData.push([
            {
              type: CLICKABLE_BUTTON,
              title: `${storeCount} stores`,
              value: storeCount,
              data: rowData,
            },
            item,
            {
              type: VALUE_TYPE_CENT_AMOUNT,
              value: annualOpportunityCost,
            },
          ])
        })

        return tableData

      case 'corporate_team':
        tableData.push(['Store Count', 'Area of Improvement'])
        const thirtyDays: ICorporateTeamSummaryDataType[] = []
        const sixtyDays: ICorporateTeamSummaryDataType[] = []
        const ninetyDays: ICorporateTeamSummaryDataType[] = []
        data?.data?.forEach((row) => {
          if (row.thirtyDayTurnoverRate > row.corporateThirtyDayTurnoverRate) {
            thirtyDays.push(row)
          }

          if (row.sixtyDayTurnoverRate > row.corporateSixtyDayTurnoverRate) {
            sixtyDays.push(row)
          }

          if (row.ninetyDayTurnoverRate > row.corporateNinetyDayTurnoverRate) {
            ninetyDays.push(row)
          }
        })

        thirtyDays.sort(
          (a, b) => b.thirtyDayTurnoverRate - a.thirtyDayTurnoverRate,
        )
        sixtyDays.sort(
          (a, b) => b.sixtyDayTurnoverRate - a.sixtyDayTurnoverRate,
        )
        ninetyDays.sort(
          (a, b) => b.ninetyDayTurnoverRate - a.ninetyDayTurnoverRate,
        )

        const getTableData = (
          rows: ICorporateTeamSummaryDataType[],
          numDays: number,
          kpi: keyof ICorporateTeamSummaryDataType,
        ) => [
          {
            type: CLICKABLE_BUTTON,
            title: `${rows.length} stores`,
            value: rows.length,
            data: [
              ['Store', `${numDays} Day Term Rate`],
              ...rows.map((row) => [
                {
                  type: LINK_BUTTON,
                  title: `${getLocationCode(
                    row.locationInfo.id,
                    row.locationInfo.code,
                  )} - ${getLocationName(
                    row.locationInfo.id,
                    row.locationInfo.name,
                  )}`,
                  value: row.locationInfo.code,
                  data: navigator.team(row.locationInfo.id),
                },
                {
                  type: VALUE_TYPE_PERCENT,
                  value: row[kpi],
                },
              ]),
            ],
          },
          {
            type: VALUE_TYPE_STRING,
            value: `High Turnover within ${numDays} days of hire`,
          },
        ]

        tableData.push(
          getTableData(thirtyDays, 30, 'thirtyDayTurnoverRate'),
          getTableData(sixtyDays, 60, 'sixtyDayTurnoverRate'),
          getTableData(ninetyDays, 90, 'ninetyDayTurnoverRate'),
        )

        return tableData

      case 'corporate_guest': {
        tableData.push(['Store Count', 'Area of Improvement'])
        let rawSummary = (data?.data[0]?.reviews ?? []).reduce(
          (result, d: ICorporateGuestSummaryDataType) => {
            if (!result[d.primaryProblem]) {
              result[d.primaryProblem] = {
                [d.locationInfo.id]: true,
              }
            } else {
              result[d.primaryProblem][d.locationInfo.id] = true
            }

            return result
          },
          {} as Record<string, any>,
        )

        const summary: { [key: string]: number } = {}
        for (let primaryProblem in rawSummary) {
          summary[primaryProblem] = Object.keys(
            rawSummary[primaryProblem],
          ).length
        }

        Object.entries(summary)
          .sort((a, b) => b[1] - a[1])
          .slice(0, 3)
          .forEach(([key, value]) => {
            tableData.push([
              {
                type: CLICKABLE_BUTTON,
                title: `${value} Stores`,
                value: key,
                data: data?.data[0]?.reviews
                  .filter(
                    (d: ICorporateGuestSummaryDataType) =>
                      d.primaryProblem === key,
                  )
                  .reduce(
                    (result, d) => {
                      const store = {
                        type: LINK_BUTTON,
                        title: `${d.locationInfo.code} - ${d.locationInfo.name}`,
                        value: d.locationInfo.code,
                        data: navigator.guest(d.locationInfo.id),
                      }
                      const item = result.find(
                        (r) =>
                          typeof r[0] !== 'string' &&
                          'value' in r[0] &&
                          r[0].value === store.value,
                      )

                      if (item) {
                        if (typeof item[1] === 'number')
                          item[1] += parseInt(d.count)

                        item[2] = `${item[2]}, ${d.dayOfWeek}`

                        return result
                      }

                      return [
                        ...result,
                        [store, parseInt(d.count), d.dayOfWeek] as [
                          {
                            type: typeof LINK_BUTTON
                            title: string
                            value: string
                            data: string
                          },
                          number,
                          string,
                        ],
                      ]
                    },
                    [
                      ['Store', `${key} Reviews`, 'Day of Review'] as [
                        string,
                        string,
                        string,
                      ],
                    ] as [
                      (
                        | string
                        | {
                            type: typeof LINK_BUTTON
                            title: string
                            value: string
                            data: string
                          }
                      ),
                      string | number,
                      string,
                    ][],
                  ),
              },
              key,
            ])
          })

        return tableData
      }

      default:
        return []
    }
  }, [data, brand])
}

export default useSummaryTableData
