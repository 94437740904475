import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import AdvancedFilter, {
  IPropsType as IAdvancedFilterPropsType,
} from '../advancedFilter'
import DateFilter, { IPropsType as IDateFilterPropsType } from '../dateFilter'
import GroupFilter, {
  IPropsType as IGroupFilterPropsType,
} from '../groupFilter'
import Badge, { IPropsType as IBadgePropsType } from './badge'
import useApi, { IApiKeyType } from './hooks/useApi'
import SubTitle, { IPropsType as ISubTitlePropsType } from './subTitle'

export interface IConfigsType {
  title?: string | IApiKeyType
  advancedFilter?: IAdvancedFilterPropsType[]
  badge?: IBadgePropsType['api']
  subTitle?: ISubTitlePropsType['api']
  height?: number
}

interface IPropsType {
  first: boolean
  dateFilterConfigs?: IDateFilterPropsType
  groupFilterConfigs?: IGroupFilterPropsType
  advancedFilterConfigs?: IAdvancedFilterPropsType[]
  children: IConfigsType['title']
  badgeConfigs: IConfigsType['badge']
  subTitleConfigs: IConfigsType['subTitle']
}

const Container = styled.div<Pick<IPropsType, 'first'>>`
  ${({ first }) =>
    !first
      ? ''
      : `
    margin-bottom: 50px;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  min-width: 800px;
  gap: 25px;
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};

  @media ${MOBILE_WIDTH} {
    min-width: initial;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 7px;
`

const Title = ({
  first,
  groupFilterConfigs,
  dateFilterConfigs,
  advancedFilterConfigs,
  children,
  badgeConfigs,
  subTitleConfigs,
}: IPropsType) => {
  const { data, loading } = useApi(children)

  return (
    <Container first={first}>
      <TitleContainer>
        <Badge api={badgeConfigs}>{loading ? 'Loading ...' : data}</Badge>

        {!first ? null : (
          <FilterContainer>
            {!groupFilterConfigs ? null : (
              <GroupFilter {...groupFilterConfigs} />
            )}

            {!dateFilterConfigs ? null : <DateFilter {...dateFilterConfigs} />}
          </FilterContainer>
        )}

        {!advancedFilterConfigs ? null : (
          <FilterContainer>
            {advancedFilterConfigs.map((advancedFilterConfig) => (
              <AdvancedFilter
                {...advancedFilterConfig}
                key={advancedFilterConfig[`${advancedFilterConfig.type}Key`]}
              />
            ))}
          </FilterContainer>
        )}
      </TitleContainer>
      <SubTitle api={subTitleConfigs} />
    </Container>
  )
}

export default Title
