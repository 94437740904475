import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IPropsType as ILinkPropsType } from '../../../tableV2/Link'

interface IQueryDataType {
  listDirectors: {
    nodes: {
      employeeId: number
      locationGroupId: number
      firstName: string
      lastName: string
    }[]
  }
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
      }[]
    }[]
  }
}

export interface IDataType {
  allStores?: {
    tableColumns: [
      {
        key: 'location'
        title: string
        onClick: 'track.locationClicked'
      },
      {
        key: 'groupBy'
        title: string
        onClick: 'track.groupByClicked'
      },
    ]
    locationGroupId: number
    locations: Record<
      number,
      {
        id: number
        code: number
        name: number
        displayName: string
        link: string
        groupBy?: {
          id: number
          displayName: string
          link?: string
        }
        tableRow: {
          location: ILinkPropsType & { id: number }
          groupBy: ILinkPropsType & { id: number }
        }
      }
    >
  }
}

const query = gql`
  query ListLocationdetails($iLocationGroupIds: [Int!]!) {
    listDirectors(iFilter: { location_group_ids: $iLocationGroupIds }) {
      nodes {
        employeeId
        locationGroupId
        firstName
        lastName
      }
    }

    listLocationDetails(iFilter: { location_group_ids: $iLocationGroupIds }) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }
  }
`

const DISABLE_GROUP_BY_LINK = ['ghai_pop', 'bibibop', 'fw_wingstop']

export const tableConfigs = {
  '<%- JSON(allStores?.tableColumns) %>': 'link',
} as const

const useAllStores = (): IDataType => {
  const { brand } = useBrands()
  const locationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const { data } = useQuery<IQueryDataType>(query, {
    variables: {
      iLocationGroupIds: [locationGroupId],
      iMetricCodes: ['total_food_and_paper', 'total_sales'],
    },
  })
  const groupByLabel = getBusinessLabel('director')

  return {
    allStores: useMemo(() => {
      const listLocationDetails = data?.listLocationDetails.nodes

      if (!listLocationDetails) return

      return {
        tableColumns: [
          {
            key: 'location',
            title: 'Stores',
            onClick: 'track.locationClicked',
            width: '200px',
          },
          {
            key: 'groupBy',
            title: groupByLabel,
            onClick: 'track.groupByClicked',
            width: '200px',
          },
        ],
        locationGroupId,
        locations: listLocationDetails.reduce(
          (result, { locationGroups, ...location }) => {
            const director = data.listDirectors.nodes.find((d) =>
              locationGroups.some((lg) => lg.id === d.locationGroupId),
            )
            const link = `/${brand}/store_detail?store=${location.id}`
            const displayName = `${location.code} - ${location.name}`
            const groupBy = director && {
              id: director.employeeId,
              displayName: `${director.firstName} ${director.lastName}`,
              link:
                !DISABLE_GROUP_BY_LINK.includes(brand) &&
                `/${brand}/employee_profile/${director.employeeId}`,
            }

            return {
              ...result,
              [location.id]: {
                ...location,
                displayName: `${location.code} - ${location.name}`,
                link,
                groupBy,
                tableRow: {
                  location: {
                    id: location.id,
                    link,
                    label: displayName,
                  },
                  groupBy: groupBy && {
                    id: groupBy.id,
                    link: groupBy.link,
                    label: groupBy.displayName,
                  },
                },
              },
            }
          },
          {},
        ),
      }
    }, [locationGroupId, data, groupByLabel]),
  }
}

export default useAllStores
