import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

const QUERY_PMIX_DATA = gql`
  query ListItemMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listItemMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemId
        itemCode
        itemName
        displayParentCategoryCode
        displayParentCategoryName
        metricData
        metricSummaryData
      }
    }
  }
`

const useUnifiedData = (options: any) => {
  const { data, loading } = useQuery(QUERY_PMIX_DATA, options)

  return {
    data: useMemo(() => {
      const unifiedEndpointData = data?.listItemMetricValues?.nodes

      if (!unifiedEndpointData) return null

      const source = unifiedEndpointData.map((rowData: any) => {
        const kpisData: { [key: string]: number } = {}

        for (const key in rowData.metricData) {
          if (rowData.metricData.hasOwnProperty(key)) {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            switch (rowData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                kpisData[newKey] = rowData.metricData[key].value * 100
                break

              default:
                kpisData[newKey] = rowData.metricData[key].value
                break
            }
          }
        }

        return {
          ...kpisData,
          itemName: rowData.itemName,
          itemCode: rowData.itemCode,
          displayParentCategoryName: rowData.displayParentCategoryName,
          displayParentCategoryCode: rowData.displayParentCategoryCode,
        }
      })

      const [rawSummary] = unifiedEndpointData.filter(
        (rowData: any) => rowData.metricSummaryData != null,
      )
      const summary: { [key: string]: number } = {}
      for (const key in rawSummary?.metricSummaryData) {
        if (rawSummary.metricSummaryData.hasOwnProperty(key)) {
          const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
          switch (rawSummary.metricSummaryData[key].unit) {
            case 'DOLLAR':
            case 'PERCENTAGE':
              summary[newKey] = rawSummary.metricSummaryData[key].value * 100
              break

            default:
              summary[newKey] = rawSummary.metricSummaryData[key].value
              break
          }
        }
      }

      return {
        source,
        summary,
      }
    }, [data]),
    loading,
  }
}

export default useUnifiedData
