import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBrand } from 'pared/utils/brand'
import { toUsdStr } from 'pared/utils/number'

import Summary from '../../Summary'
import useSummaryContent from '../../Summary/MessageBlock/hooks/useSummaryContent'
import useFetchSummaryData from '../../Summary/hooks/useFetchSummaryData'
import { IExrayDataType } from '../types'
import useConfig from './useConfig'

const LOADING = 'Loading...'

export default (
  locationGroupId?: number,
): {
  totalOpportunityCost: string
  data: IExrayDataType[]
} => {
  const brand = getBrand()
  const config = useConfig(brand)
  const startDate = moment().subtract(30, 'days')
  const endDate = moment().subtract(1, 'day')
  const startDateStr = startDate.format('YYYY-MM-DD')
  const endDateStr = endDate.format('YYYY-MM-DD')
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const selectedLocationGroupId = locationGroupId ?? brandLocationGroupId

  const useGetSummary = (
    summaryType:
      | 'corporate_labor'
      | 'corporate_loss_prevention'
      | 'corporate_cogs'
      | 'corporate_salesmanship'
      | 'corporate_voids_summary',
  ) => {
    const skip = !config.summaryTypeSettings.find(
      ({ fetchType }) => fetchType === summaryType,
    )
    const { data, loading } = useFetchSummaryData(
      summaryType,
      selectedLocationGroupId,
      brandLocationGroupId,
      startDateStr,
      endDateStr,
      skip,
    )

    const summaryContent = useSummaryContent({
      selectedLocationGroupId,
      startDate: startDateStr,
      endDate: endDateStr,
      summaryData: {
        type: summaryType,
        data,
      },
    })

    const textOnly = summaryContent?.textOnly
    const annualOpportunityCost =
      data?.reduce((prev: any, cur: any) => {
        if (cur.annualOpportunityCost > 0) {
          prev += cur.annualOpportunityCost
        }

        return prev
      }, 0) ?? 0

    return skip
      ? {}
      : {
          annualOpportunityCost:
            summaryType === 'corporate_cogs'
              ? annualOpportunityCost
              : annualOpportunityCost / 100,
          loading,
          summary: textOnly,
          component: (
            <Summary
              summaryType={summaryType}
              locationGroupId={selectedLocationGroupId}
              brandLocationGroupId={brandLocationGroupId}
              startDate={startDateStr}
              endDate={endDateStr}
              isForExray
            />
          ),
        }
  }

  const {
    annualOpportunityCost: salesAnnualOpportunityCost,
    loading: salesLoading,
    component: SalesSummary,
    summary: salesSummaryText,
  } = useGetSummary('corporate_salesmanship')

  const {
    annualOpportunityCost: lossPreventionAnnualOpportunityCost,
    loading: lossPreventionLoading,
    component: LossPreventionSummary,
    summary: lossPreventionSummaryText,
  } = useGetSummary('corporate_loss_prevention')

  const {
    annualOpportunityCost: laborAnnualOpportunityCost,
    loading: laborLoading,
    component: LaborSummary,
    summary: laborSummaryText,
  } = useGetSummary('corporate_labor')

  const {
    annualOpportunityCost: cogsAnnualOpportunityCost,
    loading: cogsLoading,
    component: CogsSummary,
    summary: cogsSummaryText,
  } = useGetSummary('corporate_cogs')

  const {
    loading: voidsLoading,
    component: VoidsSummary,
    summary: voidsSummaryText,
  } = useGetSummary('corporate_voids_summary')

  const isLoading =
    salesLoading ||
    cogsLoading ||
    laborLoading ||
    lossPreventionLoading ||
    voidsLoading

  const opportunityCosts = {
    ['Sales']: salesAnnualOpportunityCost,
    ['Loss Prevention']: lossPreventionAnnualOpportunityCost,
    ['Labor']: laborAnnualOpportunityCost,
    ['Food Cost']: cogsAnnualOpportunityCost,
  }

  const sorted = Object.keys(opportunityCosts).sort((typeA, typeB) => {
    return (
      opportunityCosts[typeB as keyof typeof opportunityCosts] -
      opportunityCosts[typeA as keyof typeof opportunityCosts]
    )
  })

  const totalOpportunityCost = toUsdStr(
    salesAnnualOpportunityCost +
      lossPreventionAnnualOpportunityCost +
      laborAnnualOpportunityCost +
      cogsAnnualOpportunityCost,
  )

  // Higher opportunity cost is more red
  const backgroundColors = ['#FE0505', '#FF5C5D', '#DAA8A8', '#474646']

  return useMemo(
    () => ({
      totalOpportunityCost: isLoading ? '-' : totalOpportunityCost,
      data: config.summaryTypeSettings?.map(({ type, title }) => {
        let header = ''
        let body
        let table = null
        switch (type) {
          case 'Sales':
            if (isLoading) {
              header = LOADING
              break
            }

            header = '-'
            if (salesAnnualOpportunityCost > 0) {
              header = `${toUsdStr(salesAnnualOpportunityCost)}`
              body = salesSummaryText
              table = SalesSummary
            }

            break

          case 'Loss Prevention':
            if (isLoading) {
              header = LOADING
              break
            }

            header = '-'
            if (lossPreventionAnnualOpportunityCost > 0) {
              header = toUsdStr(lossPreventionAnnualOpportunityCost)
              body = lossPreventionSummaryText
              table = LossPreventionSummary
            }

            break

          case 'Labor':
            if (isLoading) {
              header = LOADING
              break
            }

            header = '-'
            if (laborAnnualOpportunityCost > 0) {
              header = toUsdStr(laborAnnualOpportunityCost)
              body = laborSummaryText
              table = LaborSummary
            }

            break

          case 'Food Cost':
            if (isLoading) {
              header = LOADING
              break
            }

            header = '-'
            if (cogsAnnualOpportunityCost > 0) {
              header = toUsdStr(cogsAnnualOpportunityCost)
              body = cogsSummaryText
              table = CogsSummary
            }

            break

          case 'Voids':
            if (isLoading) {
              header = LOADING
              break
            }

            header = ' '
            body = voidsSummaryText
            table = VoidsSummary

            break
        }

        return {
          title,
          header,
          body,
          table,
          backgroundColor: isLoading
            ? 'black'
            : backgroundColors[sorted.findIndex((value) => value === type)],
        }
      }),
    }),
    [
      isLoading,
      salesAnnualOpportunityCost,
      salesSummaryText,
      SalesSummary,
      lossPreventionAnnualOpportunityCost,
      lossPreventionSummaryText,
      LossPreventionSummary,
      laborAnnualOpportunityCost,
      laborSummaryText,
      LaborSummary,
      cogsAnnualOpportunityCost,
      cogsSummaryText,
      CogsSummary,
      voidsSummaryText,
      VoidsSummary,
    ],
  )
}
