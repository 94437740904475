import { IConfigsType } from '../../types'

const ghaiPopHome: IConfigsType = {
  ghai_pop: {
    '/:brand/scorecard': {
      groupFilter: {
        api: 'locationFilter',
      },
      exray: {
        type: 'ai-summary',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
        fields: ['sales', 'ghaiPopFoodCost', 'ghaiPopLabor', 'guest'],
      },
    },
  },
}

export default ghaiPopHome
