import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import {
  MetricResult,
  useFetchGroupMetricValue,
} from './useFetchGroupMetricValue'

const useBbbCorpCtrlExpenses = (skip: boolean): IApiDataType => {
  const { variables } = useVariables()

  const currentPeriod = variables.date?.getInfo(0)
  const {
    startDateStr: currentPeriodBeginDate,
    endDateStr: currentPeriodEndDate,
  } = currentPeriod?.dateRange || {}
  const { startDateStr: priorPeriodBeginDate, endDateStr: priorPeriodEndDate } =
    variables.date?.getInfo(-1)?.dateRange || {}

  const { groupFilter } = useGroupFilter()
  const locationGroupId = groupFilter?.ids[0] || 1

  const metricCodes = [
    'total_controllable_expenses_without_bank_charges_and_third_party_fees',
    'total_controllable_expenses_without_bank_charges_and_third_party_fees_budget',
    'total_controllable_expenses_percent',
    'total_controllable_expenses_budget_percent',
  ]

  const { results: currentMetrics, isLoading: currentLoading } =
    useFetchGroupMetricValue(
      metricCodes,
      currentPeriodBeginDate,
      currentPeriodEndDate,
      locationGroupId,
      skip,
    )

  const { results: priorMetrics, isLoading: priorLoading } =
    useFetchGroupMetricValue(
      metricCodes,
      priorPeriodBeginDate,
      priorPeriodEndDate,
      locationGroupId,
      skip,
    )

  const { results: yoyMetrics, isLoading: yoyLoading } =
    useFetchGroupMetricValue(
      metricCodes,
      currentPeriod?.yoy?.dateRange?.startDateStr,
      currentPeriod?.yoy?.dateRange?.endDateStr,
      locationGroupId,
      skip,
    )

  const getMetricValue = (
    metrics: MetricResult[],
    metricCode: string,
  ): number => {
    const metric = metrics.find(
      (m: MetricResult) => m.metricCode === metricCode,
    )
    return metric?.value ?? 0
  }

  const ctrlExpenses = getMetricValue(
    currentMetrics,
    'total_controllable_expenses_without_bank_charges_and_third_party_fees',
  )
  const ctrlExpensesBudget = getMetricValue(
    currentMetrics,
    'total_controllable_expenses_without_bank_charges_and_third_party_fees_budget',
  )
  const ctrlExpensesPct = getMetricValue(
    currentMetrics,
    'total_controllable_expenses_percent',
  )
  const ctrlExpensesBudgetPct = getMetricValue(
    currentMetrics,
    'total_controllable_expenses_budget_percent',
  )
  const priorCtrlExpensesPct = getMetricValue(
    priorMetrics,
    'total_controllable_expenses_percent',
  )
  const yoyCtrlExpensesPct = getMetricValue(
    yoyMetrics,
    'total_controllable_expenses_percent',
  )

  const ctrlExpensesVarPct = ctrlExpensesPct - ctrlExpensesBudgetPct

  return {
    data: useMemo(() => {
      if (ctrlExpenses === 0 && ctrlExpensesPct === 0) return null

      // ADD TEXT HERE START

      const ctrlExpensesVarPctText =
        ctrlExpensesVarPct >= 0
          ? `Missing the ${toPercentString(
              ctrlExpensesBudgetPct,
            )} budget by ${toPercentString(ctrlExpensesVarPct)}`
          : `Beating the ${toPercentString(
              ctrlExpensesBudgetPct,
            )} budget by ${toPercentString(-ctrlExpensesVarPct)}`

      const ctrlExpensesVarPctPeriodChgText =
        ctrlExpensesPct >= priorCtrlExpensesPct
          ? `Increasing ${toPercentString(
              ctrlExpensesPct - priorCtrlExpensesPct,
            )} from Prior Period of ${toPercentString(priorCtrlExpensesPct)}`
          : `Decreasing ${toPercentString(
              priorCtrlExpensesPct - ctrlExpensesPct,
            )} from Prior Period of ${toPercentString(priorCtrlExpensesPct)}`

      const ctrlExpensesVarPctYoyChgText =
        ctrlExpensesPct >= yoyCtrlExpensesPct
          ? `Increasing ${toPercentString(
              ctrlExpensesPct - yoyCtrlExpensesPct,
            )} from Prior Year of ${toPercentString(yoyCtrlExpensesPct)}`
          : `Decreasing ${toPercentString(
              yoyCtrlExpensesPct - ctrlExpensesPct,
            )} from Prior Year of ${toPercentString(yoyCtrlExpensesPct)}`

      // ADD TEXT HERE END

      return {
        title: `Controllable Expenses`,
        total: ctrlExpenses,
        summary: `Controllable Expenses in ${
          currentPeriod?.displayName
        } was ${toPercentString(ctrlExpensesPct)}`,
        detail: `
          <ul>
            <li>${ctrlExpensesVarPctText} or ${toUsdString(
          Math.abs(ctrlExpenses - ctrlExpensesBudget),
        )}</li>
            <li>${ctrlExpensesVarPctPeriodChgText}</li>
            <li>${ctrlExpensesVarPctYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [
      ctrlExpenses,
      ctrlExpensesBudget,
      ctrlExpensesPct,
      ctrlExpensesBudgetPct,
      priorCtrlExpensesPct,
      yoyCtrlExpensesPct,
      currentPeriod,
    ]),
    loading: currentLoading || priorLoading || yoyLoading,
  }
}

export default useBbbCorpCtrlExpenses
