import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect } from 'react'

import {
  GROUP_BY_DAY,
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  IDateRange,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'

import { LIST_LOCATION_VS_SYSTEMWIDE_MOMENTFEED_SCORE } from '../gql'
import Main from './Main'

interface IProps {
  startDate: string
  endDate: string
  dateRangeType: IDateRange['type']
  locationId: number
  locationName: string
}

const DATE_DISPLAY_FORMAT = 'M/D/YY'

function Chart({
  startDate,
  endDate,
  dateRangeType,
  locationId,
  locationName,
}: IProps) {
  const [
    listScore,
    { loading: listScoreLoading, error: listScoreError, data: listScoreData },
  ] = useLazyQuery(LIST_LOCATION_VS_SYSTEMWIDE_MOMENTFEED_SCORE)

  useEffect(() => {
    let groupBy = ''
    if (dateRangeType === TYPE_YEAR) {
      groupBy = GROUP_BY_QUARTER
    } else if (dateRangeType === TYPE_QUARTER) {
      groupBy = GROUP_BY_PERIOD
    } else if (
      dateRangeType === TYPE_PERIOD ||
      dateRangeType === TYPE_TRAILING_90_DAYS
    ) {
      groupBy = GROUP_BY_WEEK
    } else {
      groupBy = GROUP_BY_DAY
    }

    listScore({
      variables: {
        iLocationId: locationId,
        iStartDate: startDate,
        iEndDate: endDate,
        iGroupBy: groupBy,
      },
    })
  }, [startDate, endDate, dateRangeType, locationId])

  let rawGuestReviewPeriodically = []

  if (
    listScoreData &&
    listScoreData.listLocationVsSystemwideMomentfeedScore &&
    Array.isArray(listScoreData.listLocationVsSystemwideMomentfeedScore.nodes)
  ) {
    rawGuestReviewPeriodically = _.get(
      listScoreData,
      'listLocationVsSystemwideMomentfeedScore.nodes',
      [],
    )
  }

  const xAxisData = _.map(
    rawGuestReviewPeriodically,
    (k) =>
      ({
        [TYPE_YEAR]: `Q${k.businessQuarter} ${k.businessYear}`,
        [TYPE_QUARTER]: `P${k.businessMonth} ${k.businessYear}`,
        [TYPE_PERIOD]: `P${k.businessMonth} W${k.businessWeek} ${k.businessYear}`,
        [TYPE_WEEK]: `${k.businessDate}`,
        [TYPE_LAST_WEEK]: `${k.businessDate}`,
        [TYPE_THIS_WEEK]: `${k.businessDate}`,
        [TYPE_YESTERDAY]: `${k.businessDate}`,
        [TYPE_TRAILING_7_DAYS]: `${k.businessDate}`,
        [TYPE_TRAILING_90_DAYS]: `P${k.businessMonth} W${k.businessWeek} ${k.businessYear}`,
      }[dateRangeType || TYPE_PERIOD]),
  )

  const yAxisLocationData = _.map(rawGuestReviewPeriodically, (k) => {
    return _.get(k, 'locationRatingScore', null)
  })

  const yAxisSystemwideData = _.map(rawGuestReviewPeriodically, (k) => {
    return _.get(k, 'systemwideRatingScore', null)
  })

  return (
    <Main
      xAxisData={xAxisData}
      yAxisLocationData={yAxisLocationData}
      yAxisSystemwideData={yAxisSystemwideData}
      locationName={locationName}
      isLoading={listScoreLoading}
    />
  )
}

export default Chart
