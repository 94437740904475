import { IConfigsType } from '../../types'

const bibibopHome: IConfigsType = {
  bibibop: {
    '/:brand/scorecard': {
      groupFilter: {
        api: 'locationFilter',
      },
      exray: {
        type: 'ai-summary',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
        fields: ['sales', 'labor', 'bbbFoodCost', 'delivery'],
      },
    },
  },
}

export default bibibopHome
