import { gql } from '@apollo/client'

export const QUERY_GET_LOCATION_ACCESS_GROUPS_FOR_BRAND = gql`
  query GetLocationAccessGroupsForBrand($brandId: Int!) {
    getLocationAccessGroupsForBrand(iBrandId: $brandId) {
      nodes {
        id
        name
        type
      }
    }
  }
`
