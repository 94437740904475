import { useMemo } from 'react'

import { IExrayDataType, ISummaryFieldType } from '../types'
import useBbbCorpCtrlExpenses from './bbb/useCorpCtrlExpenses'
import useBbbCorpEbitda from './bbb/useCorpEbitda'
import useBbbCorpFnp from './bbb/useCorpFnp'
import useBbbCorpLabor from './bbb/useCorpLabor'
import useBbbCorpSales from './bbb/useCorpSales'
import useBbbCtrlExpenses from './bbb/useCtrlExpenses'
import useBbbExeSales from './bbb/useExeSales'
import useBbbFnp from './bbb/useFoodAndPaper'
import useBbbFoodCost from './bbb/useFoodCost'
import useBbbLabor from './bbb/useLabor'
import useBbbSales from './bbb/useSales'
import useDelivery from './common/useDelivery'
import useGuest from './common/useGuest'
import useLabor from './common/useLabor'
import useLossPrevention from './common/useLossPrevention'
import useSales from './common/useSales'
import useGhaiPopFoodCost from './ghaiPop/useFoodCost'
import useGhaiPopLabor from './ghaiPop/useLabor'

interface IDataType extends IExrayDataType {
  type: ISummaryFieldType
}

interface IApiDataType {
  data: IDataType[] | null
  loading: boolean
}

// FIXME: change it like `variables/useVariables`
const useApi = (fields: ISummaryFieldType[]): IApiDataType => {
  const sales = useSales(!fields.includes('sales'))
  const delivery = useDelivery(!fields.includes('delivery'))
  const guest = useGuest(!fields.includes('guest'))
  const labor = useLabor(!fields.includes('labor'))
  const lossPrevention = useLossPrevention(!fields.includes('lossPrevention'))

  // bbb
  const bbbFoodCost = useBbbFoodCost(!fields.includes('bbbFoodCost'))
  const bbbSales = useBbbSales(!fields.includes('bbbSales'))
  const bbbExeSales = useBbbExeSales(!fields.includes('bbbExeSales'))
  const bbbCorpSales = useBbbCorpSales(!fields.includes('bbbCorpSales'))
  const bbbCorpLabor = useBbbCorpLabor(!fields.includes('bbbCorpLabor'))
  const bbbCorpFnp = useBbbCorpFnp(!fields.includes('bbbCorpFnp'))
  const bbbCorpCtrlExpenses = useBbbCorpCtrlExpenses(
    !fields.includes('bbbCorpCtrlExpenses'),
  )
  const bbbCorpEbitda = useBbbCorpEbitda(!fields.includes('bbbCorpEbitda'))
  const bbbFnp = useBbbFnp(!fields.includes('bbbFnp'))
  const bbbLabor = useBbbLabor(!fields.includes('bbbLabor'))
  const bbbCtrlExpenses = useBbbCtrlExpenses(
    !fields.includes('bbbCtrlExpenses'),
  )

  // ghai pop
  const ghaiPopFoodCost = useGhaiPopFoodCost(
    !fields.includes('ghaiPopFoodCost'),
  )
  const ghaiPopLabor = useGhaiPopLabor(!fields.includes('ghaiPopLabor'))

  return useMemo(
    () =>
      [
        { ...sales, type: 'sales' as const },
        { ...delivery, type: 'delivery' as const },
        { ...guest, type: 'guest' as const },
        { ...labor, type: 'labor' as const },
        { ...lossPrevention, type: 'lossPrevention' as const },
        { ...bbbFoodCost, type: 'bbbFoodCost' as const },
        { ...bbbSales, type: 'bbbSales' as const },
        { ...bbbExeSales, type: 'bbbExeSales' as const },
        { ...bbbCorpSales, type: 'bbbCorpSales' as const },
        { ...bbbCorpLabor, type: 'bbbCorpLabor' as const },
        { ...bbbCorpFnp, type: 'bbbCorpFnp' as const },
        { ...bbbCorpCtrlExpenses, type: 'bbbCorpCtrlExpenses' as const },
        { ...bbbFnp, type: 'bbbFnp' as const },
        { ...bbbLabor, type: 'bbbLabor' as const },
        { ...bbbCtrlExpenses, type: 'bbbCtrlExpenses' as const },
        { ...bbbCorpEbitda, type: 'bbbCorpEbitda' as const },
        { ...ghaiPopFoodCost, type: 'ghaiPopFoodCost' as const },
        { ...ghaiPopLabor, type: 'ghaiPopLabor' as const },
      ].reduce(
        (result, { type, data, loading }) => ({
          data: !data
            ? result.data
            : [...(result.data || []), { ...data, type }],
          loading: result.loading || loading,
        }),
        {
          data: null,
          loading: false,
        } as IApiDataType,
      ),
    [
      sales,
      delivery,
      guest,
      labor,
      lossPrevention,
      bbbFoodCost,
      bbbSales,
      bbbExeSales,
      bbbCorpSales,
      bbbCorpLabor,
      bbbCorpFnp,
      bbbCorpCtrlExpenses,
      bbbCorpEbitda,
      bbbFnp,
      bbbLabor,
      bbbCtrlExpenses,
      ghaiPopFoodCost,
      ghaiPopLabor,
    ],
  )
}

export default useApi
