import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import { toUsdStr } from 'pared/utils/number'

import { IExrayDataType } from '../../types'

interface ILaborDataType {
  locationSchedulingByHourByDay: {
    nodes: {
      hour: number
      details: {
        date: string
        savings: number
        unproductiveLabor: number
        hourlyWage: number
        projectedHours: number
        scheduledHours: number
      }[]
    }[]
  }
}

const query = gql`
  query LocationSchedulingByHourByDay(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    locationSchedulingByHourByDay(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        hour
        details
      }
    }
  }
`

const getLaborSummaryData = (
  locationId: number,
  locationName: string,
  data: ILaborDataType,
): IExrayDataType => {
  const dataSource = data?.locationSchedulingByHourByDay?.nodes || []
  const lastNonZeroIndex = dataSource.reduce((lastDateIdx, data) => {
    const index = [...data.details]
      .reverse()
      .findIndex((d) => d.scheduledHours !== 0)
    const lastIndex = index === -1 ? -1 : data.details.length - index - 1

    return lastIndex > lastDateIdx ? lastIndex : lastDateIdx
  }, -1)
  const scheduledDays = lastNonZeroIndex > 0 ? lastNonZeroIndex + 1 : 0

  const total = dataSource.reduce(
    (result, row) => {
      const totalSavaings = row.details
        .filter((d) => d.savings > 0)
        .reduce((acc, current) => acc + current.savings, 0)
      const totalUnproductiveLabor = row.details
        .filter((d) => d.unproductiveLabor > 0)
        .reduce((acc, current) => acc + current.unproductiveLabor, 0)

      return {
        savings: result.savings + totalSavaings,
        unproductiveLabor: result.unproductiveLabor + totalUnproductiveLabor,
      }
    },
    { savings: 0, unproductiveLabor: 0 },
  )

  const opportunityCosts = total ? total.savings / 100 : 0

  const detail = !total
    ? null
    : `For the schedule posted for the next ${scheduledDays} days, ${locationName} is wasting ${
        total.unproductiveLabor
      } hours on Unproductive Labor which is a ${toUsdStr(
        opportunityCosts,
      )} opportunity.   Refer to the <a href="./salesmanship?store=${locationId}#scheduling-analysis">Scheduling Analysis Heatmap</a> to identify the exact days and times that you should adjust your schedule.`

  return {
    title: 'Labor',
    total: total ? opportunityCosts : null,
    detail,
  }
}

const useGhaiPopLabor = (skip: boolean) => {
  const today = moment().format(DATE_FORMAT)
  const nextThirtyDays = moment().add(30, 'days').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { data, loading } = useQuery<ILaborDataType>(query, {
    variables: {
      iFilter: { location_ids: [locationId] },
      iStartDate: today,
      iEndDate: nextThirtyDays,
    },
    skip,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      return getLaborSummaryData(locationId, locationName, data)
    }, [locationId, locationName, data]),
    loading,
  }
}

export default useGhaiPopLabor
