import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

export interface MetricResult {
  metricCode: string
  value: number
  unit: string | null
  loading: boolean
}

const query = gql`
  query listLocationMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: { location_ids: $iLocationIds, metrics: $iMetricCodes }
    ) {
      nodes {
        metricData
      }
    }
  }
`

const useFetchMetricValue = (
  metrics: string[],
  startDate: string | undefined,
  endDate: string | undefined,
  locationId: number,
  skip: boolean,
): { results: MetricResult[]; isLoading: boolean } => {
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iLocationIds: [locationId],
      iMetricCodes: metrics,
    },
    skip: !startDate || !endDate || skip,
  })

  const results = useMemo(() => {
    if (!data) return []

    return metrics.reduce((acc, metricCode) => {
      let value =
        data?.listLocationMetricValues?.nodes[0]?.metricData?.[metricCode]
          ?.value ?? 0
      const unit =
        data?.listLocationMetricValues?.nodes[0]?.metricData?.[metricCode]
          ?.unit || null

      if (unit === 'CENT') {
        value *= 0.01
      } else if (unit === 'PERCENTAGE') {
        value *= 100
      }

      acc.push({ metricCode, value, unit, loading })
      return acc
    }, [] as MetricResult[])
  }, [data, metrics, loading])

  return { results, isLoading: loading }
}

export { useFetchMetricValue }
