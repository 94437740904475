import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { toPercentString, toUsdString } from 'pared/utils/number'

interface IPropsType {
  type: 'td' | 'th'
  dataType: 'PERCENT' | 'COUNT' | 'PRICE'
  value: string
  decimalDigit?: number
  code?: 'danger' | 'success'
}

const Highlight = styled.span<{ code?: string }>`
  color: ${({ code }) => {
    switch (code) {
      case 'danger':
        return COLORS.Pomodoro
      case 'success':
        return COLORS.Basil
      default:
        return 'black'
    }
  }};
`

const CustomizedTd = ({
  type = 'td',
  dataType,
  value,
  code,
  ...props
}: IPropsType) => {
  const Component = styled[type]``

  switch (dataType) {
    case 'PERCENT': {
      let valueNumber = parseFloat(value)

      if (valueNumber || valueNumber === 0) {
        return (
          <Component>
            <Highlight code={code}>
              {Math.abs(valueNumber) >= 100
                ? toPercentString(value, 0)
                : toPercentString(value, props.decimalDigit)}
            </Highlight>
          </Component>
        )
      }

      return <Component>-</Component>
    }

    case 'PRICE':
      return (
        <Component>
          {toUsdString(parseFloat(value as string) / 100, props.decimalDigit)}
        </Component>
      )

    case 'COUNT': {
      if (value)
        return (
          <Component>{parseFloat(value).toFixed(props.decimalDigit)}</Component>
        )

      return <Component>-</Component>
    }
  }
}

export default CustomizedTd
