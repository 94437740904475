import moment from 'moment'

import navParams from 'pared/Routes/navParams'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_12_MONTHS,
  TYPE_TRAILING_90_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'

import DateFilterProvider, { useDateFilter } from './DateFitlerProvider'
import useFirstDateOfYear from './hooks/useFirstDateOfYear'
import useYoyDates from './hooks/useYoyDates'
import useYtdEndDate from './hooks/useYtdEndDate'

const DATE_FILTER_TYPES = [
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_TRAILING_12_MONTHS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
  TYPE_CUSTOM,
] as const

export interface IPropsType {
  types?: typeof DATE_FILTER_TYPES[number][]
  defaultType?: typeof DATE_FILTER_TYPES[number]
  isDailyCustom?: boolean
  startDate?: string
  endDate?: string | 'now'
}

const DateFilter = ({
  types,
  defaultType,
  isDailyCustom,
  startDate,
  endDate,
}: IPropsType) => (
  <DateRangeSelector
    navParams={navParams}
    dateRangeOptions={types as unknown as string[]}
    preSelectedDateRange={defaultType as unknown as string}
    isDailyCustom={isDailyCustom}
    customStartDate={startDate}
    customEndDate={endDate === 'now' ? moment().format('MM/DD/YYYY') : endDate}
  />
)

export {
  DateFilterProvider,
  useDateFilter,
  useFirstDateOfYear,
  useYoyDates,
  useYtdEndDate,
}
export default DateFilter
