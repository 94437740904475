import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import { MetricResult, useFetchMetricValue } from './useFetchMetricValue'

const useBbbSales = (skip: boolean): IApiDataType => {
  const { variables } = useVariables()

  const currentPeriod = variables.date?.getInfo(0)
  const {
    startDateStr: currentPeriodBeginDate,
    endDateStr: currentPeriodEndDate,
  } = currentPeriod?.dateRange || {}
  const { startDateStr: priorPeriodBeginDate, endDateStr: priorPeriodEndDate } =
    variables.date?.getInfo(-1)?.dateRange || {}

  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'

  const metricCodes = [
    'total_sales',
    'total_sales_budget',
    'check_count',
    'check_average',
  ]

  const { results: currentMetrics, isLoading: currentLoading } =
    useFetchMetricValue(
      metricCodes,
      currentPeriodBeginDate,
      currentPeriodEndDate,
      locationId,
      skip,
    )

  const { results: priorMetrics, isLoading: priorLoading } =
    useFetchMetricValue(
      metricCodes,
      priorPeriodBeginDate,
      priorPeriodEndDate,
      locationId,
      skip,
    )

  const { results: yoyMetrics, isLoading: yoyLoading } = useFetchMetricValue(
    metricCodes,
    currentPeriod?.yoy?.dateRange?.startDateStr,
    currentPeriod?.yoy?.dateRange?.endDateStr,
    locationId,
    skip,
  )

  const getMetricValue = (metrics: MetricResult[], metricCode: string) => {
    return metrics.find((m) => m.metricCode === metricCode)?.value ?? 0
  }

  const sales = getMetricValue(currentMetrics, 'total_sales')
  const yoySales = getMetricValue(yoyMetrics, 'total_sales')
  const salesBudget = getMetricValue(currentMetrics, 'total_sales_budget')
  const checkCount = getMetricValue(currentMetrics, 'check_count')
  const priorCheckCount = getMetricValue(priorMetrics, 'check_count')
  const yoyCheckCount = getMetricValue(yoyMetrics, 'check_count')
  const checkAvg = getMetricValue(currentMetrics, 'check_average')
  const priorCheckAvg = getMetricValue(priorMetrics, 'check_average')
  const yoyCheckAvg = getMetricValue(yoyMetrics, 'check_average')

  const salesVar = sales - salesBudget

  const calculateChange = (
    currentValue: number,
    previousValue: number,
  ): number => {
    return previousValue !== 0
      ? (100 * (currentValue - previousValue)) / previousValue
      : 0
  }

  const salesYoyChg = calculateChange(sales, yoySales)
  const checkCountPeriodChg = calculateChange(checkCount, priorCheckCount)
  const checkCountYoyChg = calculateChange(checkCount, yoyCheckCount)
  const checkAvgPeriodChg = calculateChange(checkAvg, priorCheckAvg)
  const checkAvgYoyChg = calculateChange(checkAvg, yoyCheckAvg)

  return {
    data: useMemo(() => {
      if (!sales && !checkCount && !checkAvg) return null

      // ADD TEXT HERE START

      const salesVarText =
        salesVar <= 0
          ? `Missing the ${toUsdString(salesBudget)} budget by ${toUsdString(
              -salesVar,
            )}`
          : `Beating the ${toUsdString(salesBudget)} budget by ${toUsdString(
              salesVar,
            )}`

      const salesYoyChgText =
        salesYoyChg >= 0
          ? `increased by ${toPercentString(salesYoyChg)}`
          : `decreased by ${toPercentString(-salesYoyChg)}`

      const checkCountPeriodChgText =
        checkCountPeriodChg >= 0
          ? `increased ${toPercentString(checkCountPeriodChg)} vs Prior Period`
          : `decreased ${toPercentString(-checkCountPeriodChg)} vs Prior Period`

      const checkCountYoyChgText =
        checkCountYoyChg >= 0
          ? `increased ${toPercentString(checkCountYoyChg)} vs PY`
          : `decreased ${toPercentString(-checkCountYoyChg)} vs PY`

      const checkAvgPeriodChgText =
        checkAvgPeriodChg >= 0
          ? `increased ${toPercentString(checkAvgPeriodChg)} vs Prior Period`
          : `decreased ${toPercentString(-checkAvgPeriodChg)} vs Prior Period`

      const checkAvgYoyChgText =
        checkAvgYoyChg >= 0
          ? `increased ${toPercentString(checkAvgYoyChg)} vs PY`
          : `decreased ${toPercentString(-checkAvgYoyChg)} vs PY`

      // ADD TEXT HERE END

      return {
        title: `Sales`,
        total: salesVar * -1,
        summary: `${locationName}\`s Sales in ${
          currentPeriod?.displayName
        } was ${toUsdString(sales)}`,
        detail: `
        <ul>
          <li>${salesVarText}</li>
          <li>SSS ${salesYoyChgText}</li>
          <li>Traffic ${checkCountPeriodChgText} and ${checkCountYoyChgText}</li>
          <li>Check Avg ${checkAvgPeriodChgText} and ${checkAvgYoyChgText}</li>
        </ul>`,
        hasDetails: true,
      }
    }, [
      locationName,
      sales,
      salesBudget,
      yoySales,
      checkCount,
      priorCheckCount,
      yoyCheckCount,
      checkAvg,
      priorCheckAvg,
      yoyCheckAvg,
      currentPeriod,
    ]),
    loading: currentLoading || priorLoading || yoyLoading,
  }
}

export default useBbbSales
