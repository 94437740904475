import moment from 'moment'
import React from 'react'

import { DATE_FORMAT } from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'
import { toPercentString, toUsdString } from 'pared/utils/number'

import useConfig from '../../hooks/useConfig'
import useDailyInventoryAnalysisText from '../../hooks/useDailyInventoryAnalysisText'
import {
  ICorporateCogsSummaryDataType,
  ICorporateDeliveryLocations,
  ICorporateDeliverySummaryDataType,
  ICorporateGuestSummaryDataType,
  ICorporateLossPreventionSummaryDataType,
  ICorporateVoidsSummaryDataType,
  SummaryComponentProps,
} from '../../type'

interface IProps {
  selectedLocationGroupId: number
  startDate: string
  endDate: string
  summaryData: SummaryComponentProps['data']
}

const convertTo12HourFormat = (hour: number) => {
  const date = new Date()
  date.setHours(hour)

  const formattedTime = date.toLocaleString('en-US', {
    hour: 'numeric',
    hour12: true,
  })

  return formattedTime
}

const useSummaryContent = ({
  selectedLocationGroupId,
  startDate,
  endDate,
  summaryData: data,
}: IProps) => {
  const brand = getBrand()
  const config = useConfig()

  const dailyInventoryAnalysisText = useDailyInventoryAnalysisText(
    brand,
    selectedLocationGroupId,
  )

  if (!data?.data) return {}

  const days =
    moment(endDate, DATE_FORMAT).diff(moment(startDate, DATE_FORMAT), 'days') +
    1

  switch (data?.type) {
    case 'corporate_labor':
      const locationNum = data?.data[0]?.locationNum || 1

      const firstStoreCountPercent = toPercentString(
        (100.0 * (data?.data[0]?.locations.length || 0)) / locationNum,
        0,
      )
      const firstHour = convertTo12HourFormat(data?.data[0]?.hour || 0)
      const secondStoreCountPercent = toPercentString(
        (100.0 * (data?.data[1]?.locations.length || 0)) / locationNum,
        0,
      )
      const secondHour = convertTo12HourFormat(data?.data[1]?.hour || 0)
      const thirdStoreCountPercent = toPercentString(
        (100.0 * (data?.data[2]?.locations.length || 0)) / locationNum,
        0,
      )
      const thirdHour = convertTo12HourFormat(data?.data[2]?.hour || 0)

      const laborAnnualOpportunityCost = data?.data?.reduce(
        (total, row) => total + row.annualOpportunityCost,
        0,
      )

      const laborbasisPoint = (
        (10000.0 * laborAnnualOpportunityCost) /
        data?.data[0]?.annualTotalNetSales
      ).toFixed(0)

      return {
        conclusion: (
          <div>
            <div>For Trailing {days} days,</div>
            <div>
              {firstStoreCountPercent} of your stores have low productivity at{' '}
              {firstHour}
            </div>
            <div>
              {secondStoreCountPercent} of your stores have low productivity at{' '}
              {secondHour}.
            </div>
            <div>
              {thirdStoreCountPercent} of your stores have low productivity at{' '}
              {thirdHour}.
            </div>
          </div>
        ),
        insight: (
          <div>
            Consider decreased staffing during these times to increase
            productivity and reduce labor costs by{' '}
            {toUsdString(laborAnnualOpportunityCost / 100.0)} annually - which
            amounts to a {laborbasisPoint} basis points improvement for the
            entire company.
          </div>
        ),
        textOnly: `
          For Trailing ${days} days,
          ${firstStoreCountPercent} of your stores have low productivity at ${firstHour}.
          ${secondStoreCountPercent} of your stores have low productivity at ${secondHour}.
          ${thirdStoreCountPercent} of your stores have low productivity at ${thirdHour}.
          Consider decreased staffing during these times to increase productivity and reduce labor costs by
          ${toUsdString(
            laborAnnualOpportunityCost / 100.0,
          )} annually - which amounts to a
          ${laborbasisPoint} basis points improvement for the entire company.
        `,
      }

    case 'corporate_cogs':
      if (brand === 'bibibop') {
        const storeAmount = data?.data.reduce(
          (result, d) => result + d.locations?.length,
          0,
        )
        const opportunityTotal = data?.data.reduce(
          (result, d) => result + d.opportunityCost,
          0,
        )

        return {
          conclusion: null,
          insight: null,
          textOnly: `
            For the Trailing ${days} Days,
            ${storeAmount} stores had higher Ending Inventory than benchmark. This is a ${toUsdString(
            opportunityTotal,
          )} opportunity for the entire company.
          `,
        }
      }

      const itemNames: string[] = []
      const items = (
        <>
          {data?.data.map(
            (cogs: ICorporateCogsSummaryDataType, index: number) => (
              <React.Fragment key={index}>
                <strong>{cogs.item}</strong>

                {(() => {
                  itemNames.push(cogs.item)
                  if (index === data?.data.length - 1) return null
                  if (index === data?.data.length - 2) {
                    itemNames.push(', and ')
                    return ', and '
                  }

                  itemNames.push(', ')
                  return ', '
                })()}
              </React.Fragment>
            ),
          )}{' '}
          are being purchased in higher quantities than expected.
        </>
      )

      const annualOpportunityCost = data?.data?.reduce(
        (total, row) => total + row.annualOpportunityCost,
        0,
      )

      const cogsBasisPoint = (
        (10000.0 * annualOpportunityCost) /
        data?.data[0]?.annualTotalNetSales
      ).toFixed(0)

      return {
        conclusion: (
          <div>
            <div>For the Trailing {days} Days,</div>
            <div>{items}</div>
          </div>
        ),
        insight: (
          <div>
            Consider retraining your team to save up to{' '}
            {toUsdString(annualOpportunityCost)} on these 5 items annually -
            which amounts to a {cogsBasisPoint} basis points improvement for the
            entire company.
          </div>
        ),
        textOnly:
          dailyInventoryAnalysisText +
          `
          For the Trailing ${days} Days,
          ${itemNames.join(
            '',
          )} are being purchased in higher quantities than expected.
          Consider retraining your team to save up to
          ${toUsdString(annualOpportunityCost)} on these 5 items annually -
          which amounts to a ${cogsBasisPoint} basis points improvement for the
          entire company.
        `,
      }

    case 'corporate_loss_prevention':
      const totalStoreCount = data?.data?.reduce(
        (total: number, row: ICorporateLossPreventionSummaryDataType) =>
          total + (row?.locations?.length || 0),
        0,
      )

      const LossPreventionAnnualOpportunityCost = data?.data?.reduce(
        (total, row) => total + row.annualOpportunityCost,
        0,
      )

      const lossPreventionBasisPoint = (
        (10000.0 * LossPreventionAnnualOpportunityCost) /
        data?.data[0]?.annualTotalNetSales
      ).toFixed(0)

      return {
        conclusion: (
          <div>
            For the Trailing {days} Days, {totalStoreCount} restaurants are
            discounting more than expected
          </div>
        ),
        insight: (
          <div>
            Consider retraining your team to save up to{' '}
            {toUsdString(LossPreventionAnnualOpportunityCost / 100.0)} annually-
            which amounts to a {lossPreventionBasisPoint} basis points
            improvement for the entire company.
          </div>
        ),
        textOnly: `
          For the Trailing ${days} Days, ${totalStoreCount} restaurants are
          discounting more than expected.
          Consider retraining your team to save up to
          ${toUsdString(LossPreventionAnnualOpportunityCost / 100.0)} annually -
          which amounts to a ${lossPreventionBasisPoint} basis points
          improvement for the entire company.
        `,
      }

    case 'corporate_delivery':
      const deliveryStoreCount = data?.data.reduce(
        (
          total: number[],
          row: ICorporateDeliverySummaryDataType<
            'inaccurate' | 'cancelled' | 'delayed'
          >,
        ) =>
          row.locations
            ? row.locations.reduce(
                (
                  total_location: number[],
                  location: ICorporateDeliveryLocations<
                    'inaccurate' | 'cancelled' | 'delayed'
                  >,
                ) =>
                  total_location.includes(location.id)
                    ? [...total_location]
                    : [...total_location, location.id],
                total,
              )
            : total,
        [],
      ).length

      return {
        conclusion: (
          <div>
            For the Trailing {days} Days, {deliveryStoreCount} stores have
            higher Delivery Issues than company averages.
          </div>
        ),
      }

    case 'corporate_salesmanship':
      const salesmanshipAnnualOpportunityCost = data?.data?.reduce(
        (total, row) => total + row.annualOpportunityCost,
        0,
      )

      const salesmanshipBasisPoint = (
        (10000.0 * salesmanshipAnnualOpportunityCost) /
        data?.data[0]?.annualTotalNetSales
      ).toFixed(0)

      return {
        conclusion: (
          <div>
            For the Trailing {days} Days, the following restaurants have lower
            than average Attachment Rates.
          </div>
        ),
        insight: (
          <div>
            Consider running contests or training on suggestive selling.
            Bringing these restaurants up to system averages constitutes a{' '}
            {toUsdString(salesmanshipAnnualOpportunityCost / 100.0)} annual
            opportunity cost - which amounts to a {salesmanshipBasisPoint} basis
            points improvement for the entire company.
          </div>
        ),
        textOnly: `
          For the Trailing ${days} Days, the following restaurants have lower
          than average Attachment Rates.
          Consider running contests or training on suggestive selling.
          Bringing these restaurants up to system averages constitutes a
          ${toUsdString(salesmanshipAnnualOpportunityCost / 100.0)} annual
          opportunity cost - which amounts to a ${salesmanshipBasisPoint} basis
          points improvement for the entire company.
        `,
      }

    case 'corporate_team':
      const corporateThirtyDayTurnoverRate = toPercentString(
        data?.data[0]?.corporateThirtyDayTurnoverRate,
      )
      const corporateSixtyDayTurnoverRate = toPercentString(
        data?.data[0]?.corporateSixtyDayTurnoverRate,
      )
      const corporateNinetyDayTurnoverRate = toPercentString(
        data?.data[0]?.corporateNinetyDayTurnoverRate,
      )

      const corporateCurrentAnnualizedHourlyTurnoverPercent =
        data?.data[0]?.corporateCurrentAnnualizedHourlyTurnoverPercent
      const corporateCurrentAnnualizedSalariedTurnoverPercent =
        data?.data[0]?.corporateCurrentAnnualizedSalariedTurnoverPercent
      const corporatePastAnnualizedHourlyTurnoverPercent =
        data?.data[0]?.corporatePastAnnualizedHourlyTurnoverPercent
      const corporatePastAnnualizedSalariedTurnoverPercent =
        data?.data[0]?.corporatePastAnnualizedSalariedTurnoverPercent

      const hourlyTrend =
        corporateCurrentAnnualizedHourlyTurnoverPercent <
        corporatePastAnnualizedHourlyTurnoverPercent
          ? 'better'
          : 'worse'
      const salariedTrend =
        corporateCurrentAnnualizedSalariedTurnoverPercent <
        corporatePastAnnualizedSalariedTurnoverPercent
          ? 'better'
          : 'worse'

      return {
        conclusion: (
          <div>
            <div>Currently,</div>
            <div>
              Annualized Hourly Turnover Rates are{' '}
              {toPercentString(corporateCurrentAnnualizedHourlyTurnoverPercent)}
            </div>
            <div>This is trending {hourlyTrend} over the past 3 months.</div>
            <div>
              Annualized Salaried Turnover Rates are{' '}
              {toPercentString(
                corporateCurrentAnnualizedSalariedTurnoverPercent,
              )}
            </div>
            <div>This is trending {salariedTrend} over the past 3 months.</div>
          </div>
        ),
        insight: (
          <div>
            <div>The company is currently losing:</div>
            <div>
              {corporateThirtyDayTurnoverRate} of employees within 30 days of
              hire
            </div>
            <div>
              {corporateSixtyDayTurnoverRate} of employees within 60 days of
              hire
            </div>
            <div>
              {corporateNinetyDayTurnoverRate} of employees within 90 days of
              hire
            </div>
          </div>
        ),
      }
    case 'corporate_guest':
      const { avgAcrScore, nationalAvgAcrScore } = data?.data[0]
      const summary = (data?.data[0]?.reviews ?? []).reduce(
        (result, d: ICorporateGuestSummaryDataType) => ({
          ...result,
          [d.primaryProblem]:
            (result[d.primaryProblem] || 0) + parseInt(d.count),
        }),
        {} as Record<string, number>,
      )

      return {
        conclusion: (
          <div>
            <div>For the Trailing {days} Days,</div>
            <div>
              {config.guestScoreLabel} Score Average is {avgAcrScore}
              {config.hideComparison
                ? null
                : `, which is ${
                    avgAcrScore > nationalAvgAcrScore ? 'worse' : 'better'
                  } than
              Burger King Brand ACR of ${nationalAvgAcrScore}`}
            </div>
          </div>
        ),
        insight: (
          <div>
            {Object.entries(summary)
              .sort((a, b) => b[1] - a[1])
              .slice(0, 3)
              .map(([key, value]) => (
                <div key={key}>
                  [{value} of Reviews] {key}
                </div>
              ))}
          </div>
        ),
      }

    case 'corporate_voids_summary':
      const voidingMoreThanTenPercent = data.data.filter(
        (d) => d.voidsCheckPercent > 10.0,
      )
      const voidedChecksOver = data.data.reduce((result, l) => {
        const voidsDetailsOver =
          l.voidsDetails?.filter((v) => v.voidAmount > 7500) || []

        return voidsDetailsOver.length > 0
          ? [...result, { ...l, voidsDetails: voidsDetailsOver }]
          : result
      }, [] as ICorporateVoidsSummaryDataType[])

      return {
        textOnly: `
        For the Trailing ${days} days, ${
          voidingMoreThanTenPercent.length || 0
        } stores are voiding more than 10% of their checks and ${
          voidedChecksOver.length || 0
        } stores have voided checks over $75.
        `,
      }

    default:
      return {}
  }
}

export default useSummaryContent
