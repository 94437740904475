import * as d3 from 'd3-scale'
import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'
import ExpoAiLogo from 'pared/images/Expo/expoai.png'
import { toUsdStr } from 'pared/utils/number'

import Category, { IDetailConfigsType } from './Category'
import useApi from './hooks/useApi'
import { ISummaryFieldType } from './types'

export interface IPropsType {
  type: 'ai-summary'
  total?: string
  fields: (
    | ISummaryFieldType
    | {
        type: ISummaryFieldType
        details: IDetailConfigsType[]
      }
  )[]
  background?: {
    range?: number[]
    colors?: string[]
    value?: string
  }
}

export type IConfigsType = IPropsType

const MainContainer = styled.div`
  border: 1px solid black;
  border-radius: 8px;
  overflow: hidden;
  width: 900px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const Bold = styled.div`
  font-family: Lexend-Semibold;
  font-size: 16px;
  margin-bottom: 20px;
`

const ImageContainer = styled.div`
  margin-bottom: 30px;
`

const BACKGROUND_COLORS = ['#FE0505', '#FF5C5D', '#DAA8A8', '#474646', 'BLACK']

const AiSummary = ({
  total = 'Total Opportunities: <%- total %> Annually',
  fields,
  background,
}: IPropsType) => {
  const types = useMemo(
    () =>
      fields.map((field) => (typeof field === 'string' ? field : field.type)),
    [fields],
  )
  const { data, loading } = useApi(types)
  const allFieldsTotal = useMemo(
    () => (data || []).reduce((sum, d) => sum + (d?.total || 0), 0),
    [data],
  )
  const sortedData = useMemo(
    () =>
      data
        ?.sort((a, b) => (b.total ?? -1) - (a.total ?? -1))
        .map((d) => {
          const field = fields.find((f) =>
            typeof f === 'string' ? false : d.type === f.type,
          )

          return {
            ...d,
            details: !field || typeof field === 'string' ? null : field.details,
          }
        }),
    [fields, data],
  )
  const color = useMemo(
    () =>
      d3.scaleLinear(
        background?.range || [0, 1, 2, 3],
        background?.colors || BACKGROUND_COLORS,
      ),
    [background],
  )
  const getValue = background?.value || '<%- index %>'

  return loading ? (
    <div>Loading ...</div>
  ) : (
    <>
      <ImageContainer>
        <img height={40} src={ExpoAiLogo} alt="Expo AI" />
      </ImageContainer>
      <Bold>{_.template(total)({ total: toUsdStr(allFieldsTotal) })}</Bold>
      <MainContainer>
        {sortedData?.map(({ type, total, ...data }, index) => (
          <Category
            {...data}
            total={total}
            key={type}
            backgroundColor={((backgroundColor) =>
              !total ? 'black' : backgroundColor)(
              color(
                parseInt(
                  _.template(getValue)({
                    index,
                    total,
                  }),
                ),
              ),
            )}
          />
        ))}
      </MainContainer>
    </>
  )
}

export default AiSummary
