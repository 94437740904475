import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect } from 'react'

import {
  GROUP_BY_DAY,
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  IDateRange,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'

import { GET_LOCATION_VS_SYSTEMWIDE_DELIVERY_KPI_WEEKLY } from '../gql'
import Main from './Main'

interface IProps {
  startDate: string
  endDate: string
  dateRangeType: IDateRange['type']
  locationId: number
  locationName: string
}

const DATE_DISPLAY_FORMAT = 'M/D/YY'

function Chart({
  startDate,
  endDate,
  dateRangeType,
  locationId,
  locationName,
}: IProps) {
  const [
    listKpi,
    { loading: listKpiLoading, error: listKpiError, data: listKpiData },
  ] = useLazyQuery(GET_LOCATION_VS_SYSTEMWIDE_DELIVERY_KPI_WEEKLY)

  useEffect(() => {
    let groupBy = ''
    if (dateRangeType === TYPE_YEAR) {
      groupBy = GROUP_BY_QUARTER
    } else if (dateRangeType === TYPE_QUARTER) {
      groupBy = GROUP_BY_PERIOD
    } else if (
      dateRangeType === TYPE_PERIOD ||
      dateRangeType === TYPE_TRAILING_90_DAYS
    ) {
      groupBy = GROUP_BY_WEEK
    } else {
      groupBy = GROUP_BY_DAY
    }

    listKpi({
      variables: {
        iLocationId: locationId,
        iStartDate: startDate,
        iEndDate: endDate,
        iGroupBy: groupBy,
      },
    })
  }, [locationId, startDate, endDate])

  let rawKpiWeekly = []

  if (
    listKpiData &&
    listKpiData.listLocationVsSystemwideDeliveryKpiV2 &&
    Array.isArray(listKpiData.listLocationVsSystemwideDeliveryKpiV2.nodes)
  ) {
    rawKpiWeekly = _.get(
      listKpiData,
      'listLocationVsSystemwideDeliveryKpiV2.nodes',
      [],
    )
  }

  const xAxisData = _.map(
    rawKpiWeekly,
    (k) =>
      ({
        [TYPE_YEAR]: `Q${k.quarter} ${k.year}`,
        [TYPE_QUARTER]: `P${k.period} ${k.year}`,
        [TYPE_PERIOD]: `P${k.period} W${k.weekOfPeriod} ${k.year}`,
        [TYPE_WEEK]: `${k.groupByStartDate}`,
        [TYPE_LAST_WEEK]: `${k.groupByStartDate}`,
        [TYPE_THIS_WEEK]: `${k.groupByStartDate}`,
        [TYPE_YESTERDAY]: `${k.groupByStartDate}`,
        [TYPE_TRAILING_7_DAYS]: `${k.groupByStartDate}`,
        [TYPE_TRAILING_90_DAYS]: `P${k.period} W${k.weekOfPeriod} ${k.year}`,
      }[dateRangeType || TYPE_PERIOD]),
  )

  const yAxisLocationData = _.map(rawKpiWeekly, (k) => {
    return _.get(k, 'locationCorrectOrderPercent', 0) / 100
  })

  const yAxisSystemwideData = _.map(rawKpiWeekly, (k) => {
    return _.get(k, 'systemwideCorrectOrderPercent', 0) / 100
  })

  return (
    <Main
      xAxisData={xAxisData}
      yAxisLocationData={yAxisLocationData}
      yAxisSystemwideData={yAxisSystemwideData}
      locationName={locationName}
    />
  )
}

export default Chart
