import { Moment } from 'moment'
import Calendar from 'rc-calendar'
import 'rc-calendar/assets/index.css'
import DatePicker from 'rc-calendar/lib/Picker'
import React, { useRef } from 'react'
import styled from 'styled-components'

import { DATE_FORMAT } from 'pared/data/getDateRanges'

interface IProps {
  onChange: (value: Moment) => void
  value: Moment
  disabledDate: (value: Moment | undefined) => boolean
}

const StyledInput = styled.input`
  width: 100%;
  font-family: Lexend-Regular;
  font-size: 16px;
  cursor: pointer;
  height: 56px;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 4px;
  text-align: center;

  &:hover {
    border: 1px solid rgb(33, 33, 33);
  }
`

const Picker = ({ onChange, value, disabledDate }: IProps) => {
  const calendar = <Calendar disabledDate={disabledDate} />
  const calendarContainerRef = useRef(null)
  const getCalendarContainer = () => calendarContainerRef.current

  return (
    <DatePicker
      animation="slide-up"
      calendar={calendar}
      value={value}
      onChange={onChange}
      getCalendarContainer={getCalendarContainer}
    >
      {({ value }: any) => {
        return (
          <span>
            <StyledInput
              placeholder="Please select a date"
              readOnly
              value={(value && value.format(DATE_FORMAT)) || ''}
            />
            <div ref={calendarContainerRef} />
          </span>
        )
      }}
    </DatePicker>
  )
}

export default React.memo(Picker)
