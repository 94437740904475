import { gql } from '@apollo/client'

// FIXME: this should be generated by apollo
export interface IPerformanceChartFragmentType {
  itemName: string
  category: string
  popularity: number
  portionContributionMargin: number
  totalContributionMargin: string
}

export const performanceChartFragment = gql`
  fragment performanceChartFragment on ListLocationGroupMenuItemsRecord {
    itemName
    category
    popularity
    portionContributionMargin
    totalContributionMargin
  }
`
