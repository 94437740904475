import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { IDirector } from 'pared/data/getDirectors'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { INullableType } from 'pared/types'
import { getBrand } from 'pared/utils/brand'

import {
  GET_STORE_LIST,
  ISalesExaminerVariableTypes,
  LIST_LOCATION_SALES_EXAMINER_DATA,
} from '../gqls/getSalesExaminerData'

const useDataSource = (
  isBreakdownByDirectors: boolean,
  directors: IDirector[],
  locationGroupIds: number[],
  revenueCenter: string,
  dayPart: string,
) => {
  const brand = getBrand()
  const dateRange = useGetDateRange()
  const variables: INullableType<ISalesExaminerVariableTypes> = {
    iStartDate: dateRange?.startDateStr,
    iEndDate: dateRange?.endDateStr,
    iFilter: {
      location_group_ids: locationGroupIds,
    },
    iRevenueCenter: revenueCenter,
    iDayPart: dayPart,
    isBreakdownByDirectors,
  }

  const { data, loading: salesDataLoading } = useQuery(
    LIST_LOCATION_SALES_EXAMINER_DATA,
    {
      variables,
      skip:
        !variables?.iRevenueCenter ||
        !variables?.iDayPart ||
        !variables?.iFilter ||
        !variables?.iStartDate ||
        !variables?.iEndDate,
    },
  )

  const { data: storeList, loading: storeListLoading } = useQuery(
    GET_STORE_LIST,
    {
      variables: {
        iFilter: {
          location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
        },
      },
    },
  )

  const loading = salesDataLoading || storeListLoading

  const listSalesExaminerData =
    data?.listLocationSalesExaminerData ||
    data?.listLocationGroupSalesExaminerData

  const dataSource = useMemo(() => {
    if (!listSalesExaminerData?.nodes) return []

    return listSalesExaminerData.nodes.map((node: any) => {
      const store = storeList?.listLocationDetails?.nodes.find(
        ({ id }: any) => node?.locationId === id,
      )
      const director = directors.find(
        ({ locationGroupId }) => node.locationGroupId === locationGroupId,
      )

      if (store)
        return {
          ...node,
          storeId: store.id,
          storeCode: store.code,
          storeName: store.name,
          directorId: store.directorEmployeeInfo?.id || 0,
          directorName: [
            store.directorEmployeeInfo?.preferredName,
            store.directorEmployeeInfo?.familyName,
          ]
            .filter(Boolean)
            .join(' '),
        }

      if (director)
        return {
          ...node,
          directorId: director.employeeId,
          directorName: [director.firstName, director.lastName]
            .filter(Boolean)
            .join(' '),
        }

      return node
    })
  }, [listSalesExaminerData, directors, storeList])
  return {
    loading,
    dataSource,
  }
}

export default useDataSource
