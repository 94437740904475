import { IConfigsType } from '../../types'

const fwWingstopFlashWeeklyLaborTracker: IConfigsType = {
  fw_wingstop: {
    '/:brand/weekly_labor_tracker': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['week', 'this_week'],
        defaultType: 'this_week',
      },
      reportTitle: {
        type: 'title',
        title: 'Weekly Labor Tracker',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'farwestCustomizedFlash',
        fileName: 'getexpo-far-west-weekly-labor-tracker-part1',
        fileNameFn: (date: string) =>
          `getexpo-far-west-weekly-labor-tracker-part1-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'market',
            title: 'Market',
          },
          {
            key: 'netSalesWtd',
            title: 'Sales WTD',
          },
          {
            key: 'comparableNetSalesWtdLy',
            title: 'PY WTD Sales',
          },
          {
            key: 'netSalesWtdPercentageDiffToLastYear',
            title: 'Sales WTD Actual Comp',
          },
          {
            key: 'gmMatrixSales',
            title: 'GM Matrix $',
          },
          {
            key: 'amMatrixSales',
            title: 'ASM Matrix $',
          },
          {
            key: 'shiftMatrixSales',
            title: 'SL Matrix $',
          },
          {
            key: 'cashierMatrixSales',
            title: 'Cashier Matrix $',
          },
          {
            key: 'cookMatrixSales',
            title: 'Cook Matrix $',
          },
          {
            key: 'totalMatrixSales',
            title: 'Total Matrix $',
          },
          {
            key: 'gmMatrixSalesPercentage',
            title: 'GM Matrix $ Percentage',
          },
          {
            key: 'amMatrixSalesPercentage',
            title: 'ASM Matrix $ Percentage',
          },
          {
            key: 'shiftMatrixSalesPercentage',
            title: 'SL Matrix $ Percentage',
          },
          {
            key: 'cashierMatrixSalesPercentage',
            title: 'Cashier Matrix $ Percentage',
          },
          {
            key: 'cookMatrixSalesPercentage',
            title: 'Cook Matrix $ Percentage',
          },
          {
            key: 'totalMatrixSalesPercentage',
            title: 'Total Matrix $ Percentage',
          },
        ],
      },
      flashTablePart1: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'market',
            title: 'Market',
            sortable: true,
          },
          {
            key: 'sales',
            title: 'Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'netSalesWtd',
                title: 'Sales WTD',
                highlight: true,
                sortable: true,
              },
              {
                key: 'comparableNetSalesWtdLy',
                title: 'PY WTD Sales',
                sortable: true,
              },
              {
                key: 'netSalesWtdPercentageDiffToLastYear',
                title: 'Sales WTD Actual Comp',
                highlight: true,
                sortable: true,
              },
            ],
          },
          {
            key: 'matrixSales',
            title: 'Matrix $',
            align: 'center',
            children: [
              {
                key: 'gmMatrixSales',
                title: 'GM',
                sortable: true,
              },
              {
                key: 'amMatrixSales',
                title: 'ASM',
                sortable: true,
              },
              {
                key: 'shiftMatrixSales',
                title: 'SL',
                sortable: true,
              },
              {
                key: 'cashierMatrixSales',
                title: 'Cashier',
                sortable: true,
              },
              {
                key: 'cookMatrixSales',
                title: 'Cook',
                sortable: true,
              },
              {
                key: 'totalMatrixSales',
                title: 'Total',
                sortable: true,
              },
            ],
          },
          {
            key: 'matrixSalesPercentage',
            title: 'Matrix%',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'gmMatrixSalesPercentage',
                title: 'GM',
                sortable: true,
              },
              {
                key: 'amMatrixSalesPercentage',
                title: 'ASM',
                sortable: true,
              },
              {
                key: 'shiftMatrixSalesPercentage',
                title: 'SL',
                sortable: true,
              },
              {
                key: 'cashierMatrixSalesPercentage',
                title: 'Cashier',
                sortable: true,
              },
              {
                key: 'cookMatrixSalesPercentage',
                title: 'Cook',
                sortable: true,
              },
              {
                key: 'totalMatrixSalesPercentage',
                title: 'Total',
                sortable: true,
              },
            ],
          },
        ],
      },
      downloadReportPart2: {
        type: 'download-csv',
        api: 'farwestCustomizedFlash',
        fileName: 'getexpo-far-west-weekly-labor-tracker-part2',
        fileNameFn: (date: string) =>
          `getexpo-far-west-weekly-labor-tracker-part2-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'market',
            title: 'Market',
          },
          {
            key: 'gmTotalWage',
            title: 'GM Total Wage',
          },
          {
            key: 'amTotalWage',
            title: 'ASM Total Wage',
          },
          {
            key: 'shiftTotalWage',
            title: 'SL Total Wage',
          },
          {
            key: 'cashierTotalWage',
            title: 'Cashier Total Wage',
          },
          {
            key: 'cookTotalWage',
            title: 'Cook Total Wage',
          },
          {
            key: 'totalWage',
            title: 'Total Wage',
          },
          {
            key: 'gmTotalWagePercentage',
            title: 'GM Total Wage Percentage',
          },
          {
            key: 'amTotalWagePercentage',
            title: 'ASM Total Wage Percentage',
          },
          {
            key: 'shiftTotalWagePercentage',
            title: 'SL Total Wage Percentage',
          },
          {
            key: 'cashierTotalWagePercentage',
            title: 'Cashier Total Wage Percentage',
          },
          {
            key: 'cookTotalWagePercentage',
            title: 'Cook Total Wage Percentage',
          },
          {
            key: 'totalWagePercentage',
            title: 'Total Wage Percentage',
          },
        ],
      },
      flashTablePart2: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'market',
            title: 'Market',
            sortable: true,
          },
          {
            key: 'totalCost',
            title: 'Total Cost',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'gmTotalWage',
                title: 'GM',
                sortable: true,
              },
              {
                key: 'amTotalWage',
                title: 'ASM',
                sortable: true,
              },
              {
                key: 'shiftTotalWage',
                title: 'SL',
                sortable: true,
              },
              {
                key: 'cashierTotalWage',
                title: 'Cashier',
                sortable: true,
              },
              {
                key: 'cookTotalWage',
                title: 'Cook',
                sortable: true,
              },
              {
                key: 'totalWage',
                title: 'Total',
                sortable: true,
              },
            ],
          },
          {
            key: 'totalPercentage',
            title: 'Total %',
            align: 'center',
            children: [
              {
                key: 'gmTotalWagePercentage',
                title: 'GM',
                sortable: true,
              },
              {
                key: 'amTotalWagePercentage',
                title: 'ASM',
                sortable: true,
              },
              {
                key: 'shiftTotalWagePercentage',
                title: 'SL',
                sortable: true,
              },
              {
                key: 'cashierTotalWagePercentage',
                title: 'Cashier',
                sortable: true,
              },
              {
                key: 'cookTotalWagePercentage',
                title: 'Cook',
                sortable: true,
              },
              {
                key: 'totalWagePercentage',
                title: 'Total',
                sortable: true,
              },
            ],
          },
        ],
      },
      downloadReportPart3: {
        type: 'download-csv',
        api: 'farwestCustomizedFlash',
        fileName: 'getexpo-far-west-weekly-labor-tracker-part3',
        fileNameFn: (date: string) =>
          `getexpo-far-west-weekly-labor-tracker-part3-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'market',
            title: 'Market',
          },
          {
            key: 'gmMatrixHours',
            title: 'GM Matrix Hours',
          },
          {
            key: 'amMatrixHours',
            title: 'ASM Matrix Hours',
          },
          {
            key: 'shiftMatrixHours',
            title: 'SL Matrix Hours',
          },
          {
            key: 'cashierMatrixHours',
            title: 'Cashier Matrix Hours',
          },
          {
            key: 'cookMatrixHours',
            title: 'Cook Matrix Hours',
          },
          {
            key: 'totalMatrixHours',
            title: 'Total Matrix Hours',
          },
          {
            key: 'gmLaborActualHours',
            title: 'GM Regular Hours',
          },
          {
            key: 'amLaborActualHours',
            title: 'ASM Regular Hours',
          },
          {
            key: 'shiftLaborActualHours',
            title: 'SL Regular Hours',
          },
          {
            key: 'cashierLaborActualHours',
            title: 'Cashier Regular Hours',
          },
          {
            key: 'cookLaborActualHours',
            title: 'Cook Regular Hours',
          },
          {
            key: 'laborActualHours',
            title: 'Total Regular Hours',
          },
          {
            key: 'gmLaborOtHours',
            title: 'GM OT Hours',
          },
          {
            key: 'amLaborOtHours',
            title: 'ASM OT Hours',
          },
          {
            key: 'shiftLaborOtHours',
            title: 'SL OT Hours',
          },
          {
            key: 'cashierLaborOtHours',
            title: 'Cashier OT Hours',
          },
          {
            key: 'cookLaborOtHours',
            title: 'Cook OT Hours',
          },
          {
            key: 'otActualHours',
            title: 'Total OT Hours',
          },
        ],
      },
      flashTablePart3: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'matrixHours',
            title: 'Matrix Hours',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'market',
                title: 'Market',
                sortable: true,
              },
              {
                key: 'gmMatrixHours',
                title: 'GM',
                sortable: true,
              },
              {
                key: 'amMatrixHours',
                title: 'ASM',
                sortable: true,
              },
              {
                key: 'shiftMatrixHours',
                title: 'SL',
                sortable: true,
              },
              {
                key: 'cashierMatrixHours',
                title: 'Cashier',
                sortable: true,
              },
              {
                key: 'cookMatrixHours',
                title: 'Cook',
                sortable: true,
              },
              {
                key: 'totalMatrixHours',
                title: 'Total',
                sortable: true,
              },
            ],
          },
          {
            key: 'regularHours',
            title: 'Regular Hours',
            align: 'center',
            children: [
              {
                key: 'gmLaborActualHours',
                title: 'GM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'amLaborActualHours',
                title: 'ASM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'shiftLaborActualHours',
                title: 'SL',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cashierLaborActualHours',
                title: 'Cashier',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cookLaborActualHours',
                title: 'Cook',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'laborActualHours',
                title: 'Total',
                decimal: 1,
                sortable: true,
              },
            ],
          },
          {
            key: 'otHours',
            title: 'OT Hours',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'gmLaborOtHours',
                title: 'GM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'amLaborOtHours',
                title: 'ASM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'shiftLaborOtHours',
                title: 'SL',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cashierLaborOtHours',
                title: 'Cashier',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cookLaborOtHours',
                title: 'Cook',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'otActualHours',
                title: 'Total',
                decimal: 1,
                sortable: true,
              },
            ],
          },
        ],
      },
      downloadReportPart4: {
        type: 'download-csv',
        api: 'farwestCustomizedFlash',
        fileName: 'getexpo-far-west-weekly-labor-tracker-part4',
        fileNameFn: (date: string) =>
          `getexpo-far-west-weekly-labor-tracker-part4-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'market',
            title: 'Market',
          },
          {
            key: 'gmLaborTotalHours',
            title: 'GM Total Hours',
          },
          {
            key: 'amLaborTotalHours',
            title: 'ASM Total Hours',
          },
          {
            key: 'shiftLaborTotalHours',
            title: 'SL Total Hours',
          },
          {
            key: 'cashierLaborTotalHours',
            title: 'Cashier Total Hours',
          },
          {
            key: 'cookLaborTotalHours',
            title: 'Cook Total Hours',
          },
          {
            key: 'laborTotalHours',
            title: 'Total Hours',
          },
          {
            key: 'gmLaborHoursVariance',
            title: 'GM Hours Variance',
          },
          {
            key: 'amLaborHoursVariance',
            title: 'ASM Hours Variance',
          },
          {
            key: 'shiftLaborHoursVariance',
            title: 'SL Hours Variance',
          },
          {
            key: 'cashierLaborHoursVariance',
            title: 'Cashier Hours Variance',
          },
          {
            key: 'cookLaborHoursVariance',
            title: 'Cook Hours Variance',
          },
          {
            key: 'laborHoursVariance',
            title: 'Total Hours Variance',
          },
        ],
      },
      flashTablePart4: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'market',
            title: 'Market',
            sortable: true,
          },
          {
            key: 'totalHours',
            title: 'Total Hours',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'gmLaborTotalHours',
                title: 'GM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'amLaborTotalHours',
                title: 'ASM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'shiftLaborTotalHours',
                title: 'SL',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cashierLaborTotalHours',
                title: 'Cashier',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cookLaborTotalHours',
                title: 'Cook',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'laborTotalHours',
                title: 'Total',
                decimal: 1,
                sortable: true,
              },
            ],
          },
          {
            key: 'hoursVar',
            title: 'Variance Hours',
            align: 'center',
            children: [
              {
                key: 'gmLaborHoursVariance',
                title: 'GM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'amLaborHoursVariance',
                title: 'ASM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'shiftLaborHoursVariance',
                title: 'SL',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cashierLaborHoursVariance',
                title: 'Cashier',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cookLaborHoursVariance',
                title: 'Cook',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'laborHoursVariance',
                title: 'Total',
                decimal: 1,
                sortable: true,
              },
            ],
          },
        ],
      },
      downloadLaborTrainingReport: {
        type: 'download-csv',
        api: 'farwestCustomizedFlash',
        fileName: 'getexpo-far-west-weekly-labor-tracker-training-report',
        fileNameFn: (date: string) =>
          `'getexpo-far-west-weekly-labor-tracker-training-report-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'market',
            title: 'Market',
          },
          {
            key: 'gmTrainingHours',
            title: 'GM Training Hours',
          },
          {
            key: 'amTrainingHours',
            title: 'ASM Training Hours',
          },
          {
            key: 'shiftLeaderTrainingHours',
            title: 'SL Training Hours',
          },
          {
            key: 'cashierTrainingHours',
            title: 'Cashier Training Hours',
          },
          {
            key: 'cookTrainingHours',
            title: 'Cook Training Hours',
          },
          {
            key: 'totalTrainingHours',
            title: 'Total Training Hours',
          },
          {
            key: 'gmTrainingCost',
            title: 'GM Training Cost',
          },
          {
            key: 'amTrainingCost',
            title: 'ASM Training Cost',
          },
          {
            key: 'shiftLeaderTrainingCost',
            title: 'SL Training Cost',
          },
          {
            key: 'cashierTrainingCost',
            title: 'Cashier Training Cost',
          },
          {
            key: 'cookTrainingCost',
            title: 'Cook Training Cost',
          },
          {
            key: 'totalTrainingCost',
            title: 'Total Training Cost',
          },
          {
            key: 'gmTrainingCostPercent',
            title: 'GM Training %',
          },
          {
            key: 'amTrainingCostPercent',
            title: 'ASM Training % ',
          },
          {
            key: 'shiftLeaderTrainingCostPercent',
            title: 'SL Training %',
          },
          {
            key: 'cashierTrainingCostPercent',
            title: 'Cashier Training %',
          },
          {
            key: 'cookTrainingCostPercent',
            title: 'Cook Training %',
          },
          {
            key: 'totalTrainingCostPercent',
            title: 'Total Training %',
          },
        ],
      },
      laborTrainingTable: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'market',
            title: 'Market',
            sortable: true,
          },
          {
            key: 'trainingHours',
            title: 'Training Hours',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'gmTrainingHours',
                title: 'GM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'amTrainingHours',
                title: 'ASM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'shiftLeaderTrainingHours',
                title: 'SL',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cashierTrainingHours',
                title: 'Cashier',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cookTrainingHours',
                title: 'Cook',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'totalTrainingHours',
                title: 'Total',
                decimal: 1,
                sortable: true,
              },
            ],
          },
          {
            key: 'trainingCost',
            title: 'Training Cost',
            align: 'center',
            children: [
              {
                key: 'gmTrainingCost',
                title: 'GM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'amTrainingCost',
                title: 'ASM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'shiftLeaderTrainingCost',
                title: 'SL',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cashierTrainingCost',
                title: 'Cashier',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cookTrainingCost',
                title: 'Cook',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'totalTrainingCost',
                title: 'Total',
                decimal: 1,
                sortable: true,
              },
            ],
          },
          {
            key: 'trainingCostPercent',
            title: 'Training %',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'gmTrainingCostPercent',
                title: 'GM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'amTrainingCostPercent',
                title: 'ASM',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'shiftLeaderTrainingCostPercent',
                title: 'SL',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cashierTrainingCostPercent',
                title: 'Cashier',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'cookTrainingCostPercent',
                title: 'Cook',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'totalTrainingCostPercent',
                title: 'Total',
                decimal: 1,
                sortable: true,
              },
            ],
          },
        ],
      },
      downloadDailysplh: {
        type: 'download-csv',
        api: 'farwestTrendDailySplh',
        fileName: 'getexpo-far-west-weekly-labor-tracker-daily-splh',
        fileNameFn: (date: string) =>
          `getexpo-far-west-weekly-labor-tracker-daily-splh-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'sun',
            title: 'Sunday',
          },
          {
            key: 'mon',
            title: 'Monday',
          },
          {
            key: 'tue',
            title: 'Tuesday',
          },
          {
            key: 'wed',
            title: 'Wednesday',
          },
          {
            key: 'thu',
            title: 'Thursday',
          },
          {
            key: 'fri',
            title: 'Friday',
          },
          {
            key: 'sat',
            title: 'Saturday',
          },
        ],
      },
      dailysplh: {
        type: 'table',
        api: 'farwestTrendDailySplh',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'dailySplh',
            title: 'Daily SPLH',
            align: 'center',
            children: [
              {
                key: 'sun',
                title: 'Sunday',
                decimal: 2,
                highlight: true,
                sortable: true,
              },
              {
                key: 'mon',
                title: 'Monday',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'tue',
                title: 'Tuesday',
                decimal: 2,
                highlight: true,
                sortable: true,
              },
              {
                key: 'wed',
                title: 'Wednesday',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'thu',
                title: 'Thursday',
                decimal: 2,
                highlight: true,
                sortable: true,
              },
              {
                key: 'fri',
                title: 'Friday',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'sat',
                title: 'Saturday',
                decimal: 2,
                highlight: true,
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashWeeklyLaborTracker
