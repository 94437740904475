import { IConfigsType } from '../../types'

const lfrConfig: IConfigsType = {
  lfr: {
    '/:brand/business-review': {
      groupFilter: {
        api: 'lfrGroupFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'trailing_12_months'],
      },
      downloadAllTables: {
        type: 'download-csv',
        api: 'lfrBusinessReviewAndGuest',
        fileName: 'All',
        message: 'Download All Tables as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'grossSales',
            title: 'Gross Sales Actual ($)',
          },
          {
            key: 'grossSalesBudget',
            title: 'Gross Sales Target ($)',
          },
          {
            key: 'grossSalesBudgetVariance',
            title: 'Gross Sales Variance (%)',
          },
          {
            key: 'ytdGrossSales',
            title: 'Gross Sales YTD ($)',
          },
          {
            key: 'grossSalesSssPercent',
            title: 'Gross Sales YOY (%)',
          },
          {
            key: 'covers',
            title: 'Guest Count Actual',
          },
          {
            key: 'coversTarget',
            title: 'Guest Count Target',
          },
          {
            key: 'coversVariancePercent',
            title: 'Guest Count Variance (%)',
          },
          {
            key: 'coversSssPercent',
            title: 'Guest Count YOY (%)',
          },
          {
            key: 'ppa',
            title: 'Guest Count PPA',
          },
          {
            key: 'revPsf',
            title: 'Rev Psf',
          },
          {
            key: 'cplh',
            title: 'Guest Count CPLH',
          },
          {
            key: 'foodSalesPercent',
            title: 'Sales Category Food Sales (%)',
          },
          {
            key: 'barSalesPercent',
            title: 'Sales Category Bar Sales (%)',
          },
          {
            key: 'retailSalesPercent',
            title: 'Sales Category Retail Sales (%)',
          },
          {
            key: 'otherSalesPercent',
            title: 'Sales Category Other Sales (%)',
          },
          {
            key: 'lunchDaypart',
            title: 'Day Part Lunch (%)',
          },
          {
            key: 'dinnerDaypart',
            title: 'Day Part Dinner (%)',
          },
          {
            key: 'patioReventueCenter',
            title: 'Revenue Center Patio (%)',
          },
          {
            key: 'foodCost',
            title: 'Food Cost Actual (%)',
          },
          {
            key: 'foodCostTarget',
            title: 'Food Cost Target (%)',
          },
          {
            key: 'foodCostVariance',
            title: 'Food Cost Variance (%)',
          },
          {
            key: 'barCost',
            title: 'Bar Cost Actual (%)',
          },
          {
            key: 'barCostTarget',
            title: 'Bar Cost Target (%)',
          },
          {
            key: 'barCostVariance',
            title: 'Bar Cost Variance (%)',
          },
          {
            key: 'retailCost',
            title: 'Retail Cost Actual (%)',
          },
          {
            key: 'retailCostTarget',
            title: 'Retail Cost Target (%)',
          },
          {
            key: 'retailCostVariance',
            title: 'Retail Cost Variance (%)',
          },
          {
            key: 'fohLabor',
            title: 'FOH Labor Actual (%)',
          },
          {
            key: 'fohLaborTarget',
            title: 'FOH Labor Target (%)',
          },
          {
            key: 'fohLaborVariance',
            title: 'FOH Labor Variance (%)',
          },
          {
            key: 'bohLabor',
            title: 'BOH Labor Actual (%)',
          },
          {
            key: 'bohLaborTarget',
            title: 'BOH Labor Target (%)',
          },
          {
            key: 'bohLaborVariance',
            title: 'BOH Labor Variance (%)',
          },
          {
            key: 'fohOt',
            title: 'OT & Management FOH - OT ($)',
          },
          {
            key: 'bohOt',
            title: 'OT & Management BOH - OT ($)',
          },
          {
            key: 'keyEeHourly',
            title: 'OT & Management KEY EE - Hourly (%)',
          },
          {
            key: 'mgmtSalary',
            title: 'OT & Management Mgmt Salary (%)',
          },
          {
            key: 'primeCostsPercent',
            title: 'Prime Cost Actual (%)',
          },
          {
            key: 'primeCostsTarget',
            title: 'Prime Cost Target (%)',
          },
          {
            key: 'primeCostsVariance',
            title: 'Prime Cost Variance (%)',
          },
          {
            key: 'cogsPercent',
            title: 'Prime Cost Total COGS (%)',
          },
          {
            key: 'laborPercent',
            title: 'Prime Cost Total Labor (%)',
          },
          {
            key: 'rcp',
            title: 'EBITDA Actual ($)',
          },
          {
            key: 'rcpPercent',
            title: 'EBITDA (%)',
          },
          {
            key: 'rcpBudgetVariance',
            title: 'EBITDA Variance (%)',
          },
          {
            key: 'paidOuts',
            title: 'Cash Controls Paid Outs ($)',
          },
          {
            key: 'supplies',
            title: 'Cash Controls Supplies ($)',
          },
          {
            key: 'voids',
            title: 'Cash Controls Voids',
          },
          {
            key: 'controllableComps',
            title: 'Total Comps Controllable Comps ($)',
          },
          {
            key: 'controllableCompsPercent',
            title: 'Total Comps Controllable Comps (%)',
          },
          {
            key: 'eeComps',
            title: 'Total Comps EE Comps ($)',
          },
          {
            key: 'corpComps',
            title: 'Total Comps Corp Comps ($)',
          },
          {
            key: 'marketingComps',
            title: 'Total Comps Marketing Comps ($)',
          },
          {
            key: 'ratings30.yelp',
            title: 'Guest Rating (Last 30 Days) Yelp',
          },
          {
            key: 'ratings30.google',
            title: 'Guest Rating (Last 30 Days) Google',
          },
          {
            key: 'ratings30.tripadvisor',
            title: 'Guest Rating (Last 30 Days) Trip Advisor',
          },
          {
            key: 'dayAvg30',
            title: 'Guest Rating (Last 30 Days) 30 Day Avg',
          },
          {
            key: 'ratings90.yelp',
            title: 'Guest Rating (Last 90 Days) Yelp',
          },
          {
            key: 'ratings90.google',
            title: 'Guest Rating (Last 90 Days) Google',
          },
          {
            key: 'ratings90.tripadvisor',
            title: 'Guest Rating (Last 90 Days) Trip Advisor',
          },
          {
            key: 'dayAvg90',
            title: 'Guest Rating (Last 90 Days) 90 Day Avg',
          },
          {
            key: 'pea',
            title: 'PEA Actual',
          },
          {
            key: 'peaVariancePercent',
            title: 'PEA Variance (%)',
          },
          {
            key: 'peaSssPercent',
            title: 'PEA YOY (%)',
          },
          {
            key: 'pta',
            title: 'PTA Actual',
          },
          {
            key: 'ptaVariancePercent',
            title: 'PTA Variance (%)',
          },
          {
            key: 'ptaSssPercent',
            title: 'PTA YOY (%)',
          },
          {
            key: 'ppa',
            title: 'PPA Actual',
          },
          {
            key: 'ppaVariancePercent',
            title: 'PPA Variance (%)',
          },
          {
            key: 'ppaSssPercent',
            title: 'PPA YOY (%)',
          },
          {
            key: 'entreeCount',
            title: 'Entree Actual',
          },
          {
            key: 'entreeCountVariancePercent',
            title: 'Entree Variance (%)',
          },
          {
            key: 'entreeCountSssPercent',
            title: 'Entree YOY (%)',
          },
          {
            key: 'transactionCount',
            title: 'Transaction Actual',
          },
          {
            key: 'transactionCountVariancePercent',
            title: 'Transaction Variance (%)',
          },
          {
            key: 'transactionCountSssPercent',
            title: 'Transaction YOY (%)',
          },
          {
            key: 'covers',
            title: 'Covers Actual TY',
          },
          {
            key: 'coversTarget',
            title: 'Covers Target TY ',
          },
          {
            key: 'yoyCovers',
            title: 'Covers Actual PY',
          },
          {
            key: 'coversVariancePercent',
            title: 'Covers Variance (%)',
          },
          {
            key: 'coversSssPercent',
            title: 'Covers YOY (%)',
          },
        ],
      },
      part1DownloadCsv: {
        type: 'download-csv',
        api: 'lfrListLocationBusinessReview',
        fileName: 'Part1',
        message: 'Download Table as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'grossSales',
            title: 'Gross Sales Actual ($)',
          },
          {
            key: 'grossSalesBudget',
            title: 'Gross Sales Target ($)',
          },
          {
            key: 'grossSalesBudgetVariance',
            title: 'Gross Sales Variance (%)',
          },
          {
            key: 'ytdGrossSales',
            title: 'Gross Sales YTD ($)',
          },
          {
            key: 'grossSalesSssPercent',
            title: 'Gross Sales YOY (%)',
          },
          {
            key: 'covers',
            title: 'Guest Count Actual',
          },
          {
            key: 'coversTarget',
            title: 'Guest Count Target',
          },
          {
            key: 'coversVariancePercent',
            title: 'Guest Count Variance (%)',
          },
          {
            key: 'coversSssPercent',
            title: 'Guest Count YOY (%)',
          },
          {
            key: 'ppa',
            title: 'Guest Count PPA',
          },
          {
            key: 'revPsf',
            title: 'Rev Psf',
          },
          {
            key: 'cplh',
            title: 'Guest Count CPLH',
          },
        ],
      },
      part1: {
        type: 'table',
        api: 'lfrListLocationBusinessReview',
        pageSize: 20,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '220px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'Gross Sales',
            title: 'Gross Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'grossSales',
                title: 'Actual ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'grossSalesBudget',
                title: 'Target ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'grossSalesBudgetVariance',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'ytdGrossSales',
                title: 'YTD ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'grossSalesSssPercent',
                title: 'YOY (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Guest Count',
            title: 'Guest Count',
            align: 'center',
            children: [
              {
                key: 'covers',
                title: 'Actual',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversTarget',
                title: 'Target',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversVariancePercent',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversSssPercent',
                title: 'YOY (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'ppa',
                title: 'PPA',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
            ],
          },
          {
            key: 'revPsf',
            title: 'Rev Psf',
            align: 'center',
            sortable: true,
          },
          {
            key: 'cplh',
            title: 'CPLH',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
        ],
      },
      part2DownloadCsv: {
        type: 'download-csv',
        api: 'lfrListLocationBusinessReview',
        fileName: 'Part2',
        message: 'Download Table as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'foodSalesPercent',
            title: 'Sales Category Food Sales (%)',
          },
          {
            key: 'barSalesPercent',
            title: 'Sales Category Bar Sales (%)',
          },
          {
            key: 'retailSalesPercent',
            title: 'Sales Category Retail Sales (%)',
          },
          {
            key: 'otherSalesPercent',
            title: 'Sales Category Other Sales (%)',
          },
          {
            key: 'lunchDaypart',
            title: 'Day Part Lunch (%)',
          },
          {
            key: 'dinnerDaypart',
            title: 'Day Part Dinner (%)',
          },
          {
            key: 'patioReventueCenter',
            title: 'Revenue Center Patio (%)',
          },
        ],
      },
      part2: {
        type: 'table',
        api: 'lfrListLocationBusinessReview',
        pageSize: 20,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '220px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'Sales Category (%)',
            title: 'Sales Category (%)',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'foodSalesPercent',
                title: 'Food Sales (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'barSalesPercent',
                title: 'Bar Sales (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'retailSalesPercent',
                title: 'Retail Sales (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'otherSalesPercent',
                title: 'Other Sales (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day Part',
            title: 'Day Part',
            align: 'center',
            children: [
              {
                key: 'lunchDaypart',
                title: 'Lunch (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'dinnerDaypart',
                title: 'Dinner (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Revenue Center',
            title: 'Revenue Center',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'patioReventueCenter',
                title: 'Patio (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      part3DownloadCsv: {
        type: 'download-csv',
        api: 'lfrListLocationBusinessReview',
        fileName: 'Part3',
        message: 'Download Table as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'foodCost',
            title: 'Food Cost Actual (%)',
          },
          {
            key: 'foodCostTarget',
            title: 'Food Cost Target (%)',
          },
          {
            key: 'foodCostVariance',
            title: 'Food Cost Variance (%)',
          },
          {
            key: 'barCost',
            title: 'Bar Cost Actual (%)',
          },
          {
            key: 'barCostTarget',
            title: 'Bar Cost Target (%)',
          },
          {
            key: 'barCostVariance',
            title: 'Bar Cost Variance (%)',
          },
          {
            key: 'retailCost',
            title: 'Retail Cost Actual (%)',
          },
          {
            key: 'retailCostTarget',
            title: 'Retail Cost Target (%)',
          },
          {
            key: 'retailCostVariance',
            title: 'Retail Cost Variance (%)',
          },
        ],
      },
      part3: {
        type: 'table',
        api: 'lfrListLocationBusinessReview',
        pageSize: 20,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '220px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'Food Cost',
            title: 'Food Cost',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'foodCost',
                title: 'Actual (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'foodCostTarget',
                title: 'Target (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'foodCostVariance',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
                reverse: true,
              },
            ],
          },
          {
            key: 'Bar Cost',
            title: 'Bar Cost',
            align: 'center',
            children: [
              {
                key: 'barCost',
                title: 'Actual (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'barCostTarget',
                title: 'Target (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'barCostVariance',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
                reverse: true,
              },
            ],
          },
          {
            key: 'Retail Cost',
            title: 'Retail Cost',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'retailCost',
                title: 'Actual (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'retailCostTarget',
                title: 'Target (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'retailCostVariance',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
                reverse: true,
              },
            ],
          },
        ],
      },
      part4DownloadCsv: {
        type: 'download-csv',
        api: 'lfrListLocationBusinessReview',
        fileName: 'Part4',
        message: 'Download Table as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'fohLabor',
            title: 'FOH Labor Actual (%)',
          },
          {
            key: 'fohLaborTarget',
            title: 'FOH Labor Target (%)',
          },
          {
            key: 'fohLaborVariance',
            title: 'FOH Labor Variance (%)',
          },
          {
            key: 'bohLabor',
            title: 'BOH Labor Actual (%)',
          },
          {
            key: 'bohLaborTarget',
            title: 'BOH Labor Target (%)',
          },
          {
            key: 'bohLaborVariance',
            title: 'BOH Labor Variance (%)',
          },
          {
            key: 'fohOt',
            title: 'OT & Management FOH - OT ($)',
          },
          {
            key: 'bohOt',
            title: 'OT & Management BOH - OT ($)',
          },
          {
            key: 'keyEeHourly',
            title: 'OT & Management KEY EE - Hourly (%)',
          },
          {
            key: 'mgmtSalary',
            title: 'OT & Management Mgmt Salary (%)',
          },
        ],
      },
      part4: {
        type: 'table',
        api: 'lfrListLocationBusinessReview',
        pageSize: 20,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '220px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'FOH Labor',
            title: 'FOH Labor',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'fohLabor',
                title: 'Actual (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'fohLaborTarget',
                title: 'Target (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'fohLaborVariance',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
                reverse: true,
              },
            ],
          },
          {
            key: 'BOH Labor',
            title: 'BOH Labor',
            align: 'center',
            children: [
              {
                key: 'bohLabor',
                title: 'Actual (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'bohLaborTarget',
                title: 'Target (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'bohLaborVariance',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
                reverse: true,
              },
            ],
          },
          {
            key: 'OT & Management',
            title: 'OT & Management',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'fohOt',
                title: 'FOH - OT ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'bohOt',
                title: 'BOH - OT ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'keyEeHourly',
                title: 'KEY EE - Hourly (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'mgmtSalary',
                title: 'Mgmt Salary (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      part5DownloadCsv: {
        type: 'download-csv',
        api: 'lfrListLocationBusinessReview',
        fileName: 'Part5',
        message: 'Download Table as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'primeCostsPercent',
            title: 'Prime Cost Actual (%)',
          },
          {
            key: 'primeCostsTarget',
            title: 'Prime Cost Target (%)',
          },
          {
            key: 'primeCostsVariance',
            title: 'Prime Cost Variance (%)',
          },
          {
            key: 'cogsPercent',
            title: 'Prime Cost Total COGS (%)',
          },
          {
            key: 'laborPercent',
            title: 'Prime Cost Total Labor (%)',
          },
          {
            key: 'rcp',
            title: 'EBITDA Actual ($)',
          },
          {
            key: 'rcpPercent',
            title: 'EBITDA (%)',
          },
          {
            key: 'rcpBudgetVariance',
            title: 'EBITDA Variance (%)',
          },
        ],
      },
      part5: {
        type: 'table',
        api: 'lfrListLocationBusinessReview',
        pageSize: 20,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '220px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'Prime Cost',
            title: 'Prime Cost',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'primeCostsPercent',
                title: 'Actual (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'primeCostsTarget',
                title: 'Target (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'primeCostsVariance',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
                reverse: true,
              },
              {
                key: 'cogsPercent',
                title: 'Total COGS (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'laborPercent',
                title: 'Total Labor (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'EBITDA',
            title: 'EBITDA',
            align: 'center',
            children: [
              {
                key: 'rcp',
                title: 'Actual ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'rcpPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'rcpBudgetVariance',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      part6DownloadCsv: {
        type: 'download-csv',
        api: 'lfrListLocationBusinessReview',
        fileName: 'Part6',
        message: 'Download Table as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'paidOuts',
            title: 'Cash Controls Paid Outs ($)',
          },
          {
            key: 'supplies',
            title: 'Cash Controls Supplies ($)',
          },
          {
            key: 'voids',
            title: 'Cash Controls Voids',
          },
          {
            key: 'controllableComps',
            title: 'Total Comps Controllable Comps ($)',
          },
          {
            key: 'controllableCompsPercent',
            title: 'Total Comps Controllable Comps (%)',
          },
          {
            key: 'eeComps',
            title: 'Total Comps EE Comps ($)',
          },
          {
            key: 'corpComps',
            title: 'Total Comps Corp Comps ($)',
          },
          {
            key: 'marketingComps',
            title: 'Total Comps Marketing Comps ($)',
          },
        ],
      },
      part6: {
        type: 'table',
        api: 'lfrListLocationBusinessReview',
        pageSize: 20,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '220px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'Cash Controls',
            title: 'Cash Controls',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'paidOuts',
                title: 'Paid Outs ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'supplies',
                title: 'Supplies ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'voids',
                title: 'Voids',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Total Comps',
            title: 'Total Comps',
            align: 'center',
            children: [
              {
                key: 'controllableComps',
                title: 'Controllable Comps ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'controllableCompsPercent',
                title: 'Controllable Comps (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'eeComps',
                title: 'EE Comps ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'corpComps',
                title: 'Corp Comps ($)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'marketingComps',
                title: 'Marketing Comps ($)',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      part7DownloadCsv: {
        type: 'download-csv',
        api: 'lfrListLocationGuest',
        fileName: 'Part7',
        message: 'Download Table as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'ratings30.yelp',
            title: 'Guest Rating (Last 30 Days) Yelp',
          },
          {
            key: 'ratings30.google',
            title: 'Guest Rating (Last 30 Days) Google',
          },
          {
            key: 'ratings30.tripadvisor',
            title: 'Guest Rating (Last 30 Days) Trip Advisor',
          },
          {
            key: 'dayAvg30',
            title: 'Guest Rating (Last 30 Days) 30 Day Avg',
          },
          {
            key: 'ratings90.yelp',
            title: 'Guest Rating (Last 90 Days) Yelp',
          },
          {
            key: 'ratings90.google',
            title: 'Guest Rating (Last 90 Days) Google',
          },
          {
            key: 'ratings90.tripadvisor',
            title: 'Guest Rating (Last 90 Days) Trip Advisor',
          },
          {
            key: 'dayAvg90',
            title: 'Guest Rating (Last 90 Days) 90 Day Avg',
          },
        ],
      },
      part7: {
        type: 'table',
        api: 'lfrListLocationGuest',
        pageSize: 20,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '220px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'Guest Rating (Last 30 Days)',
            title: 'Guest Rating (Last 30 Days)',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'ratings30.yelp',
                title: 'Yelp',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'ratings30.google',
                title: 'Google',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'ratings30.tripadvisor',
                title: 'Trip Advisor',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'dayAvg30',
                title: '30 Day Avg',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'Guest Rating (Last 90 Days)',
            title: 'Guest Rating (Last 90 Days)',
            align: 'center',
            children: [
              {
                key: 'ratings90.yelp',
                title: 'Yelp',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'ratings90.google',
                title: 'Google',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'ratings90.tripadvisor',
                title: 'Trip Advisor',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'dayAvg90',
                title: '90 Day Avg',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
        ],
      },
      part8DownloadCsv: {
        type: 'download-csv',
        api: 'lfrListLocationBusinessReview',
        fileName: 'Part8',
        message: 'Download Table as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'pea',
            title: 'PEA Actual',
          },
          {
            key: 'peaVariancePercent',
            title: 'PEA Variance (%)',
          },
          {
            key: 'peaSssPercent',
            title: 'PEA YOY (%)',
          },
          {
            key: 'pta',
            title: 'PTA Actual',
          },
          {
            key: 'ptaVariancePercent',
            title: 'PTA Variance (%)',
          },
          {
            key: 'ptaSssPercent',
            title: 'PTA YOY (%)',
          },
          {
            key: 'ppa',
            title: 'PPA Actual',
          },
          {
            key: 'ppaVariancePercent',
            title: 'PPA Variance (%)',
          },
          {
            key: 'ppaSssPercent',
            title: 'PPA YOY (%)',
          },
        ],
      },
      part8: {
        type: 'table',
        api: 'lfrListLocationBusinessReview',
        pageSize: 20,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '220px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'PEA',
            title: 'PEA',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'pea',
                title: 'Actual',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'peaVariancePercent',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'peaSssPercent',
                title: 'YOY (%)',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
            ],
          },
          {
            key: 'PTA',
            title: 'PTA',
            align: 'center',
            children: [
              {
                key: 'pta',
                title: 'Actual',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'ptaVariancePercent',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'ptaSssPercent',
                title: 'YOY (%)',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
            ],
          },
          {
            key: 'PPA',
            title: 'PPA',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'ppa',
                title: 'Actual',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'ppaVariancePercent',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'ppaSssPercent',
                title: 'YOY (%)',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
            ],
          },
        ],
      },
      part9DownloadCsv: {
        type: 'download-csv',
        api: 'lfrListLocationBusinessReview',
        fileName: 'Part9',
        message: 'Download Table as CSV',
        sortedBy: 'groupInfo',
        fields: [
          'groupInfo',
          {
            key: 'entreeCount',
            title: 'Entree Actual',
          },
          {
            key: 'entreeCountVariancePercent',
            title: 'Entree Variance (%)',
          },
          {
            key: 'entreeCountSssPercent',
            title: 'Entree YOY (%)',
          },
          {
            key: 'transactionCount',
            title: 'Transaction Actual',
          },
          {
            key: 'transactionCountVariancePercent',
            title: 'Transaction Variance (%)',
          },
          {
            key: 'transactionCountSssPercent',
            title: 'Transaction YOY (%)',
          },
          {
            key: 'covers',
            title: 'Covers Actual TY',
          },
          {
            key: 'coversTarget',
            title: 'Covers Target TY ',
          },
          {
            key: 'yoyCovers',
            title: 'Covers Actual PY',
          },
          {
            key: 'coversVariancePercent',
            title: 'Covers Variance (%)',
          },
          {
            key: 'coversSssPercent',
            title: 'Covers YOY (%)',
          },
        ],
      },
      part9: {
        type: 'table',
        api: 'lfrListLocationBusinessReview',
        pageSize: 20,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '220px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'Entree',
            title: 'Entree',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'entreeCount',
                title: 'Actual',
                align: 'center',
                sortable: true,
              },
              {
                key: 'entreeCountVariancePercent',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'entreeCountSssPercent',
                title: 'YOY (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Transaction',
            title: 'Transaction',
            align: 'center',
            children: [
              {
                key: 'transactionCount',
                title: 'Actual',
                align: 'center',
                sortable: true,
              },
              {
                key: 'transactionCountVariancePercent',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'transactionCountSssPercent',
                title: 'YOY (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Covers',
            title: 'Covers',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'covers',
                title: 'Actual TY',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversTarget',
                title: 'Target TY',
                align: 'center',
                sortable: true,
              },
              {
                key: 'yoyCovers',
                title: 'Actual PY',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversVariancePercent',
                title: 'Variance (%)',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversSssPercent',
                title: 'YOY (%)',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default lfrConfig
