import { IApiDataType } from '../types'
import { api as bbbApi, configs as bbbConfigs } from './bbb'
import { api as commonApi, configs as commonConfigs } from './common'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...bbbConfigs,
  ...commonConfigs,
}

const api = {
  ...bbbApi,
  ...commonApi,
}

const useApi = (
  apiName: IApiKeyType,
): {
  data: IApiDataType
  loading: boolean
} => api[apiName]()

export default useApi
