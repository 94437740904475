import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'
import useTrendLocationGroupFinancialKpis, {
  trendLocationGroupFinancialKpisConfigs,
} from '../common/useTrendLocationGroupFinancialKpis'

const query = gql`
  query bbbWeeklySales($iInputParams: JSON!) {
    getCustomizedReport(
      iCustomizedReportName: "LIST_LOCATION_GROUP_FINANCIAL_KPIS"
      iInputParams: $iInputParams
    ) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`

export const bbbWeeklySalesConfigs = {
  date: trendLocationGroupFinancialKpisConfigs.date,
  avgAuv: trendLocationGroupFinancialKpisConfigs.avgWeeklySales,
  auv: trendLocationGroupFinancialKpisConfigs.avgWeeklySales,
} as const

const useBbbWeeklySales = () => {
  const { data, loading } = useTrendLocationGroupFinancialKpis()
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data: weeklySalesData, loading: weeklySalesLoading } = useQuery(
    query,
    {
      variables: {
        iInputParams: {
          startDate: startDate,
          endDate: endDate,
          locationGroupIds: groupFilter?.ids,
        },
      },
      skip: !startDate || !endDate || !groupFilter,
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const totalAvgWeeklySales =
        weeklySalesData?.getCustomizedReport?.nodes[0]?.reportResult
          .tableData?.[0]?.avgWeeklySales
      const startIndex = data.findIndex(
        (d) =>
          ![null, undefined].includes(d.avgWeeklySales as null | undefined),
      )
      const endIndex =
        data.length -
        [...data]
          .reverse()
          .findIndex(
            (d) =>
              ![null, undefined].includes(d.avgWeeklySales as null | undefined),
          )

      return data.map(({ avgWeeklySales, ...d }, index) => ({
        ...d,
        auv: avgWeeklySales === null ? null : (avgWeeklySales as number) * 52,
        avgAuv:
          index >= startIndex && index < endIndex
            ? (totalAvgWeeklySales as number) * 52
            : null,
      }))
    }, [data, weeklySalesData]),
    loading: loading || weeklySalesLoading,
  }
}

export default useBbbWeeklySales
