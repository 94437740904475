import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

const defaultConfig: any = {
  dailyFlash: {
    storeCode: {},
    directorName: {},
    netSales: {},
    percentVsLY: {},
    customerPercentVsLy: {},
    totalCheckCount: {},
    totalCheckAverage: {},
    credits: {},
  },
  customize: false,
  shouldUseGroupSelector: false,
}

const irmgConfig = {
  dailyFlash: {
    storeCode: {},
    directorName: {},
    netSales: {},
    percentVsLY: {},
    customerPercentVsLy: {},
    totalCheckCount: {},
    totalCheckAverage: {},
    cashOverOrShort: {},
  },
  customize: false,
  customTables: [],
  shouldUseGroupSelector: false,
}

const jitbConfig = {
  dailyFlash: {
    storeCode: {},
    directorName: {},
    netSales: {},
    percentVsLY: {},
    totalCheckCount: { title: 'Customer #' },
    checkCountDiffVsLy: { title: 'Customer # vs LY' },
    customerPercentVsLY: { title: 'Customer % vs LY' },
    totalCheckAverage: {},
    cashOverOrShort: {},
  },
  customize: true,
  customTables: [],
  shouldUseGroupSelector: false,
}

const vikingConfig = {
  dailyFlash: {
    storeCode: {},
    directorName: {},
    netSales: {},
    percentVsLY: {},
    totalCheckCount: { title: 'Check Count' },
    checkCountDiffVsLy: { title: 'Check Count vs LY' },
    totalCheckAverage: {},
    cashOverOrShort: {},
  },
  customize: true,
  customTables: [],
  shouldUseGroupSelector: false,
}

const supremePopConfig = {
  dailyFlash: {
    storeCode: {},
    directorName: {},
    locationGroupName: {},
    netSales: {},
    percentVsLY: {},
    totalCheckCount: { title: 'Check Count' },
    checkCountDiffVsLy: { title: 'Check Count vs LY' },
    totalCheckAverage: {},
    cashOverOrShort: {},
  },
  customize: false,
  customTables: [],
  shouldUseGroupSelector: true,
}

const supremeConfig = {
  ...irmgConfig,
  customize: true,
  dailyFlash: {
    storeCode: {},
    directorName: {},
    locationGroupName: {},
    netSales: {},
    percentVsLY: {},
    customerPercentVsLY: {},
    totalCheckCount: { title: 'Checks' },
    totalCheckAverage: {},
    cashOverOrShort: {},
  },
  shouldUseGroupSelector: true,
}

const jscConfig = {
  ...irmgConfig,
  dailyFlash: {
    storeCode: {},
    directorName: {},
    netSales: {},
    percentVsLY: {},
    customerPercentVsLy: {},
    totalCheckAverage: {},
    locationGroupName: {},
    totalCheckCount: { title: 'Guests' },
    checkCountDiffVsLy: { title: 'Guests vs LY' },
    customerPercentVsLY: { title: 'Guests % vs LY' },
    yoyNetSales: {},
    yoyTotalCheckCount: { title: 'Guests LY' },
    netSalesDiffVsLy: {},
  },
  shouldUseGroupSelector: true,
}

const ghaiPopConfig = {
  ...irmgConfig,
  dailyFlash: {
    storeCode: {},
    directorName: {},
    netSales: {},
    percentVsLY: {},
    customerPercentVsLy: {},
    totalCheckAverage: {},
    locationGroupName: {},
    totalCheckCount: { title: 'Check Count' },
    checkCountDiffVsLy: { title: 'Check Count vs LY' },
    customerPercentVsLY: { title: 'Check Count % vs LY' },
    yoyNetSales: {},
    yoyTotalCheckCount: { title: 'Check Count LY' },
    netSalesDiffVsLy: {},
  },
  shouldUseGroupSelector: true,
}

const wingstopConfig: any = {
  dailyFlash: {
    storeCode: {},
    netSales: {},
    totalCheckCount: { title: 'Checks' },
    totalCheckAverage: { title: 'Check Avg' },
    totalComps: {},
  },
  customize: false,
  customTables: ['chickenInventory'],
}

const wingitnorthConfig = {
  ...defaultConfig,
  dailyFlash: {
    storeCode: {},
    directorName: {},
    netSales: {},
    percentVsLY: {},
    customerPercentVsLy: {},
    totalCheckAverage: {},
    locationGroupName: {},
    totalCheckCount: { title: 'Checks' },
    checkCountDiffVsLy: { title: 'Checks vs LY' },
    customerPercentVsLY: { title: 'Checks % vs LY' },
    yoyNetSales: {},
    yoyTotalCheckCount: { title: 'Checks LY' },
    netSalesDiffVsLy: {},
    cashOverOrShort: {},
  },
  customTables: ['wingitnorthCustomFlash', 'chickenCount', 'bottomTen'],
}

const useFlashConfig = () => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'irmg_bk':
      case 'demo_bk':
        return irmgConfig
      case 'irmg_pop':
      case 'demo_pop':
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return jscConfig
      case 'ghai_pop':
        return ghaiPopConfig
      case 'viking_bk':
      case 'sandbox_bk':
      case 'timoney_bk':
        return vikingConfig
      case 'supreme_pop':
        return supremePopConfig
      case 'mbn_bk':
      case 'supreme_bk':
        return supremeConfig
      case 'wingitnorth':
        return wingitnorthConfig
      case 'wingstop':
        return wingstopConfig
      case 'jitb':
        return jitbConfig
      case 'mwb':
        return {
          ...defaultConfig,
          dailyFlash: {
            storeCode: {},
            directorName: {},
            locationGroupName: {},
            netSales: {},
            percentVsLY: {},
            customerPercentVsLy: {},
            totalCheckCount: {},
            totalCheckAverage: {},
          },
          customize: true,
          shouldUseGroupSelector: true,
        }
      default:
        return defaultConfig
    }
  }, [brand])
}

export default useFlashConfig
