import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'

import BreakdownTable from './BreakdownTable'
import OpentableBreakdownTable from './OpentableBreakdownTable'
import OpsCompsTrendChart from './OpsCompsTrendChart'
import RatingsChart from './RatingsChart'
import SmgTable from './SmgTable'
import { IGlobalGuestKpi, IGlobalGuestOpentableKpi } from './index'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector | null
  navParams: INavParams
  onDirectorChange: (directorEmployeeId: number) => Promise<void>
  selectedDateRange: IDateRange | null
  globalGuestKpiSummary: IGlobalGuestKpi
  selectedLocationGroupId: number
  breakdownType: string | null
  globalGuestOpentableKpis: IGlobalGuestOpentableKpi
}

interface IKpiTh {
  danger?: boolean
  success?: boolean
  book?: boolean
  isBackgroundGrey?: boolean
}

function WebLargeMain({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  globalGuestKpiSummary,
  selectedLocationGroupId,
  breakdownType,
  globalGuestOpentableKpis,
}: IProps) {
  const guestConfig = getBrandSettings().guest

  const acrScore = _.get(globalGuestKpiSummary, 'acr.ratingScore', '')
  const acrRatingCountStr = _.get(
    globalGuestKpiSummary,
    'acr.ratingCountStr',
    '',
  )

  const momentfeedRatingScore = _.get(
    globalGuestKpiSummary,
    'momentfeed.ratingScore',
    '',
  )
  const momentfeedRatingCountStr = _.get(
    globalGuestKpiSummary,
    'momentfeed.ratingCountStr',
    '',
  )

  const yelpRatingScore = _.get(globalGuestKpiSummary, 'yelp.ratingScore', '')
  const yelpRatingCountStr = _.get(
    globalGuestKpiSummary,
    'yelp.ratingCountStr',
    '',
  )

  const googleRatingScore = _.get(
    globalGuestKpiSummary,
    'google.ratingScore',
    '',
  )
  const googleRatingCountStr = _.get(
    globalGuestKpiSummary,
    'google.ratingCountStr',
    '',
  )

  const facebookRatingScore = _.get(
    globalGuestKpiSummary,
    'facebook.ratingScore',
    '',
  )
  const facebookRatingCountStr = _.get(
    globalGuestKpiSummary,
    'facebook.ratingCountStr',
    '',
  )

  const deliveryRatingScore = _.get(
    globalGuestKpiSummary,
    'delivery.ratingScore',
    '',
  )
  const deliveryRatingCountStr = _.get(
    globalGuestKpiSummary,
    'delivery.ratingCountStr',
    '',
  )

  const allRatingScore = _.get(globalGuestKpiSummary, 'all.ratingScore', '')
  const allRatingCountStr = _.get(
    globalGuestKpiSummary,
    'all.ratingCountStr',
    '',
  )

  const dissatisfactionScore = _.get(
    globalGuestKpiSummary,
    'dissatisfaction.ratingScore',
    '',
  )
  const dissatisfactionCountStr = _.get(
    globalGuestKpiSummary,
    'dissatisfaction.ratingCountStr',
    '',
  )

  const inaccurateScore = _.get(
    globalGuestKpiSummary,
    'inaccurate.ratingScore',
    '',
  )
  const inaccurateCountStr = _.get(
    globalGuestKpiSummary,
    'inaccurate.ratingCountStr',
    '',
  )

  const overallSatisfactionScore = _.get(
    globalGuestKpiSummary,
    'overallSatisfaction.ratingScore',
    '',
  )
  const overallSatisfactionCountStr = _.get(
    globalGuestKpiSummary,
    'overallSatisfaction.ratingCountStr',
    '',
  )

  const orderNotOnTimeScore = _.get(
    globalGuestKpiSummary,
    'orderNotOnTime.ratingScore',
    '',
  )
  const orderNotOnTimeCountStr = _.get(
    globalGuestKpiSummary,
    'orderNotOnTime.ratingCountStr',
    '',
  )

  const tasteOfFoodScore = _.get(
    globalGuestKpiSummary,
    'tasteOfFood.ratingScore',
    '',
  )
  const tasteOfFoodCountStr = _.get(
    globalGuestKpiSummary,
    'tasteOfFood.ratingCountStr',
    '',
  )

  const opentableRatingScore = _.get(
    globalGuestOpentableKpis,
    'ratingScore',
    '',
  )
  const opentableRatingCount = _.get(
    globalGuestOpentableKpis,
    'ratingCount',
    '',
  )
  const opentableRatingScoreChange = _.get(
    globalGuestOpentableKpis,
    'ratingScoreChange',
    '',
  )
  const opentableSeatedCovers = _.get(
    globalGuestOpentableKpis,
    'seatedCovers',
    '',
  )
  const opentableSeatedCoversChange = _.get(
    globalGuestOpentableKpis,
    'seatedCoversChange',
    '',
  )
  const opentablePhoneCovers = _.get(
    globalGuestOpentableKpis,
    'phoneCovers',
    '',
  )
  const opentablePhoneCoversChange = _.get(
    globalGuestOpentableKpis,
    'phoneCoversChange',
    '',
  )

  const opentableWalkinCovers = _.get(
    globalGuestOpentableKpis,
    'walkinCovers',
    '',
  )
  const opentableWalkinCoversChange = _.get(
    globalGuestOpentableKpis,
    'walkinCoversChange',
    '',
  )
  const opentableYourNetworkCovers = _.get(
    globalGuestOpentableKpis,
    'yourNetworkCovers',
    '',
  )
  const opentableYourNetworkCoversChange = _.get(
    globalGuestOpentableKpis,
    'yourNetworkCoversChange',
    '',
  )
  const opentableOpentableCovers = _.get(
    globalGuestOpentableKpis,
    'opentableCovers',
    '',
  )
  const opentableOpentableCoversChange = _.get(
    globalGuestOpentableKpis,
    'opentableCoversChange',
    '',
  )
  const opentableNoShowRate = _.get(globalGuestOpentableKpis, 'noShowRate', '')
  const opentableNoShowRateChange = _.get(
    globalGuestOpentableKpis,
    'noShowRateChange',
    '',
  )

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let breakdownTitle = `BREAKDOWN BY ${getBusinessLabel('store').toUpperCase()}`
  if (guestConfig.kpiDisplayName) {
    breakdownTitle = guestConfig.kpiDisplayName
  } else if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    breakdownTitle = `BREAKDOWN BY ${getBusinessLabel(
      'director',
    ).toUpperCase()}`
  } else if (breakdownType) {
    breakdownTitle = `BREAKDOWN BY ${breakdownType}`.toUpperCase()
  }

  return (
    <MainContainer>
      <PageHeaderContainer>
        <LeftHeaderContainer>
          <PageHeaderLineContainer>
            <PageLeftHeader>Systemwide Guest</PageLeftHeader>
            <SelectorContainer>
              <DirectorFilterSelectorContainer>
                {selectedDirector ? (
                  <DirectorFilterSelector
                    allDirectors={allDirectors}
                    selectedDirector={selectedDirector}
                    onDirectorChange={onDirectorChange}
                  />
                ) : null}
              </DirectorFilterSelectorContainer>
              <DateRangeSelector
                navParams={navParams}
                excludedDateRanges={[TYPE_YESTERDAY]}
                preSelectedDateRange={TYPE_PERIOD}
              />
            </SelectorContainer>
          </PageHeaderLineContainer>
        </LeftHeaderContainer>
        {guestConfig.isUpdatedWeekly ? (
          <SubtitleContainer>Updated Weekly</SubtitleContainer>
        ) : null}
      </PageHeaderContainer>

      {guestConfig.hideKpis ? null : (
        <>
          <KpiContainer>
            <KpiOverviewTable>
              <tbody>
                <tr>
                  <PtdKpiCategoryTd>
                    <PtdKpiCategoryNameDiv>
                      {guestConfig.kpiDisplayName || 'KPIs'}
                    </PtdKpiCategoryNameDiv>
                  </PtdKpiCategoryTd>
                  <PtdOverviewSpacerTd></PtdOverviewSpacerTd>
                </tr>
                <tr>
                  <td>
                    <PtdKpiTable>
                      <thead>
                        <tr>
                          {guestConfig.smgMwb?.isVisible ? (
                            <>
                              <td>SMG Overall Satisfaction Rating</td>
                              <td>SMG Time to Receive Order Rating</td>
                              <td>SMG Taste of Food Rating</td>
                            </>
                          ) : null}
                          {guestConfig.acr.isVisible ? (
                            <td>{'ACR Score'}</td>
                          ) : null}
                          {guestConfig.momentfeed ? (
                            <td>{'Momentfeed Rating'}</td>
                          ) : null}
                          {guestConfig.yelp ? <td>{'Yelp Rating'}</td> : null}
                          <td>{'Google Rating'}</td>
                          {guestConfig.facebook ? (
                            <td>{'Facebook Rating'}</td>
                          ) : null}
                          {guestConfig.delivery ? (
                            <td>{'Delivery Rating'}</td>
                          ) : null}
                          {guestConfig.allRating.isVisible ? (
                            <td>{guestConfig.allRating.displayName} Rating</td>
                          ) : null}
                          {guestConfig.smg?.isVisible ? (
                            <td>Dissatisfaction %</td>
                          ) : null}
                          {guestConfig.smg?.isVisible ? (
                            <td>Inaccurate %</td>
                          ) : null}
                        </tr>
                        <tr>
                          {guestConfig.smgMwb?.isVisible ? (
                            <>
                              <KpiTh book={acrScore === 'loading'}>
                                {overallSatisfactionScore}
                              </KpiTh>
                              <KpiTh book={acrScore === 'loading'}>
                                {orderNotOnTimeScore}
                              </KpiTh>
                              <KpiTh book={acrScore === 'loading'}>
                                {tasteOfFoodScore}
                              </KpiTh>
                            </>
                          ) : null}
                          {guestConfig.acr.isVisible ? (
                            <KpiTh book={acrScore === 'loading'}>
                              {acrScore}
                            </KpiTh>
                          ) : null}
                          {guestConfig.momentfeed ? (
                            <KpiTh book={momentfeedRatingScore === 'loading'}>
                              {momentfeedRatingScore}
                            </KpiTh>
                          ) : null}
                          {guestConfig.yelp ? (
                            <KpiTh book={yelpRatingScore === 'loading'}>
                              {yelpRatingScore}
                            </KpiTh>
                          ) : null}
                          <KpiTh book={googleRatingScore === 'loading'}>
                            {googleRatingScore}
                          </KpiTh>
                          {guestConfig.facebook ? (
                            <KpiTh book={facebookRatingScore === 'loading'}>
                              {facebookRatingScore}
                            </KpiTh>
                          ) : null}
                          {guestConfig.delivery ? (
                            <KpiTh book={deliveryRatingScore === 'loading'}>
                              {deliveryRatingScore}
                            </KpiTh>
                          ) : null}
                          {guestConfig.allRating.isVisible ? (
                            <KpiTh book={allRatingScore === 'loading'}>
                              {allRatingScore}
                            </KpiTh>
                          ) : null}
                          {guestConfig.smg?.isVisible ? (
                            <KpiTh book={dissatisfactionScore === 'loading'}>
                              {dissatisfactionScore}
                            </KpiTh>
                          ) : null}
                          {guestConfig.smg?.isVisible ? (
                            <KpiTh book={inaccurateScore === 'loading'}>
                              {inaccurateScore}
                            </KpiTh>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {guestConfig.smgMwb?.isVisible ? (
                            <>
                              <td>{overallSatisfactionCountStr}</td>
                              <td>{orderNotOnTimeCountStr}</td>
                              <td>{tasteOfFoodCountStr}</td>
                            </>
                          ) : null}
                          {guestConfig.acr.isVisible ? (
                            <td>{acrRatingCountStr}</td>
                          ) : null}
                          {guestConfig.momentfeed ? (
                            <td>{momentfeedRatingCountStr}</td>
                          ) : null}
                          {guestConfig.yelp ? (
                            <td>{yelpRatingCountStr}</td>
                          ) : null}
                          <td>{googleRatingCountStr}</td>
                          {guestConfig.facebook ? (
                            <td>{facebookRatingCountStr}</td>
                          ) : null}
                          {guestConfig.delivery ? (
                            <td>{deliveryRatingCountStr}</td>
                          ) : null}
                          {guestConfig.allRating.isVisible ? (
                            <td>{allRatingCountStr}</td>
                          ) : null}
                          {guestConfig.smg?.isVisible ? (
                            <td>{dissatisfactionCountStr}</td>
                          ) : null}
                          {guestConfig.smg?.isVisible ? (
                            <td>{inaccurateCountStr}</td>
                          ) : null}
                        </tr>
                      </tbody>
                    </PtdKpiTable>
                  </td>
                </tr>
              </tbody>
            </KpiOverviewTable>
          </KpiContainer>

          <VerticalSpacer60px />
        </>
      )}

      {guestConfig.opentable?.isVisible ? (
        <>
          <KpiContainer>
            <KpiOverviewTable>
              <tbody>
                <tr>
                  <PtdKpiCategoryTd>
                    <CoversCategoryNameDiv>Opentable</CoversCategoryNameDiv>
                  </PtdKpiCategoryTd>
                  <PtdOverviewSpacerTd></PtdOverviewSpacerTd>
                </tr>
                <tr>
                  <td>
                    <CoversTable>
                      <thead>
                        <tr>
                          <td>Rating</td>
                          <td>Seated Covers</td>
                          <td>OpenTable Network</td>
                          <td>Your Network</td>
                          <td>Phone</td>
                          <td>Walk-in</td>
                          <td>No-show rate</td>
                        </tr>
                        <tr>
                          <KpiTh book={opentableRatingScore === 'loading'}>
                            {opentableRatingScore}
                          </KpiTh>
                          <KpiTh book={opentableSeatedCovers === 'loading'}>
                            {opentableSeatedCovers}
                          </KpiTh>
                          <KpiTh book={opentableOpentableCovers === 'loading'}>
                            {opentableOpentableCovers}
                          </KpiTh>
                          <KpiTh
                            book={opentableYourNetworkCovers === 'loading'}
                          >
                            {opentableYourNetworkCovers}
                          </KpiTh>
                          <KpiTh book={opentablePhoneCovers === 'loading'}>
                            {opentablePhoneCovers}
                          </KpiTh>
                          <KpiTh book={opentableWalkinCovers === 'loading'}>
                            {opentableWalkinCovers}
                          </KpiTh>
                          <KpiTh book={opentableNoShowRate === 'loading'}>
                            {opentableNoShowRate}
                          </KpiTh>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{opentableRatingScoreChange}</td>
                          <td>{opentableSeatedCoversChange}</td>
                          <td>{opentableOpentableCoversChange}</td>
                          <td>{opentableYourNetworkCoversChange}</td>
                          <td>{opentablePhoneCoversChange}</td>
                          <td>{opentableWalkinCoversChange}</td>
                          <td>{opentableNoShowRateChange}</td>
                        </tr>
                      </tbody>
                    </CoversTable>
                  </td>
                </tr>
              </tbody>
            </KpiOverviewTable>
          </KpiContainer>

          <VerticalSpacer60px />
        </>
      ) : null}

      {guestConfig.hideRatingChart ? null : (
        <>
          <SectionTitleDiv>RATINGS TREND</SectionTitleDiv>
          {selectedDateRange ? (
            <RatingsChart
              endDate={selectedDateRange.endDateStr}
              dateRangeType={selectedDateRange.type}
              locationGroupId={selectedLocationGroupId}
            />
          ) : null}

          <VerticalSpacer60px />
        </>
      )}

      {!guestConfig.opsComps ? null : (
        <>
          <SectionTitleDiv>OPS COMPS TREND</SectionTitleDiv>

          <OpsCompsTrendChart
            navParams={navParams}
            selectedDateRange={selectedDateRange}
            selectedDirector={selectedDirector}
          />

          <VerticalSpacer60px />
        </>
      )}

      {guestConfig.hideRatingChart ? null : (
        <>
          <SectionTitleDiv>{`SOCIAL ${breakdownTitle}`}</SectionTitleDiv>
          {selectedDateRange && selectedDirector ? (
            <>
              <BreakdownTable
                startDate={selectedDateRange.startDateStr}
                endDate={selectedDateRange.endDateStr}
                locationGroupId={selectedLocationGroupId}
                locationGroupIds={allDirectors.map(
                  ({ locationGroupId }) => locationGroupId,
                )}
                isBreakdownByDirectors={selectedDirector.employeeId === -2}
                breakdownType={breakdownType}
              />
              <VerticalSpacer60px />
            </>
          ) : null}
        </>
      )}

      {guestConfig.smg?.isVisible ? (
        <>
          <SectionTitleDiv>{`SMG ${breakdownTitle}`}</SectionTitleDiv>
          <SmgTable
            locationGroupId={selectedLocationGroupId}
            isBreakdownByDirectors={selectedDirector?.employeeId === -2}
            breakdownType={breakdownType}
          />
        </>
      ) : null}

      {selectedDateRange &&
      selectedDirector &&
      guestConfig.opentable?.isVisible ? (
        <>
          <VerticalSpacer60px />
          <SectionTitleDiv>Opentable</SectionTitleDiv>
          <OpentableBreakdownTable
            startDate={selectedDateRange.startDateStr}
            endDate={selectedDateRange.endDateStr}
            locationGroupId={selectedLocationGroupId}
            locationGroupIds={allDirectors.map(
              ({ locationGroupId }) => locationGroupId,
            )}
            isBreakdownByDirectors={selectedDirector.employeeId === -2}
            breakdownType={breakdownType}
          />
        </>
      ) : null}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 1200px;
  padding: 0 0 50px 0;
`

const DirectorFilterSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

const KpiContainer = styled.div`
  display: inline-block;
`

const KpiOverviewTable = styled.table``

const PtdKpiCategoryTd = styled.td`
  vertical-align: top;
`

const PtdKpiCategoryNameDiv = styled.div`
  width: 700px;
  text-align: center;
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid ${COLORS.Chalkboard};
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const PtdKpiTable = styled.table`
  width: 700px;
  text-align: center;

  thead th {
    font-style: normal;
    min-width: 60px;
  }

  tbody td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    vertical-align: top;
    height: 60px;
  }
`

const PtdOverviewSpacerTd = styled.td`
  width: 40px;
`

const KpiTh = styled.th<IKpiTh>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-weight: ${(props) => (props.book ? '400' : '700')};
`

const CoversCategoryNameDiv = styled.div`
  width: 900px;
  text-align: center;
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid ${COLORS.Chalkboard};
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const CoversTable = styled.table`
  width: 900px;
  text-align: center;

  thead th {
    font-style: normal;
    min-width: 60px;
  }

  tbody td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    vertical-align: top;
    height: 60px;
  }
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
`

const PageHeaderContainer = styled.div`
  padding: 0 0 50px 0;
  margin: 0 0 50px 0;
`

const LeftHeaderContainer = styled.div`
  float: left;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

export default WebLargeMain
