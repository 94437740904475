import useAllStores, { IDataType as IAllStoresDataType } from './useAllStores'
import useCorporateGroup, {
  IDataType as ICorporateGroupDataType,
} from './useCorporateGroup'
import useDate, { IDataType as IDateDataType } from './useDate'
import useTrack, { IDataType as ITrackDataType } from './useTrack'

export type IVariablesType = 'track' | 'date' | 'allStores' | 'corporateGroup'
export type IDataType = IDateDataType &
  IAllStoresDataType &
  ITrackDataType &
  ICorporateGroupDataType

export const hooks = [
  ['track', useTrack],
  ['date', useDate],
  ['allStores', useAllStores],
  ['corporateGroup', useCorporateGroup],
]
