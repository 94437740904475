import useListLocationGroupSystemwideOverview, {
  listLocationGroupSystemwideOverviewConfigs,
} from './useListLocationGroupSystemwideOverview'
import useListLocationSystemwideOverview, {
  listLocationSystemwideOverviewConfigs,
} from './useListLocationSystemwideOverview'

export const systemwideOverviewConfigs = {
  ...listLocationSystemwideOverviewConfigs,
  ...listLocationGroupSystemwideOverviewConfigs,
}

const useSystemwideOverview = () => {
  const listLocationSystemwideOverview = useListLocationSystemwideOverview()
  const listLocationGroupSystemwideOverview =
    useListLocationGroupSystemwideOverview()

  return listLocationGroupSystemwideOverview.data
    ? listLocationGroupSystemwideOverview
    : listLocationSystemwideOverview
}

export default useSystemwideOverview
